import React, { useEffect, useRef, useState } from "react";
import { useParams } from "react-router-dom";
import {
  FETCH_AUTH_ADS_CLIENTS,
  SAVE_AUTH_ADS_CLIENTS,
} from "../../constant/Apipath";
import { PostRequestCall } from "../../apicall/PostRequest";
import { Input, Switch } from "@progress/kendo-react-inputs";
import { useSelector } from "react-redux";
import { GridLayout, GridLayoutItem } from "@progress/kendo-react-layout";
import { Label } from "@progress/kendo-react-labels";
import { toast } from "react-toastify";
import { Loader } from "@progress/kendo-react-indicators";
import { Form } from "@progress/kendo-react-form";
import { IconComponent } from "../../common/Icon";
import AddPageLayout from "../../addpagelayout/AddPageLayout";
import RecordStatusInput from "../../common/RecordStatusInput";
const AddClientAuth = () => {
  const { clientId, subMenu, userId } = useParams();
  const { loginData } = useSelector((state) => state.main);
  const isAddForm = Number(clientId) === 0;
  const [showLoader, setShowLoader] = useState(false);
  const [isFieldEdited, setIsFiledEdited] = useState(false);
  const autoCompleteRef = useRef(null);
  const [clientAdsAuth, setClientAdsAuth] = useState({
    id: 0,
    recordStatusId: 1,
    recordStatus: "New",
    firstName: "",
    lastName: "",
    userEmail: "",
    active: true,
    pictureUrl: null,
    prevousImageUrl: null,
    businessName: "",
    contactNumber: "",
    address: "",
    city: "",
    province: "",
    country: "",
    postalCode: "",
    searchAddress: "",
  });

  const loadScript = (url, callback) => {
    let script = document.createElement("script");
    script.type = "text/javascript";

    if (script.readyState) {
      script.onreadystatechange = function () {
        if (
          script.readyState === "loaded" ||
          script.readyState === "complete"
        ) {
          script.onreadystatechange = null;
          callback();
        }
      };
    } else {
      script.onload = () => callback();
    }

    script.src = url;
    document.getElementsByTagName("head")[0].appendChild(script);
  };

  const handleScriptLoad = (autoCompleteRef) => {
    const autoComplete = new window.google.maps.places.Autocomplete(
      autoCompleteRef.current
    );
    autoComplete.setFields(["address_components", "formatted_address"]);

    autoComplete.addListener("place_changed", () => {
      const place = autoComplete.getPlace();
      const addressComponents = place.address_components;

      let address = "";
      let city = "";
      let province = "";
      let postalCode = "";
      let country = "";

      addressComponents.forEach((component) => {
        switch (component.types[0]) {
          case "street_number":
            address += component.long_name + ", ";
            break;
          case "route":
            address += component.long_name + ", ";
            break;
          case "locality":
            city = component.long_name;
            break;
          case "administrative_area_level_1":
            province = component.long_name;
            break;
          case "postal_code":
            postalCode = component.long_name;
            break;
          case "country":
            country = component.long_name;
            break;
          default:
            break;
        }
      });

      setClientAdsAuth((prevState) => ({
        ...prevState,
        address: address,
        city: city,
        province: province,
        postalCode: postalCode,
        country: country,
      }));
    });
  };

  useEffect(() => {
    loadScript(
      `https://maps.googleapis.com/maps/api/js?key=AIzaSyDFLELJHL-t3S5DkTgHRPWVBNpg8uW76Kc&libraries=places`,
      () => handleScriptLoad(autoCompleteRef)
    );
  }, []);

  useEffect(() => {
    if (!isAddForm) {
      loadClientAdsAuthData();
    }
  }, []);

  const loadClientAdsAuthData = async () => {
    if (!isAddForm) {
      var obj = JSON.stringify({
        PageNr: 1,
        NrOfRecPerPage: 20,
        FullSearch: "",
        SearchList: [
          {
            Id: clientId,
          },
        ],
        SortList: [
          {
            FieldName: "Id",
            Direction: "ASC",
          },
        ],
        IncludeRecordNr: true,
      });

      const result = await PostRequestCall(
        FETCH_AUTH_ADS_CLIENTS,
        obj,
        loginData?.token
      );
      let data = result?.data?.data?.[0] ?? null;
      setClientAdsAuth((prevData) => ({
        ...prevData,
        ...data,
        pictureUrl:
          data?.pictureUrl === "" || data?.pictureUrl === null
            ? null
            : data?.pictureUrl,
        prevousImageUrl:
          data?.pictureUrl === "" || data?.pictureUrl === null
            ? null
            : data?.pictureUrl,
      }));
    }
  };

  const onInputChange = (e, type) => {
    let inputValue = e.target.value;

    setIsFiledEdited(true);
    setClientAdsAuth((prevData) => ({
      ...prevData,
      [e.target.name]: inputValue,
    }));
  };

  const onSelectImage = (e) => {
    const file = e?.target?.files?.[0] ?? null;
    if (file) {
      setClientAdsAuth((preValue) => ({ ...preValue, pictureUrl: file }));
    }
    e.target.value = "";
  };

  const checkImg = (pictureUrl) => {
    try {
      if (Object.keys(pictureUrl)?.length === 0) {
        return URL.createObjectURL(pictureUrl);
      } else {
        return pictureUrl;
      }
    } catch (error) {
      console.log("Error while getting front picture url ::", error);
    }
  };

  const onRemoveFrontImg = () => {
    setIsFiledEdited(true);
    setClientAdsAuth((preValue) => ({ ...preValue, pictureUrl: null }));
  };

  const onChooseImageClick = (e) => {
    e.preventDefault();
    setIsFiledEdited(true);
    document.getElementById("client_ads_auth_picture").click();
  };

  const saveCurrentData = async () => {
    const {
      firstName,
      lastName,
      userEmail,
      pictureUrl,
      prevousImageUrl,
      active,
      businessName,
      contactNumber,
      address,
      city,
      province,
      country,
      postalCode,
      recordStatusId,
    } = clientAdsAuth || {};

    // Validate required fields
    if (!firstName.trim()) {
      toast.error("First Name is required", { position: "top-right" });
      return;
    }
    if (!lastName.trim()) {
      toast.error("Last Name is required", { position: "top-right" });
      return;
    }
    if (!userEmail.trim()) {
      toast.error("User Email is required", { position: "top-right" });
      return;
    }

    setShowLoader(true);

    const fd = new FormData();
    fd.append("Id", isAddForm ? 0 : clientId);
    fd.append("FirstName", firstName || "");
    fd.append("LastName", lastName || "");
    fd.append("UserEmail", userEmail || "");
    fd.append("Active", active ? "true" : "false");
    fd.append("BusinessName", businessName || "");
    fd.append("ContactNumber", contactNumber || "");
    fd.append("Address", address || "");
    fd.append("City", city || "");
    fd.append("Province", province || "");
    fd.append("Country", country || "");
    fd.append("PostalCode", postalCode || "");
    fd.append("RecordStatusId", recordStatusId);
    fd.append("ModifiedBy", loginData?.userId);

    if (pictureUrl) {
      if (pictureUrl !== prevousImageUrl) {
        fd.append("files", pictureUrl);
      }
    } else {
      fd.append("ForceRemovePicture", true);
    }

    try {
      const result = await PostRequestCall(
        SAVE_AUTH_ADS_CLIENTS,
        fd,
        loginData.token,
        "formData"
      );
      setShowLoader(false);
      if (result.data.status === false) {
        toast.error(
          result?.data?.message ??
            "Something went wrong. Please try again later.",
          { position: "top-right" }
        );
      } else {
        toast.success(`Data ${!isAddForm ? "updated" : "added"} successfully`, {
          position: "top-right",
        });
        window.history.back();
      }
    } catch (error) {
      setShowLoader(false);
      toast.error("Something went wrong. Please try again later.", {
        position: "top-right",
      });
      console.error("Error saving data :", error);
    }
  };

  if (showLoader) {
    return (
      <Loader
        type="converging-spinner"
        className="kendo-spinner"
        style={{
          display: "flex",
          justifyContent: "center",
          minHeight: "500px",
          alignItems: "center",
        }}
      />
    );
  }

  return (
    <div className="add_form_height_100">
      <AddPageLayout
        dataId={clientId}
        addForm={isAddForm}
        showFooter={!isAddForm}
        // footerInfo={footerInfo}
        isFieldEdited={isFieldEdited}
        hideDeleteBtn={true}
        recordText={"Client ID"}
        saveCurrentData={saveCurrentData}>
        <div style={{ height: "100%" }}>
          <Form
            render={(formRenderProps) => (
              <GridLayout
                rows={[
                  {
                    height: 70,
                  },
                ]}
                cols={[
                  {
                    width: 375,
                  },
                  {
                    width: 375,
                  },
                  {
                    width: 375,
                  },
                ]}
                gap={{
                  rows: 30,
                  cols: 35,
                }}
                style={{ padding: "16px" }}>
                <GridLayoutItem col={1} row={1} className="form-inner">
                  <Label className="form-heading">First Name :</Label>
                  <Input
                    type="text"
                    className="field-ip"
                    name="firstName"
                    value={clientAdsAuth?.firstName}
                    onChange={(e) => {
                      onInputChange(e);
                    }}
                    style={{ "-moz-appearance": "textfield" }}
                  />
                </GridLayoutItem>
                <GridLayoutItem col={2} row={1} className="form-inner">
                  <Label className="form-heading">Last Name :</Label>
                  <Input
                    className="field-ip"
                    type="text"
                    name="lastName"
                    value={clientAdsAuth.lastName}
                    onChange={onInputChange}
                  />
                </GridLayoutItem>

                <GridLayoutItem
                  col={3}
                  row={1}
                  className="form-inner"
                  style={{ width: "370px" }}>
                  <Label className="form-heading">Email :</Label>
                  <Input
                    className="field-ip"
                    type="text"
                    name="userEmail"
                    value={clientAdsAuth.userEmail}
                    onChange={onInputChange}
                  />
                </GridLayoutItem>
                <GridLayoutItem
                  col={1}
                  row={2}
                  className="form-inner"
                  style={{ width: "370px" }}>
                  <Label className="form-heading">Contact Number :</Label>
                  <Input
                    type="text"
                    className="field-ip"
                    name="contactNumber"
                    value={clientAdsAuth?.contactNumber}
                    onChange={(e) => {
                      onInputChange(e, "number");
                    }}
                    style={{ "-moz-appearance": "textfield" }}
                  />
                </GridLayoutItem>
                <GridLayoutItem
                  col={2}
                  row={2}
                  className="form-inner"
                  style={{ width: "370px" }}>
                  <Label className="form-heading">Business Name :</Label>
                  <Input
                    type="text"
                    className="field-ip"
                    name="businessName"
                    value={clientAdsAuth?.businessName}
                    onChange={(e) => {
                      onInputChange(e);
                    }}
                    style={{ "-moz-appearance": "textfield" }}
                  />
                </GridLayoutItem>

                <GridLayoutItem
                  col={3}
                  row={2}
                  className="form-inner"
                  style={{ width: "370px" }}>
                  <Label className="form-heading">Search Address :</Label>
                  <input
                    type="text"
                    ref={autoCompleteRef}
                    name="searchAddress"
                    placeholder="Search address..."
                    className="field-ip search_address_input"
                    onChange={(e) => onInputChange(e)}
                    style={{
                      padding: "10px",
                      width: "95%",
                      height: "9px",
                      borderRadius: "4px",
                      outline: "none",
                    }}
                  />
                </GridLayoutItem>

                <GridLayoutItem col={1} row={3} className="form-inner">
                  <Label className="form-heading"> Address :</Label>
                  <Input
                    type="text"
                    id="address"
                    name="address"
                    value={clientAdsAuth?.address}
                    className="field-ip"
                    onChange={(e) => onInputChange(e)}
                  />
                </GridLayoutItem>
                <GridLayoutItem col={2} row={3} className="form-inner">
                  <Label className="form-heading">City :</Label>
                  <Input
                    type="text"
                    id="city"
                    name="city"
                    value={clientAdsAuth?.city}
                    className="field-ip"
                    onChange={(e) => onInputChange(e)}
                  />
                </GridLayoutItem>
                <GridLayoutItem col={3} row={3} className="form-inner">
                  <Label className="form-heading">Province :</Label>
                  <Input
                    type="text"
                    id="province"
                    name="province"
                    value={clientAdsAuth?.province}
                    className="field-ip"
                    onChange={(e) => onInputChange(e)}
                  />
                </GridLayoutItem>
                <GridLayoutItem col={1} row={4} className="form-inner">
                  <Label className="form-heading"> Postal Code :</Label>
                  <Input
                    type="text"
                    id="postalCode"
                    name="postalCode"
                    value={clientAdsAuth?.postalCode}
                    className="field-ip"
                    onChange={(e) => onInputChange(e)}
                  />
                </GridLayoutItem>
                <GridLayoutItem col={2} row={4} className="form-inner">
                  <Label className="form-heading"> Country :</Label>
                  <Input
                    type="text"
                    id="country"
                    name="country"
                    value={clientAdsAuth?.country}
                    className="field-ip"
                    onChange={(e) => onInputChange(e)}
                  />
                </GridLayoutItem>

                <GridLayoutItem col={3} row={4} className="form-inner">
                  <Label className="form-heading"> Active :</Label>
                  <div>
                    <Switch
                      size={"small"}
                      checked={clientAdsAuth?.active}
                      name="active"
                      onChange={onInputChange}
                    />
                  </div>
                </GridLayoutItem>

                <GridLayoutItem col={1} row={5} className="form-inner">
                  <Label className="form-heading"> Picture :</Label>
                  <div className="k-d-flex" style={{ gap: "15px" }}>
                    <input
                      type="file"
                      hidden
                      accept=".png,.jpg"
                      id="client_ads_auth_picture"
                      style={{ display: "none" }}
                      onChange={(e) => {
                        onSelectImage(e);
                      }}
                    />
                    {clientAdsAuth?.pictureUrl ? (
                      <div className="k-d-flex">
                        <div style={{ position: "relative" }}>
                          <img
                            className="user-img"
                            src={checkImg(clientAdsAuth?.pictureUrl)}
                            alt="Ad"
                          />
                          <button
                            onClick={onRemoveFrontImg}
                            style={{
                              position: "absolute",
                              top: "6px",
                              right: "8px",
                              backgroundColor: "red",
                              color: "white",
                              border: "none",
                              borderRadius: "50%",
                              cursor: "pointer",
                            }}>
                            <span>X</span>
                          </button>
                        </div>
                      </div>
                    ) : (
                      <>
                        <GridLayoutItem col={2} row={1} className="form-inner">
                          <div
                            style={{
                              position: "relative",
                              cursor: "pointer",
                              border: "0.5px solid #d3d3d3",
                              padding: "5px",
                              display: "flex",
                              justifyContent: "center",
                              borderRadius: "4px",
                              gap: "10px",
                            }}
                            onClick={onChooseImageClick}>
                            <IconComponent iconName={"Image"} size={20} />
                            <span className="add-file-text">Choose image</span>
                          </div>
                        </GridLayoutItem>
                      </>
                    )}
                  </div>
                </GridLayoutItem>

                {!isAddForm && (
                  <GridLayoutItem col={2} row={5} className="form-inner">
                    <RecordStatusInput
                      recordStatusId={clientAdsAuth?.recordStatusId}
                      recordStatus={clientAdsAuth?.recordStatus}
                      onChange={({ recordStatus, recordStatusId }) => {
                        setIsFiledEdited(true);
                        setClientAdsAuth((preValue) => ({
                          ...preValue,
                          recordStatus,
                          recordStatusId,
                        }));
                      }}
                    />
                  </GridLayoutItem>
                )}
              </GridLayout>
            )}
          />
        </div>
      </AddPageLayout>
    </div>
  );
};

export default AddClientAuth;
