import React from "react";
import { Link } from "react-router-dom";

export default function FooterInformation(props) {
  if (!props?.footerInfo) {
    return null;
  }

  return (
    <div>
      <p className="footer-info">
        Created on {new Date(props.footerInfo.dateCreated).toDateString()}. Last
        updated by{" "}
        <Link className="updated-user">{props.footerInfo.modifiedByUser}</Link>{" "}
        on {new Date(props.footerInfo.dateModified).toDateString()}. Owned by{" "}
        <Link className="updated-user">
          {props.ownendBy ?? props.footerInfo.createdByUser}
        </Link>
      </p>
    </div>
  );
}
