import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { DELETE_ADS, FETCH_ADS, SAVE_ADS } from "../../constant/Apipath";
import { PostRequestCall } from "../../apicall/PostRequest";
import { Input, Switch, TextArea } from "@progress/kendo-react-inputs";
import { useSelector } from "react-redux";
import { Form } from "@progress/kendo-react-form";
import { GridLayout, GridLayoutItem } from "@progress/kendo-react-layout";
import { Error, Label } from "@progress/kendo-react-labels";
import { IconComponent } from "../../common/Icon";
import { toast } from "react-toastify";
import { Loader } from "@progress/kendo-react-indicators";
import AddPageLayout from "../../addpagelayout/AddPageLayout";
import RecordStatusInput from "../../common/RecordStatusInput";

const AddAds = () => {
  const { dataId } = useParams();
  const isAddForm = dataId === "add-ad";
  const [footerInfo, setFooterInfo] = useState({});
  const [showLoader, setShowLoader] = useState(false);
  const { loginData } = useSelector((state) => state.main);
  const [adsData, setAdsData] = useState({
    label: "",
    description: "",
    redirectUrl: "",
    webRedirectUrl: "",
    pictureUrl: null,
    isActive: false,
    recordStatus: "New",
    recordStatusId: 1,
  });

  const [modalState, setModalState] = useState({
    for: "",
    show: false,
    onActionBtnTitle: "",
  });
  const [isFieldEdited, setIsFiledEdited] = useState(false);

  useEffect(() => {
    if (!isAddForm) {
      getAds();
    }
  }, []);

  const getAds = async () => {
    let obj = { SearchList: [{ Id: dataId }] };
    const fetchuserAuthRes = await PostRequestCall(
      FETCH_ADS,
      obj,
      loginData?.token
    );

    let getadsData = fetchuserAuthRes?.data?.data[0];
    setAdsData({
      ...adsData,
      ...getadsData,
      label: getadsData?.label,
      description: getadsData?.description,
      redirectUrl: getadsData?.redirectUrl,
      webRedirectUrl: getadsData?.webRedirectUrl,
      isActive: getadsData?.active,
      pictureUrl: getadsData?.fileUrl,
      recordStatusId: getadsData?.recordStatusId,
      recordStatus: getadsData?.recordStatus,
    });
    let footerObj = {
      dateCreated: getadsData?.dateCreated ?? null,
      modifiedByUser: getadsData?.modifiedByUser ?? "",
      dateModified: getadsData?.dateModified ?? "",
      createdByUser: getadsData?.createdByUser ?? "",
    };
    setFooterInfo(footerObj);
  };

  const onInputChange = (e) => {
    setIsFiledEdited(true);
    setAdsData({
      ...adsData,
      [e.target.name]: e.target.value,
    });
  };

  const onProfileChange = (e) => {
    if (e?.target?.files?.length > 0) {
      setAdsData({
        ...adsData,
        pictureUrl: e.target.files[0],
      });
    }
    e.target.value = "";
  };

  const onSelectProfile = () => {
    setIsFiledEdited(true);
    document.getElementById("user-profile").click();
  };

  const onRemoveImage = () => {
    setIsFiledEdited(true);
    setAdsData({
      ...adsData,
      pictureUrl: null,
    });
  };

  const saveCurrentData = async () => {
    if (!adsData?.label?.trim()) {
      return toast.error("Label is required.", { position: "top-right" });
    }
    if (!adsData?.redirectUrl?.trim()) {
      return toast.error("Redirect URL is required.", {
        position: "top-right",
      });
    }
    if (!adsData?.pictureUrl) {
      return toast.error("Please select an image.", { position: "top-right" });
    }

    const getRequest = new FormData();
    if (isAddForm) {
      getRequest.append("Id", 0);
    } else {
      getRequest.append("Id", dataId);
    }

    getRequest.append("Description", adsData.description ?? "");
    getRequest.append("Label", adsData.label ?? "");
    getRequest.append("RedirectUrl", adsData.redirectUrl ?? "");
    getRequest.append("WebRedirectUrl", adsData.webRedirectUrl ?? "");
    getRequest.append("PictureUrl", adsData?.pictureUrl || "");
    getRequest.append("Active", adsData?.isActive || false);
    getRequest.append("RecordStatusId", adsData?.recordStatusId || 1);
    getRequest.append("CreatedBy", loginData?.userId);
    getRequest.append("ModifiedBy", loginData?.userId);

    try {
      setShowLoader(true);
      let getResponse = await PostRequestCall(
        SAVE_ADS,
        getRequest,
        loginData?.token,
        "formData"
      );

      if (getResponse.data.status === false) {
        setShowLoader(false);
        toast.error(
          getResponse?.data?.data?.[0].message ??
            "Something went wrong. Please try again later.",
          {
            position: "top-right",
          }
        );
      } else {
        setShowLoader(false);
        toast.success(`Data ${!isAddForm ? "updated" : "added"} successfully`, {
          position: "top-right",
        });
        window.history.back();
      }
    } catch (error) {
      setShowLoader(false);
      toast.error("Something went wrong. Please try again later.", {
        position: "top-right",
      });
      console.error("Error saving data:", error);
    }
  };

  const isCheck = (pictureUrl) => {
    if (Object.keys(pictureUrl)?.length === 0) {
      return URL.createObjectURL(pictureUrl);
    } else {
      return pictureUrl;
    }
  };

  const onDeleteClick = async () => {
    try {
      setShowLoader(true);

      const deleteObj = {
        ReturnRecordError: true,
        ReturnRecordId: false,
        UserId: loginData?.userId,
        DeleteList: [
          {
            Id: Number(dataId),
            ModifiedBy: loginData?.userId,
          },
        ],
      };

      return PostRequestCall(DELETE_ADS, deleteObj, loginData?.token)
        .then((res) => {
          setShowLoader(false);
          if (res?.status === 201) {
            toast.success(`Data delete successfully`, {
              position: "top-right",
            });

            window.history.back();
          } else {
            toast.error(
              res?.data?.message ??
                "Something went wrong. Please try again later.",
              {
                position: "top-right",
              }
            );
          }
        })
        .catch((error) => {
          setShowLoader(false);
          toast.error("Something went wrong. Please try again later.", {
            position: "top-right",
          });
          console.log("Error while deleting a record :: ", error);
        });
    } catch (error) {
      setShowLoader(false);
      toast.error("Something went wrong. Please try again later.", {
        position: "top-right",
      });
      console.log("Error while deleting a record :: ", error);
    }
  };

  if (showLoader) {
    return (
      <Loader
        type="converging-spinner"
        className="kendo-spinner"
        style={{
          display: "flex",
          justifyContent: "center",
          minHeight: "500px",
          alignItems: "center",
        }}
      />
    );
  }

  return (
    <div className="add_form_height_95">
      <AddPageLayout
        dataId={dataId}
        addForm={isAddForm}
        showFooter={!isAddForm}
        footerInfo={footerInfo}
        isFieldEdited={isFieldEdited}
        recordText={"Faq ID"}
        saveCurrentData={saveCurrentData}
        onDeleteClick={onDeleteClick}>
        <div style={{ width: "100%" }}>
          <Form
            render={(formRenderProps) => (
              <GridLayout
                rows={[
                  { height: 70 },
                  { height: 70 },
                  { height: 70 },
                  { height: 130 },
                  { height: 30 },
                ]}
                cols={[{ width: 375 }, { width: 375 }, { width: 375 }]}
                gap={{ rows: 30, cols: 35 }}
                style={{ padding: "16px" }}>
                <GridLayoutItem col={1} row={1} className="form-inner">
                  <Label className="form-heading"> Label :</Label>
                  <Input
                    name="label"
                    value={adsData?.label}
                    className="field-ip"
                    onChange={onInputChange}
                  />
                  <Error></Error>
                </GridLayoutItem>
                <GridLayoutItem col={2} row={1} className="form-inner">
                  <Label className="form-heading"> Redirect Url :</Label>
                  <Input
                    name="redirectUrl"
                    value={adsData?.redirectUrl}
                    className="field-ip"
                    type="url"
                    onChange={onInputChange}
                  />
                  <Error></Error>
                </GridLayoutItem>
                <GridLayoutItem col={3} row={1} className="form-inner">
                  <Label className="form-heading"> Web Redirect Url :</Label>
                  <Input
                    name="webRedirectUrl"
                    value={adsData?.webRedirectUrl}
                    className="field-ip"
                    type="url"
                    onChange={onInputChange}
                  />
                  <Error></Error>
                </GridLayoutItem>

                <GridLayoutItem col={3} row={2} className="form-inner">
                  <label className="form-heading">Description :</label>
                  <div style={{ display: "flex", gap: "2px" }}>
                    <TextArea
                      type="text"
                      id="description"
                      name="description"
                      value={adsData?.description}
                      className="field-ip"
                      onChange={(e) => onInputChange(e)}
                      rows={2}
                    />
                  </div>
                </GridLayoutItem>
                <GridLayoutItem col={2} row={2} className="form-inner">
                  <label className="form-heading">Active :</label>
                  <div>
                    <Switch
                      size="small"
                      checked={adsData?.isActive}
                      onChange={(e) => {
                        setIsFiledEdited(true);
                        setAdsData({
                          ...adsData,
                          isActive: e.value,
                        });
                      }}
                    />
                  </div>
                </GridLayoutItem>

                <GridLayoutItem col={1} row={2} className="form-inner">
                  <Label className="form-heading"> Add Ads Image :</Label>
                  <div className="k-d-flex" style={{ gap: "15px" }}>
                    <input
                      type="file"
                      hidden
                      accept=".png,.jpg"
                      id="user-profile"
                      style={{ display: "none" }}
                      onChange={(e) => {
                        setIsFiledEdited(true);
                        onProfileChange(e);
                      }}
                    />
                    {adsData?.pictureUrl ? (
                      <div className="k-d-flex">
                        <div style={{ position: "relative" }}>
                          <img
                            className="user-img"
                            src={isCheck(adsData?.pictureUrl)}
                            alt="Ad"
                          />
                          <button
                            onClick={onRemoveImage}
                            style={{
                              position: "absolute",
                              top: "6px",
                              right: "8px",
                              backgroundColor: "red",
                              color: "white",
                              border: "none",
                              borderRadius: "50%",
                              cursor: "pointer",
                            }}>
                            <span>X</span>
                          </button>
                        </div>
                      </div>
                    ) : (
                      <>
                        <GridLayoutItem col={2} row={1} className="form-inner">
                          <div
                            style={{
                              position: "relative",
                              cursor: "pointer",
                              border: "0.5px solid #d3d3d3",
                              padding: "5px",
                              display: "flex",
                              justifyContent: "center",
                              borderRadius: "4px",
                              gap: "10px",
                            }}
                            onClick={onSelectProfile}>
                            <IconComponent iconName={"Image"} size={20} />
                            <span className="add-file-text">Choose image</span>
                          </div>
                        </GridLayoutItem>
                      </>
                    )}
                  </div>
                </GridLayoutItem>

                {!isAddForm && (
                  <GridLayoutItem col={2} row={3} className="form-inner">
                    <RecordStatusInput
                      recordStatusId={adsData?.recordStatusId}
                      recordStatus={adsData?.recordStatus}
                      onChange={({ recordStatus, recordStatusId }) => {
                        setIsFiledEdited(true);
                        setAdsData((preValue) => ({
                          ...preValue,
                          recordStatus,
                          recordStatusId,
                        }));
                      }}
                    />
                  </GridLayoutItem>
                )}
              </GridLayout>
            )}
          />
        </div>
      </AddPageLayout>
    </div>
  );
};

export default AddAds;
