import React, { useEffect, useState } from "react";
import { DropDownList } from "@progress/kendo-react-dropdowns";
import { useNavigate, useParams } from "react-router-dom";
import {
  DELETE_TRADES_NAMES,
  FETCH_ITEMS,
  FETCH_RECORD_STATUSES,
  FETCH_STORE_ACTION_TYPES,
  FETCH_STORE_ITEMS,
  SAVE_STORE_ITEMS,
} from "../../constant/Apipath";
import { PostRequestCall } from "../../apicall/PostRequest";
import { useSelector } from "react-redux";
import { GridLayout, GridLayoutItem } from "@progress/kendo-react-layout";
import { Label } from "@progress/kendo-react-labels";
import KendoButton from "../../common/KendoButton";
import { Input, TextArea } from "@progress/kendo-react-inputs";
import { toast } from "react-toastify";
import { Loader } from "@progress/kendo-react-indicators";
import Footerinformation from "../../common/Footerinformation";
import Modal from "../../common/Modal";

const AddNewStoreItems = () => {
  const { dataId, subMenu } = useParams();
  const [alternateTradeName, setAlternateTradeName] = useState({
    name: "",
    recordStatus: "New",
    recordStatusId: 1,
    current: "false",
  });
  const isAddForm = dataId === "add-Stote-items";

  const { loginData } = useSelector((state) => state.main);
  const [isFieldEdited, setIsFiledEdited] = useState(false);

  const [items, setItems] = useState([]);
  const [itemsList, setItemsList] = useState({
    id: -1,
    text: "Select Item..",
  });

  const [itemsActionType, setItemsActionType] = useState([]);
  const [seletedItemsActionType, setSeletedItemsActionType] = useState({
    id: -1,
    text: "Select Item..",
  });

  const [recordStatusOptions, setRecordStatusOptions] = useState([]);
  const [showLoader, setShowLoader] = useState(false);
  const [modalState, setModalState] = useState({
    for: "",
    show: false,
    onActionBtnTitle: "",
  });
  const onInputChange = (e) => {
    const { name, value } = e.target;
    setIsFiledEdited(true);
    setAlternateTradeName({ ...alternateTradeName, [name]: value });
  };

  useEffect(() => {
    if (!isAddForm) {
      loadAlternateTradeNames();
    }
    getRecordStatuses();
  }, []);
  const getRecordStatuses = async () => {
    try {
      let recordStatusesObj = {
        PageNr: 1,
        NrOfRecPerPage: 100,
        FullSearch: "",
        UserId: "1",
        SortList: [
          {
            FieldName: "Id",
            Direction: "ASC",
          },
        ],
        IncludeRecordNr: true,
      };
      const recordStatusesRes = await PostRequestCall(
        FETCH_RECORD_STATUSES,
        recordStatusesObj,
        loginData?.token
      );
      let recordStatusesData = recordStatusesRes?.data?.data?.map((record) => ({
        id: record?.id,
        text: record?.name,
      }));
      setRecordStatusOptions(recordStatusesData ?? []);
    } catch (error) {
      console.log("Error while getting record statuses data :: ", error);
    }
  };
  const loadAlternateTradeNames = async () => {
    if (dataId !== "add-Stote-items") {
      var data = JSON.stringify({
        PageNr: 1,
        NrOfRecPerPage: 20,
        FullSearch: "",
        UserId: loginData.userId,
        TypeOfObjectReturned: "",
        SearchList: [
          {
            Id: dataId,
          },
        ],
        SortList: [
          {
            FieldName: "Id",
            Direction: "ASC",
          },
        ],
      });

      const result = await PostRequestCall(
        FETCH_STORE_ITEMS,
        data,
        loginData?.token
      );
      if (result.data && result.data.data && result.data.data.length > 0) {
        const certData = result.data.data[0];
        setAlternateTradeName({
          ...certData,
          tradeType: { text: certData?.tradeType ?? "Main" },
        });

        setItemsList({
          id: certData?.itemId,
          text: certData?.itemName,
        });
        setSeletedItemsActionType({
          id: certData?.actionTypeId,
          text: certData?.actionType,
        });
      }
    }
  };

  useEffect(() => {
    const fetchItems = async () => {
      try {
        var data = JSON.stringify({
          PageNr: 1,
          NrOfRecPerPage: 100,
          FullSearch: "",
          SortList: [{ FieldName: "", Direction: "" }],
          IncludeRecordNr: true,
        });
        const result = await PostRequestCall(
          FETCH_ITEMS,
          data,
          loginData?.token
        );
        let updatedData = result?.data?.data?.map((item) => ({
          id: item?.id,
          text: item?.name,
        }));
        setItems([...updatedData]);
      } catch (error) {
        console.error("Error fetching items:", error);
      }
    };
    const fetchStoreActionType = async () => {
      try {
        var data = JSON.stringify({
          PageNr: 1,
          NrOfRecPerPage: 100,
          FullSearch: "",
          SortList: [{ FieldName: "", Direction: "" }],
          IncludeRecordNr: true,
        });
        const result = await PostRequestCall(
          FETCH_STORE_ACTION_TYPES,
          data,
          loginData?.token
        );
        let updatedData = result?.data?.data?.map((item) => ({
          id: item?.id,
          text: item?.name,
        }));
        setItemsActionType([...updatedData]);
      } catch (error) {
        console.error("Error fetching items:", error);
      }
    };

    fetchItems();
    fetchStoreActionType();
  }, [loginData]);

  const onCancelClick = () => {
    if (isFieldEdited) {
      return setModalState((preValue) => ({
        ...preValue,
        for: "edit",
        show: true,
        onActionBtnTitle: "Save",
      }));
    } else {
      return window.history.back();
    }
  };

  const onActionBtnClick = () => {
    setModalState((preValue) => ({
      ...preValue,
      for: "",
      show: false,
      onActionBtnTitle: "",
    }));
    if (modalState?.for === "delete") {
      onDeleteClick();
    } else if (modalState?.for === "edit") {
      onSubmit();
    } else {
      return null;
    }
  };

  const showDeleteConfirmation = () => {
    setModalState((preValue) => ({
      ...preValue,
      for: "delete",
      show: true,
      onActionBtnTitle: "Delete",
    }));
  };
  const onSubmit = async (e) => {
    e.preventDefault();

    // Validation checks
    if (
      itemsList.id === -1 ||
      seletedItemsActionType.id === -1 ||
      !alternateTradeName.price
    ) {
      toast.error("Item Name,Price and Action Type are required.", {
        position: "top-right",
      });
      return;
    }

    const newtrades = {
      ReturnRecordId: false,
      ReturnRecordError: true,
      UserId: loginData?.userId,
      SaveList: [
        {
          Id: isAddForm ? 0 : dataId,
          ItemId: itemsList.id,
          ActionTypeId: seletedItemsActionType.id,
          Price: alternateTradeName.price,
          Qty: alternateTradeName.qty || 0,
          RecordStatusId: alternateTradeName?.recordStatusId || 1,
          Description: alternateTradeName.description,
          ModifiedBy: loginData?.userId,
        },
      ],
    };

    try {
      setShowLoader(true);
      const result = await PostRequestCall(
        SAVE_STORE_ITEMS,
        newtrades,
        loginData?.token
      );

      if (result.data.status === false) {
        setShowLoader(false);
        toast.error(
          result?.data?.data?.[0].message ??
            "Something went wrong. Please try again later.",
          {
            position: "top-right",
          }
        );
      } else {
        setShowLoader(false);
        toast.success(
          `Data ${
            dataId !== "add-Stote-items" ? "updated" : "added"
          } successfully`,
          {
            position: "top-right",
          }
        );
        window.history.back();
      }
    } catch (error) {
      setShowLoader(false);
      toast.error("Something went wrong. Please try again later.", {
        position: "top-right",
      });
    }
  };

  const onModalClose = () => {
    window.history.back();
  };
  const fetchItemDetails = async (itemId) => {
    try {
      const data = JSON.stringify({
        PageNr: 1,
        NrOfRecPerPage: 1,
        SearchList: [{ FieldName: "id", Value: itemId }],
      });
      const result = await PostRequestCall(FETCH_ITEMS, data, loginData?.token);
      const itemDetails = result?.data?.data?.[0] ?? {};
      return itemDetails;
    } catch (error) {
      console.error("Error fetching item details:", error);
      return {};
    }
  };
  const handleEventStatusChange = async (event) => {
    setIsFiledEdited(true);
    const selectedItem = items.find((item) => item.id === event.value.id);

    if (selectedItem) {
      const itemDetails = await fetchItemDetails(selectedItem.id); // Function to fetch item details
      setItemsList({
        id: selectedItem.id,
        text: selectedItem.text,
      });
      setAlternateTradeName({
        ...alternateTradeName,
        country: itemDetails.country, // Set the country from item details
      });
    }
  };
  const handleEventStatusChange2 = (event) => {
    setIsFiledEdited(true);

    setSeletedItemsActionType({
      id: event?.value?.id,
      text: event?.value?.text,
    });
  };
  const onDeleteClick = async (e) => {
    try {
      e.stopPropagation();
      if (window.confirm("Are you sure you wish to delete this record?")) {
        setShowLoader(true);

        const deleteObj = {
          ReturnRecordError: true,
          ReturnRecordId: false,
          UserId: loginData?.userId,
          DeleteList: [
            {
              Id: dataId,
              ModifiedBy: loginData?.userId,
            },
          ],
        };

        return PostRequestCall(DELETE_TRADES_NAMES, deleteObj, loginData?.token)
          .then((res) => {
            setShowLoader(false);
            if (res?.status === 201) {
              toast.success("Data deleted successfully", {
                position: "top-right",
              });

              window.history.back();
            } else {
              toast.error(
                res?.data?.message ??
                  "Something went wrong. Please try again later.",
                {
                  position: "top-right",
                }
              );
            }
          })
          .catch((error) => {
            setShowLoader(false);
            toast.error("Something went wrong. Please try again later.", {
              position: "top-right",
            });
            console.log("Error while deleting a record :: ", error);
          });
      }
    } catch (error) {
      setShowLoader(false);
      toast.error("Something went wrong. Please try again later.", {
        position: "top-right",
      });
      console.log("Error while deleting a record :: ", error);
    }
  };
  if (showLoader) {
    return (
      <Loader
        type="converging-spinner"
        className="kendo-spinner"
        style={{
          display: "flex",
          justifyContent: "center",
          minHeight: "500px",
          alignItems: "center",
        }}
      />
    );
  }
  return (
    <section className="add-user">
      <section className="user-information">
        <div className="k-d-flex k-justify-content-between">
          <div className="k-d-flex k-align-items-center">
            {!isAddForm && (
              <span class="record-id-text">Alternate items ID : {dataId}</span>
            )}
          </div>
          <div
            className="k-d-flex k-justify-content-end k-p-2"
            style={{ gap: "10px" }}>
            <KendoButton
              iconClass=""
              size={16}
              className=""
              themeColor={"primary"}
              buttonName="Save"
              onClick={onSubmit}
            />
            {dataId !== "add-Stote-items" && (
              <KendoButton
                size={16}
                className=""
                onClick={showDeleteConfirmation}
                buttonName="Delete"
                themeColor={"secondary"}
              />
            )}
            <KendoButton
              iconClass=""
              size={16}
              className=""
              buttonName="Cancel"
              onClick={onCancelClick}
            />
          </div>
        </div>
        <div
          style={{
            borderBottom: "1px solid #f5f5f5",
            width: "100%",
          }}></div>
        <GridLayout
          rows={[
            {
              height: 70,
            },
            {
              height: 70,
            },
            {
              height: 70,
            },
            {
              height: 70,
            },
            {
              height: 70,
            },
            {
              height: 70,
            },
            {
              height: 70,
            },
            {
              // height: 20,
            },
          ]}
          cols={[
            {
              width: 375,
            },
            {
              width: 375,
            },
            {
              width: 375,
            },
          ]}
          gap={{
            rows: 25,
            cols: 35,
          }}
          style={{ padding: "25px 16px" }}>
          <GridLayoutItem col={1} row={1} className="form-inner">
            <div className="form-group">
              <label className="form-heading">Item Name :</label>

              <DropDownList
                data={items}
                value={itemsList}
                textField="text"
                dataItemKey="id"
                defaultItem={itemsList}
                onChange={handleEventStatusChange}
              />
            </div>
          </GridLayoutItem>
          <GridLayoutItem col={2} row={1} className="form-inner">
            <label className="form-heading">Country :</label>
            <Input
              type="text"
              id="country"
              name="country"
              value={alternateTradeName?.country}
              className="field-ip"
              onChange={(e) => onInputChange(e)}
              disabled
            />
          </GridLayoutItem>
          <GridLayoutItem col={2} row={2} className="form-inner">
            <label className="form-heading">Action Type :</label>
            <DropDownList
              className="dropdown-list"
              data={itemsActionType}
              value={seletedItemsActionType}
              onChange={handleEventStatusChange2}
              textField="text"
              dataItemKey="text"
              placeholder="Select items.."
            />
          </GridLayoutItem>
          <GridLayoutItem col={3} row={1} className="form-inner">
            <label className="form-heading">Price :</label>
            <Input
              type="text"
              id="price"
              name="price"
              value={alternateTradeName?.price}
              className="field-ip"
              onChange={(e) => onInputChange(e)}
            />
          </GridLayoutItem>
          <GridLayoutItem col={1} row={2} className="form-inner">
            <label className="form-heading">Quantity :</label>
            <Input
              type="text"
              id="qty"
              name="qty"
              value={alternateTradeName?.qty}
              className="field-ip"
              onChange={(e) => onInputChange(e)}
            />
          </GridLayoutItem>
          <GridLayoutItem col={3} row={2} className="form-inner">
            <Label className="form-heading">Description :</Label>
            <TextArea
              type="text"
              id="description"
              name="description"
              value={alternateTradeName?.description}
              className="field-ip"
              onChange={onInputChange}
              rows={4}
            />
          </GridLayoutItem>
          {dataId !== "add-Stote-items" && (
            <GridLayoutItem
              col={1}
              row={3}
              className="form-inner"
              style={{ width: "163px" }}>
              <div className="form-group">
                <Label className="form-heading">Record Status :</Label>
                <div>
                  <DropDownList
                    style={{ width: "370px" }}
                    onChange={(e) => {
                      setIsFiledEdited(true);

                      setAlternateTradeName((preValue) => ({
                        ...preValue,
                        recordStatus: e.value?.text,
                        recordStatusId: e.value?.id,
                      }));
                    }}
                    className="dropdown-list"
                    value={{
                      id: alternateTradeName?.recordStatusId,
                      text: alternateTradeName?.recordStatus,
                    }}
                    data={recordStatusOptions}
                    textField="text"
                    dataItemKey="id"
                  />
                </div>
              </div>
            </GridLayoutItem>
          )}

          <GridLayoutItem
            col={1}
            row={!dataId || subMenu ? 5 : 4}
            className="form-inner">
            <div className="k-d-flex" style={{ gap: "10px" }}>
              <KendoButton
                iconClass=""
                size={16}
                onClick={onSubmit}
                className="action-btn"
                buttonName="Save"
              />

              {dataId !== "add-Stote-items" && (
                <KendoButton
                  size={16}
                  className=""
                  onClick={showDeleteConfirmation}
                  buttonName="Delete"
                  themeColor={"secondary"}
                />
              )}
              <KendoButton
                iconClass=""
                themeColor=""
                size={16}
                className=""
                buttonName="Cancel"
                onClick={onCancelClick}
              />
            </div>
          </GridLayoutItem>
          {dataId !== "add-Stote-items" && (
            <GridLayoutItem colSpan={7} className="form-footer">
              <Footerinformation footerInfo={alternateTradeName} />
            </GridLayoutItem>
          )}
        </GridLayout>
      </section>
      {modalState?.show && (
        <Modal
          for={modalState?.for}
          show={modalState?.show}
          onClose={onModalClose}
          onActionBtnClick={onActionBtnClick}
          onActionBtnTitle={modalState?.onActionBtnTitle}
        />
      )}
    </section>
  );
};

export default AddNewStoreItems;
