import React, { useEffect, useState } from "react";
import { DropDownList } from "@progress/kendo-react-dropdowns";
import { useParams } from "react-router-dom";
import {
  DELETE_ROLES_EXPERIENCES,
  FETCH_RECORD_STATUSES,
  FETCH_ROLES,
  FETCH_USERS,
  FETCH_USERS_ROLES_EXPERIENCES,
  SAVE_USER_ROLES_EXPERIENCES,
} from "../../constant/Apipath";
import { PostRequestCall } from "../../apicall/PostRequest";
import { useSelector } from "react-redux";
import { GridLayout, GridLayoutItem } from "@progress/kendo-react-layout";
import { Label } from "@progress/kendo-react-labels";
import KendoButton from "../../common/KendoButton";
import { Loader } from "@progress/kendo-react-indicators";
import { toast } from "react-toastify";
import Footerinformation from "../../common/Footerinformation";
import Modal from "../../common/Modal";

const AddEditUserExperinceRoles = () => {
  const { dataId, subMenu } = useParams();
  const [userExperinceTrades, setUserExperinceTrades] = useState({
    userName: "",
    recordStatus: "New",
    recordStatusId: 1,
    tradeType: { text: "Main", id: 1 },
  });
  const [showLoader, setShowLoader] = useState(false);
  const [modalState, setModalState] = useState({
    for: "",
    show: false,
    onActionBtnTitle: "",
  });
  const { loginData } = useSelector((state) => state.main);
  const isAddForm = dataId === "add-user-role-experince";
  const [isFieldEdited, setIsFiledEdited] = useState(false);

  const [recordStatusOptions, setRecordStatusOptions] = useState([]);

  const [roleList, setRoleList] = useState([]);
  const [selectedRole, setSelectedRole] = useState({
    id: -1,
    text: "Select Role..",
  });
  const [users, setUsers] = useState([]);
  const [selectedUSers, setSelectUsers] = useState({
    id: -1,
    text: "Select User..",
  });

  useEffect(() => {
    if (dataId !== "add-user-role-experince") {
      loadFetchUserTradesExperince();
    }
    getRecordStatuses();
  }, []);

  const getRecordStatuses = async () => {
    try {
      let recordStatusesObj = {
        PageNr: 1,
        NrOfRecPerPage: 100,
        FullSearch: "",
        UserId: "1",
        SortList: [
          {
            FieldName: "Id",
            Direction: "ASC",
          },
        ],
        IncludeRecordNr: true,
      };
      const recordStatusesRes = await PostRequestCall(
        FETCH_RECORD_STATUSES,
        recordStatusesObj,
        loginData?.token
      );
      let recordStatusesData = recordStatusesRes?.data?.data?.map((record) => ({
        id: record?.id,
        text: record?.name,
      }));
      setRecordStatusOptions(recordStatusesData ?? []);
    } catch (error) {
      console.log("Error while getting record statuses data :: ", error);
    }
  };
  const loadFetchUserTradesExperince = async () => {
    if (dataId !== "add-user-role-experince") {
      var data = JSON.stringify({
        PageNr: 1,
        NrOfRecPerPage: 20,
        FullSearch: "",
        UserId: loginData.userId,
        TypeOfObjectReturned: "",
        SearchList: [
          {
            Id: dataId,
          },
        ],
        SortList: [
          {
            FieldName: "Id",
            Direction: "ASC",
          },
        ],
      });

      const result = await PostRequestCall(
        FETCH_USERS_ROLES_EXPERIENCES,
        data,
        loginData?.token
      );
      if (result.data && result.data.data && result.data.data.length > 0) {
        const certData = result.data.data[0];
        setUserExperinceTrades({
          ...certData,
        });
        console.log("certData::::", certData);
        setSelectedRole({
          id: certData?.roleId,
          text: certData?.roleName,
        });
        setSelectUsers({
          id: certData?.userId,
          text: certData?.userName,
        });
      }
    }
  };
  const fetchTrade = async () => {
    try {
      var data = JSON.stringify({
        PageNr: 1,
        NrOfRecPerPage: 100,
        FullSearch: "",
        SortList: [{ FieldName: "", Direction: "" }],
        IncludeRecordNr: true,
      });
      const result = await PostRequestCall(FETCH_ROLES, data, loginData?.token);
      let updatedData = result?.data?.data?.map((item) => ({
        id: item?.id,
        text: item?.name,
      }));
      setRoleList([{ id: -1, text: "Select Event Status" }, ...updatedData]);
    } catch (error) {
      console.error("Error fetching event statuses:", error);
    }
  };

  const fetchUsers = async () => {
    try {
      var dataObj = JSON.stringify({
        PageNr: 1,
        NrOfRecPerPage: 100,
        FullSearch: "",
        SortList: [{ FieldName: "", Direction: "" }],
        IncludeRecordNr: true,
      });

      const result = await PostRequestCall(
        FETCH_USERS,
        dataObj,
        loginData?.token
      );

      let updatedData = result?.data?.data?.map((item) => ({
        id: item?.id,
        text: `${item?.firstName} ${item?.lastName}`,
      }));

      setUsers([{ id: -1, text: "Select User" }, ...updatedData]);
    } catch (error) {
      console.error("Error fetching Users:", error);
    }
  };

  useEffect(() => {
    fetchTrade();
    fetchUsers();
  }, [loginData]);
  const onSubmit = async (e) => {
    e.preventDefault();

    const newroles = {
      UserId: loginData?.userId,
      ReturnRecordId: true,
      ReturnRecordError: true,
      SaveList: [
        {
          Id: dataId === "add-user-role-experince" ? 0 : dataId,
          UserId: selectedUSers?.id,
          RoleId: selectedRole?.id,
          UserExperienceId: userExperinceTrades?.userExperienceId || 0,
          RecordStatusId: userExperinceTrades?.recordStatusId || 1,
          ModifiedBy: loginData?.userId,
        },
      ],
    };

    try {
      const result = await PostRequestCall(
        SAVE_USER_ROLES_EXPERIENCES,
        newroles,
        loginData?.token
      );

      if (result.data.status === false) {
        setShowLoader(false);
        toast.error(
          result?.data?.data?.[0].message ??
            "Something went wrong. Please try again later.",
          {
            position: "top-right",
          }
        );
      } else {
        setShowLoader(false);
        toast.success(
          `Data ${
            dataId !== "add-user-role-experince" ? "updated" : "added"
          } successfully`,
          {
            position: "top-right",
          }
        );

        window.history.back();
      }
    } catch (error) {
      console.error("Error saving userExperinceTrades:", error);
    }
  };

  const handleEventStatusChange = (event) => {
    setIsFiledEdited(true);

    setSelectedRole({
      id: event?.value?.id,
      text: event?.value?.text,
    });
  };
  const handleReletedTradeChange = (event) => {
    setIsFiledEdited(true);
    setSelectUsers({ id: event?.value?.id, text: event?.value?.text });
  };
  const onCancelClick = () => {
    if (isFieldEdited) {
      return setModalState((preValue) => ({
        ...preValue,
        for: "edit",
        show: true,
        onActionBtnTitle: "Save",
      }));
    } else {
      return window.history.back();
    }
  };

  const onActionBtnClick = () => {
    setModalState((preValue) => ({
      ...preValue,
      for: "",
      show: false,
      onActionBtnTitle: "",
    }));
    if (modalState?.for === "delete") {
      onDeleteClick();
    } else if (modalState?.for === "edit") {
      onSubmit();
    } else {
      return null;
    }
  };

  const showDeleteConfirmation = () => {
    setModalState((preValue) => ({
      ...preValue,
      for: "delete",
      show: true,
      onActionBtnTitle: "Delete",
    }));
  };
  const onDeleteClick = async (e) => {
    try {
      setShowLoader(true);
      const deleteObj = [
        {
          Id: dataId,
          ModifiedBy: loginData?.userId,
        },
      ];
      const newTrade = {
        ReturnRecordError: true,
        ReturnRecordId: false,
        UserId: loginData?.userId,
        DeleteList: deleteObj,
      };

      return PostRequestCall(
        DELETE_ROLES_EXPERIENCES,
        newTrade,
        loginData?.token
      )
        .then((res) => {
          setShowLoader(false);
          if (res?.status === 201) {
            toast.success("Data deleted successfully", {
              position: "top-right",
            });

            window.history.back();
          } else {
            toast.error(
              res?.data?.message ??
                "Something went wrong. Please try again later.",
              {
                position: "top-right",
              }
            );
          }
        })
        .catch((error) => {
          setShowLoader(false);
          toast.error("Something went wrong. Please try again later.", {
            position: "top-right",
          });
          console.log("Error while deleting a record :: ", error);
        });
    } catch (error) {
      setShowLoader(false);
      toast.error("Something went wrong. Please try again later.", {
        position: "top-right",
      });
      console.log("Error while deleting a record :: ", error);
    }
  };
  const onModalClose = () => {
    window.history.back();
  };
  if (showLoader) {
    return (
      <Loader
        type="converging-spinner"
        className="kendo-spinner"
        style={{
          display: "flex",
          justifyContent: "center",
          minHeight: "500px",
          alignItems: "center",
        }}
      />
    );
  }
  return (
    <section className="add-user">
      <section className="user-information">
        <div className="k-d-flex k-justify-content-between">
          <div className="k-d-flex k-align-items-center">
            {!isAddForm && (
              <span class="record-id-text">Alternate Trade ID : {dataId}</span>
            )}
          </div>
          <div
            className="k-d-flex k-justify-content-end k-p-2"
            style={{ gap: "10px" }}>
            <KendoButton
              iconClass=""
              size={16}
              className=""
              themeColor={"primary"}
              buttonName="Save"
              onClick={onSubmit}
            />
            {dataId !== "add-user-role-experince" && (
              <KendoButton
                size={16}
                className=""
                onClick={showDeleteConfirmation}
                buttonName="Delete"
                themeColor={"secondary"}
              />
            )}
            <KendoButton
              iconClass=""
              size={16}
              className=""
              buttonName="Cancel"
              onClick={onCancelClick}
            />
          </div>
        </div>
        <div
          style={{
            borderBottom: "1px solid #f5f5f5",
            width: "100%",
          }}></div>
        <GridLayout
          rows={[
            {
              height: 70,
            },
            {
              height: 70,
            },
            {
              height: 70,
            },
            {
              height: 70,
            },
            {
              height: 70,
            },
            {
              height: 70,
            },
            {
              height: 70,
            },
            {
              // height: 20,
            },
          ]}
          cols={[
            {
              width: 375,
            },
            {
              width: 375,
            },
            {
              width: 375,
            },
          ]}
          gap={{
            rows: 25,
            cols: 35,
          }}
          style={{ padding: "25px 16px" }}>
          <GridLayoutItem col={1} row={1} className="form-inner">
            <div className="form-group">
              <Label className="form-heading">User Name :</Label>

              {dataId !== "add-user-role-experince" || subMenu ? (
                <DropDownList
                  data={users}
                  value={selectedUSers}
                  onChange={handleReletedTradeChange}
                  textField="text"
                  dataItemKey="text"
                  placeholder="Select Users.."
                  disabled
                />
              ) : (
                <DropDownList
                  data={users}
                  value={selectedUSers}
                  onChange={handleReletedTradeChange}
                  textField="text"
                  dataItemKey="text"
                  placeholder="Select Users.."
                />
              )}
            </div>
          </GridLayoutItem>

          <GridLayoutItem col={2} row={1} className="form-inner">
            <div className="form-group">
              <Label className="form-heading">Role Name :</Label>
              <DropDownList
                className="dropdown-list"
                data={roleList}
                value={selectedRole}
                onChange={handleEventStatusChange}
                textField="text"
                dataItemKey="text"
                placeholder="Select Role.."
              />
            </div>
          </GridLayoutItem>

          {dataId !== "add-user-role-experince" && (
            <GridLayoutItem
              col={3}
              row={1}
              className="form-inner"
              style={{ width: "163px" }}>
              <div className="form-group">
                <Label className="form-heading">Record Status :</Label>
                <div>
                  <DropDownList
                    style={{ width: "370px" }}
                    onChange={(e) => {
                      setIsFiledEdited(true);
                      setUserExperinceTrades((preValue) => ({
                        ...preValue,
                        recordStatus: e.value?.text,
                        recordStatusId: e.value?.id,
                      }));
                    }}
                    className="dropdown-list"
                    value={{
                      id: userExperinceTrades?.recordStatusId,
                      text: userExperinceTrades?.recordStatus,
                    }}
                    data={recordStatusOptions}
                    textField="text"
                    dataItemKey="id"
                  />
                </div>
              </div>
            </GridLayoutItem>
          )}

          <GridLayoutItem
            col={1}
            row={!dataId || subMenu ? 4 : 3}
            className="form-inner">
            <div className="k-d-flex" style={{ gap: "10px" }}>
              <KendoButton
                iconClass=""
                size={16}
                onClick={onSubmit}
                className="action-btn"
                buttonName="Save"
              />

              {dataId !== "add-user-role-experince" && (
                <KendoButton
                  size={16}
                  className=""
                  onClick={showDeleteConfirmation}
                  buttonName="Delete"
                  themeColor={"secondary"}
                />
              )}
              <KendoButton
                iconClass=""
                themeColor=""
                size={16}
                className=""
                buttonName="Cancel"
                onClick={onCancelClick}
              />
            </div>
          </GridLayoutItem>
          {dataId !== "add-user-role-experince" && (
            <GridLayoutItem colSpan={7} className="form-footer">
              <Footerinformation footerInfo={userExperinceTrades} />
            </GridLayoutItem>
          )}
        </GridLayout>
      </section>
      {modalState?.show && (
        <Modal
          for={modalState?.for}
          show={modalState?.show}
          onClose={onModalClose}
          onActionBtnClick={onActionBtnClick}
          onActionBtnTitle={modalState?.onActionBtnTitle}
        />
      )}
    </section>
  );
};

export default AddEditUserExperinceRoles;
