import React, { useEffect, useState } from "react";
import { AutoComplete } from "@progress/kendo-react-dropdowns";
import { useParams } from "react-router-dom";
import {
  DELETE_USER_ROLE_AUTH,
  FETCH_USER_ROLE_AUTH,
  SAVE_USER_ROLE_AUTH,
} from "../../constant/Apipath";
import { PostRequestCall } from "../../apicall/PostRequest";
import { useSelector } from "react-redux";
import { Form } from "@progress/kendo-react-form";
import { GridLayout, GridLayoutItem } from "@progress/kendo-react-layout";
import { toast } from "react-toastify";
import { Loader } from "@progress/kendo-react-indicators";
import AddPageLayout from "../../addpagelayout/AddPageLayout";
import UserSearchInput from "../../common/UserSearchInput";
import RoleAuthInput from "../../common/RoleAuthInput";

const AddUserRolesAuth = () => {


  const { dataId, subMenu, userId } = useParams();
  const { loginData } = useSelector((state) => state.main);
  const isAddForm = dataId === "add-roles-auth";

  const [userRoleAuth, setUserRoleAuth] = useState({
    userName: "",
    userId: userId ?? 0,
    roleAuthId: -1,
    rolesAuthName: "Select Role Auth",
  });


  const [showLoader, setShowLoader] = useState(false);
  const [isFieldEdited, setIsFiledEdited] = useState(false);
  const [footerInfo, setFooterInfo] = useState({});

  useEffect(() => {
    if (!isAddForm) {
      getUserRoleAuthDetails();
    }
  }, [])

  const getUserRoleAuthDetails = async () => {
    try {
      var obj = JSON.stringify({
        PageNr: 1,
        NrOfRecPerPage: 20,
        FullSearch: "",
        UserId: loginData.userId,
        SearchList: [
          {
            Id: dataId,
          },
        ],
      });

      const res = await PostRequestCall(
        FETCH_USER_ROLE_AUTH,
        obj,
        loginData?.token
      );

      const resData = res?.data?.data?.[0] ?? {};
      setUserRoleAuth((preValue) => (
        {
          ...preValue,
          userName: resData?.userName ?? "",
          userId: resData?.userId ?? 0,
          rolesAuthName: resData?.rolesAuthName,
          roleAuthId: resData?.roleAuthId,
        }
      ))
      let footerObj = {
        dateCreated: resData?.dateCreated ?? null,
        modifiedByUser: resData?.modifiedByUser ?? "",
        dateModified: resData?.dateModified ?? "",
        createdByUser: resData?.createdByUser ?? ""
      }
      setFooterInfo(footerObj)
    } catch (error) {
      console.log("Error while getting user roles auth details :: ", error);
    }
  }




  const checkSelectedUserRoleBasedOnRoleId = async (userId, roleAuthId) => {
    try {
      var data = JSON.stringify({
        PageNr: 1,
        NrOfRecPerPage: 20,
        FullSearch: "",
        UserId: loginData.userId,
        SearchList: [
          {
            roleAuthId: roleAuthId
          }, {
            userId
          }
        ],
      });
      const result = await PostRequestCall(
        FETCH_USER_ROLE_AUTH,
        data,
        loginData?.token
      );
      const resData = result?.data?.data ?? [];
      if (resData?.length === 0) {
        return false;
      } else {
        return true;
      }
    } catch (error) {
      setShowLoader(false);
      console.log("Error while checking user roles details :: ", error);
      return true;
    }
  }

  const saveUserRole = async () => {
    const saveObj = {
      ReturnRecordId: false,
      ReturnRecordError: true,
      UserId: loginData.userId,
      SaveList: [
        {
          Id: isAddForm ? 0 : dataId,
          UserId: userRoleAuth?.userId,
          RoleAuthId: userRoleAuth?.roleAuthId,
          ModifiedBy: loginData.userId,
        },
      ],
    };
    try {
      const result = await PostRequestCall(
        SAVE_USER_ROLE_AUTH,
        saveObj,
        loginData?.token
      );
      if (result.data.status === false) {
        setShowLoader(false);
        toast.error(
          result?.data?.data?.[0].message ??
          "Something went wrong. Please try again later.",
          {
            position: "top-right",
          }
        );
      } else {
        setShowLoader(false);
        toast.success(
          `Data ${dataId !== "add-roles-auth" ? "updated" : "added"
          } successfully`,
          {
            position: "top-right",
          }
        );
        window.history.back();
      }
    } catch (error) {
      setShowLoader(false);
      toast.error("Something went wrong. Please try again later.", {
        position: "top-right",
      });
      console.error("Error saving email:", error);
    }
  }


  const saveCurrentData = async () => {
    if (userRoleAuth?.userId === 0) {
      toast.error("Please select a vaild user",
        {
          position: "top-right",
        }
      );
      return;
    }

    if (userRoleAuth?.roleAuthId === -1) {
      toast.error("Please select a vaild role auth",
        {
          position: "top-right",
        }
      );
      return;
    }

    setShowLoader(true);
    return saveUserRole();

    // if (userRoleAuth?.roleAuthId === 4) {

    //   let isUserAlreadyAdmin = false;
    //   if (isAddForm) {
    //     isUserAlreadyAdmin = await checkSelectedUserRoleBasedOnRoleId(userRoleAuth?.userId, 1);
    //   }
    //   if (!isUserAlreadyAdmin) {
    //   } else {
    //     setShowLoader(false);
    //     toast.error("The selected user already has an administrator role, so the promoter role cannot be assigned.", {
    //       position: "top-right",
    //     });
    //     return;
    //   }
    // } else if (userRoleAuth?.roleAuthId === 1) {
    //   let isUserAlreadyPromoter = false;
    //   if (isAddForm) {
    //     isUserAlreadyPromoter = await checkSelectedUserRoleBasedOnRoleId(userRoleAuth?.userId, 4);
    //   }
    //   if (!isUserAlreadyPromoter) {
    //     return saveUserRole();
    //   } else {
    //     setShowLoader(false);
    //     toast.error("The selected user already has a promoter role, so the administrator role cannot be assigned.", {
    //       position: "top-right",
    //     });
    //     return;
    //   }
    // }
    // else {
    //   return saveUserRole();
    // }
  };

  const onDeleteClick = async () => {
    try {
      setShowLoader(true);
      const deleteObj = [
        {
          Id: Number(dataId),
          ModifiedBy: loginData?.userId,
        },
      ];
      const obj = {
        ReturnRecordError: true,
        ReturnRecordId: false,
        UserId: loginData?.userId,
        DeleteList: deleteObj,
      };
      return PostRequestCall(
        DELETE_USER_ROLE_AUTH,
        obj,
        loginData?.token
      )
        .then((res) => {
          setShowLoader(false);
          if (res?.status === 201) {
            toast.success("Data deleted successfully", {
              position: "top-right",
            });
            window.history.back();
          } else {
            toast.error(
              res?.data?.message ??
              "Something went wrong. Please try again later.",
              {
                position: "top-right",
              }
            );
          }
        })
        .catch((error) => {
          setShowLoader(false);
          toast.error("Something went wrong. Please try again later.", {
            position: "top-right",
          });
          console.log("Error while deleting a record :: ", error);
        });
    } catch (error) {
      setShowLoader(false);
      toast.error("Something went wrong. Please try again later.", {
        position: "top-right",
      });
      console.log("Error while deleting a record :: ", error);
    }
  };


  if (showLoader) {
    return (
      <Loader
        type="converging-spinner"
        className="kendo-spinner"
        style={{
          display: "flex",
          justifyContent: "center",
          minHeight: "500px",
          alignItems: "center",
        }}
      />
    );
  }

  return (
    <>
      <div className="add_form_height_95">
        <AddPageLayout
          dataId={dataId}
          addForm={isAddForm}
          showFooter={!isAddForm}
          footerInfo={footerInfo}
          isFieldEdited={isFieldEdited}
          recordText={"User Role Auth  ID"}
          saveCurrentData={saveCurrentData}
          onDeleteClick={onDeleteClick}
        >
          <div style={{ height: '100%' }}>
            <Form
              render={(formRenderProps) => (
                <GridLayout
                  rows={[
                    {
                      height: 70,
                    },
                  ]}
                  cols={[
                    {
                      width: 375,
                    },
                    {
                      width: 375,
                    },
                    {
                      width: 375,
                    },
                  ]}
                  gap={{
                    rows: 30,
                    cols: 35,
                  }}
                  style={{ padding: "16px" }}>

                  <GridLayoutItem col={1} row={1} className="form-inner">
                    <UserSearchInput
                      value={userRoleAuth?.userName}
                      setSelectedUser={({ label, id }) => {
                        setIsFiledEdited(true);
                        setUserRoleAuth((preValue) => ({ ...preValue, userName: label, userId: id }))
                      }}
                      disabled={!isAddForm || subMenu}
                      userId={userId}
                    />
                  </GridLayoutItem>

                  <GridLayoutItem col={2} row={1} className="form-inner">
                    <RoleAuthInput
                      roleAuthId={userRoleAuth?.roleAuthId}
                      rolesAuthName={userRoleAuth?.rolesAuthName}
                      onChange={({ rolesAuthName, roleAuthId }) => {
                        setIsFiledEdited(true);
                        setUserRoleAuth((preValue) => ({ ...preValue, rolesAuthName, roleAuthId }))
                      }}
                    />
                  </GridLayoutItem>
                </GridLayout>
              )}
            />
          </div>
        </AddPageLayout>
      </div>

    </>
  )
}

export default AddUserRolesAuth