import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import {
  FETCH_USER_DEVICES,
  SAVE_USER_DEVICES,
} from "../../constant/Apipath";
import { PostRequestCall } from "../../apicall/PostRequest";
import { Input, Switch } from "@progress/kendo-react-inputs";
import { useSelector } from "react-redux";
import { Form } from "@progress/kendo-react-form";
import { GridLayout, GridLayoutItem } from "@progress/kendo-react-layout";
import { Label } from "@progress/kendo-react-labels";
import { toast } from "react-toastify";
import { Loader } from "@progress/kendo-react-indicators";
import AddPageLayout from "../../addpagelayout/AddPageLayout";

const EditUserDevices = () => {
  const { dataId } = useParams();
  const { loginData } = useSelector((state) => state.main);
  const isAddForm = dataId === "add-user-devices";

  const [userDevices, setUserDevices] = useState({
    deviceName: "",
    isDeviceNotify: false,
    mobileDeviceModel: "",
    platform: "",
    browser: "",
    appName: "",
  });

  const [showLoader, setShowLoader] = useState(false);
  const [isFieldEdited, setIsFiledEdited] = useState(false);


  useEffect(() => {
    if (!isAddForm) {
      getUserDevicesDetails();
    }
  }, []);

  const getUserDevicesDetails = async () => {
    try {
      let obj = { SearchList: [{ Id: dataId }] };
      const res = await PostRequestCall(
        FETCH_USER_DEVICES,
        obj,
        loginData?.token
      );

      let resData = res?.data?.data?.[0] ?? {};
      setUserDevices((preValue) => ({
        ...preValue,
        deviceName: resData?.deviceName,
        isDeviceNotify: resData?.isDeviceNotify,
        mobileDeviceModel: resData?.mobileDeviceModel,
        platform: resData?.platform,
        browser: resData?.browser,
        appName: resData?.appName,
      }));
    } catch (error) {
      console.log("Error while getting user devices details :: ", error);
    }
  };

  const saveCurrentData = async () => {
    try {
      setShowLoader(true);

      const obj = {
        UserId: loginData.userId,
        ReturnRecordId: true,
        ReturnRecordError: true,
        SaveList: [
          {
            Id: dataId,
            IsDeviceNotify: userDevices?.isDeviceNotify,
          },
        ],
      };

      let result = await PostRequestCall(
        SAVE_USER_DEVICES,
        obj,
        loginData?.token,
      );
      if (result?.data?.status === false) {
        setShowLoader(false);
        let errorMessage = result?.data?.errorMessage ? result?.data?.errorMessage : result?.data?.error ? result?.data?.error : "Something went wrong. Please try again later."
        toast.error(
          errorMessage,
          {
            position: "top-right",
          }
        );
      } else {
        setShowLoader(false);
        toast.success(`Data ${isAddForm ? "added" : "updated"} successfully`, {
          position: "top-right",
        });
        window.history.back();
      }
    } catch (error) {
      setShowLoader(false);
      toast.error("Something went wrong. Please try again later.",
        {
          position: "top-right",
        }
      );
    }
  };

  if (showLoader) {
    return (
      <Loader
        type="converging-spinner"
        className="kendo-spinner"
        style={{
          display: "flex",
          justifyContent: "center",
          minHeight: "500px",
          alignItems: "center",
        }}
      />
    );
  }


  return (
    <>
      <div className="add_form_height_95">
        <AddPageLayout
          dataId={dataId}
          addForm={isAddForm}
          showFooter={false}
          hideDeleteBtn={true}
          isFieldEdited={isFieldEdited}
          recordText={"User Devices ID"}
          saveCurrentData={saveCurrentData}
        >
          <div style={{ height: "100%" }}>
            <Form
              render={() => (
                <GridLayout
                  rows={[
                    {
                      height: 70,
                    },
                  ]}
                  cols={[
                    {
                      width: 375,
                    },
                    {
                      width: 375,
                    },
                    {
                      width: 375,
                    },
                  ]}
                  gap={{
                    rows: 30,
                    cols: 35,
                  }}
                  style={{ padding: "16px" }}>
                  <GridLayoutItem col={1} row={1} className="form-inner">
                    <Label className="form-heading">App Name:</Label>
                    <Input
                      type="text"
                      className="field-ip"
                      name="appName"
                      value={userDevices.appName}
                      style={{ "-moz-appearance": "textfield" }}
                      disabled
                    />
                  </GridLayoutItem>
                  <GridLayoutItem col={2} row={1} className="form-inner">
                    <Label className="form-heading">Device Name:</Label>
                    <Input
                      className="field-ip"
                      type="text"
                      name="deviceName"
                      value={userDevices.deviceName}
                      disabled
                    />
                  </GridLayoutItem>

                  <GridLayoutItem
                    col={3}
                    row={1}
                    className="form-inner"
                    style={{ width: "370px" }}>
                    <Label className="form-heading">Is Device Notify:</Label>
                    <div>
                      <Switch
                        size={"small"}
                        checked={userDevices.isDeviceNotify}
                        onChange={(e) => {
                          setIsFiledEdited(true);
                          setUserDevices((preValue) => ({
                            ...preValue,
                            isDeviceNotify: e?.value,
                          }));
                        }}
                      />
                    </div>
                  </GridLayoutItem>
                  <GridLayoutItem
                    col={1}
                    row={2}
                    className="form-inner"
                    style={{ width: "370px" }}>
                    <Label className="form-heading">Platform:</Label>
                    <Input
                      className="field-ip"
                      type="text"
                      name="platform"
                      value={userDevices.platform}
                      disabled
                    />
                  </GridLayoutItem>
                  <GridLayoutItem
                    col={2}
                    row={2}
                    className="form-inner"
                    style={{ width: "370px" }}>
                    <Label className="form-heading">Mobile Device Model:</Label>
                    <Input
                      className="field-ip"
                      type="text"
                      name="mobileDeviceModel"
                      value={userDevices.mobileDeviceModel}
                      disabled
                    />
                  </GridLayoutItem>

                  <GridLayoutItem
                    col={3}
                    row={2}
                    className="form-inner"
                    style={{ width: "370px" }}>
                    <Label className="form-heading">Browser:</Label>
                    <Input
                      className="field-ip"
                      type="text"
                      name="browser"
                      value={userDevices.browser}
                      disabled
                    />
                  </GridLayoutItem>


                </GridLayout>
              )}
            />
          </div>
        </AddPageLayout>
      </div>
    </>
  );
};

export default EditUserDevices;
