import { Button } from "@progress/kendo-react-buttons";
import React from "react";
import { IconComponent } from "./Icon";


const KendoButton = (props) => {
  return (
    <Button
      className={props?.className}
      onClick={props?.onClick}
      disabled={props?.disabled}
      themeColor={props?.themeColor}
      fillMode={props?.fillMode}
      rounded={props?.rounded}
      style={props?.style}
    >
      <IconComponent
        className={props?.iconClassName}
        size={props?.size}
        iconName={props?.iconClass}
      />{" "}
      {props?.buttonName}
    </Button>
  );
};
export default KendoButton;
