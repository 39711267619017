export const PostRequestCall = async (apiPath, payload, token, formData) => {
  let auth = {};
  if (typeof token !== "undefined" && token !== null && token !== "") {
    auth = {
      Authorization: `Bearer ${token}`,
    };
  }
  let headers = {
    ...auth,
    Accept: "application/json",
    "Content-Type": formData ? "multipart/form-data" : "application/json",
  };
  const postResponse = await window.axios
    .post(`${process.env.REACT_APP_BASE_URL}${apiPath}`, payload, {
      headers: headers,
    })
    .then(function (result) {
      return result;
    })
    .catch((err) => err.response);
  return postResponse;
};
