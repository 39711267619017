import React from 'react'
import { useParams } from 'react-router-dom';
import AddNewAlternateTradeNames from '../../alternatetradenames/AddNewAlternateTradeNames';
import AddNewReletedTrades from '../../reletedtrades/AddNewReletedTrades';

const AddTradeRecordLayout = () => {

    const { tradeSubMenu } = useParams();


    const renderFormBasedOnUrl = () => {
        let ui = <></>;
        switch (tradeSubMenu) {
            case "alternate-trade-name":
                ui = <> <AddNewAlternateTradeNames /> </>
                break;
            case "releted-trades":
                ui = <> <AddNewReletedTrades /> </>
                break;
            default:
                ui = <></>;
                break;
        }

        return ui;
    }

    return (
        <>
            {renderFormBasedOnUrl()}
        </>
    )
}

export default AddTradeRecordLayout