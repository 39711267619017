import React from "react";
import { useParams } from "react-router-dom";
import AlternateTradeNames from "../../alternatetradenames/AlternateTradeNames";
import ReletedTrades from "../../reletedtrades/ReletedTrades";

const TradesDetailsLayout = () => {
  const { tradeSubMenu } = useParams();

  const renderTableListBasedOnUrl = () => {
    let ui = <></>;
    switch (tradeSubMenu) {
      case "alternate-trade-name":
        ui = (
          <>
            {" "}
            <AlternateTradeNames />{" "}
          </>
        );
        break;

      case "releted-trades":
        ui = (
          <>
            {" "}
            <ReletedTrades />{" "}
          </>
        );
        break;

      default:
        ui = <></>;
        break;
    }

    return ui;
  };
  return <>{renderTableListBasedOnUrl()}</>;
};

export default TradesDetailsLayout;
