import React from "react";
import "./App.css";
import MainLayout from "./mainlayout/MainLayout";
import "./App.css";
import { BrowserRouter, Routes, Route, Outlet } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import internet from "../src/assets/images/int-connection.png";
import LoginPage from "./auth/LoginPage";
import AddNewRecord from "./home/data-issues/AddNewRecord";
import axios from "axios";
import DataIssues from "./home/data-issues/DataIssues";
import ProductList from "./manufacture/products/ProductList";
import ProductPicturesList from "./manufacture/product-pictures/ProductPicturesList";
import CompanyList from "./companies/companylist/CompanyList";
import {
  ClientMenuList,
  adsMenuList,
  biRoleMenuList,
  chatMenuList,
  companiesMenuList,
  compnayRoleMenuList,
  experienceMenuList,
  faqMenuList,
  featuresMenuList,
  postMenuList,
  productMenuList,
  quizzesMenuList,
  rewardMenulist,
  rewardsMenuList,
  sitesMenuList,
  ticketsMenuList,
  tradeMenuList,
  userDevicesMenuList,
  userRolesMenuList,
  userSupportMenuList,
} from "./common/Sidemenulist";
import CompanyAddress from "./companies/companyaddress/CompanyAddress";
import AdminUserAuth from "./admin/adminuserauth/AdminUserAuth";
import Ticket from "./ticket/ticket/Ticket";
import SharedTickets from "./ticket/sharedtickets/SharedTickets";
import UserQuestions from "./users/user-questions/UserQuestions";
import UserAnswers from "./users/user-answers/UserAnswers";
import ChatGroupUsers from "./chat/chatgroupusers/ChatGroupUsers";
import ChatGroupMessages from "./chat/chatgroupmessages/ChatGroupMessages";
import ChatGroupUserMessages from "./chat/chatgroupusermessages/ChatGroupUserMessages";
import BiInfoChannels from "./chat/biinfochannels/BiInfoChannels";
import Sites from "./sites/sites/Sites";
import PostComments from "./users/post-comments/PostComments";
import UserSitesProjects from "./users/user-sites-projects/UserSitesProjects";
import ForgetPassword from "./auth/ForgetPassword";
import AddUserForm from "./admin/adminuserauth/AddUserForm";
import AddNewPost from "./users/posts/AddNewPost";
import UserDetailsMainLayout from "./admin/adminuserauth/UserDetailsMainLayout";
import AddUserRecordLayout from "./admin/adminuserauth/AddUserRecordLayout";
import UserTableDetailsLayout from "./admin/adminuserauth/UserTableDetailsLayout";
import useLocalStorage from "./network/useLocalStorage";
import useNetworkStatus from "./network/useNetworkStatus";
import MiscellaneTrades from "./miscellaneous/trades/MiscellaneTrades";
import AddMiscellaneTrades from "./miscellaneous/trades/AddMiscellaneTrades";
import AlternateTradeNames from "./miscellaneous/alternatetradenames/AlternateTradeNames";
import AddNewAlternateTradeNames from "./miscellaneous/alternatetradenames/AddNewAlternateTradeNames";
import ReletedTrades from "./miscellaneous/reletedtrades/ReletedTrades";
import AddNewReletedTrades from "./miscellaneous/reletedtrades/AddNewReletedTrades";
import HomePage from "./common/HomePage";
import Ads from "./miscellaneous/ads/Ads";
import AddAds from "./miscellaneous/ads/AddAds";
import AddQuizzes from "./rewards/quizzes/AddQuizzes";
import Quizzes from "./rewards/quizzes/Quizzes";
import Post from "./users/posts/Post";
import RewardActivities from "./rewards/rewardActivities/RewardActivities";
import AddRewardActivities from "./rewards/rewardActivities/AddRewardActivities";
import AddNewTicket from "./ticket/ticket/AddNewTicket";
import Addcompany from "./companies/companylist/Addcompany";
import AddcompanyAddress from "./companies/companyaddress/AddcompanyAddress";
import UserDevices from "./users/user-devices/UserDevices";
import AddProduct from "./manufacture/products/AddProduct";
import ChatGroups from "./chat/chatgroups/ChatGroup";
import AddChatGroupUser from "./chat/chatgroupusers/AddChatGroupUser";
import UserRewardActivities from "./users/user-reward-Activities/UserRewardActivities";
import Entitities from "./Entities/Entitities";
import AddEntity from "./Entities/AddEntity";
import Attributes from "./attributes/Attributes";
import AddAttributes from "./attributes/AddAttributes";
import EntityAttributes from "./entityattributes/EntityAttributes";
import AddEntityAttributes from "./entityattributes/AddEntityAttributes";
import CompanyRole from "./companyrole/CompanyRole";
import AddCompanyRole from "./companyrole/AddCompanyRole";
import UserWorkHistory from "./experience/UserWorkHistory";
import Adduserworkhistory from "./experience/Adduserworkhistory";
import AddNewBiInfoChannel from "./chat/biinfochannels/AddNewBiInfoChannel";
import UsersReports from "./reports/users/Users";
import Dashboard from "./dashboard/Dashboard";
import ViewUserReport from "./reports/users/ViewUserReport";
import AddPostComments from "./users/post-comments/AddNewPostComment";
import AddNewUserSiteProjects from "./users/user-sites-projects/AddNewUserSiteProjects";
import UserRolesAuth from "./admin/adminuserauth/UserRolesAuth";
import AddUserRolesAuth from "./admin/adminuserauth/AddUserRolesAuth";
import AddProductImages from "./manufacture/product-pictures/AddProductImages";
import AddChatGroupUserMessage from "./chat/chatgroupusermessages/AddChatGroupUserMessage";
import ProjectCompanies from "./projectcompanies/ProjectCompanies";
import AddProjectCompanies from "./projectcompanies/AddProjectCompanies";
import ProjectProducts from "./projectproducts/ProjectProducts";
import UserExperinceProduct from "./experience/userexperinceproduct/UserExperinceProduct";
import AddChatGroupMessage from "./chat/chatgroupmessages/AddChatGroupMessage";
import UserExperinceTrades from "./experience/userexperincetrades/UserExperinceTrades";
import UserExperinceRoles from "./experience/userexperinceroles/UserExperinceRoles";
import TicketDetailsMainLayout from "./ticket/ticketlayout/TicketDetailsMainLayout";
import TicketDetailsLayout from "./ticket/ticketlayout/TicketDetailsLayout";
import ClientAds from "./clientads/clientad/ClientAds";
import ClientAuth from "./clientads/clientauth/ClientAuth";
import ClientDevices from "./clientads/clientdevices/ClientDevices";
import AddClientAds from "./clientads/clientad/AddClientAds";
import Features from "./marketing/features/Features";
import Faq from "./marketing/faq/FAQ";
import AddFaq from "./marketing/faq/AddFaq";
import AddNewFeatures from "./marketing/features/AddNewFeatures";
import ClientDetailsMainLayout from "./clientads/clientlayout/ClientDetailsMainLayout";
import AddClientAuth from "./clientads/clientauth/AddClientAuth";
import ClientTableDetailsLayout from "./clientads/clientlayout/ClientTableDetailsLayout";
import AddClientRecordLayout from "./clientads/clientlayout/AddClientRecordLayout";
import AddProjectProducts from "./projectproducts/AddProjectProducts";
import { useSelector } from "react-redux";
import ChatDetailsMainLayout from "./chat/chatlayout/ChatDetailsMainLayout";
import ChatDetailsLayout from "./chat/chatlayout/ChatDetailsLayout";
import AddSites from "./sites/sites/AddSites";
import TradesDetailsMainLayout from "./miscellaneous/trades/tradeslayout/TradesDetailsMainLayout";
import TradesDetailsLayout from "./miscellaneous/trades/tradeslayout/TradesDetailsLayout";
import AddTradeRecordLayout from "./miscellaneous/trades/tradeslayout/AddTradeRecordLayout";
import EditUserRewardActivities from "./users/user-reward-Activities/EditUserRewardActivities";
import EditUserDevices from "./users/user-devices/EditUserDevices";
import AddEditUserExperinceTrades from "./experience/userexperincetrades/AddEditUserExperinceTrades";
import AddEditUserExperinceRoles from "./experience/userexperinceroles/AddEditUserExperinceRoles";
import AddUserExperinceProduct from "./experience/userexperinceproduct/AddUserExperinceProduct";
import Items from "./rewards/items/Items";
import StoreItems from "./rewards/storeItems/StoreItems";
import AddItems from "./rewards/items/AddItems";
import NewAccount from "./newaccount/NewAccount";
import AddNewStoreItems from "./rewards/storeItems/AddNewStoreItems";
import ItemsPitures from "./rewards/Itemspictures/ItemsPitures";
import AddItemsPitures from "./rewards/Itemspictures/AddItemsPitures";
import WelcomeAccount from "./newaccount/WelcomeAccount";
import RewardStore from "./rewards/rewardstore/RewardStore";
import QuizzesList from "./quizzeslist/QuizzesList";
import NotFoundPage from "./common/NotFoundPage";
import AddProductLayout from "./manufacture/products/AddProductLayout";
import Itemdetailslayout from "./rewards/items/Itemdetailslayout";
import PostLayout from "./users/posts/PostLayout";
import AddNewPostComment from "./users/post-comments/AddNewPostComment";
import AddCompanyLayout from "./companies/companylist/AddCompanyLayout";
import AddNewProduct from "./manufacture/products/AddProduct";
import SitesDetailsMainLayout from "./sites/sitelayout/SiteDetailsMainLayout";
import SiteDetailsLayout from "./sites/sitelayout/SiteDetailsLayout";
import Notification from "./notification/Notification";
import SendPushNotification from "./notification/SendPushNotification";

//new-design

function App() {
  window.axios = axios;
  const [isDark, setIsDark] = useLocalStorage("isDark", false);
  const status = useNetworkStatus();
  const { loginData } = useSelector((state) => state.main);

  const MainLayoutWrap = (comp) => {
    return (
      <>
        <MainLayout />
        {comp}
      </>
    );
  };

  const accountUserRoutes = () => {
    return (
      <Route
        exact
        path="accounts/users"
        element={<MainLayout subMenuName={"Accounts"} />}>
        <Route index exact element={<AdminUserAuth />} />
        <Route exact path=":userId" element={<UserDetailsMainLayout />}>
          <Route exact path="add-user" element={<AddUserForm />} />
          <Route exact path="edit-record" element={<AddUserForm />} />
          <Route exact path=":subMenu" element={<Outlet />}>
            <Route index exact element={<UserTableDetailsLayout />} />
            <Route
              exact
              path="t/:ticketId"
              element={<TicketDetailsMainLayout />}>
              <Route exact path="add-ticket" element={<AddNewTicket />} />
              <Route exact path="edit-ticket" element={<AddNewTicket />} />
              <Route exact path=":ticketSubMenu" element={<Outlet />}>
                <Route index exact element={<TicketDetailsLayout />} />
              </Route>
            </Route>

            <Route exact path="s/:siteId" element={<SitesDetailsMainLayout />}>
              <Route exact path="add-site" element={<AddSites />} />
              <Route exact path="edit-site" element={<AddSites />} />
              <Route exact path=":siteSubMenu" element={<Outlet />}>
                <Route index exact element={<SiteDetailsLayout />} />
                <Route
                  exact
                  path=":dataId"
                  element={<AddNewUserSiteProjects />}
                />
              </Route>
            </Route>

            <Route
              exact
              path="c/:ChatGroupId"
              element={<ChatDetailsMainLayout />}>
              <Route exact path=":chatSubMenu" element={<Outlet />}>
                <Route index exact element={<ChatDetailsLayout />} />
              </Route>
            </Route>

            <Route exact path="p/:postId" element={<PostLayout />}>
              <Route exact path="add-post" element={<AddNewPost />} />
              <Route exact path="edit-post" element={<AddNewPost />} />
              <Route exact path="post-comments" element={<Outlet />}>
                <Route index exact element={<PostComments />} />
                <Route
                  exact
                  path=":commentId"
                  element={<AddNewPostComment />}
                />
              </Route>
            </Route>

            <Route exact path=":dataId" element={<AddUserRecordLayout />} />
          </Route>
        </Route>
      </Route>
    );
  };

  return (
    <>
      <div className={`App ${isDark ? "dark" : ""}`}>
        <div className="container">
          <div id="" className={`status ${status ? "online" : "offline"}`}>
            <div className="ft-modal">
              <div className="ft-modal-content">
                <div className="text-center">
                  <img className="error-img" alt="" src={internet} />
                  <span className="display-1 d-block">OOPS!!!</span>
                  <div className="mb-4 lead">
                    Your internet connection appears to be downetcheckern.
                    Please check it and try again later.
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <BrowserRouter>
        <ToastContainer style={{ zIndex: 99999999 }} />
        <Routes>
          {loginData?.token ? (
            <>
              {loginData?.userRoleAuthId === 1 ? (
                <>
                  <Route
                    exact
                    path="/"
                    element={<MainLayout subMenuName={"Dashboard"} />}>
                    <Route index exact element={<Dashboard />} />
                  </Route>

                  <Route
                    exact
                    path="/dashboard"
                    element={<MainLayout subMenuName={"Dashboard"} />}>
                    <Route index exact element={<Dashboard />} />
                  </Route>

                  <Route exact path="/reports" element={<Outlet />}>
                    <Route
                      exact
                      path="home"
                      element={
                        <CommonMenu
                          componentName={
                            <HomePage title="Hello, I Am From Report_Home_page" />
                          }
                          subMenuName={"Reports"}
                          sidebarList={[]}
                        />
                      }
                    />
                    <Route
                      exact
                      path="users/users"
                      element={<MainLayout subMenuName="Users" />}>
                      <Route index exact element={<UsersReports />} />
                      <Route exact path=":id" element={<ViewUserReport />} />
                    </Route>
                  </Route>
                  {/* Report Routes  End */}

                  <Route
                    exact
                    path="quizzes/:userId"
                    element={<MainLayout subMenuName="Quizzes" />}>
                    <Route index exact element={<QuizzesList />} />
                  </Route>

                  {/* User Routes Start */}

                  <Route exact path="/user" element={<Outlet />}>
                    <Route
                      exact
                      path="home"
                      element={
                        <CommonMenu
                          componentName={
                            <HomePage title="Hello, I Am From User Home" />
                          }
                          subMenuName={"Users"}
                          sidebarList={[]}
                        />
                      }
                    />

                    <Route
                      exact
                      path="post/posts"
                      element={<MainLayout subMenuName={"Accounts"} />}>
                      <Route index exact element={<Post />} />
                      <Route exact path=":postId" element={<PostLayout />}>
                        <Route exact path="add-post" element={<AddNewPost />} />
                        <Route
                          exact
                          path="edit-post"
                          element={<AddNewPost />}
                        />
                        <Route exact path="post-comments" element={<Outlet />}>
                          <Route index exact element={<PostComments />} />
                          <Route
                            exact
                            path=":commentId"
                            element={<AddNewPostComment />}
                          />
                        </Route>
                      </Route>
                    </Route>

                    <Route
                      exact
                      path="t/:ticketId"
                      element={<TicketDetailsMainLayout />}>
                      <Route
                        exact
                        path="add-ticket"
                        element={<AddNewTicket />}
                      />
                      <Route
                        exact
                        path="edit-ticket"
                        element={<AddNewTicket />}
                      />
                      <Route exact path=":ticketSubMenu" element={<Outlet />}>
                        <Route index exact element={<TicketDetailsLayout />} />
                      </Route>
                    </Route>

                    <Route
                      exact
                      path="users-support/users-support"
                      element={
                        <MainLayout
                          // sidebarList={userSupportMenuList}
                          subMenuName={"Accounts"}
                        />
                      }>
                      <Route index exact element={<DataIssues />} />
                      <Route exact path=":dataId" element={<AddNewRecord />} />
                    </Route>

                    <Route
                      exact
                      path="users-devices/users-devices"
                      element={
                        <MainLayout
                          // sidebarList={userDevicesMenuList}
                          subMenuName={"Accounts"}
                        />
                      }>
                      <Route index exact element={<UserDevices />} />
                      <Route
                        exact
                        path=":dataId"
                        element={<EditUserDevices />}
                      />
                    </Route>

                    <Route
                      exact
                      path="new-account"
                      element={<MainLayout subMenuName={"New Account"} />}>
                      <Route index exact element={<NewAccount />} />
                    </Route>

                    <Route
                      exact
                      path="welcome-new-account"
                      element={<MainLayout subMenuName={"New Account"} />}>
                      <Route index exact element={<WelcomeAccount />} />
                    </Route>

                    {accountUserRoutes()}

                    <Route
                      exact
                      path="experience/user-work-history"
                      element={
                        <MainLayout
                          sidebarList={experienceMenuList}
                          subMenuName={"Experience"}
                        />
                      }>
                      <Route index exact element={<UserWorkHistory />} />
                      <Route
                        exact
                        path=":dataId"
                        element={<Adduserworkhistory />}
                      />
                    </Route>
                    <Route
                      exact
                      path="experience/tickets"
                      element={
                        <MainLayout
                          sidebarList={experienceMenuList}
                          subMenuName={"Accounts"}
                        />
                      }>
                      <Route index exact element={<Ticket />} />

                      <Route
                        exact
                        path=":ticketId"
                        element={<TicketDetailsMainLayout />}>
                        <Route
                          exact
                          path="add-ticket"
                          element={<AddNewTicket />}
                        />
                        <Route
                          exact
                          path="edit-ticket"
                          element={<AddNewTicket />}
                        />
                        <Route exact path=":ticketSubMenu" element={<Outlet />}>
                          <Route
                            index
                            exact
                            element={<TicketDetailsLayout />}
                          />
                        </Route>
                      </Route>
                    </Route>
                    <Route
                      exact
                      path="experience/user-experience-product"
                      element={
                        <MainLayout
                          sidebarList={experienceMenuList}
                          subMenuName={"Experience"}
                        />
                      }>
                      <Route index exact element={<UserExperinceProduct />} />
                      <Route
                        index
                        exact
                        path=":dataId"
                        element={<AddUserExperinceProduct />}
                      />
                    </Route>
                    <Route
                      exact
                      path="experience/user-trades-experience"
                      element={
                        <MainLayout
                          sidebarList={experienceMenuList}
                          subMenuName={"Experience"}
                        />
                      }>
                      <Route index exact element={<UserExperinceTrades />} />
                      <Route
                        exact
                        path=":dataId"
                        element={<AddEditUserExperinceTrades />}
                      />
                    </Route>
                    <Route
                      exact
                      path="experience/user-roles-experience"
                      element={
                        <MainLayout
                          sidebarList={experienceMenuList}
                          subMenuName={"Experience"}
                        />
                      }>
                      <Route index exact element={<UserExperinceRoles />} />
                      <Route
                        exact
                        path=":dataId"
                        element={<AddEditUserExperinceRoles />}
                      />
                    </Route>
                    <Route
                      exact
                      path="rewards/user-quizzes"
                      element={
                        <CommonMenu
                          componentName={<UserQuestions />}
                          sidebarList={rewardsMenuList}
                          subMenuName={"Rewards"}
                        />
                      }
                    />
                    <Route
                      exact
                      path="rewards/user-answers"
                      element={
                        <CommonMenu
                          componentName={<UserAnswers />}
                          sidebarList={rewardsMenuList}
                          subMenuName={"Rewards"}
                        />
                      }
                    />
                    <Route
                      exact
                      path="rewards/user-reward-activites"
                      element={
                        <MainLayout
                          sidebarList={rewardsMenuList}
                          subMenuName={"Rewards"}
                        />
                      }>
                      <Route index exact element={<UserRewardActivities />} />
                      <Route
                        exact
                        path=":dataId"
                        element={<EditUserRewardActivities />}
                      />
                    </Route>

                    {/* <Route
                            exact
                            path="t/:ticketId"
                            element={<TicketDetailsMainLayout />}>
                            <Route
                              exact
                              path="add-ticket"
                              element={<AddNewTicket />}
                            />
                            <Route
                              exact
                              path="edit-ticket"
                              element={<AddNewTicket />}
                            />
                            <Route
                              exact
                              path=":ticketSubMenu"
                              element={<Outlet />}>
                              <Route
                                index
                                exact
                                element={<TicketDetailsLayout />}
                              />
                            </Route>
                          </Route> */}

                    <Route
                      exact
                      path="rewards/items"
                      element={
                        <MainLayout
                          sidebarList={rewardsMenuList}
                          subMenuName={"Rewards"}
                        />
                      }>
                      <Route index exact element={<Items />} />
                      <Route
                        exact
                        path=":dataId"
                        element={<Itemdetailslayout />}>
                        <Route exact path="add-items" element={<AddItems />} />
                        <Route exact path="edit-item" element={<AddItems />} />
                        <Route exact path="items-pictures" element={<Outlet />}>
                          <Route index exact element={<ItemsPitures />} />
                          <Route
                            exact
                            path="add-items-pitures"
                            element={<AddItemsPitures />}
                          />
                        </Route>
                      </Route>
                    </Route>

                    <Route
                      exact
                      path="rewards/store-items"
                      element={
                        <MainLayout
                          sidebarList={rewardsMenuList}
                          subMenuName={"Rewards"}
                        />
                      }>
                      <Route index exact element={<StoreItems />} />

                      <Route
                        exact
                        path=":dataId"
                        element={<AddNewStoreItems />}
                      />
                    </Route>

                    {/* <Route
                      exact
                      path="rewards/reward-store"
                      element={
                        <MainLayout
                          sidebarList={rewardsMenuList}
                          subMenuName={"Rewards"}
                        />
                      }>
                      <Route index exact element={<RewardStore />} />
                    </Route> */}

                    {/* //! */}
                    <Route
                      exact
                      path="chat/chat-groups"
                      element={
                        <MainLayout
                          sidebarList={chatMenuList}
                          subMenuName={"Chat"}
                        />
                      }>
                      <Route index exact element={<ChatGroups />} />

                      <Route
                        exact
                        path=":ChatGroupId"
                        element={<ChatDetailsMainLayout />}>
                        <Route exact path=":chatSubMenu" element={<Outlet />}>
                          <Route index exact element={<ChatGroupMessages />} />
                          <Route
                            exact
                            path=":dataId"
                            element={<AddChatGroupMessage />}
                          />
                        </Route>
                      </Route>
                    </Route>
                    <Route
                      exact
                      path="chat/chat-groups-users"
                      element={
                        <MainLayout
                          sidebarList={chatMenuList}
                          subMenuName={"Chat"}
                        />
                      }>
                      <Route index exact element={<ChatGroupUsers />} />
                      <Route
                        exact
                        path=":dataId"
                        element={<AddChatGroupUser />}
                      />
                    </Route>
                    <Route
                      exact
                      path="chat/chat-groups-user-messages"
                      element={
                        <MainLayout
                          sidebarList={chatMenuList}
                          subMenuName={"Chat"}
                        />
                      }>
                      <Route index exact element={<ChatGroupUserMessages />} />
                      <Route
                        exact
                        path=":dataId"
                        element={<AddChatGroupUserMessage />}
                      />
                    </Route>
                    <Route
                      exact
                      path="chat/chat-groups-messages"
                      element={
                        <MainLayout
                          sidebarList={chatMenuList}
                          subMenuName={"Chat"}
                        />
                      }>
                      <Route index exact element={<ChatGroupMessages />} />
                      <Route
                        exact
                        path=":dataId"
                        element={<AddChatGroupMessage />}
                      />
                    </Route>
                  </Route>

                  {/* Admin Routes  Start */}
                  <Route exact path="/admin" element={<Outlet />}>
                    <Route
                      exact
                      path="home"
                      element={
                        <CommonMenu
                          componentName={
                            <HomePage title="Hello, I Am From Admin_Home_page" />
                          }
                          subMenuName={"Admin"}
                          sidebarList={[]}
                        />
                      }
                    />
                    <Route
                      exact
                      path="rewards/reward-activities"
                      element={
                        <MainLayout
                          sidebarList={rewardMenulist}
                          subMenuName="Rewards"
                        />
                      }>
                      <Route index exact element={<RewardActivities />} />
                      <Route
                        exact
                        path=":dataId"
                        element={<AddRewardActivities />}
                      />
                    </Route>

                    <Route
                      exact
                      path="quizzes/quizzes"
                      element={
                        <MainLayout
                          sidebarList={quizzesMenuList}
                          subMenuName="Quizzes"
                        />
                      }>
                      <Route index exact element={<Quizzes />} />
                      <Route exact path=":id" element={<AddQuizzes />} />
                    </Route>

                    <Route
                      exact
                      path="quizzes/entities"
                      element={
                        <MainLayout
                          sidebarList={quizzesMenuList}
                          subMenuName="Quizzes"
                        />
                      }>
                      <Route index exact element={<Entitities />} />
                      <Route exact path=":id" element={<AddEntity />} />
                    </Route>

                    <Route
                      exact
                      path="quizzes/attributes"
                      element={
                        <MainLayout
                          sidebarList={quizzesMenuList}
                          subMenuName="Quizzes"
                        />
                      }>
                      <Route index exact element={<Attributes />} />
                      <Route exact path=":id" element={<AddAttributes />} />
                    </Route>

                    <Route
                      exact
                      path="quizzes/entity-attributes"
                      element={
                        <MainLayout
                          sidebarList={quizzesMenuList}
                          subMenuName="Quizzes"
                        />
                      }>
                      <Route index exact element={<EntityAttributes />} />
                      <Route
                        exact
                        path=":id"
                        element={<AddEntityAttributes />}
                      />
                    </Route>

                    {/* <Route
              exact
              path="user-roles/user-roles"
              element={
                <CommonMenu
                  componentName={
                    <HomePage title="Hello, I Am From User Role" />
                  }
                  subMenuName={"User Roles"}
                  sidebarList={userRolesMenuList}
                />
              }
            /> */}
                    <Route
                      exact
                      path="user-roles/user-roles"
                      element={
                        <MainLayout
                          sidebarList={userRolesMenuList}
                          subMenuName="User Roles"
                        />
                      }>
                      <Route index exact element={<UserRolesAuth />} />
                      <Route
                        exact
                        path=":dataId"
                        element={<AddUserRolesAuth />}
                      />
                    </Route>

                    {/* <Route
                  exact
                  path=""
                  element={
                    <MainLayout
                      sidebarList={}
                      subMenuName={""}
                    />
                  }>
                  <Route index exact element={< />} />
                  <Route
                    exact
                    path=":dataId"
                    element={< />}
                  />
                </Route> */}

                    <Route
                      exact
                      path="trades/trades"
                      element={
                        <MainLayout
                          sidebarList={tradeMenuList}
                          subMenuName={"Trades"}
                        />
                      }>
                      <Route index exact element={<MiscellaneTrades />} />

                      <Route
                        exact
                        path=":tradeId"
                        element={<TradesDetailsMainLayout />}>
                        <Route
                          exact
                          path="add-trade"
                          element={<AddMiscellaneTrades />}
                        />
                        <Route
                          exact
                          path="edit-trade"
                          element={<AddMiscellaneTrades />}
                        />
                        <Route exact path=":tradeSubMenu" element={<Outlet />}>
                          <Route
                            index
                            exact
                            element={<TradesDetailsLayout />}
                          />
                          <Route
                            exact
                            path=":dataId"
                            element={<AddTradeRecordLayout />}
                          />
                        </Route>
                      </Route>
                    </Route>

                    <Route
                      exact
                      path="trades/alternate-trade-name"
                      element={
                        <MainLayout
                          sidebarList={tradeMenuList}
                          subMenuName={"Trades"}
                        />
                      }>
                      <Route index exact element={<AlternateTradeNames />} />
                      <Route
                        exact
                        path=":dataId"
                        element={<AddNewAlternateTradeNames />}
                      />
                    </Route>
                    <Route
                      exact
                      path="trades/releted-trades"
                      element={
                        <MainLayout
                          sidebarList={tradeMenuList}
                          subMenuName={"Trades"}
                        />
                      }>
                      <Route index exact element={<ReletedTrades />} />
                      <Route
                        exact
                        path=":dataId"
                        element={<AddNewReletedTrades />}
                      />
                    </Route>

                    <Route
                      exact
                      path="company-roles/company-roles"
                      element={
                        <MainLayout
                          // sidebarList={compnayRoleMenuList}
                          subMenuName={"Company Roles"}
                        />
                      }>
                      <Route index exact element={<CompanyRole />} />
                      <Route
                        exact
                        path=":dataId"
                        element={<AddCompanyRole />}
                      />
                    </Route>

                    <Route
                      exact
                      path="ads/ads"
                      element={
                        <MainLayout
                          // sidebarList={adsMenuList}
                          subMenuName={"Ads"}
                        />
                      }>
                      <Route index exact element={<Ads />} />
                      <Route exact path=":dataId" element={<AddAds />} />
                    </Route>
                    <Route
                      exact
                      path="notification/notification"
                      element={
                        <MainLayout
                          // sidebarList={adsMenuList}
                          subMenuName={"Notification"}
                        />
                      }>
                      <Route index exact element={<Notification />} />
                      <Route
                        exact
                        path=":dataId"
                        element={<SendPushNotification />}
                      />
                    </Route>

                    <Route
                      exact
                      path="ads/client-auth"
                      element={
                        <CommonMenu
                          componentName={
                            <HomePage title="Hello, I Am From Client Auth" />
                          }
                          subMenuName={"Ads"}
                          sidebarList={adsMenuList}
                        />
                      }
                    />

                    <Route
                      exact
                      path="ads/client-devices"
                      element={
                        <CommonMenu
                          componentName={<ClientDevices />}
                          subMenuName={"Ads"}
                          sidebarList={adsMenuList}
                        />
                      }
                    />
                  </Route>
                  {/* Admin Routes  End */}

                  <Route exact path="/client" element={<Outlet />}>
                    <Route
                      exact
                      path="home"
                      element={
                        <CommonMenu
                          componentName={
                            <HomePage title="Hello, I Am From Client Home" />
                          }
                          subMenuName={"client"}
                          sidebarList={[]}
                        />
                      }
                    />

                    <Route
                      exact
                      path="accounts/client-auth"
                      element={
                        <MainLayout
                          sidebarList={ClientMenuList}
                          subMenuName={"Accounts"}
                        />
                      }>
                      <Route index exact element={<ClientAuth />} />
                      <Route
                        exact
                        path=":clientId"
                        element={<ClientDetailsMainLayout />}>
                        <Route
                          exact
                          path="add-client"
                          element={<AddClientAuth />}
                        />
                        <Route
                          exact
                          path="edit-record"
                          element={<AddClientAuth />}
                        />
                        <Route exact path=":clientSubMenu" element={<Outlet />}>
                          <Route
                            index
                            exact
                            element={<ClientTableDetailsLayout />}
                          />
                          <Route
                            exact
                            path=":dataId"
                            element={<AddClientRecordLayout />}
                          />
                        </Route>
                      </Route>
                    </Route>

                    <Route
                      exact
                      path="accounts/client-ads"
                      element={
                        <MainLayout
                          sidebarList={ClientMenuList}
                          subMenuName={"Accounts"}
                        />
                      }>
                      <Route index exact element={<ClientAds />} />
                      <Route exact path=":dataId" element={<AddClientAds />} />
                    </Route>

                    <Route
                      exact
                      path="accounts/client-devices"
                      element={
                        <MainLayout
                          sidebarList={ClientMenuList}
                          subMenuName={"Accounts"}
                        />
                      }>
                      <Route index exact element={<ClientDevices />} />
                    </Route>

                    <Route
                      exact
                      path="rewards/user-quizzes"
                      element={
                        <CommonMenu
                          componentName={<UserQuestions />}
                          sidebarList={rewardsMenuList}
                          subMenuName={"Rewards"}
                        />
                      }
                    />

                    <Route
                      exact
                      path="rewards/user-answers"
                      element={
                        <CommonMenu
                          componentName={<UserAnswers />}
                          sidebarList={rewardsMenuList}
                          subMenuName={"Rewards"}
                        />
                      }
                    />

                    <Route
                      exact
                      path="rewards/user-reward-activites"
                      element={
                        <MainLayout
                          sidebarList={rewardsMenuList}
                          subMenuName={"Rewards"}
                        />
                      }>
                      <Route index exact element={<UserRewardActivities />} />
                      {/* <Route exact path=":dataId" element={<AddRewardActivities />} /> */}
                    </Route>

                    <Route
                      exact
                      path="tickets/tickets"
                      element={
                        <MainLayout
                          sidebarList={ticketsMenuList}
                          subMenuName={"Tickets"}
                        />
                      }>
                      <Route index exact element={<Ticket />} />
                      <Route exact path=":dataId" element={<AddNewTicket />} />
                    </Route>

                    <Route
                      exact
                      path="tickets/shared-with"
                      element={
                        <CommonMenu
                          componentName={<SharedTickets />}
                          sidebarList={ticketsMenuList}
                          subMenuName={"Tickets"}
                        />
                      }
                    />

                    <Route
                      exact
                      path="chat/chat-groups"
                      element={
                        <MainLayout
                          sidebarList={chatMenuList}
                          subMenuName={"Chat"}
                        />
                      }>
                      <Route index exact element={<ChatGroups />} />
                      <Route
                        exact
                        path=":dataId"
                        element={<ChatGroupMessages />}
                      />
                    </Route>

                    <Route
                      exact
                      path="chat/chat-groups-users"
                      element={
                        <MainLayout
                          sidebarList={chatMenuList}
                          subMenuName={"Chat"}
                        />
                      }>
                      <Route index exact element={<ChatGroupUsers />} />
                      <Route
                        exact
                        path=":dataId"
                        element={<AddChatGroupUser />}
                      />
                    </Route>
                    <Route
                      exact
                      path="chat/chat-groups-user-messages"
                      element={
                        <MainLayout
                          sidebarList={chatMenuList}
                          subMenuName={"Chat"}
                        />
                      }>
                      <Route index exact element={<ChatGroupUserMessages />} />
                      <Route
                        exact
                        path=":dataId"
                        element={<AddChatGroupUserMessage />}
                      />
                    </Route>

                    <Route
                      exact
                      path="chat/chat-groups-messages"
                      element={
                        <MainLayout
                          sidebarList={chatMenuList}
                          subMenuName={"Chat"}
                        />
                      }>
                      <Route index exact element={<ChatGroupMessages />} />
                      <Route
                        exact
                        path=":dataId"
                        element={<AddChatGroupMessage />}
                      />
                    </Route>
                  </Route>

                  <Route exact path="/marketing" element={<Outlet />}>
                    <Route
                      exact
                      path="home"
                      element={
                        <CommonMenu
                          componentName={
                            <HomePage title="Hello, I Am From Marketing Home" />
                          }
                          subMenuName={"client"}
                          sidebarList={[]}
                        />
                      }
                    />
                    <Route
                      exact
                      path="bill-info-channels"
                      element={
                        <MainLayout
                          sidebarList={biRoleMenuList}
                          subMenuName={"BI Info Channels"}
                        />
                      }>
                      <Route index exact element={<BiInfoChannels />} />
                      <Route
                        exact
                        path=":dataId"
                        element={<AddNewBiInfoChannel />}
                      />
                    </Route>
                    <Route
                      exact
                      path="features"
                      element={
                        <MainLayout
                          sidebarList={featuresMenuList}
                          subMenuName={"Features"}
                        />
                      }>
                      <Route index exact element={<Features />} />
                      <Route
                        exact
                        path=":dataId"
                        element={<AddNewFeatures />}
                      />
                    </Route>
                    <Route
                      exact
                      path="faq"
                      element={
                        <MainLayout
                          sidebarList={faqMenuList}
                          subMenuName={"FAQ"}
                        />
                      }>
                      <Route index exact element={<Faq />} />
                      <Route exact path=":dataId" element={<AddFaq />} />
                    </Route>
                  </Route>
                  {/* User Routes End */}

                  {/* Sites Routes Start */}
                  <Route
                    exact
                    path="/sites"
                    element={
                      <MainLayout
                        sidebarList={sitesMenuList}
                        subMenuName={"Sites"}
                      />
                    }>
                    <Route exact path="sites" element={<Outlet />}>
                      <Route index exact element={<Sites />} />
                      <Route
                        exact
                        path=":siteId/add-site"
                        element={<AddSites />}
                      />
                      <Route
                        exact
                        path=":siteId/edit-site"
                        element={<AddSites />}
                      />
                    </Route>
                    <Route exact path="user-sites-project" element={<Outlet />}>
                      <Route index exact element={<UserSitesProjects />} />
                      <Route
                        exact
                        path=":dataId"
                        element={<AddNewUserSiteProjects />}
                      />
                    </Route>

                    <Route exact path="project-companies" element={<Outlet />}>
                      <Route index exact element={<ProjectCompanies />} />
                      <Route
                        exact
                        path=":dataId"
                        element={<AddProjectCompanies />}
                      />
                    </Route>

                    <Route exact path="project-products" element={<Outlet />}>
                      <Route index exact element={<ProjectProducts />} />
                      <Route
                        exact
                        path=":dataId"
                        element={<AddProjectProducts />}
                      />
                    </Route>
                  </Route>
                  {/* Sites Routes End */}

                  {/* Product Routes Start */}
                  <Route
                    exact
                    path="/products"
                    element={<MainLayout subMenuName={"Product"} />}>
                    <Route exact path="products" element={<Outlet />}>
                      <Route index exact element={<ProductList />} />
                      <Route
                        exact
                        path=":dataId"
                        element={<AddProductLayout />}>
                        <Route
                          exact
                          path="add-product"
                          element={<AddNewProduct />}
                        />
                        <Route
                          exact
                          path="edit-product"
                          element={<AddNewProduct />}
                        />
                        <Route exact path="product-images" element={<Outlet />}>
                          <Route
                            index
                            exact
                            element={<ProductPicturesList />}
                          />
                          <Route
                            exact
                            path=":productImageId"
                            element={<AddProductImages />}
                          />
                        </Route>
                      </Route>
                    </Route>
                  </Route>
                  {/* Product Routes End */}

                  {/* Companies Routes Start */}

                  <Route
                    exact
                    path="/companies"
                    element={<MainLayout subMenuName={"Companies"} />}>
                    <Route exact path="companies" element={<Outlet />}>
                      <Route index exact element={<CompanyList />} />
                      <Route
                        exact
                        path=":companyId"
                        element={<AddCompanyLayout />}>
                        <Route
                          exact
                          path="add-company"
                          element={<Addcompany />}
                        />
                        <Route
                          exact
                          path="edit-company"
                          element={<Addcompany />}
                        />
                        <Route
                          exact
                          path="company-address"
                          element={<Outlet />}>
                          <Route index exact element={<CompanyAddress />} />
                          <Route
                            exact
                            path=":companyAddressId"
                            element={<AddcompanyAddress />}
                          />
                        </Route>
                      </Route>
                    </Route>
                  </Route>

                  <Route path="*" element={<NotFoundPage />} />
                </>
              ) : (
                <>
                  <Route
                    exact
                    path="/"
                    element={<MainLayout subMenuName={"Dashboard"} />}>
                    <Route index exact element={<Dashboard />} />
                  </Route>

                  <Route
                    exact
                    path="/dashboard"
                    element={<MainLayout subMenuName={"Dashboard"} />}>
                    <Route index exact element={<Dashboard />} />
                  </Route>

                  <Route exact path="/reports" element={<Outlet />}>
                    <Route
                      exact
                      path="home"
                      element={
                        <CommonMenu
                          componentName={
                            <HomePage title="Hello, I Am From Report_Home_page" />
                          }
                          subMenuName={"Reports"}
                          sidebarList={[]}
                        />
                      }
                    />
                    <Route
                      exact
                      path="users/users"
                      element={<MainLayout subMenuName="Users" />}>
                      <Route index exact element={<UsersReports />} />
                      <Route exact path=":id" element={<ViewUserReport />} />
                    </Route>
                  </Route>
                  {/* Report Routes  End */}

                  {/* User Routes Start */}

                  <Route exact path="/user" element={<Outlet />}>
                    <Route
                      exact
                      path="home"
                      element={
                        <CommonMenu
                          componentName={
                            <HomePage title="Hello, I Am From User Home" />
                          }
                          subMenuName={"Users"}
                          sidebarList={[]}
                        />
                      }
                    />

                    <Route
                      exact
                      path="new-account"
                      element={<MainLayout subMenuName={"New Account"} />}>
                      <Route index exact element={<NewAccount />} />
                    </Route>
                    <Route
                      exact
                      path="welcome-new-account"
                      element={<MainLayout subMenuName={"New Account"} />}>
                      <Route index exact element={<WelcomeAccount />} />
                    </Route>

                    {/* <Route
                      exact
                      path="accounts/users"
                      element={<MainLayout subMenuName={"Accounts"} />}>
                      <Route index exact element={<AdminUserAuth />} />
                      <Route
                        exact
                        path=":userId"
                        element={<UserDetailsMainLayout />}>
                        <Route
                          exact
                          path="add-user"
                          element={<AddUserForm />}
                        />
                        <Route
                          exact
                          path="edit-record"
                          element={<AddUserForm />}
                        />
                        <Route exact path=":subMenu" element={<Outlet />}>
                          <Route
                            index
                            exact
                            element={<UserTableDetailsLayout />}
                          />
                          <Route
                            exact
                            path="t/:ticketId"
                            element={<TicketDetailsMainLayout />}>
                            <Route
                              exact
                              path="add-ticket"
                              element={<AddNewTicket />}
                            />
                            <Route
                              exact
                              path="edit-ticket"
                              element={<AddNewTicket />}
                            />
                            <Route
                              exact
                              path=":ticketSubMenu"
                              element={<Outlet />}>
                              <Route
                                index
                                exact
                                element={<TicketDetailsLayout />}
                              />
                            </Route>
                          </Route>
                          <Route
                            exact
                            path="c/:ChatGroupId"
                            element={<ChatDetailsMainLayout />}>
                            <Route
                              exact
                              path=":chatSubMenu"
                              element={<Outlet />}>
                              <Route
                                index
                                exact
                                element={<ChatDetailsLayout />}
                              />
                            </Route>
                          </Route>
                          <Route
                            exact
                            path=":dataId"
                            element={<AddUserRecordLayout />}
                          />
                        </Route>
                      </Route>
                    </Route> */}
                    {accountUserRoutes()}
                  </Route>
                  <Route path="*" element={<NotFoundPage />} />
                </>
              )}
            </>
          ) : (
            <>
              <Route path="/forget-password" element={<ForgetPassword />} />
              {/* <Route path="*" element={<NewAccount />} /> */}
              <Route path="*" element={<LoginPage />} />
            </>
          )}

          {/* Companies Routes End */}
        </Routes>
      </BrowserRouter>
    </>
  );
}

export default App;

function CommonMenu({ componentName, sidebarList, subMenuName }) {
  return (
    <MainLayout sidebarList={sidebarList} subMenuName={subMenuName}>
      {componentName}
    </MainLayout>
  );
}
