import React, { useEffect, useState } from "react";
import KendoButton from "../common/KendoButton";
import { useParams } from "react-router-dom";
import { AutoComplete } from "@progress/kendo-react-dropdowns";
import { GridLayout, GridLayoutItem } from "@progress/kendo-react-layout";
import { Error, Label } from "@progress/kendo-react-labels";
import { Form } from "@progress/kendo-react-form";
import { PostRequestCall } from "../apicall/PostRequest";
import { toast } from "react-toastify";
import {
  DELETE_PROJECT_COMPANIES,
  FETCH_COMPANIES,
  FETCH_PROJECT_COMPANIES,
  FETCH_PROJECT_COMPANY_ROLES,
  FETCH_USER_SITES_PROJECTS,
  SAVE_PROJECT_COMPANIES,
} from "../constant/Apipath";
import { useSelector } from "react-redux";
import { Loader } from "@progress/kendo-react-indicators";
import Modal from "../common/Modal";

const AddProjectCompanies = () => {
  const { dataId, subMenu, userId } = useParams();
  const { loginData } = useSelector((state) => state.main);
  const [projectOptions, setProjectoptions] = useState([]);
  const [selectProject, setSelectproject] = useState(null);
  const [companyRoleOptions, setCompanyroleOptions] = useState([]);
  const [selectCompanyrole, setSelectCompanyrole] = useState(null);
  const [companyOptions, setCompanyOptions] = useState([]);
  const [selectCompany, setSelectCompany] = useState(null);
  const [isFieldEdited, setIsFiledEdited] = useState(false);
  const [openProject, setOpenproject] = useState(false);
  const [openCompany, setOpencompany] = useState(false);
  const [openCompanyrole, setOpencompanyrole] = useState(false);
  const [showLoader, setShowLoader] = useState(false);
  const [projectCompanies, setProjectCompanies] = useState({
    id: 0,
    startDate: new Date(),
    endDate: new Date(),
    present: false,
    includeInResume: false,
    description: "",
  });
  const [modalState, setModalState] = useState({
    for: "",
    show: false,
    onActionBtnTitle: "",
  });

  useEffect(() => {
    if (dataId !== "add-project-companies") {
      fetchProjectCompanies();
    }
  }, []);

  useEffect(() => {
    if (selectProject !== null && typeof selectProject?.value === "undefined") {
      if (selectProject !== "") {
        getProjectlist(false);
      }
    }
  }, [selectProject]);

  useEffect(() => {
    if (selectCompany !== null && typeof selectCompany?.value === "undefined") {
      if (selectCompany !== "") {
        getCompanylist(false);
      }
    }
  }, [selectCompany]);

  useEffect(() => {
    if (
      selectCompanyrole !== null &&
      typeof selectCompanyrole?.value === "undefined"
    ) {
      if (selectCompanyrole !== "") {
        getCompanyrole(false);
      }
    }
  }, [selectCompanyrole]);

  const fetchProjectCompanies = async () => {
    try {
      let getObject = {
        SearchList: [{ id: dataId }],
      };
      const getResult = await PostRequestCall(
        FETCH_PROJECT_COMPANIES,
        getObject,
        loginData?.token
      );
      if (getResult?.status === 200) {
        if (getResult?.data?.data?.length > 0) {
          let getUserwork = getResult?.data?.data[0];
          setProjectCompanies({
            ...projectCompanies,
            startDate: getUserwork?.startDate
              ? new Date(getUserwork?.startDate)
              : null,
            endDate:
              getUserwork?.endDate === null
                ? null
                : new Date(getUserwork?.endDate),
            includeInResume: getUserwork?.includeInResume,
            present: getUserwork?.present,
            description: getUserwork?.description,
          });

          setSelectproject({
            value: getUserwork?.projectId,
            label: getUserwork?.projectName,
          });
          setSelectCompany({
            value: getUserwork?.companyId,
            label: getUserwork?.companyName,
          });
          setSelectCompanyrole({
            value: getUserwork?.projectCompanyRoleId,
            label: getUserwork?.projectCompanyRole,
          });
        }
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    } finally {
    }
  };

  const getProjectlist = async (onFocus) => {
    try {
      let obj = {
        NrOfRecPerPage: onFocus ? 20 : 0,
        FullSearch: "",
        SortList: [{ FieldName: "Id", Direction: "DESC" }],
        SearchList: [{ projectName: selectProject }],
        IncludeRecordNr: true,
        TypeOfObjectReturned: "",
        FetchAllowedRecordsOnly: false,
      };
      const fetchSiteProjectListRes = await PostRequestCall(
        FETCH_USER_SITES_PROJECTS,
        obj,
        loginData?.token
      );
      console.log("fetchSiteProjectListRes", fetchSiteProjectListRes);
      if (fetchSiteProjectListRes?.data?.data?.length > 0) {
        const getList = fetchSiteProjectListRes?.data?.data.map((project) => ({
          value: project?.id,
          label: project?.projectName,
        }));
        setProjectoptions(getList);
        setOpenproject(true);
      }
    } catch (error) {
      console.log("error", error);
    }
  };

  const getCompanylist = async (onFocus) => {
    try {
      let companyObj = {
        NrOfRecPerPage: onFocus ? 20 : 0,
        FullSearch: "",
        UserId: loginData.userId,
        SearchList: onFocus ? [] : [{ name: selectCompany }],
        SortList: [{ FieldName: "Id", Direction: "ASC" }],
        IncludeRecordNr: true,
      };
      const companyRes = await PostRequestCall(
        FETCH_COMPANIES,
        companyObj,
        loginData?.token
      );
      let companyData = companyRes?.data?.data?.map((record) => ({
        value: record?.id,
        label: record?.name,
      }));
      setCompanyOptions(companyData ?? []);
      setOpencompany(true);
    } catch (error) {
      console.log("Error while getting company list :: ", error);
    }
  };

  const getCompanyrole = async (onFocus) => {
    try {
      let getObject = {
        NrOfRecPerPage: onFocus ? 20 : 0,
        FullSearch: "",
        UserId: loginData?.userId,
        IncludeRecordNr: "true",
        TypeOfObjectReturned: "",
        FetchAllowedRecordsOnly: false,
        SearchList: onFocus ? [] : [{ name: selectCompanyrole }],
        SortList: [],
      };
      let getList = await PostRequestCall(
        FETCH_PROJECT_COMPANY_ROLES,
        getObject,
        loginData?.token
      );
      let companyRole = getList?.data?.data?.map((role) => ({
        value: role?.id,
        label: role?.name,
      }));
      setCompanyroleOptions(companyRole?.length > 0 ? companyRole : []);
      setOpencompanyrole(true);
    } catch (error) {}
  };

  const onProjectchange = (event) => {
    setSelectproject(event?.target?.value);
  };

  const onCompanychange = (event) => {
    setSelectCompany(event?.target?.value);
  };

  const onCompanyrolechange = (event) => {
    setSelectCompanyrole(event?.target?.value);
  };

  const projectSelect = (project) => {
    setSelectproject(project);
    setOpenproject(false);
  };

  const companySelect = (company) => {
    setSelectCompany(company);
    setOpencompany(false);
  };

  const onCompanyroleSelect = (companyRole) => {
    setSelectCompanyrole(companyRole);
    setOpencompanyrole(false);
  };

  const onProjectRender = (li, itemProps) => {
    const project = itemProps.dataItem;
    return (
      <div className="user-item" onClick={() => projectSelect(project)}>
        <span>{project?.label}</span>
      </div>
    );
  };

  const onCompanyRender = (li, itemProps) => {
    const company = itemProps.dataItem;
    return (
      <div className="user-item" onClick={() => companySelect(company)}>
        <span>{company?.label}</span>
      </div>
    );
  };

  const onCompanyroleRender = (li, itemProps) => {
    const companyRole = itemProps.dataItem;
    return (
      <div
        className="user-item"
        onClick={() => onCompanyroleSelect(companyRole)}>
        <span>{companyRole?.label}</span>
      </div>
    );
  };

  const saveCurrentdata = async () => {
    const data = [
      {
        Id: dataId !== "add-project-companies" ? dataId : 0,
        ProjectId: selectProject?.value,
        projectCompanyRoleId: selectCompanyrole?.value,
        CompanyId: selectCompany?.value,
        ModifiedBy: loginData?.userId,
        recordStatusId: 1,
      },
    ];
    console.log("getObject", data);
    let getResponse = await PostRequestCall(
      SAVE_PROJECT_COMPANIES,
      data,
      loginData?.token
    );
    if (getResponse?.status === 201) {
      window.history.back();
    }
  };

  const onModalClose = () => {
    window.history.back();
  };

  const onActionBtnClick = () => {
    setModalState((preValue) => ({
      ...preValue,
      for: "",
      show: false,
      onActionBtnTitle: "",
    }));
    if (modalState?.for === "delete") {
      onDeleteClick();
    } else if (modalState?.for === "edit") {
      saveCurrentdata();
    } else {
      return null;
    }
  };

  const onDeleteClick = async () => {
    try {
      setShowLoader(true);

      const deleteObj = [
        {
          Id: Number(dataId),
          ModifiedBy: loginData?.userId,
        },
      ];

      PostRequestCall(DELETE_PROJECT_COMPANIES, deleteObj, loginData?.token)
        .then(async (res) => {
          setShowLoader(false);
          if (res?.data?.status) {
            toast.success("Data deleted successfully", {
              position: "top-right",
            });
            window.history.back();
          } else {
            toast.error(
              res?.data?.message ??
                "Something went wrong. Please try again later.",
              {
                position: "top-right",
              }
            );
          }
        })
        .catch(async (error) => {
          setShowLoader(false);
          toast.error("Something went wrong. Please try again later.", {
            position: "top-right",
          });
        });
    } catch (error) {
      setShowLoader(false);
      toast.error("Something went wrong. Please try again later.", {
        position: "top-right",
      });
    }
  };

  const showDeleteConfirmation = () => {
    setModalState((preValue) => ({
      ...preValue,
      for: "delete",
      show: true,
      onActionBtnTitle: "Delete",
    }));
  };

  const onCancelClick = () => {
    if (isFieldEdited) {
      return setModalState((preValue) => ({
        ...preValue,
        for: "edit",
        show: true,
        onActionBtnTitle: "Save",
      }));
    } else {
      return window.history.back();
    }
  };

  if (showLoader) {
    return (
      <Loader
        type="converging-spinner"
        className="kendo-spinner"
        style={{
          display: "flex",
          justifyContent: "center",
          minHeight: "500px",
          alignItems: "center",
        }}
      />
    );
  }

  return (
    <>
      <section className="add-user">
        <>
          <section className="user-information">
            <div className="k-d-flex k-justify-content-between">
              <div className="k-d-flex k-align-items-center">
                {dataId !== "add-project-companies" && (
                  <span class="record-id-text">
                    User Work History: {dataId}
                  </span>
                )}
              </div>
              <div
                className="k-d-flex k-justify-content-end k-p-2"
                style={{ gap: "10px" }}>
                <KendoButton
                  iconClass=""
                  size={16}
                  className=""
                  themeColor={"primary"}
                  buttonName="Save"
                  onClick={saveCurrentdata}
                />
                {dataId !== "add-project-companies" && (
                  <KendoButton
                    size={16}
                    className=""
                    onClick={showDeleteConfirmation}
                    buttonName="Delete"
                    themeColor={"secondary"}
                  />
                )}
                <KendoButton
                  iconClass=""
                  size={16}
                  className=""
                  buttonName="Cancel"
                  onClick={onCancelClick}
                />
              </div>
            </div>
            <div
              style={{
                borderBottom: "1px solid #f5f5f5",
                width: "100%",
              }}></div>
            <Form
              render={(formRenderProps) => (
                <GridLayout
                  rows={[
                    {
                      height: 70,
                    },
                    {
                      height: 70,
                    },
                    {
                      height: 70,
                    },
                    {
                      height: 70,
                    },
                    {
                      height: 80,
                    },
                    {
                      height: 70,
                    },
                  ]}
                  cols={[
                    {
                      width: 375,
                    },
                    {
                      width: 375,
                    },
                    {
                      width: 375,
                    },
                  ]}
                  gap={{
                    rows: 25,
                    cols: 35,
                  }}
                  style={{ padding: "25px 16px", gap: "20px 35px" }}>
                  <GridLayoutItem col={1} row={1} className="form-inner">
                    <Label className="form-heading">Project :</Label>
                    <AutoComplete
                      data={projectOptions}
                      onFocus={() => getProjectlist(true)}
                      onClose={() => setOpenproject(false)}
                      onBlur={() => setOpenproject(false)}
                      opened={openProject}
                      value={selectProject ? selectProject?.label : ""}
                      itemRender={onProjectRender}
                      className="field-ip"
                      onChange={onProjectchange}
                      textField="label"
                      placeholder="Select project..."
                    />
                    <Error></Error>
                  </GridLayoutItem>
                  <GridLayoutItem col={2} row={1} className="form-inner">
                    <Label className="form-heading">Company :</Label>
                    <AutoComplete
                      data={companyOptions}
                      onFocus={() => getCompanylist(true)}
                      onClose={() => setOpencompany(false)}
                      onBlur={() => setOpencompany(false)}
                      opened={openCompany}
                      value={selectCompany ? selectCompany?.label : ""}
                      className="field-ip"
                      itemRender={onCompanyRender}
                      onChange={onCompanychange}
                      textField="label"
                      placeholder="Select comapny..."
                    />
                    <Error></Error>
                  </GridLayoutItem>
                  <GridLayoutItem col={3} row={1} className="form-inner">
                    <Label className="form-heading">
                      Project Company Role :
                    </Label>
                    <AutoComplete
                      data={companyRoleOptions}
                      onFocus={() => getCompanyrole(true)}
                      onClose={() => setOpencompanyrole(false)}
                      onBlur={() => setOpencompanyrole(false)}
                      opened={openCompanyrole}
                      value={selectCompanyrole ? selectCompanyrole?.label : ""}
                      className="field-ip"
                      itemRender={onCompanyroleRender}
                      onChange={onCompanyrolechange}
                      textField="label"
                      placeholder="Select project company role..."
                    />
                    <Error></Error>
                  </GridLayoutItem>

                  <GridLayoutItem col={1} row={4} className="form-inner">
                    <div className="k-d-flex" style={{ gap: "10px" }}>
                      <KendoButton
                        iconClass=""
                        size={16}
                        themeColor={"primary"}
                        buttonName="Save"
                        onClick={saveCurrentdata}
                      />
                      {dataId !== "add-project-companies" && (
                        <KendoButton
                          size={16}
                          className=""
                          onClick={showDeleteConfirmation}
                          buttonName="Delete"
                          themeColor={"secondary"}
                        />
                      )}
                      <KendoButton
                        iconClass=""
                        size={16}
                        className=""
                        buttonName="Cancel"
                        onClick={onCancelClick}
                      />
                    </div>
                  </GridLayoutItem>
                </GridLayout>
              )}
            />
          </section>
        </>
      </section>
      {modalState?.show && (
        <Modal
          for={modalState?.for}
          show={modalState?.show}
          onClose={onModalClose}
          onActionBtnClick={onActionBtnClick}
          onActionBtnTitle={modalState?.onActionBtnTitle}
        />
      )}
    </>
  );
};

export default AddProjectCompanies;
