import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { AutoComplete, DropDownList } from "@progress/kendo-react-dropdowns";
import { GridLayout, GridLayoutItem } from "@progress/kendo-react-layout";
import { Error, Label } from "@progress/kendo-react-labels";
import { Form } from "@progress/kendo-react-form";
import { toast } from "react-toastify";
import {
  FETCH_CHAT_GROUPS,
  FETCH_CHAT_GROUPS_MESSAGES,
  FETCH_CHAT_GROUPS_USERS_MESSAGES,
  SAVE_CHAT_GROUPS_USERS_MESSAGES,
  FetchUsers2,
  FETCH_RECORD_STATUSES,
  DELETE_CHAT_GROUPS_USERS_MESSAGES,
} from "../../constant/Apipath";
import { useSelector } from "react-redux";
import { Loader } from "@progress/kendo-react-indicators";
import KendoButton from "../../common/KendoButton";
import { PostRequestCall } from "../../apicall/PostRequest";

const AddChatGroupUserMessage = () => {
  const { dataId } = useParams();
  const [recordStatusOptions, setRecordStatusOptions] = useState([]);
  const [chatGroupUsers, setRecordStatus] = useState({
    recordStatusId: 1,
    recordStatus: "",
  });

  const { loginData } = useSelector((state) => state.main);

  const [chatGroupOptions, setChaGroupOptions] = useState([]);
  const [selectChatGroup, setGroupId] = useState(null);

  const [CgMessageOptions, setCompanyroleOptions] = useState([]);
  const [selectCgMessage, setSelectCGMessage] = useState(null);

  const [userOptions, setUserOptions] = useState([]);
  const [selectToUser, setToUser] = useState(null);

  const [chatGroupOpen, setChatGroupOpen] = useState(false);
  const [openToList, setToUserOpen] = useState(false);
  const [openCGMessages, setOpenCGMessages] = useState(false);
  const [showLoader, setShowLoader] = useState(false);

  const [modalState, setModalState] = useState({
    for: "",
    show: false,
    onActionBtnTitle: "",
  });
  useEffect(() => {
    if (dataId != "add-chat-group-user-message") {
      getInitialResponse();
    }
  }, []);
  const getInitialResponse = async () => {
    try {
      let obj = {
        NrOfRecPerPage: 20,
        FullSearch: "",
        SortList: [{ FieldName: "Id", Direction: "ASC" }],
        SearchList: [{ id: dataId }],
        IncludeRecordNr: true,
        TypeOfObjectReturned: "",
        FetchAllowedRecordsOnly: false,
      };
      const fetchSiteProjectListRes = await PostRequestCall(
        FETCH_CHAT_GROUPS_USERS_MESSAGES,
        obj,
        loginData?.token
      );
      let res = fetchSiteProjectListRes.data.data[0];
      setGroupId({
        label: String(res.chatGroupId),
        value: String(res.chatGroupId),
      });
      setToUser({
        label: `${res.toUserFirstName} ${res.toUserLastName}`,
        value: String(res.toUserId),
      });
      setSelectCGMessage({
        label: String(res.cgMessageId),
        value: res.cgMessageId,
      });
      setRecordStatus({
        recordStatusId: res.recordStatusId,
        recordStatus: res.recordStatus,
      });
    } catch (error) {
      console.log("error", error);
    }
  };

  useEffect(() => {
    getRecordStatuses();
    if (
      selectChatGroup !== null &&
      typeof selectChatGroup?.value === "undefined"
    ) {
      if (selectChatGroup !== "") {
        getChatGroup(false);
      }
    }
  }, [selectChatGroup]);

  useEffect(() => {
    if (selectToUser !== null && typeof selectToUser?.value === "undefined") {
      if (selectToUser !== "") {
        getToUserList(false);
      }
    }
  }, [selectToUser]);

  const getChatGroup = async (onFocus) => {
    try {
      let obj = {
        NrOfRecPerPage: onFocus ? 20 : 20,
        FullSearch: "",
        SortList: [{ FieldName: "Id", Direction: "ASC" }],
        SearchList: onFocus ? [] : [{ id: selectChatGroup }],
        IncludeRecordNr: true,
        TypeOfObjectReturned: "",
        FetchAllowedRecordsOnly: false,
      };
      const fetchSiteProjectListRes = await PostRequestCall(
        FETCH_CHAT_GROUPS,
        obj,
        loginData?.token
      );
      if (fetchSiteProjectListRes?.data?.data?.length > 0) {
        const getList = fetchSiteProjectListRes?.data?.data.map((project) => ({
          value: String(project?.id),
          label: String(project?.id),
        }));
        setChaGroupOptions(getList);
        setChatGroupOpen(true);
      }
    } catch (error) {
      console.log("error", error);
    }
  };

  const getToUserList = async (onFocus) => {
    try {
      let companyObj = {
        NrOfRecPerPage: onFocus ? 20 : 0,
        FullSearch: "",
        UserId: loginData.userId,
        SearchList: onFocus ? [] : [{ name: selectToUser }],
        SortList: [{ FieldName: "Id", Direction: "ASC" }],
        IncludeRecordNr: true,
      };
      const companyRes = await PostRequestCall(
        FetchUsers2,
        companyObj,
        loginData?.token
      );
      let UserData = companyRes?.data?.data?.map((record) => ({
        value: record?.id,
        label: record?.name,
      }));
      setUserOptions(UserData ?? []);
      setToUserOpen(true);
    } catch (error) {
      console.log("Error while getting company list :: ", error);
    }
  };

  const getCgMessages = async (onFocus) => {
    try {
      let getObject = {
        NrOfRecPerPage: onFocus ? 20 : 0,
        FullSearch: "",
        UserId: loginData?.userId,
        IncludeRecordNr: "true",
        TypeOfObjectReturned: "",
        FetchAllowedRecordsOnly: false,
        SearchList: onFocus ? [] : [{ name: selectCgMessage }],
        SortList: [{ FieldName: "id", Direction: "ASC" }],
      };
      let getList = await PostRequestCall(
        FETCH_CHAT_GROUPS_MESSAGES,
        getObject,
        loginData?.token
      );
      let companyRole = getList?.data?.data?.map((role) => ({
        value: role?.id,
        label: `${role?.messageText}`,
      }));
      setCompanyroleOptions(companyRole?.length > 0 ? companyRole : []);
      setOpenCGMessages(true);
    } catch (error) {}
  };

  const onChatGroupChange = (event) => {
    setGroupId(event?.target?.value);
  };

  const onToUserChange = (event) => {
    setToUser(event?.target?.value);
  };

  const onCGMessageChange = (event) => {
    setSelectCGMessage(event?.target?.value);
  };

  const projectSelect = (groupID) => {
    setGroupId(groupID);
    setChatGroupOpen(false);
  };

  const toUserSelect = (user) => {
    setToUser(user);
    setToUserOpen(false);
  };

  const onCgMessageSelect = (companyRole) => {
    setSelectCGMessage(companyRole);
    setOpenCGMessages(false);
  };

  const onChatGroupRender = (li, itemProps) => {
    const project = itemProps.dataItem;
    return (
      <div className="user-item" onClick={() => projectSelect(project)}>
        <span>{project?.label}</span>
      </div>
    );
  };

  const onTOuserRender = (li, itemProps) => {
    const company = itemProps.dataItem;
    return (
      <div className="user-item" onClick={() => toUserSelect(company)}>
        <span>{company?.label}</span>
      </div>
    );
  };

  const onCGRender = (li, itemProps) => {
    const companyRole = itemProps.dataItem;
    return (
      <div className="user-item" onClick={() => onCgMessageSelect(companyRole)}>
        <span>{companyRole?.label}</span>
      </div>
    );
  };

  const getRecordStatuses = async () => {
    try {
      let recordStatusesObj = {
        PageNr: 1,
        NrOfRecPerPage: 100,
        FullSearch: "",
        UserId: "1",
        SortList: [
          {
            FieldName: "Id",
            Direction: "ASC",
          },
        ],
        IncludeRecordNr: true,
      };
      const recordStatusesRes = await PostRequestCall(
        FETCH_RECORD_STATUSES,
        recordStatusesObj,
        loginData?.token
      );
      let recordStatusesData = recordStatusesRes?.data?.data?.map((record) => ({
        id: record?.id,
        text: record?.name,
      }));
      setRecordStatusOptions(recordStatusesData ?? []);
    } catch (error) {
      console.log("Error while getting record statuses data :: ", error);
    }
  };

  const saveCurrentdata = async () => {
    const getObject = {
      UserId: loginData?.userId,
      ReturnRecordId: true,
      ReturnRecordError: true,
      SaveList: [
        {
          id: dataId !== "add-chat-group-user-message" ? Number(dataId) : 0,
          ChatGroupId: Number(selectChatGroup?.value),
          ToUserId: Number(selectToUser?.value),
          CGMessageId: selectCgMessage?.value,
          RecordStatusId:
            dataId == "add-chat-group-user-message"
              ? 1
              : chatGroupUsers.recordStatusId,
        },
      ],
    };

    let getResponse = await PostRequestCall(
      SAVE_CHAT_GROUPS_USERS_MESSAGES,
      getObject,
      loginData?.token
    );
    if (getResponse?.status === 201) {
      window.history.back();
    }
  };

  const onDeleteClick = async () => {
    try {
      setShowLoader(true);
      const deleteObj = {
        ReturnRecordError: true,
        ReturnRecordId: false,
        UserId: loginData?.userId,
        DeleteList: [{ id: Number(dataId), ModifiedBy: loginData?.userId }],
      };

      PostRequestCall(
        DELETE_CHAT_GROUPS_USERS_MESSAGES,
        deleteObj,
        loginData?.token
      )
        .then(async (res) => {
          setShowLoader(false);
          if (res?.data?.status) {
            toast.success("Data deleted successfully", {
              position: "top-right",
            });
            window.history.back();
          } else {
            toast.error(
              res?.data?.message ??
                "Something went wrong. Please try again later.",
              {
                position: "top-right",
              }
            );
          }
        })
        .catch(async (error) => {
          setShowLoader(false);
          toast.error("Something went wrong. Please try again later.", {
            position: "top-right",
          });
        });
    } catch (error) {
      setShowLoader(false);
      toast.error("Something went wrong. Please try again later.", {
        position: "top-right",
      });
    }
  };

  const showDeleteConfirmation = () => {
    setModalState((preValue) => ({
      ...preValue,
      for: "delete",
      show: true,
      onActionBtnTitle: "Delete",
    }));
  };

  const onCancelClick = () => {
    return window.history.back();
  };

  if (showLoader) {
    return (
      <Loader
        type="converging-spinner"
        className="kendo-spinner"
        style={{
          display: "flex",
          justifyContent: "center",
          minHeight: "500px",
          alignItems: "center",
        }}
      />
    );
  }

  return (
    <>
      <section className="add-user">
        <>
          <section className="user-information">
            <div className="k-d-flex k-justify-content-between">
              <div className="k-d-flex k-align-items-center">
                {dataId !== "add-chat-group-user-message" && (
                  <span class="record-id-text">
                    Chat Groups Users Messages : {dataId}
                  </span>
                )}
              </div>
              <div
                className="k-d-flex k-justify-content-end k-p-2"
                style={{ gap: "10px" }}>
                <KendoButton
                  iconClass=""
                  size={16}
                  className=""
                  themeColor={"primary"}
                  buttonName="Save"
                  onClick={saveCurrentdata}
                />
                {dataId !== "add-chat-group-user-message" && (
                  <KendoButton
                    size={16}
                    className=""
                    onClick={onDeleteClick}
                    buttonName="Delete"
                    themeColor={"secondary"}
                  />
                )}
                <KendoButton
                  iconClass=""
                  size={16}
                  className=""
                  buttonName="Cancel"
                  onClick={onCancelClick}
                />
              </div>
            </div>
            <div
              style={{
                borderBottom: "1px solid #f5f5f5",
                width: "100%",
              }}></div>
            <Form
              render={(formRenderProps) => (
                <GridLayout
                  rows={[
                    {
                      height: 70,
                    },
                    {
                      height: 70,
                    },
                    {
                      height: 70,
                    },
                    {
                      height: 70,
                    },
                  ]}
                  cols={[
                    {
                      width: 375,
                    },
                    {
                      width: 375,
                    },
                    {
                      width: 375,
                    },
                  ]}
                  gap={{
                    rows: 25,
                    cols: 35,
                  }}
                  style={{ padding: "25px 16px", gap: "20px 35px" }}>
                  <GridLayoutItem col={1} row={1} className="form-inner">
                    <Label className="form-heading">Chat Group :</Label>
                    <AutoComplete
                      data={chatGroupOptions}
                      onFocus={() => getChatGroup(true)}
                      onClose={() => setChatGroupOpen(false)}
                      onBlur={() => setChatGroupOpen(false)}
                      opened={chatGroupOpen}
                      value={selectChatGroup ? selectChatGroup?.label : ""}
                      itemRender={onChatGroupRender}
                      className="field-ip"
                      onChange={onChatGroupChange}
                      textField="label"
                      placeholder="Select chat group..."
                    />
                    <Error></Error>
                  </GridLayoutItem>
                  <GridLayoutItem col={2} row={1} className="form-inner">
                    <Label className="form-heading">To :</Label>
                    <AutoComplete
                      data={userOptions}
                      onFocus={() => getToUserList(true)}
                      onClose={() => setToUserOpen(false)}
                      onBlur={() => setToUserOpen(false)}
                      opened={openToList}
                      value={selectToUser ? selectToUser?.label : ""}
                      className="field-ip"
                      itemRender={onTOuserRender}
                      onChange={onToUserChange}
                      textField="label"
                      disabled={dataId !== "add-chat-group-user-message"}
                      placeholder="Select Chat User..."
                    />
                    <Error></Error>
                  </GridLayoutItem>
                  <GridLayoutItem col={3} row={1} className="form-inner">
                    <Label className="form-heading">CGMessage :</Label>
                    <AutoComplete
                      data={CgMessageOptions}
                      onFocus={() => getCgMessages(true)}
                      onClose={() => setOpenCGMessages(false)}
                      onBlur={() => setOpenCGMessages(false)}
                      opened={openCGMessages}
                      value={selectCgMessage ? selectCgMessage?.label : ""}
                      className="field-ip"
                      itemRender={onCGRender}
                      onChange={onCGMessageChange}
                      textField="label"
                      placeholder="Select ChatGroup Message..."
                    />
                    <Error></Error>
                  </GridLayoutItem>
                  {dataId !== "add-chat-group-user-message" ? (
                    <GridLayoutItem col={1} row={2} className="form-inner">
                      <div className="form-group">
                        <Label className="form-heading">Record Status :</Label>
                        <div>
                          <DropDownList
                            style={{ width: "370px" }}
                            onChange={(e) => {
                              setRecordStatus({
                                ...chatGroupUsers,
                                recordStatusId: e.value.id,
                                recordStatus: e.value.text,
                              });
                            }}
                            className="dropdown-list"
                            value={{
                              id: chatGroupUsers?.recordStatusId,
                              text: chatGroupUsers?.recordStatus,
                            }}
                            data={recordStatusOptions}
                            textField="text"
                            dataItemKey="id"
                          />
                        </div>
                      </div>
                    </GridLayoutItem>
                  ) : (
                    <></>
                  )}
                  <GridLayoutItem col={1} row={4} className="form-inner">
                    <div className="k-d-flex" style={{ gap: "10px" }}>
                      <KendoButton
                        iconClass=""
                        size={16}
                        themeColor={"primary"}
                        buttonName="Save"
                        onClick={saveCurrentdata}
                      />
                      {dataId !== "add-chat-group-user-message" && (
                        <KendoButton
                          size={16}
                          className=""
                          onClick={onDeleteClick}
                          buttonName="Delete"
                          themeColor={"secondary"}
                        />
                      )}
                      <KendoButton
                        iconClass=""
                        size={16}
                        className=""
                        buttonName="Cancel"
                        onClick={onCancelClick}
                      />
                    </div>
                  </GridLayoutItem>
                </GridLayout>
              )}
            />
          </section>
        </>
      </section>
    </>
  );
};

export default AddChatGroupUserMessage;
