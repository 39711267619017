import React from 'react'
import { useParams } from 'react-router-dom';
import AddClientAds from '../clientad/AddClientAds';

const AddClientRecordLayout = () => {
    const { clientSubMenu } = useParams();

    const renderFormBasedOnUrl = () => {
        let ui = <></>;
        switch (clientSubMenu) {
            case "client-ads":
                ui = <> <AddClientAds /> </>
                break;
            case "client-devices":
                ui = <> Client Devices </>
                break;
            default:
                ui = <></>;
                break;
        }
        return ui;
    }

    return (
        <>
            {renderFormBasedOnUrl()}
        </>
    )
}

export default AddClientRecordLayout