import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { MultiSelect } from "@progress/kendo-react-dropdowns";
import { GridLayout, GridLayoutItem } from "@progress/kendo-react-layout";
import { Error, Label } from "@progress/kendo-react-labels";
import { Form } from "@progress/kendo-react-form";
import { PostRequestCall } from "../apicall/PostRequest";
import { toast } from "react-toastify";
import { FETCH_USERS, SEND_DEVICE_NOTIFICATION } from "../constant/Apipath";
import { useSelector } from "react-redux";
import { Input } from "@progress/kendo-react-inputs";
import { Loader } from "@progress/kendo-react-indicators";
import AddPageLayout from "../addpagelayout/AddPageLayout";

const SendPushNotification = () => {
  const { dataId } = useParams();
  const { loginData } = useSelector((state) => state.main);
  const isAddForm = dataId === "send-notification";
  const [userOption, setUserOptions] = useState([]);
  const [selectUsers, setSelectUsers] = useState([]);
  const [filterUsers, setFilterUsers] = useState("");
  const [showLoader, setShowLoader] = useState(false);
  const [notification, setNotification] = useState({
    body: "",
    title: "",
  });

  useEffect(() => {
    getUserdata(false);
  }, []);

  useEffect(() => {
    if (filterUsers !== "") {
      getUserdata(true);
    }
  }, [filterUsers]);

  const getUserdata = async (onFocus, searchTerm) => {
    try {
      const obj = JSON.stringify({
        PageNr: 1,
        NrOfRecPerPage: onFocus ? 20 : 0,
        FullSearch: "",
        UserId: loginData.userId,
        SearchList: onFocus ? [] : [{ name: searchTerm }],
        IncludeRecordNr: true,
      });
      const getResult = await PostRequestCall(
        FETCH_USERS,
        obj,
        loginData?.token
      );

      if (getResult?.data?.data?.length > 0) {
        const list = getResult?.data?.data.map((item) => ({
          value: item.id,
          label: item?.name,
        }));

        if (selectUsers?.length > 0) {
          const filteredProductOptions = list.filter(
            (data) => !ifUserSelected(data)
          );
          setUserOptions(filteredProductOptions);
        } else {
          setUserOptions(list);
        }
      }
    } catch (error) {
      console.log("error", error);
    }
  };

  const ifUserSelected = (data) => {
    return selectUsers?.some(
      (selectedProduct) => selectedProduct?.value === data?.value
    );
  };
  const onInputChange = (e) => {
    setNotification({
      ...notification,
      [e.target.name]: e.target.value,
    });
  };

  const onUserchange = (event) => {
    const newValue = event?.value;
    setSelectUsers(newValue);
  };

  const onUserfilterchange = (event) => {
    setFilterUsers(event.filter.value);
  };

  const sendNotification = async () => {
    setShowLoader(true);
    let getProducts = selectUsers?.map((item) => item?.value).join(",");
    const getObject = {
      title: notification?.title,
      Body: notification?.body,
      NotifyUserList: getProducts,
      Tags: {
        "gcm.n.link": "",
      },
      ActionUrl: "",
    };
    let getResponse = await PostRequestCall(
      SEND_DEVICE_NOTIFICATION,
      getObject,
      loginData?.token
    );
    setShowLoader(false);
    if (getResponse?.status === 200) {
      toast.success(`Notification Send successfully`, {
        position: "top-right",
      });
      window.history.back();
    }
  };

  if (showLoader) {
    return (
      <Loader
        type="converging-spinner"
        className="kendo-spinner"
        style={{
          display: "flex",
          justifyContent: "center",
          minHeight: "500px",
          alignItems: "center",
        }}
      />
    );
  }

  return (
    <>
      <div className="add_form_height_95">
        <AddPageLayout
          dataId={dataId}
          addForm={isAddForm}
          saveCurrentData={sendNotification}>
          <div
            style={{
              width: "100%",
            }}>
            <Form
              render={(formRenderProps) => (
                <GridLayout
                  rows={[
                    {
                      height: 70,
                    },
                    {
                      height: 70,
                    },
                    {
                      height: 70,
                    },
                    {
                      height: 170,
                    },
                    {
                      height: 30,
                    },
                  ]}
                  cols={[
                    {
                      width: 375,
                    },
                    {
                      width: 300,
                    },
                  ]}
                  gap={{
                    rows: 25,
                    cols: 35,
                  }}
                  style={{ padding: "25px 16px", gap: "20px 35px" }}>
                  <GridLayoutItem col={1} row={1} className="form-inner">
                    <Label className="form-heading">User :</Label>
                    <MultiSelect
                      data={userOption}
                      onOpen={() => getUserdata(false)}
                      onChange={onUserchange}
                      value={selectUsers}
                      onFilterChange={onUserfilterchange}
                      filter={filterUsers}
                      className="field-ip"
                      placeholder="Please select..."
                      textField="label"
                      style={{
                        maxHeight: "80px",
                        overflowY: "auto",
                      }}
                    />
                    <Error></Error>
                  </GridLayoutItem>

                  <GridLayoutItem col={2} row={1} className="form-inner">
                    <Label className="form-heading">title :</Label>
                    <Input
                      type="text"
                      id="title"
                      name="title"
                      value={notification?.title}
                      className="field-ip"
                      onChange={onInputChange}
                    />
                    <Error></Error>
                  </GridLayoutItem>
                  <GridLayoutItem col={3} row={1} className="form-inner">
                    <label className="form-heading">Body:</label>
                    <Input
                      type="text"
                      id="body"
                      name="body"
                      value={notification?.body}
                      className="field-ip"
                      onChange={(e) => onInputChange(e)}
                    />
                  </GridLayoutItem>
                </GridLayout>
              )}
            />
          </div>
        </AddPageLayout>
      </div>
    </>
  );
};

export default SendPushNotification;
