import { Dialog } from "@progress/kendo-react-dialogs";
import React, { useEffect, useState } from "react";
import { FETCH_USERS_PURCHASES } from "../../constant/Apipath";
import { PostRequestCall } from "../../apicall/PostRequest";
import { useSelector } from "react-redux";
import {
  Card,
  CardHeader,
  CardImage,
  CardSubtitle,
  CardTitle,
} from "@progress/kendo-react-layout";
import { Loader } from "@progress/kendo-react-indicators";

export default function Userlist(props) {

  const { loginData } = useSelector((state) => state.main);
  const [userList, setUserList] = useState([]);
  const [showLoader, setShowLoader] = useState(true);


  useEffect(() => {
    fetchUserPurchases();
  }, []);

  const fetchUserPurchases = async () => {
    try {
      setShowLoader(true);

      const requestObj = {
        Id: 0,
        PageNr: 0,
        NrOfRecPerPage: 0,
        FullSearch: "",
        UserId: loginData.userId,
        IncludeRecordNr: true,
        FetchAllowedRecordsOnly: false,
        DoNotShowInSystemFields: false,
        TypeOfObjectReturned: "",
        SearchList: [
          {
            ItemId: props?.selectedItem?.itemId,
          },
        ],
        SortList: [{ fieldName: "id", direction: "desc" }],
      };
      const result = await PostRequestCall(
        FETCH_USERS_PURCHASES,
        requestObj,
        loginData?.token
      );
      setUserList(result?.data?.data || []);
    } catch (error) {
      console.error("Failed to fetch user purchases", error);
    } finally {
      setShowLoader(false);
    }
  };

  const userlistclose = () => {
    props.setShowuserlist(false);
  };

  if (showLoader) {
    return (
      <Loader
        type="converging-spinner"
        className="kendo-spinner"
        style={{
          display: "flex",
          justifyContent: "center",
          minHeight: "500px",
          alignItems: "center",
        }}
      />
    );
  }

  const formatDate = (dateString) => {
    if (dateString) {
      const options = { month: "short", day: "numeric", year: "numeric" };
      const date = new Date(dateString);
      return date.toLocaleDateString("en-US", options);
    } else {
      return null;
    }
  };


  return (
    <>
      {userList.length > 0 ? (
        <>
          <Dialog
            title={`User list - ${props?.selectedItem?.itemName ?? ""}`}
            className="userlist-dialog"
            height={"450px"}
            width={"450px"}
            onClose={userlistclose}>
            {
              userList?.map((item) => (
                <Card orientation="horizontal" className="userItem">
                  <div style={{ display: 'flex', alignItems: 'center' }}>
                    <CardImage
                      className="user-img"
                      style={{ height: "45px", width: "45px" }}
                      src={item?.userProfilePicture || "default-image-path.jpg"}
                    />
                    <CardHeader className="user-header">
                      <CardTitle>{item?.userName || "Unknown User"}</CardTitle>
                      <CardSubtitle><p>Qty: {" "}{item.qty}</p></CardSubtitle>
                      <CardSubtitle><p>Added Date: {" "}{formatDate(item.dateCreated)}</p></CardSubtitle>
                    </CardHeader>
                  </div>

                </Card>
              ))
            }

          </Dialog>
        </>
      ) : (
        <Dialog
          title="User list"
          className="userlist-dialog"
          height={"200px"}
          width={"300px"}
          onClose={userlistclose}>
          <p>No users found.</p>
        </Dialog>
      )}
    </>
  );
}
