import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import {
  DELETE_ITEMS,
  FETCH_ITEMS,
  SAVE_ITEMS,
  SAVE_ITEMS_PITURES,
} from "../../constant/Apipath";
import { PostRequestCall } from "../../apicall/PostRequest";
import { useSelector } from "react-redux";
import { GridLayout, GridLayoutItem } from "@progress/kendo-react-layout";
import { Label } from "@progress/kendo-react-labels";
import { Input } from "@progress/kendo-react-inputs";
import { toast } from "react-toastify";
import { Loader } from "@progress/kendo-react-indicators";
import { IconComponent } from "../../common/Icon";
import AddPageLayout from "../../addpagelayout/AddPageLayout";
import { Form } from "@progress/kendo-react-form";
import RecordStatusInput from "../../common/RecordStatusInput";
import CountryInput from "../../common/CountryInput";

const AddItems = () => {
  const { dataId } = useParams();
  const [footerInfo, setFooterInfo] = useState({});
  const [items, setItems] = useState({
    name: "",
    recordStatus: "New",
    recordStatusId: 1,
    current: "false",
    fileName: "",
    country: "",
  });
  const isAddForm = Number(dataId) === 0;
  const { loginData } = useSelector((state) => state.main);
  const [isFieldEdited, setIsFiledEdited] = useState(false);

  const [showLoader, setShowLoader] = useState(false);

  const onInputChange = (e) => {
    const { name, value } = e.target;
    setIsFiledEdited(true);
    setItems({ ...items, [name]: value });
  };

  useEffect(() => {
    if (!isAddForm) {
      loadItems();
    }
  }, []);

  const loadItems = async () => {
    var data = JSON.stringify({
      PageNr: 1,
      NrOfRecPerPage: 20,
      FullSearch: "",
      UserId: loginData.userId,
      TypeOfObjectReturned: "",
      SearchList: [
        {
          Id: dataId,
        },
      ],
      SortList: [
        {
          FieldName: "Id",
          Direction: "ASC",
        },
      ],
    });

    const result = await PostRequestCall(FETCH_ITEMS, data, loginData?.token);
    if (result.data && result.data.data && result.data.data.length > 0) {
      const certData = result.data.data[0];
      setItems({
        ...certData,
        name: certData?.name,
        country: certData?.country,
        recordStatusId: certData?.recordStatusId,
        recordStatus: certData?.recordStatus,
        fileName: certData?.pictureList?.[0]?.thumbFileUrl,
      });
      let footerObj = {
        dateCreated: certData?.dateCreated ?? null,
        modifiedByUser: certData?.modifiedByUser ?? "",
        dateModified: certData?.dateModified ?? "",
        createdByUser: certData?.createdByUser ?? "",
      };
      setFooterInfo(footerObj);
    }
  };

  const isCheck = (pictureUrl) => {
    if (Object.keys(pictureUrl)?.length === 0) {
      return URL.createObjectURL(pictureUrl);
    } else {
      return pictureUrl;
    }
  };

  const onProfileChange = (e) => {
    if (e?.target?.files?.length > 0) {
      setItems({
        ...items,
        fileName: e.target.files[0],
      });
    }
    e.target.value = "";
  };

  const saveCurrentData = async (e) => {
    e.preventDefault();
    const newRecordStatus = isAddForm ? 1 : items.recordStatusId;

    const newItem = {
      UserId: loginData.userId,
      ReturnRecordId: true,
      ReturnRecordError: true,
      SaveList: [
        {
          Id: isAddForm ? 0 : dataId,
          Name: items.name,
          Country: items?.country,
          RecordStatusId: newRecordStatus,
          ModifiedBy: loginData.userId,
        },
      ],
    };

    const result = await PostRequestCall(SAVE_ITEMS, newItem, loginData.token);
    if (result.data.status === false) {
      return toast.error(result.data.data[0].message, {
        position: "top-right",
      });
    }

    if (items.fileName) {
      const itemId = result.data.data[0].objData.id;
      const getRequest = new FormData();
      if (isAddForm) {
        getRequest.append("Id", 0);
      } else {
        getRequest.append("Id", dataId);
      }
      getRequest.append("ItemId", itemId);
      getRequest.append("FileUrl", items?.fileName);
      getRequest.append("Main", true);
      getRequest.append("ModifiedBy", loginData?.userId);

      await PostRequestCall(
        SAVE_ITEMS_PITURES,
        getRequest,
        loginData.token,
        "formData"
      );
    }

    console.log("Data:", result.data.data[0]);
    window.history.back();
  };

  const onSelectProfile = () => {
    setIsFiledEdited(true);
    document.getElementById("user-profile").click();
  };

  const onRemoveImage = () => {
    setIsFiledEdited(true);
    setItems({
      ...items,
      fileName: null,
    });
  };
  console.log("adsData", items);

  const onDeleteClick = async () => {
    try {
      setShowLoader(true);

      const deleteObj = {
        ReturnRecordError: true,
        softDelete: false,
        UserId: loginData?.userId,
        DeleteList: [
          {
            Id: Number(dataId),
            ModifiedBy: loginData?.userId,
          },
        ],
      };

      return PostRequestCall(DELETE_ITEMS, deleteObj, loginData?.token)
        .then((res) => {
          setShowLoader(false);
          if (res?.status === 201) {
            toast.success(`Data delete successfully`, {
              position: "top-right",
            });

            window.history.back();
          } else {
            toast.error(
              res?.data?.message ??
                "Something went wrong. Please try again later.",
              {
                position: "top-right",
              }
            );
          }
        })
        .catch((error) => {
          setShowLoader(false);
          toast.error("Something went wrong. Please try again later.", {
            position: "top-right",
          });
          console.log("Error while deleting a record :: ", error);
        });
    } catch (error) {
      setShowLoader(false);
      toast.error("Something went wrong. Please try again later.", {
        position: "top-right",
      });
      console.log("Error while deleting a record :: ", error);
    }
  };
  if (showLoader) {
    return (
      <Loader
        type="converging-spinner"
        className="kendo-spinner"
        style={{
          display: "flex",
          justifyContent: "center",
          minHeight: "500px",
          alignItems: "center",
        }}
      />
    );
  }
  return (
    <div className="add_form_height_95">
      <AddPageLayout
        dataId={dataId}
        addForm={isAddForm}
        showFooter={!isAddForm}
        isFieldEdited={isFieldEdited}
        footerInfo={footerInfo}
        recordText={"Item ID"}
        saveCurrentData={saveCurrentData}
        onDeleteClick={onDeleteClick}>
        <div style={{ width: "100%" }}>
          <Form
            render={(formRenderProps) => (
              <GridLayout
                rows={[
                  { height: 70 },
                  { height: 70 },
                  { height: 70 },
                  { height: 130 },
                  { height: 30 },
                ]}
                cols={[{ width: 375 }, { width: 375 }, { width: 375 }]}
                gap={{ rows: 30, cols: 35 }}
                style={{ padding: "16px" }}>
                <GridLayoutItem col={1} row={1} className="form-inner">
                  <label className="form-heading">name :</label>
                  <Input
                    type="text"
                    id="name"
                    name="name"
                    value={items?.name}
                    className="field-ip"
                    onChange={(e) => onInputChange(e)}
                  />
                </GridLayoutItem>
                <GridLayoutItem col={2} row={1} className="form-inner">
                  <CountryInput
                    countryId={items?.country}
                    country={items?.country}
                    onChange={({ country }) => {
                      setIsFiledEdited(true);
                      setItems((preValue) => ({
                        ...preValue,
                        country,
                      }));
                    }}
                  />
                </GridLayoutItem>
                <GridLayoutItem col={3} row={1} className="form-inner">
                  <Label className="form-heading"> Add Piture :</Label>
                  <div className="k-d-flex" style={{ gap: "15px" }}>
                    <input
                      type="file"
                      hidden
                      accept=".png,.jpg"
                      id="user-profile"
                      style={{ display: "none" }}
                      onChange={(e) => {
                        setIsFiledEdited(true);
                        onProfileChange(e);
                      }}
                    />
                    {items?.fileName ? (
                      <div className="k-d-flex">
                        <div style={{ position: "relative" }}>
                          <img
                            className="user-img"
                            src={isCheck(items?.fileName)}
                            alt="Ad"
                          />
                          <button
                            onClick={onRemoveImage}
                            style={{
                              position: "absolute",
                              top: "6px",
                              right: "8px",
                              backgroundColor: "red",
                              color: "white",
                              border: "none",
                              borderRadius: "50%",
                              cursor: "pointer",
                            }}>
                            <span>X</span>
                          </button>
                        </div>
                      </div>
                    ) : (
                      <>
                        <GridLayoutItem col={2} row={1} className="form-inner">
                          <div
                            style={{
                              position: "relative",
                              cursor: "pointer",
                              border: "0.5px solid #d3d3d3",
                              padding: "5px",
                              display: "flex",
                              justifyContent: "center",
                              borderRadius: "4px",
                              gap: "10px",
                            }}
                            onClick={onSelectProfile}>
                            <IconComponent iconName={"Image"} size={20} />
                            <span className="add-file-text">Choose image</span>
                          </div>
                        </GridLayoutItem>
                      </>
                    )}
                  </div>
                </GridLayoutItem>

                {!isAddForm && (
                  <GridLayoutItem col={1} row={2} className="form-inner">
                    <RecordStatusInput
                      recordStatusId={items?.recordStatusId}
                      recordStatus={items?.recordStatus}
                      onChange={({ recordStatus, recordStatusId }) => {
                        setIsFiledEdited(true);
                        setItems((preValue) => ({
                          ...preValue,
                          recordStatus,
                          recordStatusId,
                        }));
                      }}
                    />
                  </GridLayoutItem>
                )}
              </GridLayout>
            )}
          />
        </div>
      </AddPageLayout>
    </div>
  );
};

export default AddItems;
