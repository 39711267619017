import React from 'react'
import { useParams } from 'react-router-dom';
import ClientAds from '../clientad/ClientAds';
import ClientDevices from '../clientdevices/ClientDevices';

const ClientTableDetailsLayout = () => {
    const { clientSubMenu } = useParams();

    const renderTableListBasedOnUrl = () => {
        let ui = <></>;
        switch (clientSubMenu) {
            case "client-ads":
                ui = (
                    <>
                        {" "}
                        <ClientAds />{" "}
                    </>
                );
                break;

            case "client-devices":
                ui = (
                    <>
                        {" "}
                        <ClientDevices />{" "}
                    </>
                );
                break;

            default:
                ui = <></>;
                break;
        }

        return ui;
    };
    return <>{renderTableListBasedOnUrl()}</>;
}

export default ClientTableDetailsLayout