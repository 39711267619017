import React, { useEffect, useState } from 'react'
import "./QuizzesList.css"
import KendoButton from '../common/KendoButton'
import { FETCH_QUIZZES, FETCH_USER_QUESTIONS, SAVE_USER_QUIZ_ANSWERS } from '../constant/Apipath';
import { useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { PostRequestCall } from '../apicall/PostRequest';
import { Input, RadioButton, TextArea } from "@progress/kendo-react-inputs";
import { DatePicker } from "@progress/kendo-react-dateinputs"
import { Button } from '@progress/kendo-react-buttons';
import { IconComponent } from '../common/Icon';
import { toast } from "react-toastify";
import moment from "moment";
import { Loader } from '@progress/kendo-react-indicators';



const QuizzesList = () => {

    const { userId } = useParams();
    const { loginData } = useSelector((state) => state.main);

    let navigate = useNavigate();

    const [quizzes, setQuizzes] = useState({});
    const [questionNo, setQuestionNo] = useState(1);
    const [showLoader, setShowLoader] = useState(false);

    const [dateAnswer, setDateAnswer] = useState("");
    const [multiLineAnswer, setMultiLineAnswer] = useState("");
    const [selectAnswer, setSelectAnswer] = useState([]);
    const [options, setOptions] = useState([]);
    const [showAddInput, setShowAddInput] = useState(false);
    const [allQuizzesAnswered, setAllQuizzesAnswered] = useState(false);
    const [customInputValue, setCustomInputValue] = useState("");


    useEffect(() => {
        if (userId) {
            getQuizzes(questionNo);
        }
    }, [questionNo])


    const getQuizzeId = (questionNo) => {
        if (questionNo === 1) {
            return 29;
        } else if (questionNo === 2) {
            return 9;
        } else if (questionNo === 3) {
            return 8;
        } else {
            setAllQuizzesAnswered(true);
            return 0;
        }
    }


    const checkUserAlreadyAnswered = async (quizId) => {
        try {
            let obj = { SearchList: [{ answerTypeId: 1 }, { userId }, { quizId: quizId }] };

            const result = await PostRequestCall(
                FETCH_USER_QUESTIONS,
                obj,
                loginData?.token
            );
            let id = result?.data?.data?.[0]?.id ?? 0;
            if (id) {
                return true;
            } else {
                return false;
            }
        } catch (error) {
            console.log("Error while getting details ::  ", error);
            return false;
        }
    }

    const getQuizzes = async (questionNo) => {
        try {
            setShowLoader(true);
            let quizId = getQuizzeId(questionNo);
            if (quizId) {
                const checkAlreadyAnswered = await checkUserAlreadyAnswered(quizId);
                if (checkAlreadyAnswered) {
                    return setQuestionNo((preValue) => (preValue + 1))
                }

                let obj = { SearchList: [{ quizId: getQuizzeId(questionNo), userId }] };
                const res = await PostRequestCall(
                    FETCH_USER_QUESTIONS,
                    obj,
                    loginData?.token
                );
                let quizzesData = res?.data?.data?.[0] ?? {};
                if (Object.keys(quizzesData).length === 0) {
                    setQuestionNo(999999);
                    return setAllQuizzesAnswered(true);
                }
                setQuizzes(quizzesData);
                if (quizzesData?.quizType === "Select") {
                    const splitOptions = quizzesData?.options?.split(';').filter((op) => op?.trim() != '');
                    const cleanedArray = splitOptions.map(item => item.startsWith('\n') ? item.slice(1) : item);
                    setOptions(cleanedArray ?? [])
                }
                setShowLoader(false);
            } else {
                setShowLoader(false);
                setAllQuizzesAnswered(true);
            }
        } catch (error) {
            setShowLoader(false);
            console.log("Error while getting quizzes list :: ", error);
            return toast.error("Oops! Something went wrong while getting answers. Please try again later.",
                {
                    position: "top-right",
                }
            );
        }
    }

    const onBackPress = () => {
        return navigate(`/reports/users/users/${userId}`)
    }

    const onSaveClick = async (quizType) => {
        let answersArray = [];
        switch (quizType) {
            case 'Date':
                break;
            case 'Multiline':
                answersArray.push(multiLineAnswer);
                break;
            case 'Select':
                const selectedOptions = [...selectAnswer];
                if (selectedOptions.length > quizzes?.maxResponses) {
                    alert(`You can only select upto ${quizzes?.maxResponses} responses`)
                    return;
                }
                selectedOptions.map((op) => answersArray.push(op));
                break;
            default:
                return;
        }
        try {
            if (quizType === "Date") {
                let obj = {
                    UserId: Number(userId),
                    ReturnRecordId: true,
                    ReturnRecordError: true,
                    SaveList: [
                        {
                            UserId: Number(userId),
                            UserQuizId: quizzes?.id,
                            AnswerTypeId: 1,
                            Answer: moment(dateAnswer).format('YYYY-MM-DD') ?? '',
                            Answer1: "",
                            Answer2: '',
                            Answer3: '',
                            Answer4: '',
                            Answer5: '',
                            Answer6: '',
                            Answer7: '',
                            Answer8: "",
                            Answer9: ""
                        }
                    ]
                }
                const res = await PostRequestCall(SAVE_USER_QUIZ_ANSWERS, obj, loginData?.token);
                if (res?.data?.data?.[0]?.message === "OK") {
                    toast.success("Answer saved successfully!", {
                        position: "top-right",
                    });
                    setQuestionNo((preValue) => preValue + 1);
                    return;
                } else {
                    setShowLoader(false);
                    return toast.error("Oops! Something went wrong while saving your answers. Please try again later.",
                        {
                            position: "top-right",
                        }
                    );
                }
            } else {
                let res;
                if (quizzes?.canAddResponse || quizzes?.canAddNewRecordsToResponse) {
                    const obj2 = {
                        UserId: Number(userId),
                        ReturnRecordId: true,
                        ReturnRecordError: true,
                        SaveList: [
                            {
                                UserId: Number(userId),
                                UserQuizId: quizzes?.id,
                                AnswerTypeId: 1,
                                Answer: "",
                                Answer1: "",
                                Answer2: "",
                                Answer3: "",
                                Answer4: "",
                                Answer5: "",
                                Answer6: "",
                                Answer7: "",
                                Answer8: "",
                                Answer9: ""
                            }
                        ]
                    }
                    let userObjectCopy = { ...obj2 };
                    answersArray.forEach((answer, index) => {
                        if (index === 0) {
                            userObjectCopy.SaveList[0].Answer = answer.toString();
                        } else if (index >= 1 && index <= 8) {
                            userObjectCopy.SaveList[0][`Answer${index}`] = answer.toString();
                        }
                    });
                    res = await PostRequestCall(SAVE_USER_QUIZ_ANSWERS, obj2, loginData?.token);
                    if (res?.data?.data?.[0]?.message === "OK") {
                        toast.success("Answer saved successfully!", {
                            position: "top-right",
                        });
                        setQuestionNo((preValue) => preValue + 1);
                        return;
                    } else {
                        setShowLoader(false);
                        return toast.error("Oops! Something went wrong while saving your answers. Please try again later.",
                            {
                                position: "top-right",
                            }
                        );
                    }
                } else {
                    const ans = answersArray.map((ans) => { return ans }).pop();
                    const obj2 = {
                        UserId: Number(userId),
                        ReturnRecordId: true,
                        ReturnRecordError: true,
                        SaveList: [
                            {
                                UserId: Number(userId),
                                UserQuizId: quizzes?.id,
                                AnswerTypeId: 1,
                                Answer: typeof (ans) === 'number' ? `${ans}` : ans,
                                Answer1: "",
                                Answer2: "",
                                Answer3: "",
                                Answer4: "",
                                Answer5: "",
                                Answer6: "",
                                Answer7: "",
                                Answer8: "",
                                Answer9: ""
                            }
                        ]
                    }
                    res = await PostRequestCall(SAVE_USER_QUIZ_ANSWERS, obj2, loginData?.token);

                    if (res?.data?.data?.[0]?.message === "OK") {
                        toast.success("Answer saved successfully!", {
                            position: "top-right",
                        });
                        setQuestionNo((preValue) => preValue + 1);
                        return;
                    } else {
                        setShowLoader(false);
                        return toast.error("Oops! Something went wrong while saving your answers. Please try again later.",
                            {
                                position: "top-right",
                            }
                        );
                    }
                }
            }
        } catch (error) {
            setShowLoader(false);
            toast.error("Something went wrong. Please try again later.",
                {
                    position: "top-right",
                }
            );
            console.log("Error while saving answer :: ", error);
        }
    }


    const isSaveBtnDisabled = (quizType) => {
        if (quizType === "Date") {
            if (dateAnswer) {
                return false;
            } else {
                return true;
            }
        }
        if (quizType === "Multiline") {
            if (multiLineAnswer?.trim() !== "") {
                return false;
            } else {
                return true;
            }
        }
        if (quizType === "Select") {
            if (selectAnswer?.length !== 0) {
                return false;
            } else {
                return true;
            }
        }
    }

    const onSelectClick = (item) => {
        if (selectAnswer.includes(item)) {
            let valueClone = [...selectAnswer];
            return setSelectAnswer(valueClone?.filter((value) => (value !== item)));
        } else {
            if (quizzes?.maxResponses !== 1) {
                let newSelectAnswerClone = [...selectAnswer, item];
                if (newSelectAnswerClone.length > quizzes?.maxResponses) {
                    newSelectAnswerClone?.shift();
                    return setSelectAnswer(newSelectAnswerClone)
                } else {
                    return setSelectAnswer(newSelectAnswerClone);
                }
            } else if (quizzes?.maxResponses === 1) {
                return setSelectAnswer([item])
            }
        }
    }

    const renderQuizzesInput = (quizType) => {
        if (quizType === "Date") {
            return (
                <>
                    <DatePicker
                        placeholder="Choose a date..."
                        value={dateAnswer}
                        className="field-ip qz_inputs"
                        onChange={(e) => { setDateAnswer(e?.value ?? null) }}
                    />
                </>
            )
        }

        if (quizType === "Multiline") {
            return (
                <>
                    <TextArea
                        type="text"
                        value={multiLineAnswer}
                        className="field-ip  qz_mutiline_input"
                        onChange={(e) => {
                            setMultiLineAnswer(e?.target?.value)
                        }}
                        rows={4}
                        style={{ height: 'auto' }}
                        placeholder='Write here...'
                    />
                </>
            )
        }

        if (quizType === "Select") {
            return (
                <>
                    <div>
                        <p className='qz_max_res_title'>{quizzes?.maxResponses == 1 ? 'Select 1' : quizzes?.maxResponses > 1 ? `Select up to ${quizzes?.maxResponses}` : 'Select all that apply'}</p>
                        <div>
                            {
                                options.map((item, index) => (
                                    <div key={index} className='qz_select_continer'>
                                        <div className='qz_select_round' onClick={() => { onSelectClick(item) }}
                                            style={{ backgroundColor: selectAnswer.includes(item) ? "#116FA5" : "transparent" }}
                                        >
                                        </div>
                                        <div className='qz_select_tilte' onClick={() => { onSelectClick(item) }}>
                                            {item}
                                        </div>
                                    </div>
                                ))
                            }
                            <div>
                                {
                                    showAddInput ?
                                        (
                                            <div className='qz_select_custom_input'>
                                                <div className='qz_select_round' style={{ cursor: "default" }}></div>
                                                <div>
                                                    <Input
                                                        className="field-ip qz_inputs"
                                                        type="text"
                                                        name="dataComments"
                                                        placeholder='Enter new option'
                                                        value={customInputValue}
                                                        onChange={(e) => {
                                                            setCustomInputValue(e?.target?.value);
                                                        }}
                                                        onKeyDown={(e) => {
                                                            if (e.keyCode === 13 && e.target.value?.trim() !== "") {
                                                                if (selectAnswer.includes(e.target.value)) {
                                                                    return toast.error("This option already exists in the list. Please select that option.",
                                                                        {
                                                                            position: "top-right",
                                                                        }
                                                                    );
                                                                }
                                                                setOptions((preValue) => ([...preValue, e?.target?.value]));
                                                                setShowAddInput(false);
                                                                setCustomInputValue('');
                                                                onSelectClick(e?.target?.value);
                                                            }
                                                        }}
                                                    />
                                                </div>
                                                <IconComponent
                                                    size={25}
                                                    iconName={"X"}
                                                    className="search-icon"
                                                    onClick={async () => {
                                                        setCustomInputValue('');
                                                        setShowAddInput(false)
                                                    }}
                                                />
                                            </div>
                                        )
                                        :
                                        (
                                            <KendoButton
                                                iconClass=""
                                                size={16}
                                                themeColor={"primary"}
                                                buttonName="Add Option"
                                                className="qz_add_option_btn"
                                                onClick={() => { setShowAddInput(true) }}
                                            />
                                        )
                                }
                            </div>
                        </div>
                    </div>

                </>
            )
        }
    }


    if (showLoader) {
        return (
            <Loader
                type="converging-spinner"
                className="kendo-spinner"
                style={{
                    display: "flex",
                    justifyContent: "center",
                    minHeight: "500px",
                    alignItems: "center",
                }}
            />
        );
    }

    return (
        <div className='quizzes_main_container'>
            <div className='quizzes_main_header'>
                <h1 className='quizzes_header_title'>
                    Quizzes
                </h1>

            </div>
            <div className='qz_hr'>
            </div>
            <div className='quizzes_main_sub_container'>
                {
                    allQuizzesAnswered ?
                        (
                            <div className='all_answered_container'>
                                <p className='all_answered_message'>
                                    All questions have been answered by this user.
                                    <br />
                                    There are no more questions right now.
                                </p>
                                <div>
                                    <KendoButton
                                        iconClass=""
                                        size={16}
                                        className="qz_back_btn"
                                        buttonName="Back"
                                        onClick={onBackPress}
                                    />
                                </div>
                            </div>
                        )
                        :
                        (
                            <div className='qz_question_container'>
                                <div className='qz_question_title'>
                                    {quizzes?.quizDescription?.trim() || ''}
                                </div>
                                <div className='qz_question_answer_input'>
                                    {quizzes?.quizType ? renderQuizzesInput(quizzes?.quizType?.trim() ?? "") : null}
                                </div>
                                <div className='qz_question_footer'>
                                    <div style={{ display: "flex", justifyContent: 'space-between' }}>
                                        <KendoButton
                                            iconClass=""
                                            size={16}
                                            className="qz_back_btn"
                                            buttonName="Back"
                                            onClick={onBackPress}
                                        />
                                        <KendoButton
                                            iconClass=""
                                            size={16}
                                            themeColor={"primary"}
                                            buttonName="Save"
                                            className="qz_action_btn"
                                            disabled={isSaveBtnDisabled(quizzes?.quizType?.trim() ?? "")}
                                            onClick={() => { onSaveClick(quizzes?.quizType?.trim() ?? "") }}
                                        />
                                    </div>
                                </div>
                            </div>
                        )
                }


            </div>
        </div>
    )
}

export default QuizzesList