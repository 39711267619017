import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { Input } from "@progress/kendo-react-inputs";
import { useSelector } from "react-redux";
import { Form } from "@progress/kendo-react-form";
import { GridLayout, GridLayoutItem } from "@progress/kendo-react-layout";
import { Error, Label } from "@progress/kendo-react-labels";
import { toast } from "react-toastify";
import { Loader } from "@progress/kendo-react-indicators";
import { PostRequestCall } from "../apicall/PostRequest";
import {
  DELETE_PROJECT_COMPANY_ROLES,
  FETCH_PROJECT_COMPANY_ROLES,
  SAVE_PROJECT_COMPANY_ROLES,
} from "../constant/Apipath";
import AddPageLayout from "../addpagelayout/AddPageLayout";
import RecordStatusInput from "../common/RecordStatusInput";

const AddCompanyRole = () => {
  const { dataId } = useParams();
  const [showLoader, setShowLoader] = useState(false);
  const { loginData } = useSelector((state) => state.main);
  const isAddForm = dataId === "add-company-role";
  const [footerInfo, setFooterInfo] = useState({});

  const [companyRole, setCompanyRole] = useState({
    id: 0,
    name: "",
    recordStatus: "New",
    recordStatusId: 1,
  });

  const [isFieldEdited, setIsFiledEdited] = useState(false);

  useEffect(() => {
    if (!isAddForm) {
      getCompanyRole();
    }
  }, []);

  const getCompanyRole = async () => {
    let obj = { SearchList: [{ Id: dataId }] };
    const fetchuserAuthRes = await PostRequestCall(
      FETCH_PROJECT_COMPANY_ROLES,
      obj,
      loginData?.token
    );

    let getCompanyRoleData = fetchuserAuthRes?.data?.data[0];
    setCompanyRole({
      ...companyRole,
      ...getCompanyRoleData,
      name: getCompanyRoleData?.name,
      recordStatus: getCompanyRoleData?.recordStatus,
      recordStatusId: getCompanyRoleData?.recordStatusId,
    });
    let footerObj = {
      dateCreated: getCompanyRoleData?.dateCreated ?? null,
      modifiedByUser: getCompanyRoleData?.modifiedByUser ?? "",
      dateModified: getCompanyRoleData?.dateModified ?? "",
      createdByUser: getCompanyRoleData?.createdByUser ?? "",
    };
    setFooterInfo(footerObj);
  };

  const onInputChange = (e) => {
    setIsFiledEdited(true);
    setCompanyRole({
      ...companyRole,
      [e.target.name]: e.target.value,
    });
  };

  const saveCurrentData = async () => {
    if (companyRole?.name === "") {
      return toast.error("Please Enter Name", {
        position: "top-right",
      });
    }

    const obj = {
      UserId: loginData?.userId,
      ReturnRecordId: false,
      ReturnRecordError: true,
      SaveList: [
        {
          Id: isAddForm ? 0 : dataId,
          name: companyRole?.name,
          RecordStatusId: companyRole?.recordStatusId || 1,
          ModifiedBy: loginData?.userId,
        },
      ],
    };

    try {
      let getResponse = await PostRequestCall(
        SAVE_PROJECT_COMPANY_ROLES,
        obj,
        loginData?.token
      );

      if (getResponse.data.status === false) {
        toast.error(
          getResponse?.data?.data?.[0].message ??
            "Something went wrong. Please try again later.",
          {
            position: "top-right",
          }
        );
      } else {
        toast.success(`Data ${!isAddForm ? "updated" : "added"} successfully`, {
          position: "top-right",
        });
        window.history.back();
      }
    } catch (error) {
      toast.error("An unexpected error occurred. Please try again later.", {
        position: "top-right",
      });
    } finally {
      setShowLoader(false);
    }
  };

  const onDeleteClick = async () => {
    try {
      setShowLoader(true);
      const deleteObj = [
        {
          Id: Number(dataId),
          ModifiedBy: loginData?.userId,
        },
      ];
      const newuserTrades = {
        ReturnRecordError: true,
        ReturnRecordId: false,
        UserId: loginData?.userId,
        DeleteList: deleteObj,
      };
      return PostRequestCall(
        DELETE_PROJECT_COMPANY_ROLES,
        newuserTrades,
        loginData?.token
      )
        .then((res) => {
          setShowLoader(false);
          if (res?.status === 201) {
            toast.success("Data deleted successfully", {
              position: "top-right",
            });
            window.history.back();
          } else {
            toast.error(
              res?.data?.message ??
                "Something went wrong. Please try again later.",
              {
                position: "top-right",
              }
            );
          }
        })
        .catch((error) => {
          setShowLoader(false);
          toast.error("Something went wrong. Please try again later.", {
            position: "top-right",
          });
          console.log("Error while deleting a record :: ", error);
        });
    } catch (error) {
      setShowLoader(false);
      toast.error("Something went wrong. Please try again later.", {
        position: "top-right",
      });
      console.log("Error while deleting a record :: ", error);
    }
  };

  if (showLoader) {
    return (
      <Loader
        type="converging-spinner"
        className="kendo-spinner"
        style={{
          display: "flex",
          justifyContent: "center",
          minHeight: "500px",
          alignItems: "center",
        }}
      />
    );
  }

  return (
    <>
      <div className="add_form_height_95">
        <AddPageLayout
          dataId={dataId}
          addForm={isAddForm}
          showFooter={!isAddForm}
          footerInfo={footerInfo}
          isFieldEdited={isFieldEdited}
          recordText={"Company ID"}
          saveCurrentData={saveCurrentData}
          onDeleteClick={onDeleteClick}>
          <div
            style={{
              width: "100%",
            }}>
            <Form
              render={(formRenderProps) => (
                <GridLayout
                  rows={[
                    {
                      height: 70,
                    },
                    {
                      height: 250,
                    },
                    {
                      height: 30,
                    },
                  ]}
                  cols={[
                    {
                      width: 375,
                    },
                    {
                      width: 375,
                    },
                  ]}
                  gap={{
                    rows: 30,
                    cols: 35,
                  }}
                  style={{ padding: "16px" }}>
                  <GridLayoutItem col={1} row={1} className="form-inner">
                    <Label className="form-heading"> Role Name :</Label>
                    <Input
                      name="name"
                      value={companyRole?.name}
                      className="field-ip"
                      onChange={(e) => onInputChange(e)}
                    />
                    <Error></Error>
                  </GridLayoutItem>

                  {!isAddForm && (
                    <GridLayoutItem col={2} row={1} className="form-inner">
                      <RecordStatusInput
                        recordStatusId={companyRole?.recordStatusId}
                        recordStatus={companyRole?.recordStatus}
                        onChange={({ recordStatus, recordStatusId }) => {
                          setIsFiledEdited(true);
                          setCompanyRole((preValue) => ({
                            ...preValue,
                            recordStatus,
                            recordStatusId,
                          }));
                        }}
                      />
                    </GridLayoutItem>
                  )}
                </GridLayout>
              )}
            />
          </div>
        </AddPageLayout>
      </div>
    </>
  );
};

export default AddCompanyRole;
