import React, { useEffect, useRef, useState } from "react";
import KendoButton from "../../common/KendoButton";
import { useNavigate, useParams } from "react-router-dom";
import { DatePicker } from "@progress/kendo-react-dateinputs";
import {
  AutoComplete,
  DropDownList,
  MultiSelect,
} from "@progress/kendo-react-dropdowns";
import { GridLayout, GridLayoutItem } from "@progress/kendo-react-layout";
import { Error, Label } from "@progress/kendo-react-labels";
import { Form } from "@progress/kendo-react-form";
import { PostRequestCall } from "../../apicall/PostRequest";
import { toast } from "react-toastify";
import {
  FETCH_RECORD_STATUSES,
  FETCH_CHAT_GROUPS_MESSAGES,
  FETCH_CHAT_GROUPS,
  FetchUsers2,
  SAVE_CHAT_GROUPS_MESSAGES,
  DELETE_CHAT_GROUPS_MESSAGES,
  FETCH_COMPANIES,
  SAVE_CHAT_GROUPS_USERS_MESSAGES_PICTURE,
} from "../../constant/Apipath";
import { useSelector } from "react-redux";
import { Switch, TextArea } from "@progress/kendo-react-inputs";
import { Loader } from "@progress/kendo-react-indicators";
import Modal from "../../common/Modal";
import { IconComponent } from "../../common/Icon";

const AddChatGroupMessage = () => {
  const { dataId, subMenu, userId } = useParams();
  const navigate = useNavigate();

  const { loginData } = useSelector((state) => state.main);

  const [userOptions, setUseroptions] = useState([]);
  const [selectedUser, setSelecteduser] = useState(null);

  const [chatGroup, setChatgroup] = useState([]);
  const [selectedChatGroup, setSelectedChatGroup] = useState([]);

  const [roleOptions, setRoleoptions] = useState([]);
  const [selectRole, setSelectrole] = useState(null);

  const [tradeOptions, setTradeoptions] = useState([]);
  const [selectTrade, setSelecttrade] = useState(null);

  const [selectProducts, setSelectProducts] = useState(null);

  const [recordStatus, setRecordStatus] = useState();

  const [isFieldEdited, setIsFiledEdited] = useState(false);

  const [openUser, setOpenuser] = useState(false);
  const [openProject, setOpenproject] = useState(false);
  const [showLoader, setShowLoader] = useState(false);

  const [imageSelected, setImageSelected] = useState(false);
  const [imageFile, setImageFile] = useState(null);

  const file = useRef(null);

  const [chatGroupMessage, setChatGroupMessage] = useState({
    id: 0,
    fromUserId: 0,
    toChatGroupId: 0,
    messageText: "",
    messageTypeId: 1,
    messageType: "Text",
    recordStatusId: 1,
    recordStatus: "New",
    modifiedBy: 0,
    imgUrl: "",
  });

  const [modalState, setModalState] = useState({
    for: "",
    show: false,
    onActionBtnTitle: "",
  });

  const [messageTypeOptions, setMessageTypeOptions] = useState([
    {
      id: 1,
      label: "Text",
    },
    {
      id: 2,
      label: "Picture",
    },
  ]);

  useEffect(() => {
    console.log("dataId item is-----------------", dataId);
    if (dataId !== "add-chat-group-message") {
      // At the time of user work history edit
      fetchChatGroupMessage();
      fetchRecordStatuses();
    }
    if (userId && dataId === "add-chat-group-message") {
      getUserDetails();
    }
  }, []);

  useEffect(() => {
    if (selectedUser !== null && typeof selectedUser?.value === "undefined") {
      if (selectedUser !== "") {
        getUsers(false);
      }
    }
  }, [selectedUser]);

  useEffect(() => {
    // if (selectedChatGroup !== null && typeof selectedChatGroup?.value === "undefined") {
    if (selectedChatGroup !== "") {
      getChatGroup(false);
    }
    // }
  }, [
    selectedChatGroup,
    selectedChatGroup?.value,
    chatGroupMessage.toChatGroupId,
  ]);

  const fetchRecordStatuses = async () => {
    try {
      const data = JSON.stringify({
        PageNr: 1,
        NrOfRecPerPage: 100,
        FullSearch: "",
        UserId: loginData.userId,
        SearchList: [{ id: userId }],
        IncludeRecordNr: true,
      });

      const result = await PostRequestCall(
        FETCH_RECORD_STATUSES,
        data,
        loginData?.token
      );

      setRecordStatus(
        result?.data?.data.map((item) => ({
          ...item,
          id: item.id,
          name: item.name,
        }))
      );
    } catch (error) {
      console.log("Error while getting user details :: ", error);
    }
  };
  console.log("result Data Record Status--------------", recordStatus);
  const fetchChatGroupMessage = async () => {
    try {
      const data = JSON.stringify({
        PageNr: 1,
        NrOfRecPerPage: 100,
        FullSearch: "",
        UserId: loginData.userId,
        SearchList: [{ id: dataId }],
        IncludeRecordNr: true,
      });

      const result = await PostRequestCall(
        FETCH_CHAT_GROUPS_MESSAGES,
        data,
        loginData?.token
      );
      setChatGroupMessage({
        ...chatGroupMessage,
        id: result?.data?.data[0]?.id,
        fromUserId: result?.data?.data[0]?.fromUserId,
        fromUserName:
          result?.data?.data[0]?.fromUserFirstName +
          result?.data?.data[0]?.fromUserLastName,
        toChatGroupId: result?.data?.data[0]?.toChatGroupId,
        messageText: result?.data?.data[0]?.messageText,
        messageTypeId: {
          id: result?.data?.data[0]?.messageTypeId,
          label: result?.data?.data[0]?.messageTypeId != 1 ? "Picture" : "Text",
        },
        messageType:
          result?.data?.data[0]?.messageTypeId === 1 ? "Text" : "Picture",
        imgUrl:
          result?.data?.data[0]?.messageTypeId !== 1
            ? result?.data?.data[0]?.messageText
            : "",
        recordStatusId: result?.data?.data[0]?.recordStatusId,
        recordStatus: result?.data?.data[0]?.recordStatus,
      });
      if (result?.data?.data[0]?.messageTypeId !== 1) {
        setImageSelected(true);
      }

      setSelectedChatGroup({
        ...selectedChatGroup,
        value: String(result?.data?.data[0]?.toChatGroupId),
      });
      userId = result?.data?.data[0]?.fromUserId;
      setSelecteduser({
        ...selectedUser,
        value: result?.data?.data?.fromUserId,
        label:
          result?.data?.data[0]?.fromUserFirstName +
          result?.data?.data[0]?.fromUserLastName,
      });
    } catch (error) {
      console.log("Error while getting user details :: ", error);
    }
  };

  const getUserDetails = async () => {
    try {
      const data = JSON.stringify({
        PageNr: 1,
        NrOfRecPerPage: 100,
        FullSearch: "",
        UserId: loginData.userId,
        SearchList: [{ id: userId }],
        IncludeRecordNr: true,
      });

      const result = await PostRequestCall(FetchUsers2, data, loginData?.token);
      setSelecteduser({ value: userId, label: result?.data?.data?.[0]?.name });
    } catch (error) {
      console.log("Error while getting user details :: ", error);
    }
  };

  const getUsers = async (onFocus) => {
    console.log("selected user s----", selectedUser);
    try {
      const getData = JSON.stringify({
        PageNr: 1,
        NrOfRecPerPage: onFocus ? 20 : 0,
        FullSearch: "",
        UserId: loginData.userId,
        SearchList: onFocus ? [] : [{ name: selectedUser.label }],
        IncludeRecordNr: true,
      });
      const getResult = await PostRequestCall(
        FetchUsers2,
        getData,
        loginData?.token
      );
      if (getResult?.data?.data?.length > 0) {
        const userOptions = getResult?.data?.data.map((user) => ({
          value: user.id,
          label: user.name,
        }));
        console.log("userOptions", userOptions);
        setUseroptions(userOptions);
        setOpenuser(true);
      }
    } catch (error) {
      console.log("error", error);
    }
  };

  const getChatGroup = async (onFocus) => {
    try {
      let obj = {
        NrOfRecPerPage: onFocus === true ? 20 : 0,
        FullSearch: "",
        SortList: [{ FieldName: "Id", Direction: "ASC" }],
        SearchList:
          onFocus === true
            ? [{ UserId: selectedChatGroup?.value }]
            : [{ UserId: selectedChatGroup?.value }],
        IncludeRecordNr: true,
        TypeOfObjectReturned: "",
        FetchAllowedRecordsOnly: false,
      };
      const fetchChatGroupData = await PostRequestCall(
        FETCH_CHAT_GROUPS,
        obj,
        loginData?.token
      );
      if (fetchChatGroupData?.data?.data?.length > 0) {
        const getList = fetchChatGroupData?.data?.data.map((project) => ({
          value: String(project?.id),
          label: String(project?.id),
        }));
        setChatgroup(getList);
        if (onFocus === true) setOpenproject(true);
      }
    } catch (error) {
      console.log("error", error);
    }
  };

  const onInputChange = (e) => {
    console.log("e", e.target.value);
    setChatGroupMessage({
      ...chatGroupMessage,
      messageText: e.target.value,
    });
  };

  const handleChange = (e) => {
    setSelecteduser({ ...selectedUser, label: e.target.value });
  };

  const onProjectchange = (e) => {
    setSelectedChatGroup({ ...selectedChatGroup, value: e.target.value });
  };

  const userSelect = async (user) => {
    setOpenuser(false);
    setSelecteduser(user);
  };

  const projectSelect = (project) => {
    setSelectedChatGroup(project);
    setOpenproject(false);
  };

  const onUserRender = (li, itemProps) => {
    const user = itemProps?.dataItem;
    return (
      <div className="user-item" onClick={() => userSelect(user)}>
        <span>{user?.label}</span>
      </div>
    );
  };

  const onProjectRender = (li, itemProps) => {
    const project = itemProps.dataItem;
    return (
      <div className="user-item" onClick={() => projectSelect(project)}>
        <span>{project?.label}</span>
      </div>
    );
  };

  const formatDateForSavingData = (dateString) => {
    var date = new Date(dateString);
    var year = date.getFullYear();
    var month = (date.getMonth() + 1).toString().padStart(2, "0");
    var day = date.getDate().toString().padStart(2, "0");
    var formattedDate = year + "-" + month + "-" + day;
    return formattedDate;
  };

  const saveCurrentdata = async () => {
    let abcd = "";
    const getRequest = new FormData();
    if (
      (chatGroupMessage?.fromUserId === 0 || selectedUser === null) &&
      selectedChatGroup?.length === 0 &&
      chatGroupMessage?.toChatGroupId === 0
    ) {
      toast.error("Please Select a User , To Chat Group ID", {
        position: "top-right",
      });
      return;
    }
    if (imageSelected && imageFile !== null) {
      getRequest.append(
        "UserId",
        chatGroupMessage.fromUserId === 0
          ? selectedUser?.value
          : chatGroupMessage.fromUserId
      );
      getRequest.append("FileName", imageFile);

      let getResponse = await PostRequestCall(
        SAVE_CHAT_GROUPS_USERS_MESSAGES_PICTURE,
        getRequest,
        loginData?.token,
        "formData"
      );
      abcd = getResponse?.data?.data[0];
    }

    let updatedData = [];
    let updatedItem = {};
    updatedItem = {
      Id: chatGroupMessage.id || 0,
      fromUserId:
        chatGroupMessage.fromUserId !== 0
          ? chatGroupMessage.fromUserId
          : selectedUser?.value,
      toChatGroupId: selectedChatGroup?.value,
      messageText: abcd !== "" ? abcd : chatGroupMessage.messageText,
      messageTypeId:
        chatGroupMessage?.messageTypeId.id === 0
          ? 2
          : chatGroupMessage?.messageTypeId.id,
      recordStatusId: chatGroupMessage?.recordStatusId,
      modifiedBy: loginData?.userId,
    };
    updatedData.push(updatedItem);

    const saveObj = {
      ReturnRecordId: false,
      ReturnRecordError: true,
      UserId: loginData?.userId,
      SaveList: updatedData,
    };

    let getResponse = await PostRequestCall(
      SAVE_CHAT_GROUPS_MESSAGES,
      saveObj,
      loginData?.token
    );

    if (getResponse.status === 201) {
      toast.success(
        "Data " +
          `${dataId !== "add-chat-group-message" ? "Updated" : "Saved"}` +
          " Succesfully",
        {
          position: "top-right",
        }
      );
      window.history.back();
    } else {
      toast.error("There Was Issue Saving Data", {
        position: "top-right",
      });
    }
  };

  const onModalClose = () => {
    window.history.back();
  };

  const onActionBtnClick = () => {
    setModalState((preValue) => ({
      ...preValue,
      for: "",
      show: false,
      onActionBtnTitle: "",
    }));
    if (modalState?.for === "delete") {
      onDeleteClick();
    } else if (modalState?.for === "edit") {
      saveCurrentdata();
    } else {
      return null;
    }
  };

  const onDeleteClick = async () => {
    try {
      setShowLoader(true);
      const deleteObj = {
        ReturnRecordError: true,
        ReturnRecordId: false,
        UserId: loginData?.userId,
        DeleteList: [{ Id: Number(dataId), ModifiedBy: loginData?.userId }],
      };

      PostRequestCall(DELETE_CHAT_GROUPS_MESSAGES, deleteObj, loginData?.token)
        .then(async (res) => {
          setShowLoader(false);
          if (res?.data?.status) {
            toast.success("Data deleted successfully", {
              position: "top-right",
            });
            window.history.back();
          } else {
            toast.error(
              res?.data?.message ??
                "Something went wrong. Please try again later.",
              {
                position: "top-right",
              }
            );
          }
        })
        .catch(async (error) => {
          setShowLoader(false);
          toast.error("Something went wrong. Please try again later.", {
            position: "top-right",
          });
        });
    } catch (error) {
      setShowLoader(false);
      toast.error("Something went wrong. Please try again later.", {
        position: "top-right",
      });
    }
  };

  const showDeleteConfirmation = () => {
    setModalState((preValue) => ({
      ...preValue,
      for: "delete",
      show: true,
      onActionBtnTitle: "Delete",
    }));
  };

  const onCancelClick = () => {
    if (isFieldEdited) {
      return setModalState((preValue) => ({
        ...preValue,
        for: "edit",
        show: true,
        onActionBtnTitle: "Save",
      }));
    } else {
      return window.history.back();
    }
  };

  if (showLoader) {
    return (
      <Loader
        type="converging-spinner"
        className="kendo-spinner"
        style={{
          display: "flex",
          justifyContent: "center",
          minHeight: "500px",
          alignItems: "center",
        }}
      />
    );
  }

  const onRemoveImage = () => {
    setIsFiledEdited(true);
    setImageFile("");
    setChatGroupMessage({
      ...chatGroupMessage,
      imgUrl: null,
    });
  };
  const onImageChange = (e) => {
    setIsFiledEdited(true);
    setImageFile(e?.target?.files[0]);
    if (e?.target?.files?.length > 0) {
      setChatGroupMessage({
        ...chatGroupMessage,
        imgUrl: URL.createObjectURL(e.target.files[0]),
      });
      setImageSelected(true);
    }
    e.target.value = "";
  };

  return (
    <>
      <section className="add-user">
        <>
          <section className="user-information">
            <div className="k-d-flex k-justify-content-between">
              <div className="k-d-flex k-align-items-center">
                {dataId !== "add-chat-group-message" && (
                  <span class="record-id-text">
                    Chat Group Message : {dataId}
                  </span>
                )}
              </div>
              <div
                className="k-d-flex k-justify-content-end k-p-2"
                style={{ gap: "10px" }}>
                <KendoButton
                  iconClass=""
                  size={16}
                  className=""
                  themeColor={"primary"}
                  buttonName="Save"
                  onClick={saveCurrentdata}
                />
                {dataId !== "add-chat-group-message" && (
                  <KendoButton
                    size={16}
                    className=""
                    onClick={showDeleteConfirmation}
                    buttonName="Delete"
                    themeColor={"secondary"}
                  />
                )}
                <KendoButton
                  iconClass=""
                  size={16}
                  className=""
                  buttonName="Cancel"
                  onClick={onCancelClick}
                />
              </div>
            </div>
            <div
              style={{
                borderBottom: "1px solid #f5f5f5",
                width: "100%",
              }}></div>
            <Form
              render={(formRenderProps) => (
                <GridLayout
                  rows={[
                    {
                      height: 70,
                    },
                    {
                      height: 70,
                    },
                    {
                      height: 70,
                    },
                    {
                      height: 70,
                    },
                    {
                      height: 80,
                    },
                    {
                      height: 70,
                    },
                  ]}
                  cols={[
                    {
                      width: 375,
                    },
                    {
                      width: 375,
                    },
                    {
                      width: 375,
                    },
                  ]}
                  gap={{
                    rows: 25,
                    cols: 35,
                  }}
                  style={{ padding: "25px 16px", gap: "20px 35px" }}>
                  <GridLayoutItem col={1} row={1} className="form-inner">
                    <Label className="form-heading">From :</Label>
                    <AutoComplete
                      data={userOptions}
                      onFocus={() => getUsers(true)}
                      onClose={() => setOpenuser(false)}
                      onBlur={() => setOpenuser(false)}
                      opened={openUser}
                      value={
                        dataId !== "add-chat-group-message"
                          ? chatGroupMessage.fromUserName
                          : selectedUser &&
                            typeof selectedUser.label === "string"
                          ? selectedUser.label
                          : ""
                      }
                      className="field-ip"
                      itemRender={onUserRender}
                      onChange={handleChange}
                      textField="label"
                      placeholder="Search for users..."
                      disabled={dataId !== "add-chat-group-message"}
                    />
                    <Error></Error>
                  </GridLayoutItem>

                  <GridLayoutItem col={2} row={1} className="form-inner">
                    <Label className="form-heading">To Chat Group :</Label>
                    <AutoComplete
                      data={chatGroup}
                      onFocus={() => getChatGroup(true)}
                      onClose={() => setOpenproject(false)}
                      onBlur={() => setOpenproject(false)}
                      opened={openProject}
                      value={
                        selectedChatGroup
                          ? selectedChatGroup?.value
                          : chatGroupMessage.toChatGroupId !== 0
                          ? chatGroupMessage.toChatGroupId
                          : ""
                      }
                      itemRender={onProjectRender}
                      className="field-ip"
                      onChange={onProjectchange}
                      textField="value"
                      placeholder="Select Group..."
                    />
                    <Error></Error>
                  </GridLayoutItem>

                  <GridLayoutItem col={1} row={2} className="form-inner">
                    <Label className="form-heading">Message Type :</Label>
                    <DropDownList
                      data={messageTypeOptions}
                      value={chatGroupMessage.messageTypeId}
                      onChange={(e) => {
                        const selectedOption = messageTypeOptions.find(
                          (option) => option.id === e.target.value.id
                        );
                        setChatGroupMessage({
                          ...chatGroupMessage,
                          messageTypeId: e.target.value,
                          messageType: selectedOption.label,
                        });
                        setImageSelected(false);
                      }}
                      textField="label"
                      valueField="id"
                    />
                    <Error></Error>
                  </GridLayoutItem>
                  {chatGroupMessage.messageTypeId.id === 1 ? (
                    <GridLayoutItem col={2} row={2} className="form-inner">
                      <Label className="form-heading">Message :</Label>
                      <TextArea
                        type="text"
                        id="description"
                        name="description"
                        value={chatGroupMessage?.messageText}
                        className="field-ip"
                        onChange={onInputChange}
                        rows={4}
                      />
                      <Error></Error>
                    </GridLayoutItem>
                  ) : (
                    <GridLayoutItem col={2} row={2} className="form-inner">
                      <Label className="form-heading"> Add Users Image :</Label>
                      <div className="k-d-flex" style={{ gap: "15px" }}>
                        <input
                          type="file"
                          hidden
                          accept=".png,.jpg"
                          id="user-profile"
                          style={{ display: "none" }}
                          onChange={(e) => onImageChange(e)}
                          ref={file}
                        />
                        {chatGroupMessage?.imgUrl ? (
                          <div className="k-d-flex">
                            <div
                              // className="add-file"
                              style={{ position: "relative" }}>
                              <img
                                className="user-img"
                                src={chatGroupMessage.imgUrl}
                                alt="Ad"
                              />
                              <button
                                onClick={onRemoveImage}
                                style={{
                                  position: "absolute",
                                  top: "6px",
                                  right: "8px",
                                  backgroundColor: "red",
                                  color: "white",
                                  border: "none",
                                  borderRadius: "50%",
                                  cursor: "pointer",
                                }}>
                                <span>X</span>
                              </button>
                            </div>
                          </div>
                        ) : (
                          <>
                            <GridLayoutItem
                              col={2}
                              row={1}
                              className="form-inner">
                              <div
                                // className="add-file"
                                style={{
                                  position: "relative",
                                  cursor: "pointer",
                                  border: "0.5px solid #d3d3d3",
                                  padding: "5px",
                                  display: "flex",
                                  justifyContent: "center",
                                  borderRadius: "4px",
                                  gap: "10px",
                                }}
                                onClick={() => file.current.click()}>
                                <IconComponent iconName={"Image"} size={20} />
                                <span className="add-file-text">
                                  Choose image
                                </span>
                              </div>
                            </GridLayoutItem>
                          </>
                        )}
                      </div>
                    </GridLayoutItem>
                  )}
                  {dataId !== "add-chat-group-message" && (
                    <GridLayoutItem col={3} row={2} className="form-inner">
                      <Label className="form-heading">Record Status :</Label>
                      <DropDownList
                        data={recordStatus}
                        value={recordStatus?.find(
                          (option) =>
                            option.id === chatGroupMessage.recordStatusId
                        )}
                        onChange={(e) => {
                          setChatGroupMessage({
                            ...chatGroupMessage,
                            recordStatus: e.target.value,
                            recordStatusId: e.target.value.id,
                          });
                        }}
                        textField="name"
                        valueField="id"
                      />
                      <Error></Error>
                    </GridLayoutItem>
                  )}
                  <GridLayoutItem
                    col={1}
                    row={dataId !== "add-chat-group-message" ? 4 : 4}
                    className="form-inner">
                    <div className="k-d-flex" style={{ gap: "10px" }}>
                      <KendoButton
                        iconClass=""
                        size={16}
                        themeColor={"primary"}
                        buttonName="Save"
                        onClick={saveCurrentdata}
                      />
                      {dataId !== "add-chat-group-message" && (
                        <KendoButton
                          size={16}
                          className=""
                          onClick={showDeleteConfirmation}
                          buttonName="Delete"
                          themeColor={"secondary"}
                        />
                      )}
                      <KendoButton
                        iconClass=""
                        size={16}
                        className=""
                        buttonName="Cancel"
                        onClick={onCancelClick}
                      />
                    </div>
                  </GridLayoutItem>
                </GridLayout>
              )}
            />
          </section>
        </>
      </section>
      {modalState?.show && (
        <Modal
          for={modalState?.for}
          show={modalState?.show}
          onClose={onModalClose}
          onActionBtnClick={onActionBtnClick}
          onActionBtnTitle={modalState?.onActionBtnTitle}
        />
      )}
    </>
  );
};

export default AddChatGroupMessage;
