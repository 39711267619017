import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import {
  DELETE_USERS_REWARDS_ACTIVITIES,
  FETCH_USERS_REWARDS_ACTIVITIE,
  SAVE_USER_REWARDS_ACTIVITIES,
} from "../../constant/Apipath";
import { PostRequestCall } from "../../apicall/PostRequest";
import { Input } from "@progress/kendo-react-inputs";
import { useSelector } from "react-redux";
import { GridLayout, GridLayoutItem } from "@progress/kendo-react-layout";
import KendoButton from "../../common/KendoButton";
import { toast } from "react-toastify";
import { Loader } from "@progress/kendo-react-indicators";
import Modal from "../../common/Modal";
import Footerinformation from "../../common/Footerinformation";

const EditUserRewardActivities = () => {
  const { dataId } = useParams();
  const { loginData } = useSelector((state) => state.main);

  const [userRewardActivity, setUseruserRewardActivity] = useState({
    appName: "",
    userName: "",
    bucks: "",
    description: "",
    recordStatusId: 1,
    recordStatus: "New",
    createdByUser: "",
    modifiedByUser: "",
    dateCreated: "",
    dateModified: "",
  });

  const [showLoader, setShowLoader] = useState(false);
  const [modalState, setModalState] = useState({
    for: "",
    show: false,
    onActionBtnTitle: "",
  });
  const [isFieldEdited, setIsFiledEdited] = useState(false);

  useEffect(() => {
    if (dataId !== "add-user-reward-activity") {
      loadData();
    }
  }, []);

  const loadData = async () => {
    if (dataId !== "add-user-reward-activity") {
      var obj = JSON.stringify({
        PageNr: 1,
        NrOfRecPerPage: 20,
        FullSearch: "",
        UserId: loginData.userId,
        SearchList: [
          {
            Id: dataId,
          },
        ],
      });

      const result = await PostRequestCall(
        FETCH_USERS_REWARDS_ACTIVITIE,
        obj,
        loginData?.token
      );
      let data = result?.data?.data?.[0] ?? null;
      console.log("data", data);
      setUseruserRewardActivity((prevData) => ({
        ...prevData,
        appName: data?.appName,
        userName: data?.userName,
        bucks: data?.bucks,
        description: data?.activityDescription,
        userId: data?.userId,
        userRewardActivityId: data?.rewardActivityId,
        recordId: data?.recordId,
        applicationId: data?.applicationId,
      }));
    }
  };

  const onInputChange = (e, type) => {
    let { name, value } = e.target;
    if (type === "number") {
      setUseruserRewardActivity({
        ...userRewardActivity,
        [name]: value > 0 ? value : "",
      });
    } else {
      setUseruserRewardActivity({ ...userRewardActivity, [name]: value });
    }
    setIsFiledEdited(true);
  };

  const onCancelClick = () => {
    if (isFieldEdited) {
      return setModalState((preValue) => ({
        ...preValue,
        for: "edit",
        show: true,
        onActionBtnTitle: "Save",
      }));
    } else {
      return window.history.back();
    }
  };
  console.log("user:::", userRewardActivity);
  const onSubmit = async (e) => {
    setShowLoader(true);

    const { bucks } = userRewardActivity;

    if (bucks < 0) {
      setShowLoader(false);
      toast.error("Bucks cannot be negative.", {
        position: "top-right",
      });
      return;
    }

    const obj = {
      UserId: loginData?.userId,
      ReturnRecordId: true,
      ReturnRecordError: true,
      SaveList: [
        {
          Id: dataId,
          UserId: userRewardActivity?.userId,
          RewardActivityId: userRewardActivity?.userRewardActivityId,
          Bucks: userRewardActivity?.bucks,
          RecordId: userRewardActivity?.recordId,
          ApplicationId: userRewardActivity?.applicationId,
          ModifiedBy: loginData?.userId,
        },
      ],
    };

    try {
      const result = await PostRequestCall(
        SAVE_USER_REWARDS_ACTIVITIES,
        obj,
        loginData?.token
      );
      setShowLoader(false);
      if (result?.data?.status === false || result?.data?.status === 400) {
        toast.error(result?.data?.message ?? "Please Enter All Fields.", {
          position: "top-right",
        });
      } else {
        toast.success(
          `Data ${
            dataId !== "add-user-reward-activity" ? "updated" : "added"
          } successfully`,
          {
            position: "top-right",
          }
        );
        window.history.back();
      }
    } catch (error) {
      setShowLoader(false);
      toast.error("Something went wrong. Please try again later.", {
        position: "top-right",
      });
      console.error("Error saving data :", error);
    }
  };

  const onDeleteClick = async () => {
    try {
      setShowLoader(true);
      const deleteObj = {
        ReturnRecordError: true,
        ReturnRecordId: false,
        UserId: loginData.userId,
        DeleteList: [
          {
            Id: dataId,
            ModifiedBy: loginData.userId,
          },
        ],
      };
      return PostRequestCall(
        DELETE_USERS_REWARDS_ACTIVITIES,
        deleteObj,
        loginData?.token
      )
        .then((res) => {
          setShowLoader(false);
          if (res?.data?.status) {
            toast.success("Data deleted successfully", {
              position: "top-right",
            });
            window.history.back();
          } else {
            toast.error(
              res?.data?.message ??
                "Something went wrong. Please try again later.",
              {
                position: "top-right",
              }
            );
          }
        })
        .catch((error) => {
          setShowLoader(false);
          toast.error("Something went wrong. Please try again later.", {
            position: "top-right",
          });
          console.log("Error while deleting a record :: ", error);
        });
    } catch (error) {
      setShowLoader(false);
      toast.error("Something went wrong. Please try again later.", {
        position: "top-right",
      });
      console.log("Error while deleting a record :: ", error);
    }
  };

  const onActionBtnClick = () => {
    setModalState((preValue) => ({
      ...preValue,
      for: "",
      show: false,
      onActionBtnTitle: "",
    }));
    if (modalState?.for === "delete") {
      onDeleteClick();
    } else if (modalState?.for === "edit") {
      onSubmit();
    } else {
      return null;
    }
  };

  const showDeleteConfirmation = () => {
    setModalState((preValue) => ({
      ...preValue,
      for: "delete",
      show: true,
      onActionBtnTitle: "Delete",
    }));
  };

  const onModalClose = () => {
    window.history.back();
  };

  if (showLoader) {
    return (
      <Loader
        type="converging-spinner"
        className="kendo-spinner"
        style={{
          display: "flex",
          justifyContent: "center",
          minHeight: "500px",
          alignItems: "center",
        }}
      />
    );
  }

  return (
    <section className="add-user">
      <section className="user-information">
        <div className="k-d-flex k-justify-content-between">
          <div className="k-d-flex k-align-items-center">
            {dataId !== "add-user-reward-activity" && (
              <span className="record-id-text">Reward Id : {dataId}</span>
            )}
          </div>
          <div className="k-d-flex k-p-2" style={{ gap: "10px" }}>
            <KendoButton
              iconClass=""
              size={16}
              onClick={onSubmit}
              className=""
              themeColor={"primary"}
              buttonName="Save"
            />
            {dataId !== "add-user-reward-activity" && (
              <KendoButton
                size={16}
                className=""
                onClick={showDeleteConfirmation}
                buttonName="Delete"
                themeColor={"secondary"}
              />
            )}
            <KendoButton
              iconClass=""
              size={16}
              className=""
              buttonName="Cancel"
              onClick={onCancelClick}
            />
          </div>
        </div>
        <div style={{ borderBottom: "1px solid #f5f5f5", width: "100%" }}></div>
        <GridLayout
          rows={[
            {
              height: 70,
            },
            {
              height: 70,
            },
            {
              height: 285,
            },
            {
              height: 30,
            },
          ]}
          cols={[
            {
              width: 375,
            },
            {
              width: 375,
            },
            {
              width: 375,
            },
          ]}
          gap={{
            rows: 25,
            cols: 35,
          }}
          style={{ padding: "25px 16px", gap: "20px 35px" }}>
          <GridLayoutItem col={1} row={1} className="form-inner">
            <label className="form-heading">App Name :</label>
            <div>
              <Input
                type="text"
                id="appName"
                name="appName"
                value={userRewardActivity?.appName}
                className="field-ip"
                onChange={(e) => onInputChange(e)}
                disabled
              />
            </div>
          </GridLayoutItem>
          <GridLayoutItem col={2} row={1} className="form-inner">
            <label className="form-heading">User Name :</label>
            <div>
              <Input
                type="text"
                id="userName"
                name="userName"
                value={userRewardActivity?.userName}
                className="field-ip"
                onChange={(e) => onInputChange(e)}
                disabled
              />
            </div>
          </GridLayoutItem>
          <GridLayoutItem col={3} row={1} className="form-inner">
            <label className="form-heading">Bucks :</label>
            <div>
              <Input
                type="number"
                id="bucks"
                name="bucks"
                value={userRewardActivity?.bucks}
                className="field-ip"
                onChange={(e) => onInputChange(e, "number")}
              />
            </div>
          </GridLayoutItem>
          <GridLayoutItem col={1} row={2} className="form-inner">
            <label className="form-heading">Activity Description :</label>
            <div>
              <Input
                type="text"
                id="description"
                name="description"
                value={userRewardActivity?.description}
                className="field-ip"
                onChange={(e) => onInputChange(e)}
                disabled
              />
            </div>
          </GridLayoutItem>

          <GridLayoutItem col={1} row={3} className="form-inner">
            <div className="k-d-flex" style={{ gap: "10px" }}>
              <KendoButton
                iconClass=""
                size={16}
                onClick={onSubmit}
                themeColor={"primary"}
                buttonName="Save"
              />
              {dataId !== "add-user-reward-activity" && (
                <KendoButton
                  size={16}
                  className=""
                  onClick={showDeleteConfirmation}
                  buttonName="Delete"
                  themeColor={"secondary"}
                />
              )}
              <KendoButton
                iconClass=""
                size={16}
                className=""
                buttonName="Cancel"
                onClick={onCancelClick}
              />
            </div>
          </GridLayoutItem>
          {dataId !== "add-user-reward-activity" && (
            <GridLayoutItem colSpan={3} row={4} className="form-footer">
              <Footerinformation footerInfo={userRewardActivity} />
            </GridLayoutItem>
          )}
        </GridLayout>
      </section>
      {modalState?.show && (
        <Modal
          for={modalState?.for}
          show={modalState?.show}
          onClose={onModalClose}
          onActionBtnClick={onActionBtnClick}
          onActionBtnTitle={modalState?.onActionBtnTitle}
        />
      )}
    </section>
  );
};

export default EditUserRewardActivities;
