import React, { useEffect, useState } from 'react'
import { DropDownList } from '@progress/kendo-react-dropdowns';
import { Label } from '@progress/kendo-react-labels';
import { useSelector } from 'react-redux';
import { PostRequestCall } from '../apicall/PostRequest';
import { FETCH_RECORD_STATUSES, FETCH_ROLES_AUTH } from '../constant/Apipath';

const RoleAuthInput = (props) => {

    const [roleAuthOptions, setRoleAuthOptions] = useState([]);
    const { loginData } = useSelector((state) => state.main);

    useEffect(() => {
        getRoleAuthList();
    }, [])

    const getRoleAuthList = async () => {
        try {
            let obj = {
                PageNr: 1,
                NrOfRecPerPage: 100,
                FullSearch: "",
                UserId: "1",
                SortList: [
                    {
                        FieldName: "Id",
                        Direction: "ASC",
                    },
                ],
                IncludeRecordNr: true,
            };
            const res = await PostRequestCall(
                FETCH_ROLES_AUTH,
                obj,
                loginData?.token
            );
            let data = res?.data?.data?.map((record) => ({
                id: record?.id,
                text: record?.name,
            }));
            setRoleAuthOptions(data ?? []);
        } catch (error) {
            console.log("Error while getting role list :: ", error);
        }
    };


    return (
        <>
            <Label className="form-heading">Role Auth:</Label>
            <div>
                <DropDownList
                    style={{ width: "370px" }}
                    onChange={(e) => {
                        let rolesAuthName = e.value?.text
                        let roleAuthId = e.value?.id
                        props?.onChange({ rolesAuthName, roleAuthId })
                    }}
                    className="dropdown-list"
                    value={{
                        id: props?.roleAuthId,
                        text: props?.rolesAuthName,
                    }}
                    data={roleAuthOptions}
                    textField="text"
                    dataItemKey="id"
                />
            </div>
        </>
    )
}

export default RoleAuthInput