import { GridLayout } from "@progress/kendo-react-layout";
import React from "react";

function HomePage(props) {
  return (
    <>
      <section className="add-user">
        <section className="user-information">
          <GridLayout
            rows={[
              {
                height: 70,
              },
              {
                height: 70,
              },
              {
                height: 70,
              },
              {
                height: 70,
              },
              {
                height: 70,
              },
              {
                height: 70,
              },
              {
                height: 70,
              },
              {
                // height: 20,
              },
            ]}
            cols={[
              {
                width: 375,
              },
              {
                width: 375,
              },
              {
                width: 375,
              },
            ]}
            gap={{
              rows: 25,
              cols: 35,
            }}
            style={{
              padding: "25px 16px",
              justifyContent: "center",
              display: "flex",
            }}>
            <h1
              style={{
                display: "flex",
                justifyContent: "center",
                //   marginTop: "10%",
              }}>
              {props.title}
            </h1>
          </GridLayout>
        </section>
      </section>
    </>
  );
}

export default HomePage;
