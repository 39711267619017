import React, { useEffect, useRef, useState } from "react";
import { useParams } from "react-router-dom";
import { Input, Switch, TextArea } from "@progress/kendo-react-inputs";
import { useSelector } from "react-redux";
import { GridLayout, GridLayoutItem } from "@progress/kendo-react-layout";
import { toast } from "react-toastify";
import { Loader } from "@progress/kendo-react-indicators";
import { PostRequestCall } from "../../apicall/PostRequest";
import {
  DELETE_BI_INFO_CHANNEL,
  FETCH_BI_INFO_CHANNEL,
  SAVE_BI_INFO_CHANNEL,
} from "../../constant/Apipath";
import { Form } from "@progress/kendo-react-form";
import AddPageLayout from "../../addpagelayout/AddPageLayout";
import RecordStatusInput from "../../common/RecordStatusInput";

const AddNewBiInfoChannel = () => {
  const { dataId } = useParams();
  const listRef = useRef(null);
  const isAddForm = dataId === "add-bill-info-channel";
  const [footerInfo, setFooterInfo] = useState({});
  const { loginData } = useSelector((state) => state.main);
  const [company, setCompany] = useState({
    title: "",
    description: "",
    color: "",
    sendNotification: "",
    recordStatusId: 1,
    recordStatus: "New",
  });
  const [showLoader, setShowLoader] = useState(false);
  const [products, setProducts] = useState("");

  const [isFieldEdited, setIsFiledEdited] = useState(false);

  useEffect(() => {
    if (!isAddForm) {
      loadCompanyData();
    }
  }, [products]);

  const loadCompanyData = async () => {
    var obj = JSON.stringify({
      PageNr: 1,
      NrOfRecPerPage: 20,
      FullSearch: "",
      UserId: loginData.userId,
      SearchList: [
        {
          Id: dataId,
        },
      ],
    });

    const result = await PostRequestCall(
      FETCH_BI_INFO_CHANNEL,
      obj,
      loginData?.token
    );

    let data = result?.data?.data?.[0] ?? null;
    setCompany((prevData) => ({
      ...prevData,
      ...data,
      title: data?.title,
      description: data?.description,
      color: data?.color,
      sendNotification: data?.sendNotification,
      recordStatus: data?.recordStatus,
      recordStatusId: data?.recordStatusId,
    }));
    let footerObj = {
      dateCreated: data?.dateCreated ?? null,
      modifiedByUser: data?.modifiedByUser ?? "",
      dateModified: data?.dateModified ?? "",
      createdByUser: data?.createdByUser ?? "",
    };
    setFooterInfo(footerObj);
    setProducts(data?.industryName);
  };

  const onInputChange = (e) => {
    setIsFiledEdited(true);
    setCompany({ ...company, [e.target.name]: e.target.value });
  };

  const saveCurrentData = async () => {
    setShowLoader(true);

    const newCompany = {
      ReturnRecordId: false,
      ReturnRecordError: true,
      UserId: loginData?.userId,
      SaveList: [
        {
          Id: isAddForm ? 0 : dataId,
          Title: company.title,
          Color: company.color,
          Description: company.description,
          SendNotification: company?.sendNotification || "false",
          RecordStatusId: company?.recordStatusId || 1,
          ModifiedBy: loginData?.userId,
        },
      ],
    };
    try {
      const result = await PostRequestCall(
        SAVE_BI_INFO_CHANNEL,
        newCompany,
        loginData?.token
      );

      if (result?.data?.status === false || result?.data?.status === 400) {
        setShowLoader(false);
        toast.error(
          result?.data?.message ??
            "Something went wrong. Please try again later.",
          {
            position: "top-right",
          }
        );
      } else {
        setShowLoader(false);
        toast.success(`Data ${!isAddForm ? "updated" : "added"} successfully`, {
          position: "top-right",
        });
        window.history.back();
      }
    } catch (error) {
      setShowLoader(false);
      toast.error("Something went wrong. Please try again later.", {
        position: "top-right",
      });
      console.error("Error saving data :", error);
    }
  };

  const onDeleteClick = async () => {
    try {
      setShowLoader(true);
      const deleteObj = {
        UserId: loginData.userId,
        ReturnRecordId: false,
        ReturnRecordError: true,
        SoftDelete: true,
        DeleteList: [
          {
            Id: dataId,
          },
        ],
      };
      return PostRequestCall(
        DELETE_BI_INFO_CHANNEL,
        deleteObj,
        loginData?.token
      )
        .then((res) => {
          setShowLoader(false);
          if (res?.data?.status) {
            toast.success("Data deleted successfully", {
              position: "top-right",
            });
            window.history.back();
          } else {
            toast.error(
              res?.data?.message ??
                "Something went wrong. Please try again later.",
              {
                position: "top-right",
              }
            );
          }
        })
        .catch((error) => {
          setShowLoader(false);
          toast.error("Something went wrong. Please try again later.", {
            position: "top-right",
            handleScroll,
          });
          console.log("Error while deleting a record :: ", error);
        });
    } catch (error) {
      setShowLoader(false);
      toast.error("Something went wrong. Please try again later.", {
        position: "top-right",
      });
      console.log("Error while deleting a record :: ", error);
    }
  };
  const handleScroll = (scrollOffset) => {
    listRef.current.scrollLeft += scrollOffset;
  };

  const convertToHex = (colorValue) => {
    const rgbaMatch = colorValue.match(
      /rgba?\((\d+),\s*(\d+),\s*(\d+)(?:,\s*(\d*\.?\d+))?\)/
    );
    if (rgbaMatch) {
      const r = parseInt(rgbaMatch[1]);
      const g = parseInt(rgbaMatch[2]);
      const b = parseInt(rgbaMatch[3]);
      return `#${((1 << 24) + (r << 16) + (g << 8) + b).toString(16).slice(1)}`;
    }

    const hexMatch = colorValue.match(/^#([0-9a-f]{3}){1,2}$/i);
    if (hexMatch) {
      return colorValue;
    }

    return "#ffffff";
  };

  if (showLoader) {
    return (
      <Loader
        type="converging-spinner"
        className="kendo-spinner"
        style={{
          display: "flex",
          justifyContent: "center",
          minHeight: "500px",
          alignItems: "center",
        }}
      />
    );
  }

  return (
    <div className="add_form_height_95">
      <AddPageLayout
        dataId={dataId}
        addForm={isAddForm}
        showFooter={!isAddForm}
        footerInfo={footerInfo}
        isFieldEdited={isFieldEdited}
        recordText={"Bi Info ID"}
        saveCurrentData={saveCurrentData}
        onDeleteClick={onDeleteClick}>
        <div
          style={{
            width: "100%",
          }}>
          <Form
            render={(formRenderProps) => (
              <GridLayout
                rows={[
                  {
                    height: 70,
                  },
                  {
                    height: 70,
                  },
                  {
                    height: 70,
                  },
                  {
                    height: 170,
                  },
                  {
                    height: 30,
                  },
                ]}
                cols={[
                  {
                    width: 375,
                  },
                  {
                    width: 300,
                  },
                ]}
                gap={{
                  rows: 25,
                  cols: 35,
                }}
                style={{ padding: "25px 16px", gap: "20px 35px" }}>
                <GridLayoutItem col={1} row={1} className="form-inner">
                  <label className="form-heading">Title :</label>
                  <Input
                    type="text"
                    id="title"
                    name="title"
                    value={company?.title}
                    className="field-ip"
                    onChange={(e) => onInputChange(e)}
                  />
                </GridLayoutItem>
                <GridLayoutItem col={2} row={1} className="form-inner">
                  <label className="form-heading">Color :</label>
                  <div style={{ display: "flex", gap: "2px" }}>
                    <Input
                      type="color"
                      id="primary_color"
                      name="color"
                      value={convertToHex(company?.color)}
                      className="field-ip"
                      style={{ padding: "0px" }}
                      onChange={(e) => onInputChange(e)}
                    />
                  </div>
                </GridLayoutItem>
                <GridLayoutItem col={3} row={1} className="form-inner">
                  <label className="form-heading">Send Notification :</label>
                  <div>
                    <Switch
                      size={"small"}
                      checked={company.sendNotification}
                      onChange={(e) => {
                        setIsFiledEdited(true);
                        setCompany((preValue) => ({
                          ...preValue,
                          sendNotification: e?.value,
                        }));
                      }}
                    />
                  </div>
                </GridLayoutItem>
                <GridLayoutItem col={1} row={2} className="form-inner">
                  <label className="form-heading">Description :</label>
                  <div style={{ display: "flex", gap: "2px" }}>
                    <TextArea
                      type="text"
                      id="description"
                      name="description"
                      value={company?.description}
                      className="field-ip"
                      onChange={(e) => onInputChange(e)}
                      rows={5}
                    />
                  </div>
                </GridLayoutItem>

                {!isAddForm && (
                  <GridLayoutItem col={2} row={2} className="form-inner">
                    <RecordStatusInput
                      recordStatusId={company?.recordStatusId}
                      recordStatus={company?.recordStatus}
                      onChange={({ recordStatus, recordStatusId }) => {
                        setIsFiledEdited(true);
                        setCompany((preValue) => ({
                          ...preValue,
                          recordStatus,
                          recordStatusId,
                        }));
                      }}
                    />
                  </GridLayoutItem>
                )}
              </GridLayout>
            )}
          />
        </div>
      </AddPageLayout>
    </div>
  );
};

export default AddNewBiInfoChannel;
