import React, { useRef } from 'react'
import { Outlet, useNavigate, useParams } from 'react-router-dom';

const AddProductLayout = () => {

    const { dataId } = useParams();
    const listRef = useRef(null);
    const navigate = useNavigate();

    const pathname = window.location.pathname;
    const pathArray = pathname.split("/");

    let menuList = [
        { title: "Edit product", url: "edit-product" },
        { title: "Product Images", url: "product-images" },
    ];

    return (
        <>
            <section className="add-user" style={{ height: "100%" }}>
                <section className="table-list userauth-submenu">
                    <div className="menu-list-2 usersub-menu">
                        {Number(dataId) !== 0 ? (
                            <>
                                <ul className="second-list" ref={listRef}>
                                    {menuList?.map((item, index) => (
                                        <li
                                            className="menu"
                                            key={index}
                                            onClick={() => {
                                                navigate(item?.url);
                                            }}>
                                            <div
                                                style={{ cursor: "pointer" }}
                                                className={`k-d-flex k-align-items-center k-flex-row menu ${item?.url === pathArray?.[4] ? "active" : ""
                                                    }`}>
                                                <span className="item-text">{item?.title}</span>
                                            </div>
                                        </li>
                                    ))}
                                </ul>
                            </>
                        ) : (
                            <></>
                        )}
                    </div>
                </section>
                <div style={{ height: Number(dataId) === 0 ? "100%" : "product-images" === pathArray?.[4] ? '93%' : "95%" }}>
                    <Outlet />
                </div>
            </section>
        </>
    )
}

export default AddProductLayout; 