import React, { useEffect, useState } from "react";
import { AutoComplete, DropDownList } from "@progress/kendo-react-dropdowns";
import { useParams } from "react-router-dom";
import {
  FETCH_CHAT_GROUPS_USERS,
  FETCH_RECORD_STATUSES,
  FETCH_CHAT_GROUPS,
  FetchUsers2,
  SAVE_CHAT_GROUPS_USERS,
  DELETE_CHAT_GROUPS_USERS,
} from "../../constant/Apipath";
import { PostRequestCall } from "../../apicall/PostRequest";
import { useSelector } from "react-redux";
import { GridLayout, GridLayoutItem } from "@progress/kendo-react-layout";
import { Label } from "@progress/kendo-react-labels";
import KendoButton from "../../common/KendoButton";
import { toast } from "react-toastify";
import { Loader } from "@progress/kendo-react-indicators";
import { Checkbox } from "@progress/kendo-react-inputs";

function AddChatGroupUser() {
  const { dataId, userId } = useParams();

  const { loginData } = useSelector((state) => state.main);
  const [chatGroupOptions, setChaGroupOptions] = useState([]);
  const [openToList, setToUserOpen] = useState(false);
  const isAddForm = dataId === "add-chat-group-user";
  const [selectChatGroup, setGroupId] = useState(null);
  const [userOptions, setUserOptions] = useState([]);
  const [selectToUser, setToUser] = useState(null);
  const [showLoader, setShowLoader] = useState(false);
  const [chatGroupOpen, setChatGroupOpen] = useState(false);
  const [recordStatusOptions, setRecordStatusOptions] = useState([]);
  const [isMuted, setIsMuted] = useState(true);

  const [chatGroupUsers, setChatGroupUsers] = useState({
    Id: 0,
    name: "",
    recordStatusId: 1,
    recordStatus: "",
    userId: 0,
    chatGroupId: 0,
    muted: true,
  });

  const onChatGroupChange = (event) => {
    setGroupId(event?.target?.value);
  };

  const onToUserChange = (event) => {
    setToUser(event?.target?.value);
  };

  const projectSelect = (groupID) => {
    setGroupId(groupID);
    setChatGroupOpen(false);
  };

  const toUserSelect = (user) => {
    setToUser(user);
    setToUserOpen(false);
  };

  const onTOuserRender = (li, itemProps) => {
    const company = itemProps.dataItem;
    return (
      <div className="user-item" onClick={() => toUserSelect(company)}>
        <span>{company?.label}</span>
      </div>
    );
  };

  useEffect(() => {
    if (dataId !== "add-chat-group-user") {
      getRecordStatuses();
    }
  }, []);
  useEffect(() => {
    if (dataId != "add-chat-group-user") {
      getInitialResponse();
    }
  }, []);
  useEffect(() => {
    getRecordStatuses();
    if (
      selectChatGroup !== null &&
      typeof selectChatGroup?.value === "undefined"
    ) {
      if (selectChatGroup !== "") {
        getChatGroup(false);
      }
    }
  }, [selectChatGroup]);
  useEffect(() => {
    if (selectToUser !== null && typeof selectToUser?.value === "undefined") {
      if (selectToUser !== "") {
        getToUserList(false);
      }
    }
  }, [selectToUser]);

  const getInitialResponse = async () => {
    if (dataId != "add-chat-group-user") {
      var obj = JSON.stringify({
        PageNr: 1,
        NrOfRecPerPage: 20,
        FullSearch: "",
        UserId: loginData.userId,
        SearchList: [
          {
            Id: dataId,
          },
        ],
      });
      const result = await PostRequestCall(
        FETCH_CHAT_GROUPS_USERS,
        obj,
        loginData?.token
      );
      let data = result?.data?.data?.[0] ?? null;

      setChatGroupUsers((prevData) => ({
        ...prevData,
        chatGroupId: data?.chatGroupId,
        Id: data?.id,
        name: data?.userFirstName + " " + data?.userLastName,
        muted: data?.muted,
        recordStatus: data?.recordStatus,
        recordStatusId: data?.recordStatusId,
      }));
      setIsMuted(data?.muted);
      setGroupId({
        label: String(data?.chatGroupId),
        value: String(data?.chatGroupId),
      });
      setToUser({
        label: data?.userFirstName + " " + data?.userLastName,
        value: data.userId,
      });
    }
  };

  const getRecordStatuses = async () => {
    try {
      let recordStatusesObj = {
        PageNr: 1,
        NrOfRecPerPage: 100,
        FullSearch: "",
        UserId: "1",
        SortList: [
          {
            FieldName: "Id",
            Direction: "ASC",
          },
        ],
        IncludeRecordNr: true,
      };
      const recordStatusesRes = await PostRequestCall(
        FETCH_RECORD_STATUSES,
        recordStatusesObj,
        loginData?.token
      );
      let recordStatusesData = recordStatusesRes?.data?.data?.map((record) => ({
        id: record?.id,
        text: record?.name,
      }));
      setRecordStatusOptions(recordStatusesData ?? []);
    } catch (error) {
      console.log("Error while getting record statuses data :: ", error);
    }
  };

  const onCancelClick = () => {
    window.history.back();
  };
  const getToUserList = async (onFocus) => {
    try {
      let companyObj = {
        NrOfRecPerPage: onFocus ? 20 : 0,
        FullSearch: "",
        UserId: loginData.userId,
        SearchList: onFocus ? [] : [{ name: selectToUser }],
        SortList: [{ FieldName: "Id", Direction: "ASC" }],
        IncludeRecordNr: true,
      };
      const companyRes = await PostRequestCall(
        FetchUsers2,
        companyObj,
        loginData?.token
      );
      let UserData = companyRes?.data?.data?.map((record) => ({
        value: record?.id,
        label: record?.name,
      }));
      setUserOptions(UserData ?? []);
      setToUserOpen(true);
    } catch (error) {
      console.log("Error while getting company list :: ", error);
    }
  };

  const onDeleteClick = async (e) => {
    try {
      e.stopPropagation();
      if (window.confirm("Are you sure you wish to delete this record?")) {
        setShowLoader(true);
        const deleteObj = {
          ReturnRecordError: true,
          ReturnRecordId: false,
          UserId: loginData.userId,
          DeleteList: [
            {
              Id: dataId,
              ModifiedBy: loginData.userId,
            },
          ],
        };
        return PostRequestCall(
          DELETE_CHAT_GROUPS_USERS,
          deleteObj,
          loginData?.token
        )
          .then((res) => {
            setShowLoader(false);
            if (res?.data?.status) {
              toast.success("Data deleted successfully", {
                position: "top-right",
              });
              window.history.back();
            } else {
              toast.error(
                res?.data?.message ??
                  "Something went wrong. Please try again later.",
                {
                  position: "top-right",
                }
              );
            }
          })
          .catch((error) => {
            setShowLoader(false);
            toast.error("Something went wrong. Please try again later.", {
              position: "top-right",
            });
            console.log("Error while deleting a record :: ", error);
          });
      }
    } catch (error) {
      setShowLoader(false);
      toast.error("Something went wrong. Please try again later.", {
        position: "top-right",
      });
      console.log("Error while deleting a record :: ", error);
    }
  };
  const onSubmit = async (e) => {
    e.preventDefault();
    const firstOptionValue =
      chatGroupOptions.length > 0 ? chatGroupOptions[0].value : null;

    if (chatGroupUsers?.chatGroupId === 0) {
      if (!firstOptionValue) {
        return toast.error("Please select chat Users", {
          position: "top-right",
        });
      }
    }
    if (selectChatGroup === null || selectChatGroup == "") {
      return toast.error("Please select chat Group", {
        position: "top-right",
      });
    }

    if (selectToUser === null || selectToUser == "") {
      return toast.error("Please select User", {
        position: "top-right",
      });
    }
    setShowLoader(true);

    const obj = {
      ReturnRecordId: true,
      ReturnRecordError: true,
      UserId: loginData?.userId,
      SaveList: [
        {
          Id: dataId === "add-chat-group-user" ? 0 : dataId,
          ChatGroupId: Number(selectChatGroup?.value),
          ModifiedBy: loginData?.userId,
          Muted: isMuted,
          RecordStatusId: chatGroupUsers?.recordStatusId || 1,
          UserId: selectToUser.value,
        },
      ],
    };
    try {
      const result = await PostRequestCall(
        SAVE_CHAT_GROUPS_USERS,
        obj,
        loginData?.token
      );

      if (result.data.status === false) {
        setShowLoader(false);
        toast.error(
          result?.data?.message ??
            "Something went wrong. Please try again later.",
          {
            position: "top-right",
          }
        );
      } else {
        setShowLoader(false);
        toast.success(
          `Data ${
            dataId !== "add-chat-group-user" ? "updated" : "added"
          } successfully`,
          {
            position: "top-right",
          }
        );
        window.history.back();
      }
    } catch (error) {
      setShowLoader(false);
      toast.error("Something went wrong. Please try again later.", {
        position: "top-right",
      });
      console.error("Error saving data :", error);
    }
  };

  const onChatGroupRender = (li, itemProps) => {
    const project = itemProps.dataItem;
    return (
      <div className="user-item" onClick={() => projectSelect(project)}>
        <span>{project?.label}</span>
      </div>
    );
  };

  const handleChange = (event) => {
    setIsMuted(!isMuted);
  };

  if (showLoader) {
    return (
      <Loader
        type="converging-spinner"
        className="kendo-spinner"
        style={{
          display: "flex",
          justifyContent: "center",
          minHeight: "500px",
          alignItems: "center",
        }}
      />
    );
  }
  const getChatGroup = async (onFocus) => {
    try {
      let obj = {
        NrOfRecPerPage: onFocus ? 20 : 20,
        FullSearch: "",
        SortList: [{ FieldName: "Id", Direction: "ASC" }],
        SearchList: onFocus ? [] : [{ id: selectChatGroup }],
        IncludeRecordNr: true,
        TypeOfObjectReturned: "",
        FetchAllowedRecordsOnly: false,
      };
      const fetchSiteProjectListRes = await PostRequestCall(
        FETCH_CHAT_GROUPS,
        obj,
        loginData?.token
      );
      if (fetchSiteProjectListRes?.data?.data?.length > 0) {
        const getList = fetchSiteProjectListRes?.data?.data.map((project) => ({
          value: String(project?.id),
          label: String(project?.id),
        }));
        setChaGroupOptions(getList);
        setChatGroupOpen(true);
      }
    } catch (error) {
      console.log("error", error);
    }
  };

  return (
    <section className="add-user">
      <section className="user-information">
        <div className="k-d-flex k-justify-content-between">
          <div className="k-d-flex k-align-items-center">
            {!isAddForm && (
              <span class="record-id-text">Chat ID : {dataId}</span>
            )}
          </div>
          <div
            className="k-d-flex k-justify-content-end k-p-2"
            style={{ gap: "10px" }}>
            <KendoButton
              iconClass=""
              size={16}
              className=""
              themeColor={"primary"}
              buttonName="Save"
              onClick={onSubmit}
            />
            {!isAddForm && (
              <KendoButton
                size={16}
                className=""
                onClick={onDeleteClick}
                buttonName="Delete"
                themeColor={"secondary"}
              />
            )}
            <KendoButton
              iconClass=""
              size={16}
              className=""
              buttonName="Cancel"
              onClick={onCancelClick}
            />
          </div>
        </div>
        <div
          style={{
            borderBottom: "1px solid #f5f5f5",
            width: "100%",
          }}></div>
        <GridLayout
          rows={[
            {
              height: 70,
            },
            {
              height: 70,
            },
            {
              height: 70,
            },
            {
              height: 70,
            },
          ]}
          cols={[
            {
              width: 375,
            },
            {
              width: 375,
            },
          ]}
          gap={{
            rows: 25,
            cols: 35,
          }}
          style={{ padding: "25px 16px", gap: "20px 35px" }}>
          <GridLayoutItem col={1} row={1} className="form-inner">
            <label className="form-heading">Chat Group :</label>
            <AutoComplete
              data={chatGroupOptions}
              onFocus={() => getChatGroup(true)}
              onClose={() => setChatGroupOpen(false)}
              onBlur={() => setChatGroupOpen(false)}
              opened={chatGroupOpen}
              value={selectChatGroup ? selectChatGroup?.label : ""}
              itemRender={onChatGroupRender}
              className="field-ip"
              onChange={onChatGroupChange}
              textField="label"
              placeholder="Select project..."
            />
          </GridLayoutItem>

          <GridLayoutItem col={2} row={1} className="form-inner">
            <label className="form-heading">Users :</label>
            <AutoComplete
              data={userOptions}
              onFocus={() => getToUserList(true)}
              onClose={() => setToUserOpen(false)}
              onBlur={() => setToUserOpen(false)}
              opened={openToList}
              value={selectToUser ? selectToUser?.label : ""}
              className="field-ip"
              itemRender={onTOuserRender}
              onChange={onToUserChange}
              textField="label"
              disabled={dataId !== "add-chat-group-user"}
              placeholder="Select Chat User..."
            />
          </GridLayoutItem>

          <GridLayoutItem col={1} row={2} className="form-inner">
            <Checkbox
              checked={isMuted}
              label={"Muted"}
              onChange={handleChange}
            />
          </GridLayoutItem>

          {dataId !== "add-chat-group-user" ? (
            <GridLayoutItem col={3} row={1} className="form-inner">
              <div className="form-group">
                <Label className="form-heading">Record Status :</Label>
                <div>
                  <DropDownList
                    style={{ width: "370px" }}
                    onChange={(e) => {
                      setChatGroupUsers({
                        ...chatGroupUsers,
                        recordStatusId: e.value.id,
                        recordStatus: e.value.text,
                      });
                    }}
                    className="dropdown-list"
                    value={{
                      id: chatGroupUsers?.recordStatusId,
                      text: chatGroupUsers?.recordStatus,
                    }}
                    data={recordStatusOptions}
                    textField="text"
                    dataItemKey="id"
                  />
                </div>
              </div>
            </GridLayoutItem>
          ) : (
            <></>
          )}
          <GridLayoutItem col={1} row={4} className="form-inner">
            <div className="k-d-flex " style={{ gap: "10px" }}>
              <KendoButton
                iconClass=""
                size={16}
                onClick={onSubmit}
                className="action-btn"
                buttonName="Save"
              />
              {dataId !== "add-chat-group-user" && (
                <KendoButton
                  size={16}
                  onClick={onDeleteClick}
                  buttonName="Delete"
                  themeColor={"secondary"}
                />
              )}
              <KendoButton
                iconClass=""
                themeColor=""
                size={16}
                className=""
                buttonName="Cancel"
                onClick={onCancelClick}
              />
            </div>
          </GridLayoutItem>
        </GridLayout>
      </section>
    </section>
  );
}
export default AddChatGroupUser;
