import React, { useEffect, useState } from "react";
import { AutoComplete, DropDownList } from "@progress/kendo-react-dropdowns";
import { useParams } from "react-router-dom";

import { Input, Switch } from "@progress/kendo-react-inputs";
import { useSelector } from "react-redux";
import { GridLayout, GridLayoutItem } from "@progress/kendo-react-layout";
import { Label } from "@progress/kendo-react-labels";
import { toast } from "react-toastify";
import { Loader } from "@progress/kendo-react-indicators";
import {
  DELETE_ENTITY_ATTRIBUTES,
  FETCH_ATTRIBUTES,
  FETCH_ENTITIES,
  FETCH_ENTITY_ATTRIBUTES,
  FETCH_RECORD_STATUSES,
  SAVE_ENTITY_ATTRIBUTES,
} from "../constant/Apipath";
import { PostRequestCall } from "../apicall/PostRequest";
import KendoButton from "./../common/KendoButton";
import Modal from "../common/Modal";
import Footerinformation from "../common/Footerinformation";

const AddEntityAttributes = () => {
  const { id } = useParams();
  const { loginData } = useSelector((state) => state.main);

  const [data, setData] = useState({
    id: undefined,
    entityId: "",
    entityName: "",
    attributeId: "",
    attributeName: "",
    verifiedByDeveloper: false,
    recordStatusId: 1,
    recordStatus: "New",
    createdBy: "",
    dateCreated: "",
    modifiedBy: "",
    dateModified: "",
    createdByUser: "",
    modifiedByUser: "",
  });
  const [showLoader, setShowLoader] = useState(false);
  const [entityOptions, setEntityOptions] = useState([]);
  const [attributeOptions, setAttributeOptions] = useState([]);
  const [recordStatusOptions, setRecordStatusOptions] = useState([]);

  const [modalState, setModalState] = useState({
    for: "",
    show: false,
    onActionBtnTitle: "",
  });
  const [isFieldEdited, setIsFiledEdited] = useState(false);

  useEffect(() => {
    if (id) {
      getUserDetails();
    }
    getRecordStatuses();

    loadoptoins();
  }, []);

  const loadoptoins = async () => {
    try {
      let fetchEntityobj = {
        PageNr: 1,
        NrOfRecPerPage: 100,
        FullSearch: "",
        UserId: "1",
        SortList: [
          {
            FieldName: "Id",
            Direction: "ASC",
          },
        ],
        IncludeRecordNr: true,
      };
      const entitiesOptions = await PostRequestCall(
        FETCH_ENTITIES,
        fetchEntityobj,
        loginData?.token
      );
      let entitiesData = entitiesOptions?.data?.data?.map((record) => ({
        id: record?.id,
        text: record?.name,
      }));
      setEntityOptions(entitiesData ?? []);
    } catch (error) {
      console.log("Error while getting record statuses data :: ", error);
    }
    try {
      let fetchattributes = {
        PageNr: 1,
        NrOfRecPerPage: 100,
        FullSearch: "",
        UserId: "1",
        SortList: [
          {
            FieldName: "Id",
            Direction: "ASC",
          },
        ],
        IncludeRecordNr: true,
      };
      const attributres = await PostRequestCall(
        FETCH_ATTRIBUTES,
        fetchattributes,
        loginData?.token
      );

      const attributesData = attributres?.data?.data?.map((record) => ({
        id: record?.id,
        text: record?.name,
      }));
      setAttributeOptions(attributesData ?? []);
    } catch (error) {
      console.log("Error while getting record statuses data :: ", error);
    } finally {
      setShowLoader(false);
    }
  };

  const getRecordStatuses = async () => {
    try {
      let recordStatusesObj = {
        PageNr: 1,
        NrOfRecPerPage: 100,
        FullSearch: "",
        UserId: "1",
        SortList: [
          {
            FieldName: "Id",
            Direction: "ASC",
          },
        ],
        IncludeRecordNr: true,
      };
      const recordStatusesRes = await PostRequestCall(
        FETCH_RECORD_STATUSES,
        recordStatusesObj,
        loginData?.token
      );
      let recordStatusesData = recordStatusesRes?.data?.data?.map((record) => ({
        id: record?.id,
        text: record?.name,
      }));
      setRecordStatusOptions(recordStatusesData ?? []);
    } catch (error) {
      console.log("Error while getting record statuses data :: ", error);
    } finally {
      setShowLoader(false);
    }
  };

  const getUserDetails = async () => {
    setShowLoader(true);
    try {
      const data = JSON.stringify({
        PageNr: 1,
        NrOfRecPerPage: 100,
        FullSearch: "",
        UserId: loginData.userId,
        SearchList: [{ id: id }],
        IncludeRecordNr: true,
      });

      const result = await PostRequestCall(
        FETCH_ENTITY_ATTRIBUTES,
        data,
        loginData?.token
      );
      let items = result.data.data[0] || [];
      setData({
        ...data,
        id: items?.id ?? undefined,
        entityId: items?.entityId,
        entityName: items?.entityName,
        attributeId: items?.attributeId,
        attributeName: items?.attributeName,
        verifiedByDeveloper: items?.verifiedByDeveloper,
        recordStatusId: items?.recordStatusId,
        recordStatus: items?.recordStatus,
        createdBy: items?.createdBy,
        dateCreated: items?.dateCreated,
        modifiedBy: items?.modifiedBy,
        dateModified: items?.dateModified,
        createdByUser: items?.createdByUser,
        modifiedByUser: items?.modifiedByUser,
      });

      console.log("items", items);
    } catch (error) {
      console.log("Error while getting user details :: ", error);
    } finally {
      setShowLoader(false);
    }
  };

  const onCancelClick = () => {
    if (isFieldEdited) {
      return setModalState((preValue) => ({
        ...preValue,
        for: "edit",
        show: true,
        onActionBtnTitle: "Save",
      }));
    } else {
      return window.history.back();
    }
  };

  const onActionBtnClick = () => {
    setModalState((preValue) => ({
      ...preValue,
      for: "",
      show: false,
      onActionBtnTitle: "",
    }));
    if (modalState?.for === "delete") {
      onDeleteClick();
    } else if (modalState?.for === "edit") {
      onSubmit();
    } else {
      return null;
    }
  };

  const showDeleteConfirmation = () => {
    setModalState((preValue) => ({
      ...preValue,
      for: "delete",
      show: true,
      onActionBtnTitle: "Delete",
    }));
  };

  const onModalClose = () => {
    window.history.back();
  };

  const onSubmit = async () => {
    setShowLoader(true);

    const obj = {
      UserId: loginData.userId,
      ReturnRecordId: true,
      ReturnRecordError: true,
      SaveList: [
        {
          Id: id === "add-entity-attributes" ? 0 : id,
          EntityId: data?.entityId,
          AttributeId: data?.attributeId,
          VerifiedByDeveloper: data?.verifiedByDeveloper,
          ModifiedBy: loginData?.userId,
        },
      ],
    };

    try {
      const result = await PostRequestCall(
        SAVE_ENTITY_ATTRIBUTES,
        obj,
        loginData?.token
      );

      if (result.data.status === true) {
        setShowLoader(false);
        toast.success(
          `Data ${
            id !== "add-entity-attributes" ? "updated" : "added"
          } successfully`,
          {
            position: "top-right",
          }
        );
        window.history.back();
      } else {
        toast.error(result?.data?.message ?? "Error while saving the data", {
          position: "top-right",
        });
        setShowLoader(false);
      }
    } catch (error) {
      setShowLoader(false);
      toast.error("Something went wrong. Please try again later.", {
        position: "top-right",
      });
      console.error("Error saving data :", error);
    }
  };

  const onDeleteClick = async () => {
    try {
      setShowLoader(true);
      const deleteObj = {
        ReturnRecordError: true,
        ReturnRecordId: false,
        UserId: loginData.userId,
        DeleteList: [
          {
            Id: id,
            ModifiedBy: loginData.userId,
          },
        ],
      };
      return PostRequestCall(
        DELETE_ENTITY_ATTRIBUTES,
        deleteObj,
        loginData?.token
      )
        .then((res) => {
          setShowLoader(false);
          if (res?.data?.status) {
            toast.success("Data deleted successfully", {
              position: "top-right",
            });
            window.history.back();
          } else {
            toast.error(
              res?.data?.message ??
                "Something went wrong. Please try again later.",
              {
                position: "top-right",
              }
            );
          }
        })
        .catch((error) => {
          setShowLoader(false);
          toast.error("Something went wrong. Please try again later.", {
            position: "top-right",
          });
          console.log("Error while deleting a record :: ", error);
        });
    } catch (error) {
      setShowLoader(false);
      toast.error("Something went wrong. Please try again later.", {
        position: "top-right",
      });
      console.log("Error while deleting a record :: ", error);
    }
  };

  if (showLoader) {
    return (
      <Loader
        type="converging-spinner"
        className="kendo-spinner"
        style={{
          display: "flex",
          justifyContent: "center",
          minHeight: "500px",
          alignItems: "center",
        }}
      />
    );
  }

  return (
    <section className="add-user">
      <section className="user-information">
        <div className="k-d-flex k-justify-content-between">
          <div className="k-d-flex k-align-items-center">
            {id !== "add-entity-attributes" && (
              <span class="record-id-text">Entity Attribute Id : {id}</span>
            )}
          </div>
          <div
            className="k-d-flex k-justify-content-end k-p-2"
            style={{ gap: "10px" }}>
            <KendoButton
              iconClass=""
              size={16}
              onClick={onSubmit}
              className=""
              themeColor={"primary"}
              buttonName="Save"
            />
            {id !== "add-entity-attributes" && (
              <KendoButton
                size={16}
                className=""
                themeColor={"secondary"}
                onClick={showDeleteConfirmation}
                buttonName="Delete"
              />
            )}
            <KendoButton
              iconClass=""
              size={16}
              className=""
              buttonName="Cancel"
              onClick={onCancelClick}
            />
          </div>
        </div>
        <div style={{ borderBottom: "1px solid #f5f5f5", width: "100%" }}></div>
        <GridLayout
          rows={[
            {
              height: 70,
            },
            {
              height: 70,
            },
            {
              height: 270,
            },
            {
              height: 30,
            },
          ]}
          cols={[
            {
              width: 375,
            },
            {
              width: 375,
            },
            {
              width: 375,
            },
          ]}
          gap={{
            rows: 25,
            cols: 35,
          }}
          style={{ padding: "25px 16px", gap: "0px 35px" }}>
          <GridLayoutItem col={1} row={1} className="form-inner">
            <div className="form-group">
              <Label className="form-heading">Entity Name :</Label>
              <DropDownList
                style={{ width: "370px" }}
                data={entityOptions}
                value={{
                  id: data?.entityId,
                  text: data?.entityName,
                }}
                onChange={(e) => {
                  setIsFiledEdited(true);
                  setData({
                    ...data,
                    entityId: e.target.value.id,
                    entityName: e.target.value.text,
                  });
                }}
                className="dropdown-list"
                textField="text"
                dataItemKey="text"
              />
            </div>
          </GridLayoutItem>
          <GridLayoutItem col={2} row={1} className="form-inner">
            <div className="form-group">
              <Label className="form-heading"> Attributes Name :</Label>
              <DropDownList
                style={{ width: "370px" }}
                data={attributeOptions}
                className="dropdown-list"
                value={{
                  id: data?.attributeId,
                  text: data?.attributeName,
                }}
                textField="text"
                dataItemKey="text"
                onChange={(e) => {
                  setIsFiledEdited(true);
                  setData({
                    ...data,
                    attributeId: e.target.value.id,
                    attributeName: e.target.value.text,
                  });
                }}
              />
            </div>
          </GridLayoutItem>

          <GridLayoutItem col={3} row={1} className="form-inner">
            <div className="form-group">
              <Label className="form-heading">Verified By Developer :</Label>
              <div>
                <Switch
                  size={"small"}
                  checked={data.verifiedByDeveloper}
                  onChange={(e) => {
                    setIsFiledEdited(true);
                    setData((preValue) => ({
                      ...preValue,
                      verifiedByDeveloper: e?.value,
                    }));
                  }}
                />
              </div>
            </div>
          </GridLayoutItem>

          <GridLayoutItem
            col={1}
            row={id !== "add-entity-attributes" ? 3 : 2}
            className="form-inner">
            <div className="k-d-flex" style={{ gap: "10px" }}>
              <KendoButton
                iconClass=""
                size={16}
                onClick={onSubmit}
                className=""
                themeColor={"primary"}
                buttonName="Save"
              />
              {id !== "add-entity-attributes" && (
                <KendoButton
                  size={16}
                  className=""
                  onClick={showDeleteConfirmation}
                  themeColor={"secondary"}
                  buttonName="Delete"
                />
              )}
              <KendoButton
                iconClass=""
                size={16}
                className=""
                buttonName="Cancel"
                onClick={onCancelClick}
              />
            </div>
          </GridLayoutItem>

          {id !== "add-entity-attributes" && (
            <GridLayoutItem colSpan={4} row={4} className="form-footer">
              <Footerinformation footerInfo={data} />
            </GridLayoutItem>
          )}
        </GridLayout>
      </section>
      {modalState?.show && (
        <Modal
          for={modalState?.for}
          show={modalState?.show}
          onClose={onModalClose}
          onActionBtnClick={onActionBtnClick}
          onActionBtnTitle={modalState?.onActionBtnTitle}
        />
      )}
    </section>
  );
};

export default AddEntityAttributes;
