import React, { useEffect, useState } from "react";
import { AutoComplete, DropDownList } from "@progress/kendo-react-dropdowns";
import { useParams } from "react-router-dom";
import {
  DELETE_USERS_USAGES,
  FETCH_RECORD_STATUSES,
  FETCH_USER_USAGES,
  FetchUsers2,
  SAVE_USERS_USAGES,
} from "../../constant/Apipath";
import { PostRequestCall } from "../../apicall/PostRequest";
import { Input } from "@progress/kendo-react-inputs";
import { useSelector } from "react-redux";
import { GridLayout, GridLayoutItem } from "@progress/kendo-react-layout";
import { Label } from "@progress/kendo-react-labels";
import KendoButton from "../../common/KendoButton";
import { toast } from 'react-toastify';
import { Loader } from "@progress/kendo-react-indicators";


const AddNewUsages = () => {
  const { dataId, subMenu, userId } = useParams();
  const { loginData } = useSelector((state) => state.main);


  const [userUsages, setUserUsage] = useState({
    userName: "",
    userId: 0,
    recordStatusId: 1,
    recordStatus: "New",
    moduleName: "",
  });

  const [value, setValue] = useState("");
  const [options, setOptions] = useState([]);
  const [showLoader, setShowLoader] = useState(false);
  const [recordStatusOptions, setRecordStatusOptions] = useState([]);


  useEffect(() => {
    if (dataId !== "add-user-usage") {
      loadUserUsages();
    }
    if (userId && dataId === "add-user-usage") {
      getUserDetails();
    }
    getRecordStatuses();
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      try {
        var srchList = [];
        srchList.push({ Name: value });
        const data = JSON.stringify({
          PageNr: 1,
          NrOfRecPerPage: 100,
          FullSearch: value,
          UserId: loginData.userId,
          SearchList: srchList,
          IncludeRecordNr: true,
        });

        const result = await PostRequestCall(
          FetchUsers2,
          data,
          loginData?.token
        );
        const users = result?.data?.data ?? [];

        const userOptions = users.map((user) => ({
          value: user.id,
          label: `${user?.firstName} ${user?.lastName}`,
        }));

        setOptions(userOptions);
      } catch (error) { }
    };

    if (value.trim() !== "") {
      fetchData();
    } else {
      setOptions([]);
    }
  }, [value]);


  const loadUserUsages = async () => {
    if (dataId !== "add-user-usage") {
      var obj = JSON.stringify({
        PageNr: 1,
        NrOfRecPerPage: 20,
        FullSearch: "",
        SearchList: [
          {
            Id: dataId,
          },
        ],
        SortList: [
          {
            FieldName: "Id",
            Direction: "ASC",
          },
        ],
        IncludeRecordNr: true,
      });

      const result = await PostRequestCall(
        FETCH_USER_USAGES,
        obj,
        loginData?.token
      );

      let data = result?.data?.data?.[0] ?? null;
      setUserUsage((prevData) => (
        {
          ...prevData,
          userName: data?.userName,
          userId: data?.userId,
          moduleName: data?.moduleName,
          recordStatus: data?.recordStatus,
          recordStatusId: data?.recordStatusId
        }
      ))
    }
  };

  const getRecordStatuses = async () => {
    try {
      let recordStatusesObj = {
        PageNr: 1,
        NrOfRecPerPage: 100,
        FullSearch: "",
        UserId: "1",
        SortList: [
          {
            FieldName: "Id",
            Direction: "ASC",
          },
        ],
        IncludeRecordNr: true,
      };
      const recordStatusesRes = await PostRequestCall(
        FETCH_RECORD_STATUSES,
        recordStatusesObj,
        loginData?.token
      );
      let recordStatusesData = recordStatusesRes?.data?.data?.map((record) => ({
        id: record?.id,
        text: record?.name,
      }));
      setRecordStatusOptions(recordStatusesData ?? []);
    } catch (error) {
      console.log("Error while getting record statuses data :: ", error);
    }
  };

  const getUserDetails = async () => {
    try {
      const data = JSON.stringify({
        PageNr: 1,
        NrOfRecPerPage: 100,
        FullSearch: "",
        UserId: loginData.userId,
        SearchList: [{ id: userId }],
        IncludeRecordNr: true,
      });

      const result = await PostRequestCall(
        FetchUsers2,
        data,
        loginData?.token
      );
      setUserUsage((prevData) => ({ ...prevData, userName: result?.data?.data?.[0]?.name, userId }));
    } catch (error) {
      console.log("Error while getting user details :: ", error);
    }
  }


  const onInputChange = (e) => {
    setUserUsage({ ...userUsages, [e.target.name]: e.target.value });
  };

  const handleChange = (event) => {
    setValue(event?.target?.value);
  };
  const onCancelClick = () => {
    window.history.back();
  };

  const onSubmit = async (e) => {
    e.preventDefault();

    const firstOptionValue = options.length > 0 ? options[0].value : null;
    if (userUsages?.userId === 0) {
      if (!firstOptionValue) {
        return toast.error("Please select a user", {
          position: "top-right",
        });
      }
    }

    if (userUsages?.moduleName === "") {
      return toast.error("Please a enter a vaild module name", {
        position: "top-right",
      });
    }

    setShowLoader(true);
    const obj = {
      ReturnRecordId: false,
      ReturnRecordError: true,
      UserId: loginData?.userId,
      SaveList: [
        {
          Id: dataId === "add-user-usage" ? 0 : dataId,
          ModuleName: userUsages?.moduleName,
          RecordStatusId: userUsages?.recordStatusId || 1,
          UserId: userUsages.userId || firstOptionValue,
        },
      ],
    };

    try {
      const result = await PostRequestCall(
        SAVE_USERS_USAGES,
        obj,
        loginData?.token
      );

      if (result.data.status === false) {
        setShowLoader(false);
        toast.error(result?.data?.message ?? "Something went wrong. Please try again later.", {
          position: "top-right",
        });
      } else {
        setShowLoader(false);
        toast.success(`Data ${dataId !== "add-user-usage" ? "updated" : "added"} successfully`, {
          position: "top-right",
        });
        window.history.back();
      }
    } catch (error) {
      setShowLoader(false);
      toast.error("Something went wrong. Please try again later.", {
        position: "top-right",
      });
      console.error("Error saving data :", error);
    }
  };


  const onDeleteClick = async (e) => {
    try {
      e.stopPropagation();
      if (window.confirm("Are you sure you wish to delete this record?")) {
        setShowLoader(true);
        const deleteObj = {
          ReturnRecordError: true,
          ReturnRecordId: false,
          UserId: loginData.userId,
          DeleteList: [
            {
              Id: dataId,
              ModifiedBy: loginData.userId,
            },
          ],
        };
        return PostRequestCall(DELETE_USERS_USAGES, deleteObj, loginData?.token).then((res) => {
          setShowLoader(false);
          if (res?.data?.status) {
            toast.success("Data deleted successfully", {
              position: "top-right",
            });
            window.history.back();
          } else {
            toast.error(res?.data?.message ?? "Something went wrong. Please try again later.", {
              position: "top-right",
            });
          }
        }).catch((error) => {
          setShowLoader(false);
          toast.error("Something went wrong. Please try again later.", {
            position: "top-right",
          });
          console.log("Error while deleting a record :: ", error);
        })
      }
    } catch (error) {
      setShowLoader(false);
      toast.error("Something went wrong. Please try again later.", {
        position: "top-right",
      });
      console.log("Error while deleting a record :: ", error);
    }
  }

  if (showLoader) {
    return (
      <Loader
        type="converging-spinner"
        className="kendo-spinner"
        style={{
          display: "flex",
          justifyContent: "center",
          minHeight: "500px",
          alignItems: "center",
        }}
      />
    );
  }


  return (
    <section className="add-user">
      <section className="user-information">
        <GridLayout
          rows={[
            {
              height: 70,
            },
            {
              height: 70,
            },
            {
              height: 70,
            },
            {
              height: 70,
            },
            {
              height: 70,
            },
            {
              height: 70,
            },
            {
              height: 70,
            },
            {
              // height: 70,
            },
          ]}
          cols={[
            {
              width: 375,
            },
            {
              width: 375,
            },
            {
              width: 375,
            },
          ]}
          gap={{
            rows: 25,
            cols: 35,
          }}
          style={{ padding: "25px 16px" }}>
          <GridLayoutItem col={1} row={1} className="form-inner">
            <Label className="form-heading">User :</Label>
            <div className="form-control">
              {dataId !== "add-user-usage" || subMenu ? (
                <AutoComplete
                  data={options}
                  className="field-ip"
                  value={userUsages?.userName}
                  textField="label"
                  placeholder="Search for users..."
                  disabled
                />
              ) : (
                <AutoComplete
                  data={options}
                  value={value}
                  className="field-ip"
                  onChange={handleChange}
                  textField="label"
                  placeholder="Search for users..."
                />
              )}
            </div>
          </GridLayoutItem>
          <GridLayoutItem col={2} row={1} className="form-inner">
            <Label className="form-heading">Module Name :</Label>
            <div className="form-control">
              <Input
                type="text"
                id="moduleName"
                name="moduleName"
                value={userUsages?.moduleName}
                className="field-ip"
                onChange={(e) => onInputChange(e)}
              />
            </div>
          </GridLayoutItem>

          {dataId !== "add-user-usage" || subMenu ? (
            <GridLayoutItem col={3} row={1} className="form-inner">
              <div className="form-group">
                <Label className="form-heading">Record Status :</Label>
                <div>
                  <DropDownList
                    style={{ width: "370px" }}
                    onChange={(e) => {
                      setUserUsage((preValue) => ({
                        ...preValue,
                        recordStatus: e.value?.text,
                        recordStatusId: e.value?.id,
                      }));
                    }}
                    className="dropdown-list"
                    value={{
                      id: userUsages?.recordStatusId,
                      text: userUsages?.recordStatus,
                    }}
                    data={recordStatusOptions}
                    textField="text"
                    dataItemKey="id"
                  />
                </div>
              </div>
            </GridLayoutItem>
          ) : (
            <></>
          )}
          <GridLayoutItem col={3} row={3} className="form-inner">
            <div
              className="k-d-flex k-justify-content-end"
              style={{ gap: "10px" }}>
              <KendoButton
                iconClass=""
                size={16}
                onClick={onSubmit}
                className="action-btn"
                buttonName="Save"
              />
              {
                dataId !== "add-user-usage" && (
                  <KendoButton
                    size={16}
                    className="action-btn delete-btn"
                    onClick={onDeleteClick}
                    buttonName="Delete"
                  />
                )
              }
              <KendoButton
                iconClass=""
                themeColor=""
                size={16}
                className=""
                buttonName="Cancel"
                onClick={onCancelClick}
              />
            </div>
          </GridLayoutItem>
        </GridLayout>
      </section>
    </section>
  );
};

export default AddNewUsages;
