import React, { useState } from "react";
import "./AddPageLayout.css";
import KendoButton from "../common/KendoButton";
import Footerinformation from "../common/Footerinformation";
import Modal from "../common/Modal";

const AddPageLayout = (props) => {
  const [modalState, setModalState] = useState({
    for: "",
    show: false,
    onActionBtnTitle: "",
  });

  const onCancelClick = () => {
    if (props?.isFieldEdited) {
      return setModalState((preValue) => ({
        ...preValue,
        for: "edit",
        show: true,
        onActionBtnTitle: "Save",
      }));
    } else {
      return window.history.back();
    }
  };

  const onActionBtnClick = () => {
    setModalState((preValue) => ({
      ...preValue,
      for: "",
      show: false,
      onActionBtnTitle: "",
    }));
    if (modalState?.for === "delete") {
      props?.onDeleteClick();
    } else if (modalState?.for === "edit") {
      props?.saveCurrentData();
    } else {
      return null;
    }
  };

  const showDeleteConfirmation = () => {
    setModalState((preValue) => ({
      ...preValue,
      for: "delete",
      show: true,
      onActionBtnTitle: "Delete",
    }));
  };

  const onModalClose = () => {
    window.history.back();
  };

  return (
    <>
      <div className="add_page_main_container">
        <div className="add_page_container">
          <div className="add_page_header_container">
            <div className="k-d-flex k-justify-content-between">
              <div className="k-d-flex k-align-items-center">
                {!props?.addForm && (
                  <span class="record-id-text">
                    {props?.recordText ?? ""}: {props?.dataId ?? 0}
                  </span>
                )}
              </div>
              <div
                className="k-d-flex k-justify-content-end k-p-2"
                style={{ gap: "10px" }}>
                <KendoButton
                  iconClass=""
                  size={16}
                  className="action-btn"
                  buttonName="Save"
                  onClick={props?.saveCurrentData}
                />
                {!props?.addForm && !props?.hideDeleteBtn && (
                  <KendoButton
                    size={16}
                    className=""
                    onClick={showDeleteConfirmation}
                    buttonName="Delete"
                    themeColor={"secondary"}
                  />
                )}
                <KendoButton
                  iconClass=""
                  themeColor=""
                  size={16}
                  className=""
                  buttonName="Cancel"
                  onClick={onCancelClick}
                />
              </div>
            </div>
          </div>
          <div
            className={
              props?.showFooter
                ? "add_page_form_container_with_footer_info"
                : "add_page_form_container"
            }>
            <div className="apfc_container_inputs">{props?.children}</div>
          </div>
          <div
            className={
              props?.showFooter
                ? "add_page_footer_container_with_footer_info"
                : "add_page_footer_container"
            }>
            <div
              className="k-d-flex k-justify-content-start k-p-2 apfc_btns"
              style={{ gap: "10px" }}>
              <KendoButton
                iconClass=""
                size={16}
                className="action-btn"
                buttonName="Save"
                onClick={props?.saveCurrentData}
              />
              {!props?.addForm && !props?.hideDeleteBtn && (
                <KendoButton
                  size={16}
                  className=""
                  onClick={showDeleteConfirmation}
                  buttonName="Delete"
                  themeColor={"secondary"}
                />
              )}
              <KendoButton
                iconClass=""
                themeColor=""
                size={16}
                className=""
                buttonName="Cancel"
                onClick={onCancelClick}
              />
            </div>
            {props?.showFooter && (
              <div className="apfc_footer">
                <Footerinformation footerInfo={props?.footerInfo} />
              </div>
            )}
          </div>
        </div>
      </div>
      {modalState?.show && (
        <Modal
          for={modalState?.for}
          show={modalState?.show}
          onClose={onModalClose}
          onActionBtnClick={onActionBtnClick}
          onActionBtnTitle={modalState?.onActionBtnTitle}
        />
      )}
    </>
  );
};

export default AddPageLayout;
