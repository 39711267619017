import React from "react";
import { useParams } from "react-router-dom";
import SharedTickets from "../sharedtickets/SharedTickets";

const TicketDetailsLayout = () => {
  const { ticketSubMenu } = useParams();

  const renderTableListBasedOnUrl = () => {
    let ui = <></>;
    switch (ticketSubMenu) {
      case "ticket-shared-with":
        ui = (
          <>
            {" "}
            <SharedTickets />{" "}
          </>
        );
        break;

      default:
        ui = <></>;
        break;
    }

    return ui;
  };
  return <>{renderTableListBasedOnUrl()}</>;
};

export default TicketDetailsLayout;
