import React from "react";
import { useParams } from "react-router-dom";
import UserSitesProjects from "../../users/user-sites-projects/UserSitesProjects";

const SiteDetailsLayout = () => {
  const { siteSubMenu } = useParams();

  const renderTableListBasedOnUrl = () => {
    let ui = <></>;
    switch (siteSubMenu) {
      case "user-projects":
        ui = (
          <>
            {" "}
            <UserSitesProjects />{" "}
          </>
        );
        break;

      default:
        ui = <></>;
        break;
    }

    return ui;
  };
  return <>{renderTableListBasedOnUrl()}</>;
};

export default SiteDetailsLayout;
