export const actionTypes = {
  SET_LOGIN_DATA: "SET_LOGIN_DATA",
  SET_FILTER_DATA: "SET_FILTER_DATA",
  CLEAR_FILTER_DATA: "CLEAR_FILTER_DATA",
};

export const setLogindata = (payload) => {
  return {
    type: actionTypes.SET_LOGIN_DATA,
    payload: payload,
  };
};

export const setFiterData = (payload) => {
  return {
    type: actionTypes.SET_FILTER_DATA,
    payload: payload,
  };
};

export const clearFiterData = (payload) => {
  return {
    type: actionTypes.CLEAR_FILTER_DATA,
    payload: payload,
  };
};
