import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import {
  DELETE_POSTS_COMMENTS,
  DELETE_USER_TRADES,
  FETCH_POSTS_COMMENTS,
  FETCH_USER_SITES_PROJECTS,
  FETCH_USERS_TRADES,
  SAVE_POSTS_COMMENTS,
  SAVE_USERS_SITES_PROJECTS,
  SAVE_USERS_TRADES,
} from "../../constant/Apipath";
import { PostRequestCall } from "../../apicall/PostRequest";
import { Input, Switch, TextArea } from "@progress/kendo-react-inputs";
import { useSelector } from "react-redux";
import { Form } from "@progress/kendo-react-form";
import { GridLayout, GridLayoutItem } from "@progress/kendo-react-layout";
import { toast } from "react-toastify";
import { Loader } from "@progress/kendo-react-indicators";
import AddPageLayout from "../../addpagelayout/AddPageLayout";
import UserSearchInput from "../../common/UserSearchInput";
import RecordStatusInput from "../../common/RecordStatusInput";
import { DropDownList } from "@progress/kendo-react-dropdowns";
import TradeSearchInput from "../../common/TradeSearchInput";
import { Label } from "@progress/kendo-react-labels";
import SiteSearchInput from "../../common/SiteSearchInput";
import SiteTypeInput from "../../common/SiteTypeInput";
import KendoButton from "../../common/KendoButton";

const AddNewUserSiteProjects = () => {
  const { dataId, siteId, subMenu, userId } = useParams();
  const { loginData } = useSelector((state) => state.main);
  const isAddForm = dataId === "add-site-projects";

  const [project, setProject] = useState({
    projectName: "",
    userId: userId ?? 0,
    userName: "",
    siteId: siteId ?? 0,
    siteName: "",
    projectTypeId: 0,
    projectType: "",
    active: false,
    description: "",
    projectWebsite: "",
    recordStatusId: 1,
    recordStatus: "New",
  });

  const [showLoader, setShowLoader] = useState(false);
  const [isFieldEdited, setIsFiledEdited] = useState(false);
  const [footerInfo, setFooterInfo] = useState({});

  useEffect(() => {
    if (!isAddForm) {
      getProjectDetails();
    }
  }, []);

  const getProjectDetails = async () => {
    try {
      let obj = { SearchList: [{ Id: dataId }] };
      const res = await PostRequestCall(
        FETCH_USER_SITES_PROJECTS,
        obj,
        loginData?.token
      );

      let resData = res?.data?.data?.[0] ?? {};
      setProject((preValue) => ({
        ...preValue,
        projectName: resData?.projectName ?? "",
        userId: resData?.userId ?? 0,
        userName: resData?.userName ?? "",
        siteId: resData?.siteId ?? 0,
        siteName: resData?.siteName ?? "",
        siteId: resData?.siteId ?? 0,
        projectTypeId: resData?.projectType ?? "",
        projectType: resData?.projectTypeName ?? "",
        active: resData?.isActive ?? false,
        description: resData?.description ?? "",
        projectWebsite: resData?.projectWebsite ?? "",
        recordStatus: resData?.recordStatus ?? "New",
        recordStatusId: resData?.recordStatusId ?? 1,
      }));
      let footerObj = {
        dateCreated: resData?.dateCreated ?? null,
        modifiedByUser: resData?.modifiedByUser ?? "",
        dateModified: resData?.dateModified ?? "",
        createdByUser: resData?.createdByUser ?? "",
      };
      setFooterInfo(footerObj);
    } catch (error) {
      console.log("Error while getting post comment details :: ", error);
    }
  };


  const onInputChange = (e) => {
    setProject({
      ...project,
      [e.target.name]: e.target.value,
    });
  };

  function navigateToWebsite() {
    const websiteUrl = project?.projectWebsite;
    if (websiteUrl) {
      window.open(`https://` + websiteUrl, "_blank");
    }
  }

  const saveCurrentData = async () => {

    if (project?.projectName?.trim() === "") {
      toast.error("Please enter a vaild project name", {
        position: "top-right",
      });
      return;
    }

    if (project?.userId === 0) {
      toast.error("Please select a vaild user", {
        position: "top-right",
      });
      return;
    }

    if (project?.siteId === 0) {
      toast.error("Please select a vaild site", {
        position: "top-right",
      });
      return;
    }

    try {
      setShowLoader(true);
      const obj = {
        UserId: loginData?.userId,
        ReturnRecordId: true,
        ReturnRecordError: true,
        SaveList: [
          {
            Id: isAddForm ? 0 : dataId,
            UserId: project?.userId ?? 0,
            SiteId: project?.siteId,
            ProjectName: project?.projectName,
            RecordStatusId: project?.recordStatusId ?? 1,
            Description: project?.description,
            ProjectType: project?.projectTypeId,
            ProjectWebsite: project?.projectWebsite,
            IsActive: project?.active,
            ModifiedBy: loginData.userId,
          },
        ],
      };

      const result = await PostRequestCall(
        SAVE_USERS_SITES_PROJECTS,
        obj,
        loginData?.token
      );

      if (result.data.status === false) {
        setShowLoader(false);
        toast.error(
          result?.data?.data?.[0]?.message ??
          result?.data?.message ??
          "Something went wrong. Please try again later.",
          {
            position: "top-right",
          }
        );
      } else {
        setShowLoader(false);
        toast.success(
          `Data ${!isAddForm ? "updated" : "added"
          } successfully`,
          {
            position: "top-right",
          }
        );
        window.history.back();
      }
    } catch (error) {
      setShowLoader(false);
      toast.error(
        error?.data?.data?.[0]?.message ??
        error?.data?.message ??
        "Something went wrong. Please try again later.",
        {
          position: "top-right",
        }
      );
      console.log("Error while saving trade data :: ", error);
    }
  };

  const onDeleteClick = async () => {
    // try {
    //   setShowLoader(true);
    //   const deleteObj = [
    //     {
    //       Id: Number(dataId),
    //       ModifiedBy: loginData?.userId,
    //     },
    //   ];
    //   const obj = {
    //     ReturnRecordError: true,
    //     ReturnRecordId: false,
    //     UserId: loginData?.userId,
    //     DeleteList: deleteObj,
    //   };
    //   return PostRequestCall(DELETE_USER_TRADES, obj, loginData?.token)
    //     .then((res) => {
    //       setShowLoader(false);
    //       if (res?.status === 201) {
    //         toast.success("Data deleted successfully", {
    //           position: "top-right",
    //         });
    //         window.history.back();
    //       } else {
    //         toast.error(
    //           res?.data?.message ??
    //           "Something went wrong. Please try again later.",
    //           {
    //             position: "top-right",
    //           }
    //         );
    //       }
    //     })
    //     .catch((error) => {
    //       setShowLoader(false);
    //       toast.error("Something went wrong. Please try again later.", {
    //         position: "top-right",
    //       });
    //       console.log("Error while deleting a record :: ", error);
    //     });
    // } catch (error) {
    //   setShowLoader(false);
    //   toast.error("Something went wrong. Please try again later.", {
    //     position: "top-right",
    //   });
    //   console.log("Error while deleting a record :: ", error);
    // }
  };

  if (showLoader) {
    return (
      <Loader
        type="converging-spinner"
        className="kendo-spinner"
        style={{
          display: "flex",
          justifyContent: "center",
          minHeight: "500px",
          alignItems: "center",
        }}
      />
    );
  }

  return (
    <>
      <div className="add_form_height_95">
        <AddPageLayout
          dataId={dataId}
          addForm={isAddForm}
          showFooter={!isAddForm}
          footerInfo={footerInfo}
          isFieldEdited={isFieldEdited}
          recordText={"User Project ID"}
          saveCurrentData={saveCurrentData}
          onDeleteClick={onDeleteClick}>
          <div style={{ height: "100%" }}>
            <Form
              render={() => (
                <GridLayout
                  rows={[
                    {
                      height: 70,
                    },
                  ]}
                  cols={[
                    {
                      width: 375,
                    },
                    {
                      width: 375,
                    },
                    {
                      width: 375,
                    },
                  ]}
                  gap={{
                    rows: 30,
                    cols: 35,
                  }}
                  style={{ padding: "16px" }}>
                  <GridLayoutItem col={1} row={1} className="form-inner">
                    <Label className="form-heading">Project Name :</Label>
                    <Input
                      type="text"
                      id="projectName"
                      name="projectName"
                      value={project?.projectName}
                      className="field-ip"
                      onChange={(e) => onInputChange(e)}
                    />
                  </GridLayoutItem>

                  <GridLayoutItem col={2} row={1} className="form-inner">
                    <UserSearchInput
                      value={project?.userName}
                      setSelectedUser={({ label, id }) => {
                        setIsFiledEdited(true);
                        setProject((preValue) => ({
                          ...preValue,
                          userName: label,
                          userId: id,
                        }));
                      }}
                      disabled={userId}
                      userId={userId}
                    />
                  </GridLayoutItem>

                  <GridLayoutItem col={3} row={1} className="form-inner">
                    <SiteSearchInput
                      value={project?.siteName}
                      setSelectedSite={({ label, id }) => {
                        setIsFiledEdited(true);
                        setProject((preValue) => ({
                          ...preValue,
                          siteName: label,
                          siteId: id,
                        }));
                      }}
                      disabled={siteId}
                      siteId={siteId}
                    />
                  </GridLayoutItem>

                  <GridLayoutItem col={1} row={2} className="form-inner">
                    <SiteTypeInput
                      label="Project Type"
                      placeholder="Search for project type..."
                      value={project?.projectType}
                      setSelectedSiteType={({ label, id }) => {
                        setIsFiledEdited(true);
                        setProject((preValue) => ({
                          ...preValue,
                          projectType: label,
                          projectTypeId: id,
                        }));
                      }}
                    />
                  </GridLayoutItem>

                  <GridLayoutItem col={2} row={2} className="form-inner">
                    <Label className="form-heading">Project Website:</Label>
                    <div style={{ display: "flex", gap: "2px" }}>
                      <Input
                        type="url"
                        id="projectWebsite"
                        name="projectWebsite"
                        value={project?.projectWebsite}
                        className="field-ip"
                        onChange={(e) => onInputChange(e)}
                      />
                      <KendoButton
                        onClick={navigateToWebsite}
                        className="navigate-button"
                        buttonName="-->"
                        disabled={
                          project?.projectWebsite === "" ? true : false
                        }
                      />
                    </div>
                  </GridLayoutItem>

                  <GridLayoutItem col={3} row={2} className="form-inner">
                    <Label className="form-heading">Description:</Label>
                    <TextArea
                      type="text"
                      id="description"
                      name="description"
                      value={project?.description}
                      className="field-ip"
                      onChange={onInputChange}
                      rows={4}
                    />
                  </GridLayoutItem>

                  <GridLayoutItem col={1} row={3} className="form-inner">
                    <label className="form-heading">Active:</label>
                    <div>
                      <Switch
                        size={"small"}
                        checked={project?.active}
                        onChange={(e) => {
                          setIsFiledEdited(true);
                          setProject((preValue) => ({
                            ...preValue,
                            active: e?.value,
                          }));
                        }}
                      />
                    </div>
                  </GridLayoutItem>

                  {!isAddForm || subMenu && (
                    <GridLayoutItem col={2} row={3} className="form-inner">
                      <RecordStatusInput
                        recordStatusId={project?.recordStatusId}
                        recordStatus={project?.recordStatus}
                        onChange={({ recordStatus, recordStatusId }) => {
                          setIsFiledEdited(true);
                          setProject((preValue) => ({
                            ...preValue,
                            recordStatus,
                            recordStatusId,
                          }));
                        }}
                      />
                    </GridLayoutItem>
                  )}
                </GridLayout>
              )}
            />
          </div>
        </AddPageLayout>
      </div>
    </>
  );
};

export default AddNewUserSiteProjects;
