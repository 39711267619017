import React, { useEffect, useState } from "react";
import { DropDownList } from "@progress/kendo-react-dropdowns";
import { useLocation, useParams } from "react-router-dom";
import {
  DELETE_QUIZZES,
  FECTH_ENTITY_ATTRIBUTES,
  FETCH_QUIZZES,
  FETCH_RECORD_STATUSES,
  SAVE_QUIZZES,
} from "../../constant/Apipath";
import { PostRequestCall } from "../../apicall/PostRequest";
import { Input, Switch, TextArea } from "@progress/kendo-react-inputs";
import { useSelector } from "react-redux";
import { Form } from "@progress/kendo-react-form";
import { GridLayout, GridLayoutItem } from "@progress/kendo-react-layout";
import { Error, Label } from "@progress/kendo-react-labels";
import KendoButton from "../../common/KendoButton";
import { toast } from "react-toastify";
import { Loader } from "@progress/kendo-react-indicators";
import Modal from "../../common/Modal";
import Footerinformation from "../../common/Footerinformation";

const AddQuizzes = () => {
  const { id } = useParams();
  const location = useLocation();

  const { loginData } = useSelector((state) => state.main);
  const [showLoader, setShowLoader] = useState(false);
  const [quizzes, setQuizzes] = useState({
    quizType: "",
    quizDescription: "",
    quiz_EA1: 0,
    quiz_EA1_UsersRecordsOnly: false,
    quiz_EA2: 0,
    quiz_EA2_UsersRecordsOnly: false,
    response_EA: 0,
    canAddNewRecordsToResponse: "",
    minResponses: "",
    maxResponses: "",
    options: "",
    active: false,
    recordStatusId: 1,
    recordStatus: "New",
    quiz_EntityName1: "",
    quiz_EntityName2: "",
    response_EntityName: "",
    createdByUser: "",
    modifiedByUser: "",
    dateCreated: "",
    dateModified: "",
  });

  const [recordStatusOptions, setRecordStatusOptions] = useState([]);
  const [entityAttributeOtpions, setEntityAttributeOtpions] = useState([]);
  const yesNoOptions = [
    {
      value: true,
      text: "Yes",
    },
    {
      value: false,
      text: "No",
    },
  ];

  const [modalState, setModalState] = useState({
    for: "",
    show: false,
    onActionBtnTitle: "",
  });
  const [isFieldEdited, setIsFiledEdited] = useState(false);

  useEffect(() => {
    if (id !== "add-quiz") {
      getQuizzesData();
    }
    getRecordStatuses();
    getEntityAttributes();
  }, []);

  const getEntityAttributes = async () => {
    try {
      let obj = {
        PageNr: 1,
        NrOfRecPerPage: 100,
        FullSearch: "",
        UserId: "1",
        SortList: [
          {
            FieldName: "Id",
            Direction: "ASC",
          },
        ],
        IncludeRecordNr: true,
      };
      const res = await PostRequestCall(
        FECTH_ENTITY_ATTRIBUTES,
        obj,
        loginData?.token
      );
      let data = res?.data?.data?.map((record) => ({
        id: record?.id,
        text: record?.entityName + " " + record?.attributeName,
      }));
      setEntityAttributeOtpions(data ?? []);
    } catch (error) {
      console.log("Error while getting record statuses data :: ", error);
    }
  };

  const getRecordStatuses = async () => {
    try {
      let recordStatusesObj = {
        PageNr: 1,
        NrOfRecPerPage: 100,
        FullSearch: "",
        UserId: "1",
        SortList: [
          {
            FieldName: "Id",
            Direction: "ASC",
          },
        ],
        IncludeRecordNr: true,
      };
      const recordStatusesRes = await PostRequestCall(
        FETCH_RECORD_STATUSES,
        recordStatusesObj,
        loginData?.token
      );
      let recordStatusesData = recordStatusesRes?.data?.data?.map((record) => ({
        id: record?.id,
        text: record?.name,
      }));
      setRecordStatusOptions(recordStatusesData ?? []);
    } catch (error) {
      console.log("Error while getting record statuses data :: ", error);
    }
  };

  const getQuizzesData = async () => {
    let obj = { SearchList: [{ Id: id }] };
    const fetchuserAuthRes = await PostRequestCall(
      FETCH_QUIZZES,
      obj,
      loginData?.token
    );

    let quizzesData = fetchuserAuthRes?.data?.data?.[0];
    setQuizzes({
      ...quizzes,
      quizType: quizzesData?.quizType,
      quizDescription: quizzesData?.quizDescription,
      active: quizzesData?.active,
      quiz_EA1: quizzesData?.quiz_EA1,
      quiz_EA1_UsersRecordsOnly: quizzesData?.quiz_EA1_UsersRecordsOnly,
      quiz_EA2: quizzesData?.quiz_EA2,
      quiz_EA2_UsersRecordsOnly: quizzesData?.quiz_EA2_UsersRecordsOnly,
      response_EA: quizzesData?.response_EA,
      canAddNewRecordsToResponse: quizzesData?.canAddNewRecordsToResponse,
      minResponses: quizzesData?.minResponses,
      maxResponses: quizzesData?.maxResponses,
      options: quizzesData?.options,
      recordStatusId: quizzesData?.recordStatusId,
      recordStatus: quizzesData?.recordStatus,
      createdBy: quizzesData?.createdBy,
      quiz_EntityName1:
        quizzesData?.quiz_EntityName1 + " " + quizzesData?.quiz_AttributeName1,
      quiz_EntityName2:
        quizzesData?.quiz_EntityName2 + " " + quizzesData?.quiz_AttributeName2,
      response_EntityName:
        quizzesData?.response_EntityName +
        " " +
        quizzesData?.response_AttributeName,
      createdByUser: quizzesData?.createdByUser,
      modifiedByUser: quizzesData?.modifiedByUser,
      dateCreated: quizzesData?.dateCreated,
      dateModified: quizzesData?.dateModified,
    });
  };

  const onInputChange = (e) => {
    setIsFiledEdited(true);
    setQuizzes({
      ...quizzes,
      [e.target.name]: e.target.value,
    });
  };

  const saveCurrentdata = async () => {
    if (!quizzes.quizType) {
      toast.error("QuizType is required", {
        position: "top-right",
      });
      return;
    }

    if (quizzes.quiz_EA1_UsersRecordsOnly === undefined) {
      toast.error("Quiz_EA1_UsersRecordsOnly is required", {
        position: "top-right",
      });
      return;
    }

    if (quizzes.quiz_EA2_UsersRecordsOnly === undefined) {
      toast.error("Quiz_EA2_UsersRecordsOnly is required", {
        position: "top-right",
      });
      return;
    }

    if (!quizzes.response_EA) {
      toast.error("Response_EA is required", {
        position: "top-right",
      });
      return;
    }

    if (quizzes.canAddNewRecordsToResponse === undefined) {
      toast.error("CanAddNewRecordsToResponse is required", {
        position: "top-right",
      });
      return;
    }
    const newQuestion = {
      ReturnRecordId: false,
      ReturnRecordError: true,
      UserId: loginData?.userId,
      SaveList: [
        {
          Id: id === "add-quiz" ? 0 : id,
          Active: quizzes?.active || false,
          QuizType: quizzes.quizType,
          QuizDescription: quizzes.quizDescription,
          Quiz_EA1: quizzes.quiz_EA1,
          Quiz_EA2: quizzes.quiz_EA2,
          Quiz_EA1_UsersRecordsOnly: quizzes?.quiz_EA1_UsersRecordsOnly,
          Quiz_EA2_UsersRecordsOnly: quizzes?.quiz_EA2_UsersRecordsOnly,
          Response_EA: quizzes.response_EA,
          CanAddNewRecordsToResponse: quizzes?.canAddNewRecordsToResponse,
          MinResponses: quizzes.minResponses,
          MaxResponses: quizzes.maxResponses,
          Options: quizzes.options || "",
          createdBy: quizzes?.createdBy,
          ModifiedBy: loginData?.userId,
          RecordStatusId: quizzes?.recordStatusId || 1,
        },
      ],
    };
    let getResponse = await PostRequestCall(
      SAVE_QUIZZES,
      newQuestion,
      loginData?.token
    );
    console.log("===>getResponse", getResponse);
    if (getResponse.data.status === 400) {
      setShowLoader(false);
      toast.error(
        getResponse?.data?.title ??
          "Something went wrong. Please try again later.",
        {
          position: "top-right",
        }
      );
    } else {
      setShowLoader(false);
      toast.success(
        `Data ${id !== "add-quiz" ? "updated" : "added"} successfully`,
        {
          position: "top-right",
        }
      );
      window.history.back();
    }
  };

  const onCancelClick = () => {
    if (isFieldEdited) {
      return setModalState((preValue) => ({
        ...preValue,
        for: "edit",
        show: true,
        onActionBtnTitle: "Save",
      }));
    } else {
      return window.history.back();
    }
  };

  const onActionBtnClick = () => {
    setModalState((preValue) => ({
      ...preValue,
      for: "",
      show: false,
      onActionBtnTitle: "",
    }));
    if (modalState?.for === "delete") {
      onDeleteClick();
    } else if (modalState?.for === "edit") {
      saveCurrentdata();
    } else {
      return null;
    }
  };

  const showDeleteConfirmation = () => {
    setModalState((preValue) => ({
      ...preValue,
      for: "delete",
      show: true,
      onActionBtnTitle: "Delete",
    }));
  };

  const onModalClose = () => {
    window.history.back();
  };

  const onDeleteClick = async (e) => {
    try {
      setShowLoader(true);

      const deleteObj = {
        ReturnRecordError: true,
        ReturnRecordId: false,
        UserId: loginData?.userId,
        DeleteList: [
          {
            Id: Number(id),
          },
        ],
      };

      return PostRequestCall(DELETE_QUIZZES, deleteObj, loginData?.token)
        .then((res) => {
          setShowLoader(false);
          if (res?.status === 201) {
            toast.success(`Data delete successfully`, {
              position: "top-right",
            });

            window.history.back();
          } else {
            toast.error(
              res?.data?.message ??
                "Something went wrong. Please try again later.",
              {
                position: "top-right",
              }
            );
          }
        })
        .catch((error) => {
          setShowLoader(false);
          toast.error("Something went wrong. Please try again later.", {
            position: "top-right",
          });
          console.log("Error while deleting a record :: ", error);
        });
    } catch (error) {
      setShowLoader(false);
      toast.error("Something went wrong. Please try again later.", {
        position: "top-right",
      });
      console.log("Error while deleting a record :: ", error);
    }
  };

  if (showLoader) {
    return (
      <Loader
        type="converging-spinner"
        className="kendo-spinner"
        style={{
          display: "flex",
          justifyContent: "center",
          minHeight: "500px",
          alignItems: "center",
        }}
      />
    );
  }
  return (
    <>
      <section className="add-user">
        <>
          <section className="user-information">
            <div className="k-d-flex k-justify-content-between">
              <div className="k-d-flex k-align-items-center">
                {id !== "add-quiz" && (
                  <span class="record-id-text">Quiz Id : {id}</span>
                )}
              </div>
              <div
                className="k-d-flex k-justify-content-end k-p-2"
                style={{ gap: "10px" }}>
                <KendoButton
                  iconClass=""
                  size={16}
                  className=""
                  themeColor={"primary"}
                  buttonName="Save"
                  onClick={saveCurrentdata}
                />
                {id !== "add-quiz" && (
                  <KendoButton
                    size={16}
                    className=""
                    onClick={showDeleteConfirmation}
                    buttonName="Delete"
                    themeColor={"secondary"}
                  />
                )}
                <KendoButton
                  iconClass=""
                  size={16}
                  className=""
                  buttonName="Cancel"
                  onClick={onCancelClick}
                />
              </div>
            </div>
            <div
              style={{
                borderBottom: "1px solid #f5f5f5",
                width: "100%",
              }}></div>
            <Form
              render={(formRenderProps) => (
                <GridLayout
                  rows={[
                    {
                      height: 70,
                    },
                    {
                      height: 70,
                    },
                    {
                      height: 70,
                    },
                    {
                      height: 70,
                    },
                    {
                      height: 70,
                    },
                    {
                      height: 50,
                    },
                    {
                      height: 30,
                    },
                  ]}
                  cols={[
                    {
                      width: 375,
                    },
                    {
                      width: 375,
                    },
                    {
                      width: 375,
                    },
                  ]}
                  gap={{
                    rows: 25,
                    cols: 35,
                  }}
                  style={{ padding: "25px 16px", gap: "20px 35px" }}>
                  <GridLayoutItem col={1} row={1} className="form-inner">
                    <Label className="form-heading"> Quiz Type :</Label>
                    <Input
                      name="quizType"
                      value={quizzes?.quizType}
                      className="field-ip"
                      onChange={(e) => onInputChange(e)}
                    />
                    <Error></Error>
                  </GridLayoutItem>
                  <GridLayoutItem col={2} row={1} className="form-inner">
                    <div>
                      <Label className="form-heading">
                        {" "}
                        Quiz Entity Name :
                      </Label>
                    </div>
                    <div>
                      <DropDownList
                        onChange={(e) => {
                          setIsFiledEdited(true);
                          setQuizzes((preValue) => ({
                            ...preValue,
                            quiz_EA1: e.value?.id,
                            quiz_EntityName1: e.value?.text,
                          }));
                        }}
                        className="dropdown-list"
                        value={{
                          id: quizzes?.quiz_EA1,
                          text: quizzes?.quiz_EntityName1,
                        }}
                        data={entityAttributeOtpions}
                        textField="text"
                        dataItemKey="id"
                      />
                    </div>
                    <Error></Error>
                  </GridLayoutItem>
                  <GridLayoutItem col={3} row={1} className="form-inner">
                    <div>
                      <Label className="form-heading">
                        {" "}
                        Quiz EA1 Users Records Only :
                      </Label>
                    </div>
                    <div>
                      <Switch
                        size={"small"}
                        checked={quizzes.quiz_EA1_UsersRecordsOnly}
                        onChange={(e) => {
                          setIsFiledEdited(true);
                          setQuizzes((preValue) => ({
                            ...preValue,
                            quiz_EA1_UsersRecordsOnly: e?.value,
                          }));
                        }}
                      />
                    </div>
                    <Error></Error>
                  </GridLayoutItem>

                  <GridLayoutItem col={1} row={2} className="form-inner">
                    <div>
                      <Label className="form-heading">
                        {" "}
                        Quiz Entity Name 2 :
                      </Label>
                    </div>
                    <div>
                      <DropDownList
                        onChange={(e) => {
                          setIsFiledEdited(true);
                          setQuizzes((preValue) => ({
                            ...preValue,
                            quiz_EA2: e.value?.id,
                            quiz_EntityName2: e.value?.text,
                          }));
                        }}
                        className="dropdown-list"
                        value={{
                          id: quizzes?.quiz_EA2,
                          text: quizzes?.quiz_EntityName2,
                        }}
                        data={entityAttributeOtpions}
                        textField="text"
                        dataItemKey="id"
                      />
                    </div>
                    <Error></Error>
                  </GridLayoutItem>
                  <GridLayoutItem col={2} row={2} className="form-inner">
                    <div>
                      <Label className="form-heading">
                        {" "}
                        Quiz EA2 Users Records Only :
                      </Label>
                    </div>
                    <div>
                      <Switch
                        size={"small"}
                        checked={quizzes.quiz_EA2_UsersRecordsOnly}
                        onChange={(e) => {
                          setIsFiledEdited(true);
                          setQuizzes((preValue) => ({
                            ...preValue,
                            quiz_EA2_UsersRecordsOnly: e?.value,
                          }));
                        }}
                      />
                    </div>
                    <Error></Error>
                  </GridLayoutItem>
                  <GridLayoutItem col={3} row={2} className="form-inner">
                    <div>
                      <Label className="form-heading">
                        Response Entity Name :
                      </Label>
                    </div>
                    <DropDownList
                      onChange={(e) => {
                        setIsFiledEdited(true);
                        setQuizzes((preValue) => ({
                          ...preValue,
                          response_EA: e.value?.id,
                          response_EntityName: e.value?.text,
                        }));
                      }}
                      className="dropdown-list"
                      value={{
                        id: quizzes?.response_EA,
                        text: quizzes?.response_EntityName,
                      }}
                      data={entityAttributeOtpions}
                      textField="text"
                      dataItemKey="id"
                    />
                    <Error></Error>
                  </GridLayoutItem>

                  <GridLayoutItem col={1} row={3} className="form-inner">
                    <Label className="form-heading">
                      {" "}
                      Can Add New Records to Response :
                    </Label>
                    <div>
                      <Switch
                        size={"small"}
                        checked={quizzes.canAddNewRecordsToResponse}
                        onChange={(e) => {
                          setIsFiledEdited(true);
                          setQuizzes((preValue) => ({
                            ...preValue,
                            canAddNewRecordsToResponse: e?.value,
                          }));
                        }}
                      />
                    </div>
                    <Error></Error>
                  </GridLayoutItem>
                  <GridLayoutItem col={2} row={3} className="form-inner">
                    <Label className="form-heading"> Min Responses :</Label>
                    <Input
                      name="minResponses"
                      value={quizzes?.minResponses}
                      className="field-ip"
                      onChange={(e) => onInputChange(e)}
                      type="number"
                      min="0"
                    />
                    <Error></Error>
                  </GridLayoutItem>
                  <GridLayoutItem col={3} row={3} className="form-inner">
                    <Label className="form-heading"> Max Responses :</Label>
                    <Input
                      name="maxResponses"
                      value={quizzes?.maxResponses}
                      className="field-ip"
                      onChange={(e) => onInputChange(e)}
                      type="number"
                      min="0"
                    />

                    <Error></Error>
                  </GridLayoutItem>
                  <GridLayoutItem col={1} row={4} className="form-inner">
                    <div>
                      <label className="form-heading">Active :</label>
                    </div>
                    <div>
                      <Switch
                        size={"small"}
                        checked={quizzes.active}
                        onChange={(e) => {
                          setIsFiledEdited(true);
                          setQuizzes((preValue) => ({
                            ...preValue,
                            active: e?.value,
                          }));
                        }}
                      />
                    </div>
                  </GridLayoutItem>
                  <GridLayoutItem col={2} row={4} className="form-inner">
                    <div>
                      <Label className="form-heading">Options :</Label>
                    </div>
                    <TextArea
                      type="text"
                      id="options"
                      name="options"
                      value={quizzes?.options}
                      className="field-ip"
                      onChange={(e) => onInputChange(e)}
                      rows={2}
                    />

                    <Error></Error>
                  </GridLayoutItem>

                  <GridLayoutItem col={3} row={4} className="form-inner">
                    <Label className="form-heading">Description :</Label>
                    <TextArea
                      type="text"
                      id="description"
                      name="quizDescription"
                      value={quizzes?.quizDescription}
                      className="field-ip"
                      onChange={(e) => onInputChange(e)}
                      rows={2}
                    />
                    <Error></Error>
                  </GridLayoutItem>
                  {id !== "add-quiz" && (
                    <GridLayoutItem
                      col={1}
                      row={5}
                      className="form-inner"
                      style={{ width: "163px", marginTop: "10px" }}>
                      <div className="form-group">
                        <Label className="form-heading">Record Status :</Label>
                        <div>
                          <DropDownList
                            style={{ width: "370px" }}
                            onChange={(e) => {
                              setIsFiledEdited(true);
                              setQuizzes((preValue) => ({
                                ...preValue,
                                recordStatus: e.value?.text,
                                recordStatusId: e.value?.id,
                              }));
                            }}
                            className="dropdown-list"
                            value={{
                              id: quizzes?.recordStatusId,
                              text: quizzes?.recordStatus,
                            }}
                            data={recordStatusOptions}
                            textField="text"
                            dataItemKey="id"
                          />
                        </div>
                      </div>
                    </GridLayoutItem>
                  )}
                  <GridLayoutItem
                    col={1}
                    row={id !== "add-quiz" ? 6 : 5}
                    className="form-inner">
                    <div className="k-d-flex" style={{ gap: "10px" }}>
                      <KendoButton
                        iconClass=""
                        size={16}
                        themeColor={"primary"}
                        buttonName="Save"
                        onClick={saveCurrentdata}
                      />
                      {id !== "add-quiz" && (
                        <KendoButton
                          size={16}
                          className=""
                          onClick={showDeleteConfirmation}
                          buttonName="Delete"
                          themeColor={"secondary"}
                        />
                      )}
                      <KendoButton
                        iconClass=""
                        size={16}
                        className=""
                        buttonName="Cancel"
                        onClick={onCancelClick}
                      />
                    </div>
                  </GridLayoutItem>
                  {id !== "add-quiz" && (
                    <GridLayoutItem colSpan={7} className="form-footer">
                      <Footerinformation footerInfo={quizzes} />
                    </GridLayoutItem>
                  )}
                </GridLayout>
              )}
            />
          </section>
        </>
      </section>
      {modalState?.show && (
        <Modal
          for={modalState?.for}
          show={modalState?.show}
          onClose={onModalClose}
          onActionBtnClick={onActionBtnClick}
          onActionBtnTitle={modalState?.onActionBtnTitle}
        />
      )}
    </>
  );
};

export default AddQuizzes;
