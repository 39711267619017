import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import { Form } from "@progress/kendo-react-form";
import { GridLayout, GridLayoutItem } from "@progress/kendo-react-layout";
import { Label } from "@progress/kendo-react-labels";
import { Loader } from "@progress/kendo-react-indicators";
import AddPageLayout from "../../addpagelayout/AddPageLayout";
import ProductInput from "../../common/ProductInput";
import { IconComponent } from "../../common/Icon";
import { FETCH_PRODUCTS_POSTS_PICTURES, SAVE_POST_PITUTRES, SAVE_POSTS } from "../../constant/Apipath";
import { toast } from "react-toastify";
import { PostRequestCall } from "../../apicall/PostRequest";


const AddProductImages = () => {

  const { dataId, productImageId } = useParams();
  const { loginData } = useSelector((state) => state.main);
  const isAddForm = productImageId === "add-project-image";

  const [productImage, setProductImage] = useState({
    productId: dataId ?? 0,
    productName: "",
    thumbImageUrl: null
  });

  const [showLoader, setShowLoader] = useState(false);
  const [isFieldEdited, setIsFiledEdited] = useState(false);

  useEffect(() => {
    if (!isAddForm) {
      getProductImageDetails();
    }
  }, []);

  const getProductImageDetails = async () => {
    try {
      let obj = { SearchList: [{ id: productImageId }] };
      const fetchPostComments = await PostRequestCall(
        FETCH_PRODUCTS_POSTS_PICTURES,
        obj,
        loginData?.token
      );

      let resData = fetchPostComments?.data?.data?.[0] ?? {};
      setProductImage((preValue) => ({
        ...preValue,
        productId: resData?.appRecordId ?? dataId ?? 0,
        productName: resData?.appRecordName ?? "",
        thumbImageUrl: resData?.thumbUrl ?? null
      }));
    } catch (error) {
      console.log("Error while getting post comment details :: ", error);
    }
  };


  const saveCurrentData = async () => {

    if (productImage?.productId === 0) {
      toast.error("Please select a vaild product", {
        position: "top-right",
      });
      return;
    }

    if (!productImage?.thumbImageUrl) {
      toast.error("Please select a vaild product image", {
        position: "top-right",
      });
      return;
    }

    setShowLoader(true);

    try {

      const fd = new FormData();

      const obj = {
        "UserId": loginData.userId,
        "ReturnRecordId": true,
        "ReturnRecordError": true,
        "SaveList": [
          {
            "Id": isAddForm ? 0 : productImageId,
            "FormatTypeId": 1,
            "PostedByUserId": loginData.userId,
            "AppId": 4,
            "AppRecordId": productImage?.productId,
            "Message": "pictures_for_products",
            "ModifiedBy": loginData.userId
          }
        ]
      }

      await PostRequestCall(SAVE_POSTS, obj, loginData?.token).then(async (res) => {
        let postID = res?.data?.data?.[0]?.objData?.id ?? null;
        if (postID) {
          fd.append("Id", 0);
          fd.append("postId", postID);
          fd.append("Pictures", productImage?.thumbImageUrl);
          fd.append("ModifiedBy", loginData.userId);

          let res = await PostRequestCall(
            SAVE_POST_PITUTRES,
            fd,
            loginData?.token,
            "formData"
          );
          if (res?.data?.status === false) {
            let errorMessage = res?.data?.errorMessage ? res?.data?.errorMessage : res?.data?.error ? res?.data?.error : "Something went wrong. Please try again later."
            toast.error(errorMessage,
              {
                position: "top-right",
              }
            );
            setShowLoader(false);
          } else {
            setShowLoader(false);
            toast.success(`Data ${isAddForm ? "added" : "updated"} successfully`, {
              position: "top-right",
            });
            window.history.back();
          }
        } else {
          let errorMessage = res?.data?.data?.[0]?.message ? res?.data?.data?.[0]?.message : res?.data?.error ? res?.data?.error : "Something went wrong. Please try again later."
          toast.error(errorMessage,
            {
              position: "top-right",
            }
          );
          setShowLoader(false);
        }
      }
      ).catch((error) => {
        let errorMessage = error?.data?.errorMessage ? error?.data?.errorMessage : error?.data?.error ? error?.data?.error : "Something went wrong. Please try again later."
        toast.error(errorMessage,
          {
            position: "top-right",
          }
        );
        setShowLoader(false);
        console.log("Error while saving product :: ", error);
      })
    } catch (error) {
      let errorMessage = error?.data?.errorMessage ? error?.data?.errorMessage : error?.data?.error ? error?.data?.error : "Something went wrong. Please try again later."
      toast.error(errorMessage,
        {
          position: "top-right",
        }
      );
      setShowLoader(false);
      console.log("Error while saving product :: ", error);
    }
  };



  const onSelectProductImage = () => {
    setIsFiledEdited(true);
    document.getElementById("product-image").click();
  };

  const onImageChange = (e) => {
    if (e?.target?.files?.length > 0) {
      setProductImage({
        ...productImage,
        thumbImageUrl: e.target.files[0],
      });
    }
    e.target.value = "";
  };

  const isCheck = (thumbImageUrl) => {
    if (Object.keys(thumbImageUrl)?.length === 0) {
      return URL.createObjectURL(thumbImageUrl);
    } else {
      return thumbImageUrl;
    }
  };

  const onRemoveImage = () => {
    setIsFiledEdited(true);
    setProductImage({
      ...productImage,
      thumbImageUrl: null,
    });
  };



  if (showLoader) {
    return (
      <Loader
        type="converging-spinner"
        className="kendo-spinner"
        style={{
          display: "flex",
          justifyContent: "center",
          minHeight: "500px",
          alignItems: "center",
        }}
      />
    );
  }

  return (
    <>
      <div className="add_form_height_100">
        <AddPageLayout
          dataId={dataId}
          addForm={isAddForm}
          showFooter={false}
          isFieldEdited={isFieldEdited}
          recordText={"Product ID"}
          hideDeleteBtn={true}
          saveCurrentData={saveCurrentData}
        // onDeleteClick={onDeleteClick}
        >
          <div style={{ height: "100%" }}>
            <Form
              render={() => (
                <GridLayout
                  rows={[
                    {
                      height: 70,
                    },
                  ]}
                  cols={[
                    {
                      width: 375,
                    },
                    {
                      width: 375,
                    },
                    {
                      width: 375,
                    },
                  ]}
                  gap={{
                    rows: 30,
                    cols: 35,
                  }}
                  style={{ padding: "16px" }}>

                  <GridLayoutItem col={1} row={1} className="form-inner">
                    <ProductInput
                      value={productImage?.productName}
                      setSelectedProduct={({ label, id }) => {
                        setIsFiledEdited(true);
                        setProductImage((preValue) => ({
                          ...preValue,
                          productName: label,
                          productId: id,
                        }));
                      }}
                      disabled
                      productId={dataId}
                    />
                  </GridLayoutItem>

                  <GridLayoutItem col={2} row={1} className="form-inner">
                    <Label className="form-heading"> Add Product Image:</Label>
                    <div className="k-d-flex" style={{ gap: "15px" }}>
                      <input
                        type="file"
                        hidden
                        accept=".png,.jpg"
                        id="product-image"
                        style={{ display: "none" }}
                        onChange={(e) => {
                          setIsFiledEdited(true);
                          onImageChange(e);
                        }}
                      />
                      {productImage?.thumbImageUrl ? (
                        <div className="k-d-flex">
                          <div
                            // className="add-file"
                            style={{ position: "relative" }}>
                            <img
                              className="user-img"
                              src={isCheck(productImage?.thumbImageUrl)}
                              alt="Ad"
                            />
                            <button
                              onClick={onRemoveImage}
                              style={{
                                position: "absolute",
                                top: "6px",
                                right: "8px",
                                backgroundColor: "red",
                                color: "white",
                                border: "none",
                                borderRadius: "50%",
                                cursor: "pointer",
                              }}>
                              <span>X</span>
                            </button>
                          </div>
                        </div>
                      ) : (
                        <>
                          <GridLayoutItem
                            col={2}
                            row={1}
                            className="form-inner">
                            <div
                              // className="add-file"
                              style={{
                                position: "relative",
                                cursor: "pointer",
                                border: "0.5px solid #d3d3d3",
                                padding: "5px",
                                display: "flex",
                                justifyContent: "center",
                                borderRadius: "4px",
                                gap: "10px",
                              }}
                              onClick={onSelectProductImage}>
                              <IconComponent iconName={"Image"} size={20} />
                              <span className="add-file-text">
                                Choose image
                              </span>
                            </div>
                          </GridLayoutItem>
                        </>
                      )}
                    </div>
                  </GridLayoutItem>

                </GridLayout>
              )}
            />
          </div>
        </AddPageLayout>
      </div>
    </>
  );
};

export default AddProductImages;
