import React, { useEffect, useRef, useState } from 'react'
import { useSelector } from 'react-redux';
import { FETCH_APPLICATIONS, FetchUsers2 } from '../constant/Apipath';
import { PostRequestCall } from '../apicall/PostRequest';
import { IconComponent } from './Icon';
import { Loader } from '@progress/kendo-react-indicators';
import { Label } from '@progress/kendo-react-labels';
import { AutoComplete } from '@progress/kendo-react-dropdowns';
import { Input } from '@progress/kendo-react-inputs';

const AppSearchInput = (props) => {

    const debounceTimeout = useRef(null);
    const { loginData } = useSelector((state) => state.main);

    const [userOptions, setOptions] = useState([]);
    const [searchLoader, setSearchLoader] = useState(false);
    const [open, setOpen] = useState(false);

    useEffect(() => {
        getUserList("");
    }, [])

    const getUserList = async (seacrhTerm) => {
        try {
            let obj = {
                PageNr: 1,
                NrOfRecPerPage: 20,
                FullSearch: "",
                SearchList: [{ name: seacrhTerm }],
                SortList: [{ FieldName: "", Direction: "" }],
                IncludeRecordNr: true,
            };
            obj.SearchList.push({ active: true })
            const res = await PostRequestCall(
                FETCH_APPLICATIONS,
                obj,
                loginData?.token
            );
            let resData = res?.data?.data ?? [];
            let data = resData.map((record) => ({
                id: record?.id,
                label: record?.name,
            }));
            setOptions(data ?? []);
            return setSearchLoader(false);
        } catch (error) {
            setSearchLoader(false);
            console.log("Error while getting user list :: ", error);
        }
    };

    return (
        <>
            <Label className="form-heading">App:</Label>
            <div style={{ position: 'relative' }}>
                <AutoComplete
                    data={userOptions}
                    value={props?.value}
                    className="field-ip"
                    placeholder='Search for a app...'
                    onChange={async (event) => {
                        setSearchLoader(true);
                        const searchTerm = event.target.value;
                        props?.setSelectedApp({ label: event.target.value, id: 0 });
                        if (searchTerm) {
                            setOpen(true);
                            if (debounceTimeout.current) {
                                clearTimeout(debounceTimeout.current);
                            }
                            debounceTimeout.current = setTimeout(() => {
                                getUserList(event.target.value);
                            }, 1000);
                        } else {
                            setOpen(false);
                        }
                    }}
                    textField="label"
                    opened={open}
                    onBlur={() => {
                        setOpen(false)
                    }}
                    itemRender={(li, itemProps) => {
                        const user = itemProps?.dataItem;
                        return (
                            <div className="user-item" onClick={() => {
                                setSearchLoader(false);
                                props?.setSelectedApp(user)
                                setOpen(false);
                            }}>
                                <span>{user?.label}</span>
                            </div>
                        );
                    }}
                />
                {
                    props?.value?.trim() === "" && (
                        <div style={{ position: 'absolute', top: '20%', right: '15px' }}>
                            <IconComponent
                                size={15}
                                iconName={"Search"}
                                color="#B5B5B5"
                            />
                        </div>
                    )
                }
                {
                    searchLoader && props?.value?.trim() !== "" && (
                        <div style={{ position: 'absolute', top: '15%', right: '2%', cursor: 'pointer', zIndex: 999, backgroundColor: "white" }}
                        >
                            <Loader size={"small"} style={{ color: '#083DB8' }} />
                        </div>
                    )
                }
            </div>

        </>
    )
}

export default AppSearchInput