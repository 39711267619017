import React, { useEffect, useState } from "react";
import { DropDownList } from "@progress/kendo-react-dropdowns";
import { useParams } from "react-router-dom";
import {
  DELETE_REWARDS_ACTIVITIES,
  FETCH_RECORD_STATUSES,
  FETCH_REWARD_ACTIVITIES,
  SAVE_REWARDS_ACTIVITIES,
} from "../../constant/Apipath";
import { PostRequestCall } from "../../apicall/PostRequest";
import { Input } from "@progress/kendo-react-inputs";
import { useSelector } from "react-redux";
import { GridLayout, GridLayoutItem } from "@progress/kendo-react-layout";
import { Label } from "@progress/kendo-react-labels";
import KendoButton from "../../common/KendoButton";
import { toast } from "react-toastify";
import { Loader } from "@progress/kendo-react-indicators";
import Modal from "../../common/Modal";
import Footerinformation from "../../common/Footerinformation";

const AddRewardActivities = () => {
  const { dataId } = useParams();
  const { loginData } = useSelector((state) => state.main);

  const [rewardActivity, setRewardActivity] = useState({
    title: "",
    contentSyntax: "",
    bucks: "",
    description: "",
    recordStatusId: 1,
    recordStatus: "New",
    createdByUser: "",
    modifiedByUser: "",
    dateCreated: "",
    dateModified: "",
  });

  const [showLoader, setShowLoader] = useState(false);
  const [recordStatusOptions, setRecordStatusOptions] = useState([]);
  const [modalState, setModalState] = useState({
    for: "",
    show: false,
    onActionBtnTitle: "",
  });
  const [isFieldEdited, setIsFiledEdited] = useState(false);

  useEffect(() => {
    if (dataId !== "add-reward-activity") {
      loadData();
      getRecordStatuses();
    }
  }, []);

  const getRecordStatuses = async () => {
    try {
      let recordStatusesObj = {
        PageNr: 1,
        NrOfRecPerPage: 100,
        FullSearch: "",
        UserId: "1",
        SortList: [
          {
            FieldName: "Id",
            Direction: "ASC",
          },
        ],
        IncludeRecordNr: true,
      };
      const recordStatusesRes = await PostRequestCall(
        FETCH_RECORD_STATUSES,
        recordStatusesObj,
        loginData?.token
      );
      let recordStatusesData = recordStatusesRes?.data?.data?.map((record) => ({
        id: record?.id,
        text: record?.name,
      }));
      setRecordStatusOptions(recordStatusesData ?? []);
    } catch (error) {
      console.log("Error while getting record statuses data :: ", error);
    }
  };

  const loadData = async () => {
    if (dataId !== "add-reward-activity") {
      var obj = JSON.stringify({
        PageNr: 1,
        NrOfRecPerPage: 20,
        FullSearch: "",
        UserId: loginData.userId,
        SearchList: [
          {
            Id: dataId,
          },
        ],
      });

      const result = await PostRequestCall(
        FETCH_REWARD_ACTIVITIES,
        obj,
        loginData?.token
      );

      let data = result?.data?.data?.[0] ?? null;
      setRewardActivity((prevData) => ({
        ...prevData,
        title: data?.title,
        contentSyntax: data?.contentSyntax,
        bucks: data?.bucks,
        description: data?.description,
        recordStatus: data?.recordStatus,
        recordStatusId: data?.recordStatusId,
        modifiedByUser: data?.modifiedByUser,
        createdByUser: data?.createdByUser,
        dateCreated: data?.dateCreated,
        dateModified: data?.dateModified,
      }));
    }
  };

  const onInputChange = (e, type) => {
    let { name, value } = e.target;
    if (type === "number") {
      setRewardActivity({ ...rewardActivity, [name]: value > 0 ? value : "" });
    } else {
      setRewardActivity({ ...rewardActivity, [name]: value });
    }
    setIsFiledEdited(true);
  };

  const onCancelClick = () => {
    if (isFieldEdited) {
      return setModalState((preValue) => ({
        ...preValue,
        for: "edit",
        show: true,
        onActionBtnTitle: "Save",
      }));
    } else {
      return window.history.back();
    }
  };

  const onSubmit = async (e) => {
    setShowLoader(true);

    const { title, contentSyntax, bucks, description } = rewardActivity;
    if (!title || !contentSyntax || !bucks) {
      setShowLoader(false);
      toast.error(
        "Please enter all mandatory fields: Title, ContentSyntax, and Bucks.",
        {
          position: "top-right",
        }
      );
      return;
    }

    if (bucks < 0) {
      setShowLoader(false);
      toast.error("Bucks cannot be negative.", {
        position: "top-right",
      });
      return;
    }

    const obj = {
      UserId: loginData?.userId,
      ReturnRecordId: true,
      ReturnRecordError: true,
      SaveList: [
        {
          Id: dataId === "add-reward-activity" ? 0 : dataId,
          Title: title,
          ContentSyntax: contentSyntax,
          Bucks: bucks,
          Description: description,
          RecordStatusId: rewardActivity?.recordStatusId || 1,
          ModifiedBy: loginData?.userId,
        },
      ],
    };

    try {
      const result = await PostRequestCall(
        SAVE_REWARDS_ACTIVITIES,
        obj,
        loginData?.token
      );
      setShowLoader(false);
      if (result?.data?.status === false || result?.data?.status === 400) {
        toast.error(result?.data?.message ?? "Please Enter All Fields.", {
          position: "top-right",
        });
      } else {
        toast.success(
          `Data ${dataId !== "add-reward-activity" ? "updated" : "added"
          } successfully`,
          {
            position: "top-right",
          }
        );
        window.history.back();
      }
    } catch (error) {
      setShowLoader(false);
      toast.error("Something went wrong. Please try again later.", {
        position: "top-right",
      });
      console.error("Error saving data :", error);
    }
  };

  const onDeleteClick = async () => {
    try {
      setShowLoader(true);
      const deleteObj = {
        ReturnRecordError: true,
        ReturnRecordId: false,
        UserId: loginData.userId,
        DeleteList: [
          {
            Id: dataId,
            ModifiedBy: loginData.userId,
          },
        ],
      };
      return PostRequestCall(
        DELETE_REWARDS_ACTIVITIES,
        deleteObj,
        loginData?.token
      )
        .then((res) => {
          setShowLoader(false);
          if (res?.data?.status) {
            toast.success("Data deleted successfully", {
              position: "top-right",
            });
            window.history.back();
          } else {
            toast.error(
              res?.data?.message ??
              "Something went wrong. Please try again later.",
              {
                position: "top-right",
              }
            );
          }
        })
        .catch((error) => {
          setShowLoader(false);
          toast.error("Something went wrong. Please try again later.", {
            position: "top-right",
          });
          console.log("Error while deleting a record :: ", error);
        });
    } catch (error) {
      setShowLoader(false);
      toast.error("Something went wrong. Please try again later.", {
        position: "top-right",
      });
      console.log("Error while deleting a record :: ", error);
    }
  };

  const onActionBtnClick = () => {
    setModalState((preValue) => ({
      ...preValue,
      for: "",
      show: false,
      onActionBtnTitle: "",
    }));
    if (modalState?.for === "delete") {
      onDeleteClick();
    } else if (modalState?.for === "edit") {
      onSubmit();
    } else {
      return null;
    }
  };

  const showDeleteConfirmation = () => {
    setModalState((preValue) => ({
      ...preValue,
      for: "delete",
      show: true,
      onActionBtnTitle: "Delete",
    }));
  };

  const onModalClose = () => {
    window.history.back();
  };

  if (showLoader) {
    return (
      <Loader
        type="converging-spinner"
        className="kendo-spinner"
        style={{
          display: "flex",
          justifyContent: "center",
          minHeight: "500px",
          alignItems: "center",
        }}
      />
    );
  }

  return (
    <section className="add-user">
      <section className="user-information">
        <div className="k-d-flex k-justify-content-between">
          <div className="k-d-flex k-align-items-center">
            {dataId !== "add-reward-activity" && (
              <span className="record-id-text">Reward Id : {dataId}</span>
            )}
          </div>
          <div className="k-d-flex k-p-2" style={{ gap: "10px" }}>
            <KendoButton
              iconClass=""
              size={16}
              onClick={onSubmit}
              className=""
              themeColor={"primary"}
              buttonName="Save"
            />
            {dataId !== "add-reward-activity" && (
              <KendoButton
                size={16}
                className=""
                onClick={showDeleteConfirmation}
                buttonName="Delete"
                themeColor={"secondary"}
              />
            )}
            <KendoButton
              iconClass=""
              size={16}
              className=""
              buttonName="Cancel"
              onClick={onCancelClick}
            />
          </div>
        </div>
        <div style={{ borderBottom: "1px solid #f5f5f5", width: "100%" }}></div>
        <GridLayout
          rows={[
            {
              height: 70,
            },
            {
              height: 70,
            },
            {
              height: 285,
            },
            {
              height: 30,
            },
          ]}
          cols={[
            {
              width: 375,
            },
            {
              width: 375,
            },
            {
              width: 375,
            },
          ]}
          gap={{
            rows: 25,
            cols: 35,
          }}
          style={{ padding: "25px 16px", gap: "20px 35px" }}>
          <GridLayoutItem col={1} row={1} className="form-inner">
            <label className="form-heading">Title :</label>
            <div>
              <Input
                type="text"
                id="title"
                name="title"
                value={rewardActivity?.title}
                className="field-ip"
                onChange={(e) => onInputChange(e)}
              />
            </div>
          </GridLayoutItem>
          <GridLayoutItem col={2} row={1} className="form-inner">
            <label className="form-heading">Content Syntax :</label>
            <div>
              <Input
                type="text"
                id="contentSyntax"
                name="contentSyntax"
                value={rewardActivity?.contentSyntax}
                className="field-ip"
                onChange={(e) => onInputChange(e)}
              />
            </div>
          </GridLayoutItem>
          <GridLayoutItem col={3} row={1} className="form-inner">
            <label className="form-heading">Bucks :</label>
            <div>
              <Input
                type="number"
                id="bucks"
                name="bucks"
                value={rewardActivity?.bucks}
                className="field-ip"
                onChange={(e) => onInputChange(e, "number")}
              />
            </div>
          </GridLayoutItem>
          <GridLayoutItem col={1} row={2} className="form-inner">
            <label className="form-heading">Description :</label>
            <div>
              <Input
                type="text"
                id="description"
                name="description"
                value={rewardActivity?.description}
                className="field-ip"
                onChange={(e) => onInputChange(e)}
              />
            </div>
          </GridLayoutItem>
          {dataId !== "add-reward-activity" ? (
            <GridLayoutItem col={2} row={2} className="form-inner">
              <div className="form-group">
                <Label className="form-heading">Record Status :</Label>
                <div>
                  <DropDownList
                    style={{ width: "370px" }}
                    onChange={(e) => {
                      setIsFiledEdited(true);
                      setRewardActivity((preValue) => ({
                        ...preValue,
                        recordStatus: e.value?.text,
                        recordStatusId: e.value?.id,
                      }));
                    }}
                    className="dropdown-list"
                    value={{
                      id: rewardActivity?.recordStatusId,
                      text: rewardActivity?.recordStatus,
                    }}
                    data={recordStatusOptions}
                    textField="text"
                    dataItemKey="id"
                  />
                </div>
              </div>
            </GridLayoutItem>
          ) : (
            <></>
          )}
          <GridLayoutItem col={1} row={3} className="form-inner">
            <div className="k-d-flex" style={{ gap: "10px" }}>
              <KendoButton
                iconClass=""
                size={16}
                onClick={onSubmit}
                themeColor={"primary"}
                buttonName="Save"
              />
              {dataId !== "add-reward-activity" && (
                <KendoButton
                  size={16}
                  className=""
                  onClick={showDeleteConfirmation}
                  buttonName="Delete"
                  themeColor={"secondary"}
                />
              )}
              <KendoButton
                iconClass=""
                size={16}
                className=""
                buttonName="Cancel"
                onClick={onCancelClick}
              />
            </div>
          </GridLayoutItem>
          {/* {dataId !== "add-reward-activity" && (
            <GridLayoutItem colSpan={3} row={4} className="form-footer">
              <Footerinformation footerInfo={rewardActivity} />
            </GridLayoutItem>
          )} */}
        </GridLayout>
      </section>
      {modalState?.show && (
        <Modal
          for={modalState?.for}
          show={modalState?.show}
          onClose={onModalClose}
          onActionBtnClick={onActionBtnClick}
          onActionBtnTitle={modalState?.onActionBtnTitle}
        />
      )}
    </section>
  );
};

export default AddRewardActivities;
