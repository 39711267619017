import React from 'react'
import './NewAccount.css'
import BuildIDLogo from "../assets/images/Build-ID-Transparent.png";

const WelcomeAccount = () => {
    return (
        <>
            <div className='new_account_container'>
                <div style={{ height: '100%' }}>
                    <div className='new_account_form_container'>
                        <div className='form_container'>
                            <div>
                                <img
                                    src={BuildIDLogo}
                                    alt=""
                                    style={{
                                        maxWidth: "100%", maxHeight: "100%",
                                        width: "auto", height: "auto"
                                    }} />
                            </div>



                            <div style={{ marginTop: '30px' }}>
                                <div style={{ marginTop: '20px' }}>

                                    <h4 className='na_form_title'>Welcome to our construction community!</h4>
                                    <div style={{ marginTop: '20px' }}>
                                        <p className='na_form_description' style={{ color: '#545050' }}>Finish setting up your account by:</p>
                                        <div style={{ color: '#545050' }}>
                                            <p>1. Download the BuildID app on your device</p>
                                            <p>2. Tap on <b className='light_bold'>Log In</b> </p>
                                            <p>3. Tap on <b className='light_bold'>Forgot your password?</b></p>
                                        </div>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default WelcomeAccount