import React, { useEffect, useState } from "react";
import { DatePicker } from "@progress/kendo-react-dateinputs";
import { AutoComplete, DropDownList } from "@progress/kendo-react-dropdowns";
import { useNavigate, useParams } from "react-router-dom";
import {
  DeleteTradeCertifications,
  FETCH_RECORD_STATUSES,
  FetchUserTradeCertifications,
  FetchUsers2,
  SAVE_TRADE_CERTIFICATION,
} from "../../constant/Apipath";
import { PostRequestCall } from "../../apicall/PostRequest";
import { Input } from "@progress/kendo-react-inputs";
import { useSelector } from "react-redux";
import { GridLayout, GridLayoutItem } from "@progress/kendo-react-layout";
import { Label } from "@progress/kendo-react-labels";
import KendoButton from "../../common/KendoButton";
import { toast } from "react-toastify";
import { Loader } from "@progress/kendo-react-indicators";

const AddNewCertificate = () => {
  const navigate = useNavigate();
  const { dataId, subMenu, userId } = useParams();
  const { loginData } = useSelector((state) => state.main);

  const [certification, setCertification] = useState({
    userName: "",
    userId: 0,
    recordStatusId: 1,
    recordStatus: "New",
    statusId: "",
    startDate: "",
    endDate: "",
    school: "",
    fieldOfStudy: "",
    isPresent: false,
  });

  const [value, setValue] = useState("");
  const [options, setOptions] = useState([]);
  const [showLoader, setShowLoader] = useState(false);
  const [recordStatusOptions, setRecordStatusOptions] = useState([]);

  const yesNoOptions = [
    {
      value: true,
      text: "Yes",
    },
    {
      value: false,
      text: "No",
    },
  ];

  useEffect(() => {
    if (dataId !== "add-certification") {
      loadCertification();
    }
    getRecordStatuses();
    if (userId && dataId === "add-certification") {
      getUserDetails();
    }
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const srchList = [{ Name: value }];
        const data = JSON.stringify({
          PageNr: 1,
          NrOfRecPerPage: 100,
          FullSearch: value,
          UserId: loginData.userId,
          SearchList: srchList,
          IncludeRecordNr: true,
        });
        const result = await PostRequestCall(
          FetchUsers2,
          data,
          loginData?.token
        );
        const users = result.data.data || [];

        const userOptions = users.map((user) => ({
          value: user.id,
          label: `${user.firstName} ${user.lastName}`,
        }));

        setOptions(userOptions);
      } catch (error) {
        console.error("Error fetching users:", error);
      }
    };

    if (value.trim() !== "") {
      fetchData();
    } else {
      setOptions([]);
    }
  }, [value]);

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const isoDate = date.toISOString().split("T")[0];
    return isoDate;
  };

  const formatDateForSavingData = (dateString) => {
    var date = new Date(dateString);
    var year = date.getFullYear();
    var month = (date.getMonth() + 1).toString().padStart(2, "0");
    var day = date.getDate().toString().padStart(2, "0");
    var formattedDate = year + "-" + month + "-" + day;
    return formattedDate;
  };

  const loadCertification = async () => {
    if (dataId !== "add-certification") {
      var obj = JSON.stringify({
        PageNr: 1,
        NrOfRecPerPage: 20,
        FullSearch: "",
        SearchList: [
          {
            Id: dataId,
          },
        ],
        SortList: [
          {
            FieldName: "Id",
            Direction: "ASC",
          },
        ],
        IncludeRecordNr: true,
      });

      const result = await PostRequestCall(
        FetchUserTradeCertifications,
        obj,
        loginData?.token
      );

      let data = result?.data?.data?.[0] ?? null;
      setCertification((prevData) => ({
        ...prevData,
        userName: data?.userName,
        userId: data?.userId,
        school: data?.school,
        fieldOfStudy: data?.fieldOfStudy,
        startDate: data?.startDate
          ? formatDateForSavingData(data?.startDate)
          : "",
        endDate: data?.endDate ? formatDateForSavingData(data?.endDate) : "",
        isPresent: data?.isPresent,
        recordStatus: data?.recordStatus,
        recordStatusId: data?.recordStatusId,
      }));
    }
  };

  const getRecordStatuses = async () => {
    try {
      let recordStatusesObj = {
        PageNr: 1,
        NrOfRecPerPage: 100,
        FullSearch: "",
        UserId: "1",
        SortList: [
          {
            FieldName: "Id",
            Direction: "ASC",
          },
        ],
        IncludeRecordNr: true,
      };
      const recordStatusesRes = await PostRequestCall(
        FETCH_RECORD_STATUSES,
        recordStatusesObj,
        loginData?.token
      );
      let recordStatusesData = recordStatusesRes?.data?.data?.map((record) => ({
        id: record?.id,
        text: record?.name,
      }));
      setRecordStatusOptions(recordStatusesData ?? []);
    } catch (error) {
      console.log("Error while getting record statuses data :: ", error);
    }
  };

  const getUserDetails = async () => {
    try {
      const data = JSON.stringify({
        PageNr: 1,
        NrOfRecPerPage: 100,
        FullSearch: "",
        UserId: loginData.userId,
        SearchList: [{ id: userId }],
        IncludeRecordNr: true,
      });

      const result = await PostRequestCall(FetchUsers2, data, loginData?.token);
      setCertification((prevData) => ({
        ...prevData,
        userName: result?.data?.data?.[0]?.name,
        userId,
      }));
    } catch (error) {
      console.log("Error while getting user details :: ", error);
    }
  };

  const onInputChange = (e) => {
    setCertification({ ...certification, [e.target.name]: e.target.value });
  };

  const handleChange = (event) => {
    setValue(event.target.value);
  };

  const onCancelClick = () => {
    window.history.back();
  };

  // const onSubmit = async (e) => {
  //   e.preventDefault();

  //   const formattedStartDate = formatDate(certification.startDate);
  //   const formattedEndDate = formatDate(certification.endDate);
  //   const firstOptionValue = options.length > 0 ? options[0].value : null;
  //   const newCertification = {
  //     Id: dataId === "add-certification" ? 0 : dataId,
  //     UserId: certification.userId || firstOptionValue,
  //     School: certification.school,
  //     RecordStatusId: certification?.recordStatusId || 1,
  //     IsPresent: certification.isPresent,
  //     FieldOfStudy: certification.fieldOfStudy,
  //     Startdate: formattedStartDate,
  //     EndDate: formattedEndDate,
  //     ModifiedBy: loginData.userId,
  //   };

  //   try {
  //     await PostRequestCall(
  //       SaveTradeCertification,
  //       newCertification,
  //       loginData?.token
  //     );

  //     navigate("/users/certification");
  //   } catch (error) {
  //     console.error("Error saving certification:", error);
  //   }
  // };

  const onSubmit = async (e) => {
    e.preventDefault();
    const firstOptionValue = options.length > 0 ? options[0].value : null;

    if (certification?.userId === 0) {
      if (!firstOptionValue) {
        return toast.error("Please select a user", {
          position: "top-right",
        });
      }
    }

    if (certification.school === "") {
      return toast.error("Please enter valid school name", {
        position: "top-right",
      });
    }

    if (certification.fieldOfStudy === "") {
      return toast.error("Please select a vaild field of study", {
        position: "top-right",
      });
    }

    if (certification.startDate === "") {
      return toast.error("Please select a vaild start date", {
        position: "top-right",
      });
    }

    if (certification?.endDate === "") {
      return toast.error("Please select a vaild end date", {
        position: "top-right",
      });
    }

    setShowLoader(true);
    const obj = {
      Id: dataId === "add-certification" ? 0 : dataId,
      UserId: certification.userId || firstOptionValue,
      School: certification.school,
      RecordStatusId: certification?.recordStatusId || 1,
      IsPresent: certification.isPresent,
      FieldOfStudy: certification.fieldOfStudy,
      EndDate: formatDateForSavingData(certification?.endDate),
      StartDate: formatDateForSavingData(certification.startDate),
      ModifiedBy: loginData.userId,
    };

    try {
      const result = await PostRequestCall(
        SAVE_TRADE_CERTIFICATION,
        obj,
        loginData?.token
      );

      if (result.data.status === false) {
        setShowLoader(false);
        toast.error(
          result?.data?.message ??
            "Something went wrong. Please try again later.",
          {
            position: "top-right",
          }
        );
      } else {
        setShowLoader(false);
        toast.success(
          `Data ${
            dataId !== "add-certification" ? "updated" : "added"
          } successfully`,
          {
            position: "top-right",
          }
        );
        window.history.back();
      }
    } catch (error) {
      setShowLoader(false);
      toast.error("Something went wrong. Please try again later.", {
        position: "top-right",
      });
      console.error("Error saving data :", error);
    }
  };

  const onDeleteClick = async (e) => {
    try {
      e.stopPropagation();
      if (window.confirm("Are you sure you wish to delete this record?")) {
        setShowLoader(true);
        const deleteObj = [
          {
            Id: dataId,
            ModifiedBy: loginData?.userId,
          },
        ];
        return PostRequestCall(
          DeleteTradeCertifications,
          deleteObj,
          loginData?.token
        )
          .then((res) => {
            setShowLoader(false);
            if (res?.data?.status) {
              toast.success("Data deleted successfully", {
                position: "top-right",
              });
              window.history.back();
            } else {
              toast.error(
                res?.data?.data?.[0]?.message ??
                  res?.data?.message ??
                  "Something went wrong. Please try again later.",
                {
                  position: "top-right",
                }
              );
            }
          })
          .catch((error) => {
            setShowLoader(false);
            toast.error("Something went wrong. Please try again later.", {
              position: "top-right",
            });
            console.log("Error while deleting a record :: ", error);
          });
      }
    } catch (error) {
      setShowLoader(false);
      toast.error("Something went wrong. Please try again later.", {
        position: "top-right",
      });
      console.log("Error while deleting a record :: ", error);
    }
  };

  if (showLoader) {
    return (
      <Loader
        type="converging-spinner"
        className="kendo-spinner"
        style={{
          display: "flex",
          justifyContent: "center",
          minHeight: "500px",
          alignItems: "center",
        }}
      />
    );
  }

  return (
    <section className="add-user">
      <section className="user-information">
        <GridLayout
          rows={[
            {
              height: 70,
            },
            {
              height: 70,
            },
            {
              height: 70,
            },
            {
              height: 70,
            },
            {
              height: 70,
            },
            {
              height: 70,
            },
            {
              height: 70,
            },
            {
              // height: 20,
            },
          ]}
          cols={[
            {
              width: 375,
            },
            {
              width: 375,
            },
            {
              width: 375,
            },
          ]}
          gap={{
            rows: 25,
            cols: 35,
          }}
          style={{ padding: "25px 16px" }}>
          <GridLayoutItem col={1} row={1} className="form-inner">
            <Label className="form-heading">User :</Label>
            <div className="form-control">
              {dataId !== "add-certification" || subMenu ? (
                <AutoComplete
                  data={options}
                  className="field-ip"
                  value={certification?.userName}
                  textField="label"
                  placeholder="Search for users..."
                  disabled
                />
              ) : (
                <AutoComplete
                  data={options}
                  value={value}
                  className="field-ip"
                  onChange={handleChange}
                  textField="label"
                  placeholder="Search for users..."
                />
              )}
            </div>
          </GridLayoutItem>
          <GridLayoutItem col={2} row={1} className="form-inner">
            <label className="form-heading">School :</label>
            <div className="form-control">
              <Input
                type="text"
                id="school"
                name="school"
                value={certification?.school}
                className="field-ip"
                onChange={(e) => onInputChange(e)}
              />
            </div>
          </GridLayoutItem>
          <GridLayoutItem col={3} row={1} className="form-inner">
            <label className="form-heading">Field Of Study :</label>
            <div className="form-control">
              <Input
                type="text"
                id="fieldOfStudy"
                name="fieldOfStudy"
                value={certification?.fieldOfStudy}
                className="field-ip"
                onChange={(e) => onInputChange(e)}
              />
            </div>
          </GridLayoutItem>
          <GridLayoutItem col={1} row={2} className="form-inner">
            <label className="form-heading">Start Date :</label>
            <div className="form-control">
              {dataId === "add-certification" ? (
                <DatePicker
                  id="startDate"
                  name="startDate"
                  className="field-ip"
                  value={certification?.startDate}
                  onChange={onInputChange}
                />
              ) : (
                <DatePicker
                  id="startDate"
                  name="startDate"
                  className="field-ip"
                  value={new Date(certification?.startDate)}
                  onChange={onInputChange}
                />
              )}
            </div>
          </GridLayoutItem>
          <GridLayoutItem col={2} row={2} className="form-inner">
            <label className="form-heading">End Date :</label>
            <div className="form-control">
              {dataId === "add-certification" ? (
                <DatePicker
                  id="endDate"
                  name="endDate"
                  className="field-ip"
                  value={certification?.endDate}
                  onChange={onInputChange}
                />
              ) : (
                <DatePicker
                  id="endDate"
                  name="endDate"
                  className="field-ip"
                  value={new Date(certification?.endDate)}
                  onChange={onInputChange}
                />
              )}
            </div>
          </GridLayoutItem>
          <GridLayoutItem
            col={3}
            row={2}
            className="form-inner"
            style={{ width: "370px" }}>
            <label className="form-heading">Is Present :</label>
            <DropDownList
              style={{ width: "370px" }}
              onChange={(e) => {
                setCertification((preValue) => ({
                  ...preValue,
                  isPresent: e.value?.value,
                }));
              }}
              className="dropdown-list"
              value={{
                value: certification?.isPresent,
                text: certification?.isPresent ? "Yes" : "No",
              }}
              data={yesNoOptions}
              textField="text"
              dataItemKey="text"
            />
          </GridLayoutItem>

          {dataId !== "add-certification" || subMenu ? (
            <GridLayoutItem col={1} row={3} className="form-inner">
              <div className="form-group">
                <Label className="form-heading">Record Status :</Label>
                <div>
                  <DropDownList
                    style={{ width: "370px" }}
                    onChange={(e) => {
                      setCertification((preValue) => ({
                        ...preValue,
                        recordStatus: e.value?.text,
                        recordStatusId: e.value?.id,
                      }));
                    }}
                    className="dropdown-list"
                    value={{
                      id: certification?.recordStatusId,
                      text: certification?.recordStatus,
                    }}
                    data={recordStatusOptions}
                    textField="text"
                    dataItemKey="id"
                  />
                </div>
              </div>
            </GridLayoutItem>
          ) : (
            <></>
          )}
          <GridLayoutItem col={3} row={4} className="form-inner">
            <div
              className="k-d-flex k-justify-content-end"
              style={{ gap: "10px" }}>
              <KendoButton
                iconClass=""
                size={16}
                onClick={onSubmit}
                className="action-btn"
                buttonName="Save"
              />
              {dataId !== "add-certification" && (
                <KendoButton
                  size={16}
                  className="action-btn delete-btn"
                  onClick={onDeleteClick}
                  buttonName="Delete"
                />
              )}
              <KendoButton
                iconClass=""
                themeColor=""
                size={16}
                className=""
                buttonName="Cancel"
                onClick={onCancelClick}
              />
            </div>
          </GridLayoutItem>
        </GridLayout>
      </section>
    </section>
  );
};

export default AddNewCertificate;
