import React from 'react'
import { useParams } from 'react-router-dom';
import AddNewUserEmail from '../../users/user-emails/AddNewUserEmail';
import AddNewContactEvents from '../../users/contact-events/AddNewContactEvenets';
import AddNewUserPhone from '../../users/user-phones/AddNewUserPhone';
import AddNewUserCompany from '../../users/user-companies/AddNewUserCompany';
import AddNewCertificate from '../../users/certification/AddNewCertificate';
import AddNewUsages from '../../home/user-usages/AddNewUsages';
import AddNewRecord from '../../home/data-issues/AddNewRecord';
import AddNewUserTrades from '../../users/user-trades/AddNewUserTrades';
import AddNewResumeSharedWith from '../../users/resume-shared-with/AddNewResumeSharedWith';
import AddUserRolesAuth from './AddUserRolesAuth';
import AddNewStoreItems from '../../rewards/storeItems/AddNewStoreItems';
import Adduserworkhistory from '../../experience/Adduserworkhistory';
import AddNewPost from '../../users/posts/AddNewPost';
import AddPostComments from '../../users/post-comments/AddNewPostComment';
import AddNewUserSiteProjects from '../../users/user-sites-projects/AddNewUserSiteProjects';
import EditUserRewardActivities from '../../users/user-reward-Activities/EditUserRewardActivities';
import AddSites from '../../sites/sites/AddSites';
import EditUserDevices from '../../users/user-devices/EditUserDevices';

const AddUserRecordLayout = () => {

    const { subMenu } = useParams();


    const renderFormBasedOnUrl = () => {
        let ui = <></>;
        switch (subMenu) {
            case "user-emails":
                ui = <> <AddNewUserEmail /> </>
                break;
            case "contact-events":
                ui = <> <AddNewContactEvents /> </>
                break;
            case "certification":
                ui = <> <AddNewCertificate /> </>
                break;
            case "user-phones":
                ui = <> <AddNewUserPhone /> </>
                break;
            case "user-usage":
                ui = <> <AddNewUsages /> </>
                break;
            case "user-experience":
                ui = <> userExperience </>
                break;
            case "user-addresses":
                ui = <> userAddresses </>
                break;
            case "tickets":
                ui = <> tickets </>
                break;
            case "user-quizzes":
                ui = <> userQuizzes </>
                break;
            case "user-answers":
                ui = <> userAnswers </>
                break;
            case "user-support":
                ui = <> <AddNewRecord /> </>
                break;
            case "user-trades":
                ui = <> <AddNewUserTrades /> </>
                break;
            case "user-companies":
                ui = <> <AddNewUserCompany /> </>
                break;
            case "user-purchase":
                ui = <> <AddNewStoreItems /> </>
                break;
            case "ticket-shared-with":
                ui = <> ticketSharedWith </>
                break;
            case "resume-shared-with":
                ui = <> <AddNewResumeSharedWith /> </>
                break;
            case "user-rewards":
                ui = <> userRewards </>
                break;
            case "user-roles-auth":
                ui = <> <AddUserRolesAuth /> </>
                break;
            case "user-work-history":
                ui = <> <Adduserworkhistory /> </>
                break;
            case "user-post":
                ui = <> <AddNewPost /> </>
                break;
            case "user-post-comments":
                ui = <> <AddPostComments /> </>
                break;
            case "user-site-projects":
                ui = <> <AddNewUserSiteProjects /> </>
                break;
            case "user-reward-activity":
                ui = <> <EditUserRewardActivities /> </>
                break;
            case "user-sites":
                ui = <> <AddSites /> </>
                break;
            case "user-devices":
                ui = <> <EditUserDevices /> </>
                break;
            default:
                ui = <></>;
                break;
        }

        return ui;
    }

    return (
        <>
            {renderFormBasedOnUrl()}
        </>
    )
}

export default AddUserRecordLayout