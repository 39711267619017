import React, { useRef } from "react";
import { Outlet, useNavigate, useParams } from "react-router-dom";

const TicketDetailsMainLayout = () => {
  const { ticketId, userId } = useParams();
  const listRef = useRef(null);
  const navigate = useNavigate();

  const pathname = window.location.pathname;
  const pathArray = pathname.split("/");

  let isFromUserDetails = userId ? true : false;
  let activePathUrl = isFromUserDetails ? pathArray?.[8] : pathArray?.[5];


  let menuList = [
    { title: "Edit Ticket", url: "edit-ticket" },
    { title: "Ticket Shared With", url: "ticket-shared-with" },
  ];

  return (
    <>
      <section className="add-user" style={{ height: "92%" }}>
        <section className="table-list userauth-submenu">
          <div className="menu-list-2 usersub-menu">
            {Number(ticketId) !== 0 ? (
              <>
                <ul className="second-list" ref={listRef}>
                  {menuList?.map((item, index) => (
                    <li
                      className="menu"
                      key={index}
                      onClick={() => {
                        navigate(item?.url);
                      }}>
                      <div
                        style={{ cursor: "pointer" }}
                        className={`k-d-flex k-align-items-center k-flex-row menu ${item?.url === activePathUrl ? "active" : ""
                          }`}>
                        <span className="item-text">{item?.title}</span>
                      </div>
                    </li>
                  ))}
                </ul>
              </>
            ) : (
              <></>
            )}
          </div>
        </section>
        <Outlet />
      </section>
    </>
  );
};

export default TicketDetailsMainLayout;
