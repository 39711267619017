import React, { useState } from "react";
import { Card } from "@progress/kendo-react-layout";
import { FieldWrapper, Form } from "@progress/kendo-react-form";
import { Label } from "@progress/kendo-react-labels";
import { Loader } from "@progress/kendo-react-indicators";
import { Button } from "@progress/kendo-react-buttons";
import axios from "axios";
import "./Login.css";
import { PostRequestCall } from "../apicall/PostRequest";
import { LOGIN } from "../constant/Apipath";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router";
import { setLogindata } from "../redux/actions/action";
import logo from "../assets/images/logo.png";
import { sha256 } from "crypto-hash";
const LoginPage = () => {
  const [showLoader, setShowLoader] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [passwordError, setPasswordError] = useState(null);
  const [error, setError] = useState(null);
  const [emailError, setEmailError] = useState(null);

  const iconChange = () => {
    setShowPassword((prevShowPassword) => !prevShowPassword);
  };

  const hash = (str) => {
    return sha256(str);
  };
  const validateEmail = (email) => {
    const isValid = /\S+@\S+\.\S+/.test(email);
    return isValid ? null : "Please enter a valid email address";
  };

  const validatePassword = (password) => {
    return password.length >= 6
      ? null
      : "Password must be at least 6 characters";
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const emailValidation = validateEmail(email);
    const passwordValidation = validatePassword(password);

    if (emailValidation) {
      setEmailError(emailValidation);
      return;
    } else {
      setEmailError(null);
    }

    if (passwordValidation) {
      setPasswordError(passwordValidation);
      return;
    } else {
      setPasswordError(null);
    }
    setShowLoader(true);

    try {
      let headers_without_authorization = {
        "Content-Type": "application/json",
        Accept: "application/json",
      };
      const res = await axios.get("https://api.ipify.org/?format=json", {
        headers: headers_without_authorization,
      });

      const userObj = {
        Email: email,
        Password: await hash(password),
        AppName: "ConstructionDatabase",
        AppVersion: "2.10.0",
        Platform: "windows",
        PlatformVersion: "NA",
        IsDeviceNotify: "false",
        DeviceName:
          res?.status === 200 ? "Browser" : Math.floor(Math.random() * 100) + 1,
      };

      const loginResult = await PostRequestCall(LOGIN, userObj, "");

      if (loginResult?.data?.data?.token) {
        const userRoleAuthList = loginResult.data.data.userRoleAuthList;
        const isAdmin = userRoleAuthList.some(
          (role) => role.userRoleAuthId === 1
        );

        const isPromoter = userRoleAuthList.some(
          (role) => role.userRoleAuthId === 4
        );

        let loginData = {
          ...loginResult?.data?.data,
          userRoleAuthId: isAdmin ? 1 : isPromoter ? 4 : 0,
        };

        if (isAdmin || isPromoter) {
          dispatch(setLogindata(loginData));
          setEmail("");
          setPassword("");
          setError(null);
          navigate("/dashboard");
        } else {
          setError("User does not have administrator privileges.");
        }
      } else if (loginResult?.data?.errorMessage) {
        const errorMessage = loginResult?.data?.errorMessage;
        if (errorMessage === "User Doesn't Exists") {
          setEmailError("Please check the email");
        } else if (errorMessage === "Incorrect Password !!!") {
          setPasswordError("Please check the password");
        } else {
          setError(errorMessage);
        }
      }
    } catch (error) {
      console.log("error", error);
      setError("An error occurred. Please try again.");
    }
    setShowLoader(false);
  };

  return (
    <div>
      <section className="login-section">
        <Card className="login-card">
          <div>
            <a className="logo">
              <img
                src={logo}
                alt="logo"
                style={{ height: "50px", width: "50px" }}
              />
              <h3 className="img-fluid">CONSTRUCTION DATABASE </h3>
            </a>
          </div>
          <Form
            render={(formRenderProps) => (
              <FieldWrapper>
                <h5 className="login-text k-mb-2">
                  <b>Sign in to account</b>
                </h5>
                <p style={{ color: "#898989", marginTop: "3px" }}>
                  Enter your email & password to login
                </p>
                <div className="form-group">
                  <Label className="auth-lb">Email Address</Label>
                  <input
                    name="email"
                    className="auth-ip k-mt-2 k-p-0"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                  />
                  {emailError && (
                    <div style={{ color: "red", marginTop: "0.5rem" }}>
                      {emailError}
                    </div>
                  )}
                </div>
                <div className="form-group" style={{ position: "relative" }}>
                  <Label className="auth-lb">Password</Label>
                  <input
                    className="auth-ip k-mt-2 k-p-0"
                    style={{
                      display: "flex",
                      alignItems: "center",
                    }}
                    type={showPassword ? "text" : "password"}
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                  />
                  {passwordError && (
                    <div style={{ color: "red", marginTop: "0.5rem" }}>
                      {passwordError}
                    </div>
                  )}
                  <div
                    style={{
                      position: "absolute",
                      right: "15px",
                      transform: "translateY(-50%)",
                      top: passwordError ? "50%" : "70%",
                    }}>
                    <span
                      style={{
                        cursor: "pointer",
                        color: "#7A70BA",
                      }}
                      onClick={iconChange}>
                      {showPassword ? "Hide" : "Show"}
                    </span>
                  </div>
                  <div>
                    <span
                      className="forgot"
                      onClick={() => {
                        navigate("/forget-password", {
                          state: {
                            email,
                          },
                        });
                      }}>
                      Forgot password?
                    </span>
                  </div>
                </div>
                {showLoader ? (
                  <div
                    className="k-d-flex k-justify-content-center k-align-items-center login-btn"
                    style={{ marginTop: "3rem" }}>
                    <Loader
                      type="pulsing"
                      size="medium"
                      style={{ color: "#fffff" }}
                    />
                  </div>
                ) : (
                  <div style={{ marginTop: "3rem" }}>
                    <Button
                      className="login-btn"
                      type="submit"
                      onClick={handleSubmit}>
                      Sign In
                    </Button>
                  </div>
                )}
                {error && (
                  <div style={{ color: "red", marginTop: "1rem" }}>{error}</div>
                )}
              </FieldWrapper>
            )}
          />
        </Card>
      </section>
    </div>
  );
};

export default LoginPage;
