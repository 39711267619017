import React from "react";
import { useNavigate } from "react-router-dom";
import KendoButton from "./KendoButton";

const NotFoundPage = () => {
  const navigate = useNavigate();

  const handleGoHome = () => {
    navigate("/");
  };

  return (
    <div style={styles.container}>
      <h1 style={styles.heading}>404</h1>
      <p style={styles.text}>Page Not Found</p>
      <KendoButton
        size={18}
        style={styles?.button}
        onClick={handleGoHome}
        buttonName="Go To Dashboard"
      />
    </div>
  );
};

const styles = {
  container: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    height: "100vh",
    backgroundColor: "#f3f4f6",
    color: "#333",
  },
  heading: {
    fontSize: "6rem",
    margin: "0",
  },
  text: {
    fontSize: "1.5rem",
    margin: "0.5rem 0",
  },
  button: {
    padding: "0.5rem 1rem",
    fontSize: "1rem",
    color: "#fff",
    backgroundColor: "#1274ac",
    border: "none",
    marginTop: "20px",
    borderRadius: "5px",
    cursor: "pointer",
    // marginTop: "1rem",
  },
};

export default NotFoundPage;
