import { actionTypes } from "../actions/action"

const initailData = {
    loginData: {},
    filterData: {},
}

const mainReducer = (state = initailData, action) => {
    switch (action.type) {
        case actionTypes.SET_LOGIN_DATA:
            return {
                ...state,
                loginData: action?.payload
            };
        case actionTypes.SET_FILTER_DATA:
            return {
                ...state,
                filterData: action?.payload
            };
        case actionTypes.CLEAR_FILTER_DATA:
            return {
                ...state,
                filterData: {}
            };
        default:
            return state;
    }
}

export default mainReducer;