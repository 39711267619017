import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import {
  DELETE_PRODUCTS,
  FETCH_PRODUCTS,
  SAVE_PRODUCTS,
} from "../../constant/Apipath";
import { PostRequestCall } from "../../apicall/PostRequest";
import { Input, TextArea } from "@progress/kendo-react-inputs";
import { useSelector } from "react-redux";
import { Form } from "@progress/kendo-react-form";
import { GridLayout, GridLayoutItem } from "@progress/kendo-react-layout";
import { Label } from "@progress/kendo-react-labels";
import { toast } from "react-toastify";
import { Loader } from "@progress/kendo-react-indicators";
import AddPageLayout from "../../addpagelayout/AddPageLayout";
import RecordStatusInput from "../../common/RecordStatusInput";
import KendoButton from "../../common/KendoButton";
import ManufacturerInput from "../../common/ManufacturerInput";

const AddNewProduct = () => {
  const { dataId } = useParams();
  const { loginData } = useSelector((state) => state.main);
  const isAddForm = Number(dataId) === 0;
  let navigate = useNavigate();

  const [product, setProduct] = useState({
    manufacturerId: 0,
    manufacturerName: "",
    webSite: "",
    info: "",
    recordStatusId: 1,
    recordStatus: "",
    name: ""
  });

  const [showLoader, setShowLoader] = useState(false);
  const [isFieldEdited, setIsFiledEdited] = useState(false);

  useEffect(() => {
    if (!isAddForm) {
      getProductDetails();
    }
  }, []);

  const getProductDetails = async () => {
    try {
      let obj = { SearchList: [{ Id: dataId }] };
      const fetchPostComments = await PostRequestCall(
        FETCH_PRODUCTS,
        obj,
        loginData?.token
      );

      let resData = fetchPostComments?.data?.data?.[0] ?? {};
      setProduct((preValue) => ({
        ...preValue,
        name: resData?.name ?? "",
        manufacturerName: resData?.manufacturerName ?? "",
        manufacturerId: resData?.manufacturerId ?? 0,
        webSite: resData?.productWebSite ?? "",
        info: resData?.info ?? "",
        recordStatus: resData?.recordStatus ?? "New",
        recordStatusId: resData?.recordStatusId ?? 1,
      }));
    } catch (error) {
      console.log("Error while getting post comment details :: ", error);
    }
  };

  console.log("products ==>", product)

  const saveCurrentData = async () => {
    if (product?.name?.trim() === "") {
      toast.error("Please enter a valid product name", {
        position: "top-right",
      });
      return;
    }

    if (product?.manufacturerId === 0) {
      toast.error("Please select a vaild manufacturer", {
        position: "top-right",
      });
      return;
    }


    setShowLoader(true);
    const obj = {
      UserId: loginData?.userId,
      ReturnRecordId: false,
      ReturnRecordError: true,
      SaveList: [
        {
          Id: dataId ?? 0,
          Name: product?.name,
          ManufacturerId: product?.manufacturerId,
          Website: product?.webSite,
          info: product?.info === "" ? "" : product.info,
          RecordStatusId: product?.recordStatusId || 1,
          ModifiedBy: loginData?.userId,
        },
      ],
    };

    try {
      let res = await PostRequestCall(
        SAVE_PRODUCTS,
        obj,
        loginData?.token,
      );
      if (res?.data?.status === false) {
        let errorMessage = res?.data?.errorMessage ? res?.data?.errorMessage : res?.data?.error ? res?.data?.error : "Something went wrong. Please try again later."
        toast.error(errorMessage,
          {
            position: "top-right",
          }
        );
        setShowLoader(false);
      } else {
        setShowLoader(false);
        toast.success(`Data ${isAddForm ? "added" : "updated"} successfully`, {
          position: "top-right",
        });
        // window.history.back();
        navigate('/products/products');
      }
    } catch (error) {
      let errorMessage = error?.data?.errorMessage ? error?.data?.errorMessage : error?.data?.error ? error?.data?.error : "Something went wrong. Please try again later."
      toast.error(errorMessage,
        {
          position: "top-right",
        }
      );
      setShowLoader(false);
      console.log("Error while saving product :: ", error);
    }
  };

  const onDeleteClick = async () => {
    try {
      setShowLoader(true);
      const deleteObj = [
        {
          Id: Number(dataId),
          ModifiedBy: loginData?.userId,
        },
      ];
      const obj = {
        ReturnRecordError: true,
        ReturnRecordId: false,
        UserId: loginData?.userId,
        DeleteList: deleteObj,
      };
      return PostRequestCall(DELETE_PRODUCTS, obj, loginData?.token)
        .then((res) => {
          setShowLoader(false);
          if (res?.data?.status) {
            toast.success("Data deleted successfully", {
              position: "top-right",
            });
            // window.history.back();
            navigate('/products/products');

          } else {
            let errorMessage = res?.data?.data?.[0]?.message ? res?.data?.data?.[0]?.message : res?.data?.error ? res?.data?.error : "Something went wrong. Please try again later."
            toast.error(
              errorMessage ??
              "Something went wrong. Please try again later.",
              {
                position: "top-right",
              }
            );
          }
        })
        .catch((error) => {
          setShowLoader(false);
          toast.error("Something went wrong. Please try again later.", {
            position: "top-right",
          });
          console.log("Error while deleting a record :: ", error);
        });
    } catch (error) {
      setShowLoader(false);
      toast.error("Something went wrong. Please try again later.", {
        position: "top-right",
      });
      console.log("Error while deleting a record :: ", error);
    }
  };

  const onInputChange = (e) => {
    let inputValue = e.target.value;
    setProduct({
      ...product,
      [e.target.name]: inputValue,
    });
    setIsFiledEdited(true);
  };

  function navigateToWebsite() {
    const websiteUrl = document.getElementById("webSite").value;
    if (websiteUrl) {
      window.open(websiteUrl, "_blank");
    }
  }

  if (showLoader) {
    return (
      <Loader
        type="converging-spinner"
        className="kendo-spinner"
        style={{
          display: "flex",
          justifyContent: "center",
          minHeight: "500px",
          alignItems: "center",
        }}
      />
    );
  }

  return (
    <>
      <div className="add_form_height_100">
        <AddPageLayout
          dataId={dataId}
          addForm={isAddForm}
          showFooter={false}
          isFieldEdited={isFieldEdited}
          recordText={"Product ID"}
          saveCurrentData={saveCurrentData}
          onDeleteClick={onDeleteClick}>
          <div style={{ height: "100%" }}>
            <Form
              render={() => (
                <GridLayout
                  rows={[
                    {
                      height: 70,
                    },
                  ]}
                  cols={[
                    {
                      width: 375,
                    },
                    {
                      width: 375,
                    },
                    {
                      width: 375,
                    },
                  ]}
                  gap={{
                    rows: 30,
                    cols: 35,
                  }}
                  style={{ padding: "16px" }}>

                  <GridLayoutItem col={1} row={1} className="form-inner">
                    <label className="form-heading">Name:</label>
                    <Input
                      type="text"
                      id="name"
                      name="name"
                      value={product?.name}
                      className="field-ip"
                      onChange={(e) => onInputChange(e)}
                    />
                  </GridLayoutItem>

                  <GridLayoutItem col={2} row={1} className="form-inner">
                    <label className="form-heading">WebSite:</label>
                    <div style={{ display: "flex", gap: "2px" }}>
                      <Input
                        type="text"
                        id="webSite"
                        name="webSite"
                        value={product?.webSite}
                        className="field-ip"
                        onChange={(e) => onInputChange(e)}
                      />
                      <KendoButton
                        onClick={navigateToWebsite}
                        className="navigate-button"
                        buttonName="-->"
                        disabled={!product?.webSite}
                      />
                    </div>
                  </GridLayoutItem>

                  <GridLayoutItem col={1} row={2} className="form-inner">
                    <div className="form-group">
                      <Label className="form-heading"> Info:</Label>
                      <TextArea
                        type="text"
                        id="info"
                        name="info"
                        value={product?.info}
                        className="field-ip"
                        onChange={(e) => onInputChange(e)}
                        rows={4}
                      />
                    </div>
                  </GridLayoutItem>

                  <GridLayoutItem col={3} row={1} className="form-inner">
                    <ManufacturerInput
                      value={product?.manufacturerName}
                      setSelectedManufacturer={({ label, id }) => {
                        setIsFiledEdited(true);
                        setProduct((preValue) => ({
                          ...preValue,
                          manufacturerName: label,
                          manufacturerId: id,
                        }));
                      }}
                    />
                  </GridLayoutItem>

                  {!isAddForm && (
                    <GridLayoutItem col={2} row={2} className="form-inner">
                      <RecordStatusInput
                        recordStatusId={product?.recordStatusId}
                        recordStatus={product?.recordStatus}
                        onChange={({ recordStatus, recordStatusId }) => {
                          setIsFiledEdited(true);
                          setProduct((preValue) => ({
                            ...preValue,
                            recordStatus,
                            recordStatusId,
                          }));
                        }}
                      />
                    </GridLayoutItem>
                  )}
                </GridLayout>
              )}
            />
          </div>
        </AddPageLayout>
      </div>
    </>
  );
};

export default AddNewProduct;
