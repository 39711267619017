import React, { useEffect, useRef, useState } from "react";
import {
  Chart,
  ChartLegend,
  ChartSeries,
  ChartSeriesItem,
  ChartTitle,
  ChartSeriesLabels,
  ChartCategoryAxis,
  ChartCategoryAxisItem,
  ChartCategoryAxisTitle,
} from "@progress/kendo-react-charts";
import { GET_USERS_RECORDS } from "../constant/Apipath";
import { useSelector } from "react-redux";
import { GetrequestCall } from "../apicall/GetRequest";
import totalUsers from "../assets/images/total-users.png";
import uniqueAccess from "../assets/images/unique-access.png";
import newEmployee from "../assets/images/new-amployee.png";
import { Card, CardBody } from "@progress/kendo-react-layout";
import { useNavigate } from "react-router-dom";
import "hammerjs";
import { IconComponent } from "../common/Icon";

const Dashboard = () => {
  let navigate = useNavigate();
  const hoverTimeoutRef = useRef(null);

  const { loginData } = useSelector((state) => state.main);

  const [userCounts, setUserCounts] = useState({
    totalNoOfUsers: 0,
    lastSixtyDaysNewUsers: 0,
    lastFiveDaysAccessUsers: 0,
  });

  const [userStatus, setUserStatus] = useState([
    {
      title: "Active",
      percentage: 50,
    },
    {
      title: "InActive",
      percentage: 50,
    },
  ]);

  const [signUpWith, setSignUpWith] = useState([
    {
      title: "Password",
      count: 25,
      percentage: 25,
    },
    {
      title: "Google",
      count: 25,
      percentage: 25,
    },
    {
      title: "Apple",
      count: 25,
      percentage: 25,
    },
    {
      title: "FaceBook",
      count: 25,
      percentage: 25,
    },
    {
      title: "Unknown",
      count: 0,
      percentage: 0,
    },
  ]);

  const [activeUserListByCountry, setActiveUserListByCountry] = useState([]);
  const [uniqueUserListByCountry, setUniqueUserListByCountry] = useState([]);
  const [supportRequestCounts, setSupportRequestCounts] = useState(0);
  const [uniqueAccessPerMonth, setUniqueAccessPerMonth] = useState([]);

  const [selectedFilter, setSelectedFilter] = useState({
    userSupport: {
      title: "This Month",
      value: "ThisMonth",
    },
    uniqueUsersByCountry: {
      title: "This Month",
      value: "ThisMonth",
    },
  });

  const filterOptions = [
    {
      title: "All",
      value: "All",
    },
    {
      title: "This Year",
      value: "ThisYear",
    },
    {
      title: "This Month",
      value: "ThisMonth",
    },
    {
      title: "This Week",
      value: "ThisWeek",
    },
    {
      title: "Last 2 days",
      value: "2days",
    },
  ];

  useEffect(() => {
    getUserStatus();
    getUsersCounts();
    getUsersSignUpWith();
    getActiveUsersByCountry();
    getUniqueUsersByCountry(selectedFilter?.userSupport?.value);
    getSupportRequestCounts(selectedFilter?.uniqueUsersByCountry?.value);
    getUniqueAccessPerMonth();
  }, []);

  const getUsersCounts = async () => {
    try {
      const res = await GetrequestCall(
        `${GET_USERS_RECORDS}/1/All`,
        loginData?.token
      );
      const data = res?.data?.data ?? {};
      setUserCounts((pre) => ({
        ...pre,
        totalNoOfUsers: data?.TotalUsers,
        lastFiveDaysAccessUsers: data?.LastFiveDaysAccessUsers,
        lastSixtyDaysNewUsers: data?.LastSixtyDaysNewUsers,
      }));
    } catch (error) {
      console.log("Error while getting data :: ", error);
    }
  };

  const getUserStatus = async () => {
    try {
      const res = await GetrequestCall(
        `${GET_USERS_RECORDS}/2/All`,
        loginData?.token
      );
      const data = res?.data?.data ?? {};
      setUserStatus(
        calculateUsersStatus(
          data?.TotalUsers,
          data?.ActiveUsers,
          data?.InactiveUsers
        )
      );
    } catch (error) {
      console.log("Error while getting data :: ", error);
    }
  };

  const getUsersSignUpWith = async () => {
    try {
      const res = await GetrequestCall(
        `${GET_USERS_RECORDS}/3/All`,
        loginData?.token
      );
      const data = res?.data?.data ?? {};
      const usersBySignUpWithData = countUsersBySignUpWith(
        data?.TotalUsers,
        data?.SignUpWith_APPLE,
        data?.SignUpWith_FACEBOOK,
        data?.SignUpWith_GOOGLE,
        data?.SignUpWith_PASSWORD,
        data?.SignUpWith_Unknown
      );
      setSignUpWith(usersBySignUpWithData);
    } catch (error) {
      console.log("Error while getting data :: ", error);
    }
  };

  const getUniqueAccessPerMonth = async () => {
    try {
      const res = await GetrequestCall(
        `${GET_USERS_RECORDS}/4/All`,
        loginData?.token
      );
      const data = res?.data?.data ?? [];
      setUniqueAccessPerMonth(data);
    } catch (error) {
      console.log("Error while getting data :: ", error);
    }
  };

  const getActiveUsersByCountry = async () => {
    try {
      const res = await GetrequestCall(
        `${GET_USERS_RECORDS}/5/All`,
        loginData?.token
      );
      const data = res?.data?.data?.ActiveUsersByCountry ?? [];
      setActiveUserListByCountry(data);
    } catch (error) {
      console.log("Error while getting data :: ", error);
    }
  };

  const getUniqueUsersByCountry = async (filterValue) => {
    try {
      const res = await GetrequestCall(
        `${GET_USERS_RECORDS}/7/${filterValue}`,
        loginData?.token
      );
      const data = res?.data?.data?.ActiveUsersByCountry ?? [];
      console.log("RES____", res?.data?.data);
      setUniqueUserListByCountry(data);
    } catch (error) {
      console.log("Error while getting data :: ", error);
    }
  };

  const getSupportRequestCounts = async (filterValue) => {
    try {
      const res = await GetrequestCall(
        `${GET_USERS_RECORDS}/6/${filterValue}`,
        loginData?.token
      );
      const data = res?.data?.data?.SupportRequestsCount ?? 0;
      setSupportRequestCounts(data);
    } catch (error) {
      console.log("Error while getting data :: ", error);
    }
  };

  const countUsersBySignUpWith = (
    totalCount,
    apple,
    facebook,
    google,
    password,
    unknown
  ) => {
    let data = [
      {
        title: "Password",
        count: password,
        percentage:
          totalCount > 0 ? ((password / totalCount) * 100).toFixed(2) : 0,
      },
      {
        title: "Google",
        count: google,
        percentage:
          totalCount > 0 ? ((google / totalCount) * 100).toFixed(2) : 0,
      },
      {
        title: "Apple",
        count: apple,
        percentage:
          totalCount > 0 ? ((apple / totalCount) * 100).toFixed(2) : 0,
      },
      {
        title: "FaceBook",
        count: facebook,
        percentage:
          totalCount > 0 ? ((facebook / totalCount) * 100).toFixed(2) : 0,
      },
      {
        title: "Unknown",
        count: unknown,
        percentage:
          totalCount > 0 ? ((unknown / totalCount) * 100).toFixed(2) : 0,
      },
    ];
    return data;
  };

  const calculateUsersStatus = (totalCount, activeCount, inactiveCount) => {
    const activePercentage = ((activeCount / totalCount) * 100).toFixed(2); // Format to 2 decimal places
    const inactivePercentage = ((inactiveCount / totalCount) * 100).toFixed(2); // Format to 2 decimal places

    return [
      { title: "Active", percentage: parseFloat(activePercentage) },
      { title: "Inactive", percentage: parseFloat(inactivePercentage) },
    ];
  };

  const signUpWithlabelContent = (e) => {
    return `${e.category} (${e.value}%)`;
  };

  const userStatuslabelContent = (e) => {
    return `${e.category}: ${e.value}%`;
  };

  const activeUserByCountrylabelContent = (e) => {
    return `${e.category}: ${e.value}%`;
  };

  const getMonthNameAndYear = (month, year) => {
    const months = [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec",
    ];
    return months[month] + " " + year;
  };

  const onTotalUserClick = () => {
    navigate("/user/accounts/users");
  };

  const onNewUsersLast60daysClick = () => {
    navigate("/user/accounts/users", {
      state: {
        from: "dashboard",
        apiType: "last60days",
      },
    });
  };

  const onNewUsersLastAccessClick = () => {
    navigate("/user/accounts/users", {
      state: {
        from: "dashboard",
        apiType: "lastAccess",
      },
    });
  };

  const handleUserStatusClick = (event) => {
    let filter = event?.category ?? null;
    if (filter) {
      navigate(`/user/accounts/users`, {
        state: {
          from: "dashboard",
          filter,
        },
      });
    }
  };

  const handleSignUpWithClick = (event) => {
    let filter = event?.category ?? null;
    if (filter) {
      navigate(`/user/accounts/users`, {
        state: {
          from: "dashboard",
          filter,
        },
      });
    }
  };

  const handleActiveUserByCountryClick = (event) => {
    let filter = event?.category ?? null;
    if (filter) {
      navigate(`/user/accounts/users`, {
        state: {
          from: "dashboard",
          filter: "country",
          filterOptions: [{ active: true, country: filter }]
        },
      });
    }
  }

  const handleUniqueLoginByCountryClick = (event) => {
    let filter = event?.category ?? null;
    if (filter) {
      navigate(`/user/accounts/users`, {
        state: {
          from: "dashboard",
          filter: "country",
          filterOptions: [{ country: filter }],
          apiType: "unique_login_by_country"
        },
      });
    }
  }


  const handleUniqueAccessPerMonthClick = (event) => {
    const months = [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec",
    ];

    let filter = event?.category ?? null;
    const arrayFilter = filter.split(" ");
    let getMonthIndex = months.indexOf(arrayFilter?.[0]);
    let mounthNumber = Number(getMonthIndex ?? 0) + 1;

    if (filter) {
      navigate(`/user/accounts/users`, {
        state: {
          from: "dashboard",
          apiType: "lastMonthYear",
          apiUrl: arrayFilter?.[1] + "/" + mounthNumber,
        },
      });
    }
  };


  const handleFilterChange = (key, filterValue) => {
    setSelectedFilter((preValue) => ({ ...preValue, [key]: filterValue }));
    if (key === "userSupport") {
      getSupportRequestCounts(filterValue?.value);
    } else if (key === "uniqueUsersByCountry") {
      getUniqueUsersByCountry(filterValue?.value);
    } else {
      // getUserActivity(filterValue?.value);
    }
  };

  const onUserSupportClick = () => {
    navigate(`/user/accounts/users-support`);
  };

  const renderBarChat = () => {
    const categories = uniqueAccessPerMonth?.map((item) =>
      getMonthNameAndYear(item?.Month - 1, item?.Year)
    );
    const data = uniqueAccessPerMonth?.map((item) => item.UserCount);

    return (
      <Chart onSeriesClick={(e) => handleUniqueAccessPerMonthClick(e)}>
        <ChartTitle text="Unique Access Per Month" />
        <ChartCategoryAxis>
          <ChartCategoryAxisItem categories={categories}>
            <ChartCategoryAxisTitle text="Last Date Logged In" />
          </ChartCategoryAxisItem>
        </ChartCategoryAxis>
        <ChartSeries>
          <ChartSeriesItem type="line" data={data} />
        </ChartSeries>
      </Chart>
    );
  };

  return (
    <div className="dashboard_container">
      <div className="card-container count-container">
        <Card className="custom-card" onClick={onTotalUserClick}>
          <CardBody className="count-body">
            <div>
              <h3 className="card-heading" style={{ marginTop: "0px" }}>
                Total no. of users
              </h3>
              <span className="card-subheading">
                {userCounts?.totalNoOfUsers}
              </span>
            </div>
            <div>
              <img className="count-img" src={totalUsers}></img>
            </div>
          </CardBody>
        </Card>

        <Card className="custom-card" onClick={onNewUsersLast60daysClick}>
          <CardBody className="count-body">
            <div>
              <h3 className="card-heading" style={{ marginTop: "0px" }}>
                New users in the last 60 days
              </h3>
              <span className="card-subheading">
                {userCounts?.lastSixtyDaysNewUsers}
              </span>
            </div>
            <div>
              <img className="count-img" src={newEmployee}></img>
            </div>
          </CardBody>
        </Card>

        <Card className="custom-card" onClick={onNewUsersLastAccessClick}>
          <CardBody className="count-body">
            <div>
              <h3 className="card-heading" style={{ marginTop: "0px" }}>
                Unique access in the last 5 days
              </h3>
              <span className="card-subheading">
                {userCounts?.lastFiveDaysAccessUsers}
              </span>
            </div>
            <div>
              <img className="count-img" src={uniqueAccess}></img>
            </div>
          </CardBody>
        </Card>

        <Card className="custom-card" onClick={onUserSupportClick}>
          <CardBody className="count-body">
            <div>
              <h3 className="card-heading" style={{ marginTop: "0px" }}>
                User Support in{" "}
                {selectedFilter?.userSupport?.value === "2days" && "the"}{" "}
                {selectedFilter?.userSupport?.title?.toLocaleLowerCase()}
                <span className="card-title-desc"></span>
              </h3>
              <span className="card-subheading">{supportRequestCounts}</span>
            </div>
            <div>
              <img className="count-img" src={uniqueAccess}></img>
            </div>
            <div
              className="dropdown_user_support"
              style={{ alignSelf: "baseline" }}>
              <IconComponent iconName={"ThreeDots"} size={15} />

              <div class="dropdown-content_user_support">
                {filterOptions?.map((item, index) => (
                  <p
                    key={index}
                    style={{
                      cursor: "pointer",
                      color:
                        item?.value === selectedFilter?.userSupport?.value
                          ? "#2d6ea3"
                          : "black",
                    }}
                    onClick={(e) => {
                      e.stopPropagation();
                      handleFilterChange("userSupport", item);
                    }}>
                    <div className="dropdown-title">{item?.title}</div>
                  </p>
                ))}
              </div>
            </div>
          </CardBody>
        </Card>
      </div>

      <div className="pie_chart_container">
        <div className="pie_chart_details">
          <Chart onSeriesClick={(e) => handleUserStatusClick(e)}>
            <ChartTitle text="Users Status" />
            <ChartSeries>
              <ChartSeriesItem
                type="donut"
                data={userStatus}
                categoryField="title"
                field="percentage">
                <ChartSeriesLabels
                  position="outsideEnd"
                  color="black"
                  background="none"
                  content={userStatuslabelContent}
                />
              </ChartSeriesItem>
            </ChartSeries>
            <ChartLegend visible={true} />
          </Chart>
        </div>
        <div className="pie_chart_details">
          <Chart onSeriesClick={(e) => handleSignUpWithClick(e)}>
            <ChartTitle text="Sign Up With" />
            {/* <ChartLegend position="bottom" /> */}
            <ChartSeries>
              <ChartSeriesItem
                type="pie"
                data={signUpWith}
                field="percentage"
                categoryField="title"
                labels={{
                  visible: true,
                  content: signUpWithlabelContent,
                }}
              />
            </ChartSeries>
          </Chart>
        </div>
      </div>

      <div className="pie_chart_container">
        <div className="pie_chart_details">
          <Chart
            onSeriesClick={(e) => handleActiveUserByCountryClick(e)}
          // style={{ height: 300 }}
          >
            <ChartTitle text="Active User by Country" />
            <ChartSeries>
              <ChartSeriesItem
                type="donut"
                data={activeUserListByCountry}
                categoryField="Country"
                field="PercentageOfTotalActiveUsers">
                <ChartSeriesLabels
                  position="outsideEnd"
                  color="black"
                  background="none"
                  content={activeUserByCountrylabelContent}
                />
              </ChartSeriesItem>
            </ChartSeries>
            <ChartLegend visible={true} position="bottom" />
          </Chart>
        </div>

        <div className="pie_chart_details" style={{ display: "flex" }}>
          <Chart
            onSeriesClick={(e) => handleUniqueLoginByCountryClick(e)}
            style={{ width: "95%" }}>
            <ChartTitle
              text={`Unique Login by Country in 
              ${selectedFilter?.uniqueUsersByCountry?.value === "2days"
                  ? "the"
                  : " "
                }
                ${selectedFilter?.uniqueUsersByCountry?.title}`}
            // text={<>
            //   <div>HEllo </div>
            //   <button>HEllo btn </button>
            // </>}
            />
            <ChartLegend position="bottom" />
            <ChartSeries>
              <ChartSeriesItem
                type="pie"
                data={uniqueUserListByCountry}
                field="PercentageOfTotalActiveUsers"
                categoryField="Country"
                labels={{
                  visible: true,
                  content: signUpWithlabelContent,
                }}
              />
            </ChartSeries>
          </Chart>

          <div
            className="dropdown_user_support"
            style={{ alignSelf: "baseline" }}>
            <IconComponent iconName={"ThreeDots"} size={15} />

            <div class="dropdown-content_user_support">
              {filterOptions?.map((item, index) => (
                <p
                  key={index}
                  style={{
                    cursor: "pointer",
                    color:
                      item?.value === selectedFilter?.uniqueUsersByCountry?.value
                        ? "#2d6ea3"
                        : "black",
                  }}
                  onClick={(e) => {
                    e.stopPropagation();
                    handleFilterChange("uniqueUsersByCountry", item);
                  }}>
                  <div className="dropdown-title">{item?.title}</div>
                </p>
              ))}
            </div>
          </div>
        </div>
      </div>

      <div className="bar_chart_container">{renderBarChat()}</div>
    </div>
  );
};

export default Dashboard;
