import {
  Card,
  CardBody,
  GridLayout,
  GridLayoutItem,
} from "@progress/kendo-react-layout";
import {
  FETCH_USERS,
  GET_USERS_RECORDS,
  GET_USER_RECORDS,
} from "../../constant/Apipath";
import { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import "../Reports.css";
import { IconComponent } from "../../common/Icon";
import { Grid, GridColumn } from "@progress/kendo-react-grid";
import { GetrequestCall } from "./../../apicall/GetRequest";
import { Loader } from "@progress/kendo-react-indicators";
import img from "../../assets/images/profile-logo.jpg";
import { PostRequestCall } from "../../apicall/PostRequest";
import questionMark from "../../assets/images/question-ico.png";
import questionAnswerMark from "../../assets/images/question-answer-ico.png";
import builderBuck from "../../assets/images/builder-buck-ico.png";
import { Button } from "@progress/kendo-react-buttons";
import KendoButton from "../../common/KendoButton";

function ViewUserReport() {
  const hoverTimeoutRef = useRef(null);
  const { id } = useParams();
  const { loginData } = useSelector((state) => state.main);
  const navigate = useNavigate();

  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [DropdownOpen, setDropdownOpen] = useState(false);

  const [userDetails, setUserdetails] = useState({
    firstName: "",
    lastName: "",
    birthday: "---",
    dateCreated: null,
    lastDateLoggedIn: null,
  });

  const [userDescription, setUserDescription] = useState({});

  const [contribution, setContribution] = useState();

  const [rewards, setRewards] = useState({
    questions: 0,
    questionAnswers: 0,
    questionsToAnswer: 0,
    totalBuilderBucks: 0,
  });
  const [activity, setActivity] = useState([]);
  const [filter, setFilter] = useState({
    contribution: {
      title: "All",
      value: "All",
    },
    activity: {
      title: "All",
      value: "All",
    },
  });
  const [sort, setSort] = useState([
    { field: "lastModifiedDate", dir: "desc" },
  ]);
  // const [sort, setSort] = useState([]);

  const filterOptions = [
    {
      title: "All",
      value: "All",
    },
    {
      title: "This Year",
      value: "ThisYear",
    },
    {
      title: "This Month",
      value: "ThisMonth",
    },
    {
      title: "This Week",
      value: "ThisWeek",
    },
  ];

  useEffect(() => {
    getUserDetails();
    getUserDescription();
    getUserRewardsCounts();
    getUserContributionCounts(filter?.contribution?.value);
    getUserActivity(filter?.activity?.value, 2);
  }, []);

  const formatDate = (dateString) => {
    if (dateString) {
      const options = { month: "short", day: "numeric", year: "numeric" };
      const date = new Date(dateString);
      return date.toLocaleDateString("en-US", options);
    } else {
      return null;
    }
  };

  const getUserDetails = async () => {
    try {
      let obj = { SearchList: [{ Id: id }] };
      const res = await PostRequestCall(FETCH_USERS, obj, loginData?.token);
      const userDetailsData = res?.data?.data?.[0] ?? {};
      setUserdetails({
        firstName: userDetailsData?.firstName,
        lastName: userDetailsData?.lastName,
        pictureUrl: userDetailsData?.pictureUrl,
        birthday: userDetailsData?.birthday
          ? formatDate(userDetailsData?.birthday)
          : "---",
        lastDateLoggedIn: userDetailsData?.lastDateLoggedIn
          ? formatDate(userDetailsData?.lastDateLoggedIn)
          : "---",
        dateCreated: formatDate(userDetailsData?.dateCreated),
      });
    } catch (error) {
      console.log("Error while getting data:: ", error);
    }
  };

  const getUserDescription = async () => {
    try {
      const res = await GetrequestCall(
        `${GET_USER_RECORDS}/${id}/9`,
        loginData?.token
      );
      const resData = res?.data?.data ?? {};

      setUserDescription({
        CurrentTrade: resData?.CurrentTrade !== "" ? resData?.CurrentTrade : null,
        CurrentCompany: resData?.CurrentCompany !== "" ? resData?.CurrentCompany : null,
        CurrentSite: resData?.CurrentSite !== "" ? resData?.CurrentSite : null,
        CurrentProject: resData?.CurrentProject !== "" ? resData?.CurrentProject : null,
      });
    } catch (error) {
      console.log("Error while getting data:: ", error);
    }
  }

  const getUserRewardsCounts = async () => {
    try {
      const res = await GetrequestCall(
        `${GET_USER_RECORDS}/${id}/7/7`,
        loginData?.token
      );
      const rewardsData = res?.data?.data?.[0] ?? {};
      setRewards({
        questions: rewardsData?.Questions ?? 0,
        questionsToAnswer: rewardsData?.QuestiontoAnswers ?? 0,
        totalBuilderBucks: rewardsData?.TotalBuilderBucks ?? 0,
      });
    } catch (error) {
      console.log("Error while getting data:: ", error);
    }
  };

  const getUserContributionCounts = async (filterType) => {
    try {
      const res = await GetrequestCall(
        `${GET_USER_RECORDS}/${id}/5/${filterType}`,
        loginData?.token
      );
      const contributionData = res?.data?.data ?? [];
      setContribution(contributionData?.sort((a, b) => b?.Count - a?.Count));
    } catch (error) {
      console.log("Error while getting data:: ", error);
    }
  };

  const getUserActivity = async (filterType, order) => {
    try {
      const res = await GetrequestCall(
        `${GET_USER_RECORDS}/${id}/6/${filterType}/${order}`,
        loginData?.token
      );
      const data = res?.data?.data ?? [];
      console.log("Activity ===>", data);
      setActivity(
        data?.map((item) => ({
          moduleName: item.ModuleName,
          count: item.ModuleCount,
          lastModifiedDate: formatDate(item.LastDateModified),
        }))
      );
    } catch (error) {
      console.log("Error while getting data:: ", error);
    }
  };

  const handleFilterChange = (key, filterValue) => {
    setFilter((preValue) => ({ ...preValue, [key]: filterValue }));
    if (key === "contribution") {
      getUserContributionCounts(filterValue?.value);
    } else {
      getUserActivity(filterValue?.value, sort?.[0]?.dir === "desc" ? 2 : 1);
    }
    setDropdownOpen(false);
    setIsDropdownOpen(false);
  };

  console.log("soeryt ===>", sort);

  const handleMouseEnter = () => {
    if (hoverTimeoutRef.current) {
      clearTimeout(hoverTimeoutRef.current);
      hoverTimeoutRef.current = null;
    }
    setIsDropdownOpen(true);
  };
  const handleMouseEnter2 = () => {
    if (hoverTimeoutRef.current) {
      clearTimeout(hoverTimeoutRef.current);
      hoverTimeoutRef.current = null;
    }
    setDropdownOpen(true);
  };

  const handleMouseLeave = () => {
    hoverTimeoutRef.current = setTimeout(() => {
      setIsDropdownOpen(false);
    }, 200);
  };
  const handleMouseLeave2 = () => {
    hoverTimeoutRef.current = setTimeout(() => {
      setDropdownOpen(false);
    }, 200);
  };

  const handleClick = () => {
    setIsDropdownOpen((prevState) => !prevState);
  };
  const handleClick2 = () => {
    setDropdownOpen((prevState) => !prevState);
  };
  const navigateToUserProfile = () => {
    navigate(`/user/accounts/users/${id}/edit-record`);
  };
  const navigateToUserQuestions = () => {
    navigate(`/user/accounts/users/${id}/user-quizzes`);
  };

  const navigateToUserQuestionsToAnswers = () => {
    navigate(`/user/accounts/users/${id}/user-quizzes`, {
      state: {
        from: "report",
        filter: "question_to_answers",
      },
    });
  };

  // const navigateToUserQuestionsAnswered = () => {
  //   navigate(`/user/accounts/users/${id}/user-quizzes`, {
  //     state: {
  //       from: "report",
  //       filter: "question_answered",
  //     },
  //   });
  // };
  // const navigateToUserPost = () => {
  //   navigate(`/user/accounts/users/${id}/user-post`, {
  //     state: {
  //       from: "report",
  //       filter: "user_post",
  //     },
  //   });
  // };
  // const navigateToUserPostComments = () => {
  //   navigate(`/user/accounts/users/${id}/user-post-comments`, {
  //     state: {
  //       from: "report",
  //       filter: "user_post_comments",
  //     },
  //   });
  // };

  const navigateToBuilderBugs = () => {
    // navigate(`/user/accounts/users/${id}/user-quizzes`);
    alert("Is In Process");
  };

  const handleSortChange = (event) => {
    const sorted = event.sort.filter((s) => s.field === "lastModifiedDate");
    setSort(sorted);
    getUserActivity(
      filter?.activity?.value,
      sorted?.[0]?.dir === "desc" ? 2 : 1
    );
  };

  const onContributionClick = (item) => {
    let title = item?.Title?.trim();
    if (title === "Sites") {
      return navigate(`/user/accounts/users/${id}/user-sites`);
    }
    if (title === "Projects") {
      return navigate(`/user/accounts/users/${id}/user-site-projects`);
    }
    if (title === "Companies") {
      return alert("Is in process");
    }
    if (title === "Products") {
      return alert("Is in process");
    }
    if (title === "Experiences") {
      return navigate(`/user/accounts/users/${id}/user-work-history`);
    }
    if (title === "Questions Answered") {
      return navigate(`/user/accounts/users/${id}/user-quizzes`, {
        state: {
          from: "report",
          filter: "question_answered",
        },
      });
    }
    if (title === "Nr of Posts") {
      return navigate(`/user/accounts/users/${id}/user-post`, {
        state: {
          from: "report",
          filter: "user_post",
        },
      });
    }
    if (title === "Nr of Post Comments") {
      return navigate(`/user/accounts/users/${id}/user-post-comments`, {
        state: {
          from: "report",
          filter: "user_post_comments",
        },
      });
    }
    if (title === "Nr of Support Requests") {
      return navigate(`/user/accounts/users/${id}/user-support`);
    }

    return alert("Is in process");
  };

  return (
    <div>
      <div className="report-section">
        <GridLayout
          rows={[
            {
              height: "auto",
            },
          ]}
          cols={[
            {
              width: "35%",
            },
            {
              width: "64%",
            },
          ]}
          gap={{
            rows: 15,
            cols: 15,
          }}
          style={{
            // marginTop: "15px",
            height: "auto",
            borderRadius: "10px",
          }}>
          <GridLayoutItem col={1} row={1} className="form-inner">
            <Card
              className="user-detail box-shadow"
              onClick={navigateToUserProfile}>
              <div className="profile-container">
                <img
                  src={userDetails?.pictureUrl}
                  alt="Profile"
                  onError={(e) => {
                    e.target.src = img;
                  }}
                  className="profile-image"
                />
                <h4 className="input-value">
                  {userDetails?.firstName} {userDetails?.lastName}
                </h4>
              </div>
              <div className="profile-info">
                <div style={{ width: '50%' }}>
                  <div className="user-id">
                    <h4>User ID: </h4>
                    <p>{id}</p>
                  </div>
                  <div className="user-id">
                    <h4>Birthday: </h4>
                    <p>{userDetails?.birthday}</p>
                  </div>
                  <div >
                    <h4 className="ud_title">Trade: </h4>
                    <p className="ud_value">{userDescription?.CurrentTrade ?? "---"}</p>
                  </div>
                  <div>
                    <h4 className="ud_title">Current Company: </h4>
                    <p className="ud_value">{userDescription?.CurrentCompany ?? "---"}</p>
                  </div>
                </div>
                <div style={{ width: '50%' }}>
                  <div className="user-id">
                    <h4>Last Logged in: </h4>
                    <p>{userDetails?.lastDateLoggedIn}</p>
                  </div>
                  <div className="user-id">
                    <h4>Date Created: </h4>
                    <p>{userDetails?.dateCreated}</p>
                  </div>
                  <div >
                    <h4 className="ud_title">Current Site: </h4>
                    <p className="ud_value">{userDescription?.CurrentSite ?? "---"}</p>
                  </div>
                  <div >
                    <h4 className="ud_title">Current Project: </h4>
                    <p className="ud_value">{userDescription?.CurrentProject ?? "---"}</p>
                  </div>
                </div>
              </div>
            </Card>
            <Card
              className="k-mt-3 report-data"
              style={{ borderRadius: "10px" }}>
              <div className="report-header">
                <h4 className="report-title">
                  <span style={{ fontSize: "18px", fontWeight: "bold" }}>
                    {" "}
                    Contribution{" "}
                  </span>
                  <span style={{ color: "#2d6ea3", marginTop: "4px" }}>
                    ({filter?.contribution?.title})
                  </span>
                </h4>

                <div
                  onMouseEnter={handleMouseEnter2}
                  onMouseLeave={handleMouseLeave2}
                  onClick={handleClick2}>
                  <IconComponent iconName={"ThreeDots"} size={15} />
                </div>
                {DropdownOpen && (
                  <div
                    className="dropdown-menu"
                    onMouseEnter={handleMouseEnter2}
                    onMouseLeave={handleMouseLeave2}>
                    <div className="dropdown-item">
                      {filterOptions?.map((item, index) => (
                        <div
                          key={index}
                          style={{
                            cursor: "pointer",
                            color:
                              item?.value === filter?.contribution?.value
                                ? "#2d6ea3"
                                : "black",
                          }}
                          onClick={() =>
                            handleFilterChange("contribution", item)
                          }>
                          <div className="dropdown-title">{item?.title}</div>
                        </div>
                      ))}
                    </div>
                  </div>
                )}
              </div>
              <div className="report-data-constribution">
                <ul className="k-p-0" style={{ marginTop: "5px" }}>
                  {contribution?.map((item, index) => (
                    <li
                      key={index}
                      className="Answer k-d-flex k-justify-content-between k-align-items-center report-list k-pt-2"
                      style={{ paddingBottom: "5px", cursor: "pointer" }}
                      onClick={() => {
                        onContributionClick(item);
                      }}>
                      <span className="gray-color">{item?.Title?.trim()}</span>
                      <div className="info k-d-flex k-justify-content-center k-align-items-center">
                        <span className="theme-color fw-semibold">
                          {item?.Count}
                        </span>
                      </div>
                    </li>
                  ))}
                </ul>
              </div>
            </Card>
          </GridLayoutItem>
          <GridLayoutItem col={2} row={1} className="form-inner">
            <div className="card-container count-container">
              <Card className="custom-card" onClick={navigateToUserQuestions}>
                <CardBody className="count-body">
                  <div>
                    <h3 className="card-heading">Questions</h3>
                    <span className="card-subheading">{rewards?.questions}</span>
                  </div>
                  <div>
                    <img className="count-img" src={questionMark}></img>
                  </div>
                </CardBody>
              </Card>

              <Card
                className="custom-card"
                onClick={navigateToUserQuestionsToAnswers}>
                <CardBody className="count-body">
                  <div>
                    <h3 className="card-heading">Questions to Answer</h3>
                    <span className="card-subheading">
                      {rewards?.questionsToAnswer}
                    </span>
                  </div>
                  <div>
                    <img className="count-img" src={questionAnswerMark}></img>
                  </div>
                </CardBody>
              </Card>

              <Card className="custom-card" onClick={navigateToBuilderBugs}>
                <CardBody className="count-body">
                  <div>
                    <h3 className="card-heading">Builder Bucks</h3>
                    <span className="card-subheading">
                      {rewards?.totalBuilderBucks}
                    </span>
                  </div>
                  <div>
                    <img className="count-img" src={builderBuck}></img>
                  </div>
                </CardBody>
              </Card>
            </div>
            <Card
              style={{ height: "450px", borderRadius: "10px" }}
              className="report-data k-mt-3">
              <div className="report-header">
                <h4 className="report-title">
                  <span style={{ fontSize: "18px", fontWeight: "bold" }}>
                    Activity{" "}
                  </span>
                  <span style={{ color: "#2d6ea3", marginTop: "3px" }}>
                    ({filter?.activity?.title})
                  </span>
                </h4>

                <div
                  onMouseEnter={handleMouseEnter}
                  onMouseLeave={handleMouseLeave}
                  onClick={handleClick}>
                  <IconComponent iconName={"ThreeDots"} size={15} />
                </div>
                {isDropdownOpen && (
                  <div
                    className="dropdown-menu"
                    onMouseEnter={handleMouseEnter}
                    onMouseLeave={handleMouseLeave}>
                    <div className="dropdown-item">
                      {filterOptions?.map((item, index) => (
                        <div
                          key={index}
                          style={{
                            cursor: "pointer",
                            color:
                              item?.value === filter?.activity?.value
                                ? "blue"
                                : "black",
                          }}
                          onClick={() => handleFilterChange("activity", item)}>
                          <div className="dropdown-title">{item?.title}</div>
                        </div>
                      ))}
                    </div>
                  </div>
                )}
              </div>
              <Grid
                data={activity}
                style={{ height: "390px" }}
                onSortChange={handleSortChange}
                sortable={{
                  allowUnsort: true,
                  mode: "multiple",
                }}
                sort={sort}>
                <GridColumn
                  field="moduleName"
                  title="Module Name"
                  width="auto"
                />
                <GridColumn field="count" title="Count" width="auto" />
                <GridColumn
                  field="lastModifiedDate"
                  title="Last Modified Date"
                  width="auto"
                />
              </Grid>
            </Card>
            <div style={{ marginTop: '20px', textAlign: 'end', marginRight: '40px' }}>
              <KendoButton
                iconClass=""
                size={16}
                themeColor={"primary"}
                buttonName="Quizzes"
                onClick={() => {
                  navigate(`/quizzes/${id}`)
                }}
                style={{ padding: "8px" }}
              />
            </div>
          </GridLayoutItem>
        </GridLayout>
      </div>
    </div>
  );
}

export default ViewUserReport;
