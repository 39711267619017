import React, { useEffect, useState } from "react";
import { AutoComplete, DropDownList } from "@progress/kendo-react-dropdowns";
import { useNavigate, useParams } from "react-router-dom";
import {
  DELETE_USER_EMAIL,
  FETCH_USER_EMAILS,
  FetchRecordStatuses,
  FetchUsers2,
  SAVE_USER_EMAIL,
} from "../../constant/Apipath";
import { PostRequestCall } from "../../apicall/PostRequest";
import { Input } from "@progress/kendo-react-inputs";
import { useSelector } from "react-redux";
import { Label } from "@progress/kendo-react-labels";
import { GridLayout, GridLayoutItem } from "@progress/kendo-react-layout";
import KendoButton from "../../common/KendoButton";
import { toast } from "react-toastify";
import { Loader } from "@progress/kendo-react-indicators";

const AddNewUserEmail = () => {
  const navigate = useNavigate();

  const { loginData } = useSelector((state) => state.main);
  const { dataId, subMenu, userId } = useParams();

  const [email, setEmail] = useState({
    userName: "",
    userId: 0,
    recordStatus: "New",
    emailType: "",
    email: "",
    recordStatusId: 1,
  });
  const [recordStatusList, setRecordStatusList] = useState([]);
  const [value, setValue] = useState("");
  const [options, setOptions] = useState([]);
  const [showLoader, setShowLoader] = useState(false);

  const emailTypeOptions = [
    {
      value: "P",
      text: "Personal",
    },
    {
      value: "W",
      text: "Work",
    },
    {
      value: "O",
      text: "Other",
    },
  ];

  useEffect(() => {
    if (dataId !== "add-email" || subMenu) {
      loademail();
    }
    if (userId) {
      getUserDetails();
    }
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const srchList = [{ Name: value }];
        const data = JSON.stringify({
          PageNr: 1,
          NrOfRecPerPage: 100,
          FullSearch: value,
          UserId: loginData.userId,
          SearchList: srchList,
          IncludeRecordNr: true,
        });

        const result = await PostRequestCall(
          FetchUsers2,
          data,
          loginData?.token
        );
        const users = result.data.data || [];

        const userOptions = users.map((user) => ({
          value: user.id,
          label: `${user.firstName} ${user.lastName}`,
        }));

        setOptions(userOptions);
      } catch (error) {
        console.error("Error fetching users:", error);
      }
    };

    if (value.trim() !== "") {
      fetchData();
    } else {
      setOptions([]);
    }
  }, [value]);

  const loademail = async () => {
    if (dataId !== "add-email") {
      var data = JSON.stringify({
        PageNr: 1,
        NrOfRecPerPage: 20,
        FullSearch: "",
        UserId: loginData.userId,
        SearchList: [
          {
            Id: dataId,
          },
        ],
      });

      const result = await PostRequestCall(
        FETCH_USER_EMAILS,
        data,
        loginData?.token
      );

      const resulData = result?.data?.data?.[0] ?? null;

      if (resulData) {
        setEmail((prevData) => ({
          ...prevData,
          userName: resulData?.userName,
          userId: resulData?.userId,
          recordStatus: resulData?.recordStatus,
          emailType: resulData?.emailType,
          email: resulData?.email,
          recordStatusId: resulData?.recordStatusId,
        }));
      }
    }

    var data = JSON.stringify({
      PageNr: 1,
      NrOfRecPerPage: 20,
      FullSearch: "",
      UserId: loginData.userId,
      SortList: [
        {
          FieldName: "Id",
          Direction: "ASC",
        },
      ],

      IncludeRecordNr: true,
    });
    try {
      const recordStatusesRes = await PostRequestCall(
        FetchRecordStatuses,
        data,
        loginData?.token
      );
      let recordStatusesData = recordStatusesRes?.data?.data?.map((record) => ({
        id: record?.id,
        text: record?.name,
      }));
      setRecordStatusList(recordStatusesData ?? []);
    } catch (error) { }
  };

  const getUserDetails = async () => {
    try {
      const data = JSON.stringify({
        PageNr: 1,
        NrOfRecPerPage: 100,
        FullSearch: "",
        UserId: loginData.userId,
        SearchList: [{ id: userId }],
        IncludeRecordNr: true,
      });

      const result = await PostRequestCall(FetchUsers2, data, loginData?.token);
      setEmail((prevData) => ({
        ...prevData,
        userName: result?.data?.data?.[0]?.name,
        userId,
      }));
    } catch (error) {
      console.log("Error while getting user details :: ", error);
    }
  };

  const onInputChange = (e) => {
    setEmail({ ...email, [e.target.name]: e.target.value });
  };

  const handleChange = (event) => {
    setValue(event.target.value);
  };

  const goBackToUserAuthPage = () => {
    navigate(`/admin/users/${userId}/${subMenu}`);
  };

  const cancelClick = () => {
    if (subMenu) {
      return goBackToUserAuthPage();
    }
    navigate("/users/user-emails");
  };

  const onSubmit = async (e) => {
    e.preventDefault();

    const firstOptionValue = options.length > 0 ? options[0].value : null;

    if (email?.userId === 0) {
      if (!firstOptionValue) {
        return toast.error("Please select a user", {
          position: "top-right",
        });
      }
    }

    if (email.email === "" || !/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email.email)) {
      return toast.error("Please enter valid email addresses", {
        position: "top-right",
      });
    }
    const newEmail = [
      {
        Id: dataId === "add-email" ? 0 : dataId,
        UserId: email.userId || firstOptionValue,
        EmailType: email?.emailType || "O",
        RecordStatusId: email?.recordStatusId || 1,
        Email: email.email || "",
        ModifiedBy: loginData.userId,
      },
    ];

    try {
      setShowLoader(true);
      const result = await PostRequestCall(
        SAVE_USER_EMAIL,
        newEmail,
        loginData?.token
      );
      if (result.data.status === false) {
        setShowLoader(false);
        toast.error(
          result?.data?.data?.[0].message ??
          "Something went wrong. Please try again later.",
          {
            position: "top-right",
          }
        );
      } else {
        setShowLoader(false);
        toast.success(`Data ${dataId !== "add-email" ? "updated" : "added"} successfully`, {
          position: "top-right",
        });
        if (subMenu) {
          return goBackToUserAuthPage();
        }
        window.history.back();
      }
    } catch (error) {
      setShowLoader(false);
      toast.error("Something went wrong. Please try again later.", {
        position: "top-right",
      });
      console.error("Error saving email:", error);
    }
  };

  const onDeleteClick = async (e) => {
    try {
      e.stopPropagation();
      if (window.confirm("Are you sure you wish to delete this record?")) {
        setShowLoader(true);
        const deleteObj = [
          {
            Id: Number(dataId),
            ModifiedBy: loginData?.userId,
          },
        ];
        return PostRequestCall(DELETE_USER_EMAIL, deleteObj, loginData?.token)
          .then((res) => {
            setShowLoader(false);
            if (res?.status === 201) {
              toast.success("Email deleted successfully", {
                position: "top-right",
              });
              if (subMenu) {
                return goBackToUserAuthPage();
              }
              window.history.back();
            } else {
              toast.error(
                res?.data?.message ??
                "Something went wrong. Please try again later.",
                {
                  position: "top-right",
                }
              );
            }
          })
          .catch((error) => {
            setShowLoader(false);
            toast.error("Something went wrong. Please try again later.", {
              position: "top-right",
            });
            console.log("Error while deleting a record :: ", error);
          });
      }
    } catch (error) {
      setShowLoader(false);
      toast.error("Something went wrong. Please try again later.", {
        position: "top-right",
      });
      console.log("Error while deleting a record :: ", error);
    }
  };

  if (showLoader) {
    return (
      <Loader
        type="converging-spinner"
        className="kendo-spinner"
        style={{
          display: "flex",
          justifyContent: "center",
          minHeight: "500px",
          alignItems: "center",
        }}
      />
    );
  }

  return (
    <section className="add-user">
      <section className="user-information">
        <GridLayout
          rows={[
            {
              height: 70,
            },
            {
              height: 70,
            },
            {
              height: 70,
            },
            {
              height: 70,
            },
            {
              height: 70,
            },
            {
              height: 70,
            },
            {
              height: 70,
            },
            {
              // height: 20,
            },
          ]}
          cols={[
            {
              width: 375,
            },
            {
              width: 375,
            },
            {
              width: 375,
            },
          ]}
          gap={{
            rows: 25,
            cols: 35,
          }}
          className="layout"
          style={{ padding: "25px 16px", gap: "0px 35px" }}
        >
          <GridLayoutItem col={1} row={1} className="form-inner">
            <Label className="form-heading">User:</Label>
            <div className="form-control">
              {dataId !== "add-email" || subMenu ? (
                <Input
                  id="User Name"
                  name="User Name"
                  value={email?.userName}
                  className="field-ip"
                  disabled
                />
              ) : (
                <AutoComplete
                  data={options}
                  value={value}
                  className="field-ip"
                  onChange={handleChange}
                  textField="label"
                  placeholder="Search for users..."
                />
              )}
            </div>
          </GridLayoutItem>

          <GridLayoutItem col={2} row={1} className="form-inner">
            <div className="form-group">
              <Label className="form-heading">Email Type :</Label>
              <div>
                <DropDownList
                  style={{ width: "370px" }}
                  onChange={(e) => {
                    setEmail((preValue) => ({
                      ...preValue,
                      emailType: e.value?.value,
                    }));
                  }}
                  className="dropdown-list"
                  value={{
                    value: email.emailType,
                    text:
                      email.emailType === "P"
                        ? "Personal"
                        : email?.emailType === "W"
                          ? "Work"
                          : "Other",
                  }}
                  data={emailTypeOptions}
                  textField="text"
                  dataItemKey="text"
                />
              </div>
            </div>
          </GridLayoutItem>
          <GridLayoutItem col={3} row={1} className="form-inner">
            <label className="form-heading">Email :</label>
            <div className="form-control">
              <Input
                type="email"
                id="Email"
                name="email"
                value={email?.email}
                className="field-ip"
                onChange={(e) => onInputChange(e)}
              />
            </div>
          </GridLayoutItem>
          {dataId !== "add-email" || subMenu ? (
            <GridLayoutItem col={1} row={2} className="form-inner">
              <div className="form-group">
                <Label className="form-heading">Record Status :</Label>
                <div>
                  <DropDownList
                    style={{ width: "370px" }}
                    onChange={(e) => {
                      setEmail((preValue) => ({
                        ...preValue,
                        recordStatus: e.value?.text,
                        recordStatusId: e.value?.id,
                      }));
                    }}
                    className="dropdown-list"
                    value={{
                      id: email?.recordStatusId,
                      text: email?.recordStatus,
                    }}
                    data={recordStatusList}
                    textField="text"
                    dataItemKey="id"
                  />
                </div>
              </div>
            </GridLayoutItem>
          ) : (
            <></>
          )}

          <GridLayoutItem col={3} row={4} className="form-inner">
            <div
              className="k-d-flex k-justify-content-end"
              style={{ gap: "10px" }}>
              <KendoButton
                iconClass=""
                size={16}
                onClick={onSubmit}
                className="action-btn"
                buttonName="Save"
              />
              {dataId !== "add-email" && (
                <KendoButton
                  size={16}
                  className="action-btn delete-btn"
                  onClick={onDeleteClick}
                  buttonName="Delete"
                />
              )}

              <KendoButton
                iconClass=""
                themeColor=""
                size={16}
                className=""
                buttonName="Cancel"
                onClick={cancelClick}
              />
            </div>
          </GridLayoutItem>
        </GridLayout>
      </section>
    </section>
  );
};

export default AddNewUserEmail;
