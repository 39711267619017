import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { FETCH_ITEMS, SAVE_ITEMS_PITURES } from "../../constant/Apipath";
import { PostRequestCall } from "../../apicall/PostRequest";
import { useSelector } from "react-redux";
import { Form } from "@progress/kendo-react-form";
import { GridLayout, GridLayoutItem } from "@progress/kendo-react-layout";
import { Label } from "@progress/kendo-react-labels";
import { IconComponent } from "../../common/Icon";
import { toast } from "react-toastify";
import { Loader } from "@progress/kendo-react-indicators";
import { AutoComplete } from "@progress/kendo-react-dropdowns";
import AddPageLayout from "../../addpagelayout/AddPageLayout";

const AddItemsPitures = () => {
  const { dataId, clientSubMenu } = useParams();
  const [showLoader, setShowLoader] = useState(false);
  const { loginData } = useSelector((state) => state.main);
  const isAddForm = true;
  const [itemsPitures, setItemsPitures] = useState({
    title: "",
    description: "",
    redirectUrl: "",
    webRedirectUrl: "",
    itemId: 0,
    fileName: "",
    isActive: false,
    alreadyFileName: "",
    clientSearchTerm: "",
  });

  const [isFieldEdited, setIsFiledEdited] = useState(false);
  const [showItemsList, setShowItemsList] = useState(false);
  const [itemsOptions, setItems] = useState([]);

  useEffect(() => {
    getItems();
  }, []);

  const getItems = async () => {
    try {
      const obj = JSON.stringify({
        PageNr: 1,
        NrOfRecPerPage: 0,
        FullSearch: "",
        UserId: loginData.userId,
        SearchList: [{ id: dataId }],
        IncludeRecordNr: true,
      });
      const getResult = await PostRequestCall(
        FETCH_ITEMS,
        obj,
        loginData?.token
      );
      if (getResult?.data?.data?.length > 0) {
        const data = getResult?.data?.data.map((record) => ({
          value: record?.id,
          label: record?.name,
        }));
        setItems(data);
        setItemsPitures({
          ...itemsPitures,
          itemName: data[0],
        });
        setShowItemsList(false);
      }
    } catch (error) {
      console.log("error", error);
    }
  };

  const onProfileChange = (e) => {
    if (e?.target?.files?.length > 0) {
      setItemsPitures({
        ...itemsPitures,
        fileName: e.target.files[0],
      });
    }
    e.target.value = "";
  };

  const onSelectProfile = () => {
    setIsFiledEdited(true);
    document.getElementById("user-profile").click();
  };

  const onRemoveImage = () => {
    setIsFiledEdited(true);
    setItemsPitures({
      ...itemsPitures,
      fileName: null,
    });
  };

  const saveCurrentData = async () => {
    if (!itemsPitures?.fileName) {
      return toast.error("File  is required", {
        position: "top-right",
      });
    }

    const getRequest = new FormData();
    setShowLoader(true);

    getRequest.append("Id", 0);
    getRequest.append("ItemId", Number(dataId));
    getRequest.append("FileUrl", itemsPitures?.fileName);
    getRequest.append("Main", true);
    getRequest.append("ModifiedBy", loginData?.userId);

    let getResponse = await PostRequestCall(
      SAVE_ITEMS_PITURES,
      getRequest,
      loginData?.token,
      "formData"
    );

    setShowLoader(false);

    if (getResponse?.data?.status === false) {
      toast.error(
        getResponse?.data?.message ??
          "Something went wrong. Please try again later.",
        {
          position: "top-right",
        }
      );
    } else {
      toast.success(
        `Data ${!isAddForm ? "updated" : "added"} added successfully`,
        {
          position: "top-right",
        }
      );
      window.history.back();
    }
  };

  const isCheck = (pictureUrl) => {
    if (Object.keys(pictureUrl)?.length === 0) {
      return URL.createObjectURL(pictureUrl);
    } else {
      return pictureUrl;
    }
  };

  const onClientRender = (li, itemProps) => {
    const selectedClient = itemProps?.dataItem;
    return (
      <div
        className="user-item"
        onClick={() => {
          console.log("Selected client ===>", selectedClient);
          setShowItemsList(false);
          setItemsPitures((prevData) => ({
            ...prevData,
            clientId: selectedClient?.value ?? 0,
          }));
        }}>
        <span>{selectedClient?.label}</span>
      </div>
    );
  };

  if (showLoader) {
    return (
      <Loader
        type="converging-spinner"
        className="kendo-spinner"
        style={{
          display: "flex",
          justifyContent: "center",
          minHeight: "500px",
          alignItems: "center",
        }}
      />
    );
  }

  return (
    <div className="add_form_height_95">
      <AddPageLayout
        dataId={dataId}
        addForm={isAddForm}
        showFooter={!isAddForm}
        isFieldEdited={isFieldEdited}
        recordText={"ItemPiture ID"}
        saveCurrentData={saveCurrentData}>
        <div style={{ width: "100%" }}>
          <Form
            render={(formRenderProps) => (
              <GridLayout
                rows={[
                  { height: 70 },
                  { height: 70 },
                  { height: 70 },
                  { height: 130 },
                  { height: 30 },
                ]}
                cols={[{ width: 375 }, { width: 375 }, { width: 375 }]}
                gap={{ rows: 30, cols: 35 }}
                style={{ padding: "16px" }}>
                <GridLayoutItem col={1} row={1} className="form-inner">
                  <Label className="form-heading"> Item Name :</Label>
                  <AutoComplete
                    data={itemsOptions}
                    onFocus={() =>
                      getItems(true, itemsPitures?.clientSearchTerm)
                    }
                    onClose={() => setShowItemsList(false)}
                    onBlur={() => setShowItemsList(false)}
                    opened={showItemsList}
                    value={itemsPitures?.itemName?.label}
                    className="field-ip"
                    itemRender={onClientRender}
                    onChange={(e) => {
                      console.log("eeee=>", e.target);
                      getItems(false, e?.target?.value);
                    }}
                    textField="label"
                    placeholder="Search for client..."
                    disabled={isAddForm || clientSubMenu}
                  />
                </GridLayoutItem>
                <GridLayoutItem col={2} row={1} className="form-inner">
                  <Label className="form-heading"> Add Piture :</Label>
                  <div className="k-d-flex" style={{ gap: "15px" }}>
                    <input
                      type="file"
                      hidden
                      accept=".png,.jpg"
                      id="user-profile"
                      style={{ display: "none" }}
                      onChange={(e) => {
                        setIsFiledEdited(true);
                        onProfileChange(e);
                      }}
                    />
                    {itemsPitures?.fileName ? (
                      <div className="k-d-flex">
                        <div style={{ position: "relative" }}>
                          <img
                            className="user-img"
                            src={isCheck(itemsPitures?.fileName)}
                            alt="Ad"
                          />
                          <button
                            onClick={onRemoveImage}
                            style={{
                              position: "absolute",
                              top: "6px",
                              right: "8px",
                              backgroundColor: "red",
                              color: "white",
                              border: "none",
                              borderRadius: "50%",
                              cursor: "pointer",
                            }}>
                            <span>X</span>
                          </button>
                        </div>
                      </div>
                    ) : (
                      <>
                        <GridLayoutItem col={2} row={1} className="form-inner">
                          <div
                            style={{
                              position: "relative",
                              cursor: "pointer",
                              border: "0.5px solid #d3d3d3",
                              padding: "5px",
                              display: "flex",
                              justifyContent: "center",
                              borderRadius: "4px",
                              gap: "10px",
                            }}
                            onClick={onSelectProfile}>
                            <IconComponent iconName={"Image"} size={20} />
                            <span className="add-file-text">Choose image</span>
                          </div>
                        </GridLayoutItem>
                      </>
                    )}
                  </div>
                </GridLayoutItem>
              </GridLayout>
            )}
          />
        </div>
      </AddPageLayout>
    </div>
  );
};

export default AddItemsPitures;
