import { Dialog } from "@progress/kendo-react-dialogs";
import KendoButton from "./KendoButton";
import { IconComponent } from "./Icon";

const Modal = (props) => {
  if (!props?.show) {
    return null;
  }

  return (
    <div>
      <Dialog onClose={props?.onClose} className="delete-modal-dialog">
        <div className="delete-modal-dialog-closeicon" onClick={props?.onClose}>
          <IconComponent iconName={"XLg"} size={23} color={"#888"} />
        </div>
        <div className="delete-modal-dialog-container">
          {/* <div className="x-button-container">
                        <span className="x-circle">
                            <IconComponent iconName={"XLg"} color={"rgb(241, 94, 94)"} size={30} />
                        </span>
                    </div> */}
          <h1 className="delete-modal-dialog-h1">Are you sure?</h1>

          {props?.for === "delete" ? (
            <p className="delete-modal-dialog-p">
              Do you really want to delete these records?
              <br /> This process cannot be undone.
            </p>
          ) : (
            <p className="delete-modal-dialog-p">
              Are you sure you want to cancel? <br /> Any changes you've made
              will be discarded.
            </p>
          )}
          <div className="k-d-flex justify-content-between button-footer">
            <KendoButton
              className="k-button k-button-md k-rounded-md k-button-solid k-button-solid-base cancel-btn"
              onClick={props?.onClose}
              buttonName="Cancel"
            />
            <KendoButton
              className="k-button k-button-md k-rounded-md k-button-solid k-button-solid-base delete-btn"
              onClick={props.onActionBtnClick}
              buttonName={props.onActionBtnTitle}
            />
          </div>
        </div>
      </Dialog>
    </div>
  );
};

export default Modal;
