import { IconComponent } from "../common/Icon"

export const showBooleanIcon = (value) => {
    return (
        <td>
            <div style={{ textAlign: 'center' }}>
                <IconComponent
                    iconName={value ? "CheckCircleFill" : "Ban"}
                    color={value ? "green" : "red"}
                    size='20'
                />
            </div>
        </td>
    )
}