import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { DELETE_FAQ, FETCH_FAQ, SAVE_FAQ } from "../../constant/Apipath";
import { PostRequestCall } from "../../apicall/PostRequest";
import { Input, Switch, TextArea } from "@progress/kendo-react-inputs";
import { useSelector } from "react-redux";
import { Form } from "@progress/kendo-react-form";
import { GridLayout, GridLayoutItem } from "@progress/kendo-react-layout";
import { Error, Label } from "@progress/kendo-react-labels";
import { toast } from "react-toastify";
import { Loader } from "@progress/kendo-react-indicators";
import AddPageLayout from "../../addpagelayout/AddPageLayout";

const AddFaq = () => {
  const { dataId } = useParams();
  const [showLoader, setShowLoader] = useState(false);
  const { loginData } = useSelector((state) => state.main);
  const isAddForm = dataId === "add-faq";
  const [faq, setFaq] = useState({
    category: "",
    description: "",
    subCategory: "",
    isActive: false,
  });
  const [isFieldEdited, setIsFiledEdited] = useState(false);

  useEffect(() => {
    if (!isAddForm) {
      loadFaq();
    }
  }, []);

  const loadFaq = async () => {
    var obj = JSON.stringify({
      SearchList: [
        {
          Id: dataId,
        },
      ],
    });

    try {
      const res = await PostRequestCall(FETCH_FAQ, obj, loginData?.token);
      let resData = res?.data?.data?.[0] ?? {};
      setFaq({
        ...faq,
        ...resData,
        category: resData?.category,
        description: resData?.description,
        subCategory: resData?.subCategory,
        isActive: resData?.active,
        clientId: resData?.clientId,
        fileName: resData?.fileName,
      });
    } catch (error) {
      console.log("Error while loading client ads data :: ", error);
    }
  };

  const onInputChange = (e) => {
    setIsFiledEdited(true);
    setFaq({
      ...faq,
      [e.target.name]: e.target.value,
    });
  };

  const saveCurrentData = async () => {
    if (!faq.category.trim()) {
      toast.error("Category is required", {
        position: "top-right",
      });
      return;
    }
    if (!faq.description.trim()) {
      toast.error("Description is required", {
        position: "top-right",
      });
      return;
    }

    const getObject = {
      ReturnRecordId: true,
      ReturnRecordError: true,
      SaveList: [
        {
          Id: isAddForm ? 0 : dataId,
          Category: faq.category,
          SubCategory: faq.subCategory,
          Description: faq.description,
          DisplayOrder: faq?.displayOrder,
          Active: faq.isActive,
        },
      ],
    };

    try {
      let getResponse = await PostRequestCall(
        SAVE_FAQ,
        getObject,
        loginData?.token
      );
      if (getResponse.data.status === false) {
        setShowLoader(false);
        toast.error(
          getResponse?.data?.message ??
            "Something went wrong. Please try again later.",
          {
            position: "top-right",
          }
        );
      } else {
        setShowLoader(false);
        toast.success(`Data ${!isAddForm ? "updated" : "added"} successfully`, {
          position: "top-right",
        });
        window.history.back();
      }
    } catch (error) {
      setShowLoader(false);
      toast.error("Something went wrong. Please try again later.", {
        position: "top-right",
      });
      console.error("Error saving data:", error);
    }
  };

  const onDeleteClick = async () => {
    try {
      setShowLoader(true);

      const deleteObj = {
        returnRecordError: true,
        softDelete: true,
        deleteList: [
          {
            id: dataId,
          },
        ],
      };

      return PostRequestCall(DELETE_FAQ, deleteObj, loginData?.token)
        .then((res) => {
          setShowLoader(false);
          if (res?.status === 201) {
            toast.success(`Data delete successfully`, {
              position: "top-right",
            });

            window.history.back();
          } else {
            toast.error(
              res?.data?.message ??
                "Something went wrong. Please try again later.",
              {
                position: "top-right",
              }
            );
          }
        })
        .catch((error) => {
          setShowLoader(false);
          toast.error("Something went wrong. Please try again later.", {
            position: "top-right",
          });
          console.log("Error while deleting a record :: ", error);
        });
    } catch (error) {
      setShowLoader(false);
      toast.error("Something went wrong. Please try again later.", {
        position: "top-right",
      });
      console.log("Error while deleting a record :: ", error);
    }
  };

  if (showLoader) {
    return (
      <Loader
        type="converging-spinner"
        className="kendo-spinner"
        style={{
          display: "flex",
          justifyContent: "center",
          minHeight: "500px",
          alignItems: "center",
        }}
      />
    );
  }

  return (
    <div className="add_form_height_95">
      <AddPageLayout
        dataId={dataId}
        addForm={isAddForm}
        showFooter={false}
        isFieldEdited={isFieldEdited}
        recordText={"Faq ID"}
        saveCurrentData={saveCurrentData}
        onDeleteClick={onDeleteClick}>
        <div style={{ width: "100%" }}>
          <Form
            render={(formRenderProps) => (
              <GridLayout
                rows={[
                  { height: 70 },
                  { height: 70 },
                  { height: 70 },
                  { height: 130 },
                  { height: 30 },
                ]}
                cols={[{ width: 375 }, { width: 375 }, { width: 375 }]}
                gap={{ rows: 30, cols: 35 }}
                style={{ padding: "16px" }}>
                <GridLayoutItem col={1} row={1} className="form-inner">
                  <Label className="form-heading"> Category:</Label>
                  <Input
                    name="category"
                    value={faq?.category}
                    className="field-ip"
                    onChange={onInputChange}
                  />
                  <Error></Error>
                </GridLayoutItem>
                <GridLayoutItem col={2} row={1} className="form-inner">
                  <Label className="form-heading"> Sub Category:</Label>
                  <Input
                    name="subCategory"
                    value={faq?.subCategory}
                    className="field-ip"
                    onChange={onInputChange}
                  />
                  <Error></Error>
                </GridLayoutItem>
                <GridLayoutItem col={3} row={1} className="form-inner">
                  <label className="form-heading">Active:</label>
                  <div>
                    <Switch
                      size="small"
                      checked={faq?.isActive}
                      onChange={(e) => {
                        setIsFiledEdited(true);
                        setFaq({
                          ...faq,
                          isActive: e.value,
                        });
                      }}
                    />
                  </div>
                </GridLayoutItem>

                <GridLayoutItem col={1} row={2} className="form-inner">
                  <label className="form-heading">Description:</label>
                  <div style={{ display: "flex", gap: "2px" }}>
                    <TextArea
                      type="text"
                      id="description"
                      name="description"
                      value={faq?.description}
                      className="field-ip"
                      onChange={(e) => onInputChange(e)}
                      rows={4}
                    />
                  </div>
                </GridLayoutItem>
              </GridLayout>
            )}
          />
        </div>
      </AddPageLayout>
    </div>
  );
};

export default AddFaq;
