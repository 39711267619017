import React, { useRef } from "react";
import "./Adduserform.css";
import { Outlet, useNavigate, useParams } from "react-router-dom";
import { IconComponent } from "../../common/Icon";

const UserDetailsMainLayout = (props) => {
  const { userId } = useParams();
  const listRef = useRef(null);
  const navigate = useNavigate();

  const pathname = window.location.pathname;
  const pathArray = pathname.split("/");

  let menuList = [
    { title: "Edit record", url: "edit-record" },
    { title: "User Quizzes", url: "user-quizzes" },
    { title: "User Answers", url: "user-answers" },
    { title: "User Reward Activities", url: "user-reward-activity" },
    { title: "User Roles Auth", url: "user-roles-auth" },
    { title: "User Trades", url: "user-trades" },
    { title: "User Companies", url: "user-companies" },
    { title: "User Sites", url: "user-sites" },
    { title: "User Projects", url: "user-site-projects" },
    { title: "User Work History", url: "user-work-history" },
    { title: "User Support", url: "user-support" },
    { title: "User Devices", url: "user-devices" },
    { title: "User Tickets", url: "user-tickets" },
    { title: "Post", url: "user-post" },
    // { title: "Post Comments", url: "user-post-comments" },
  ];

  const scrollMenu = (direction) => {
    if (listRef.current) {
      const { scrollLeft, clientWidth } = listRef.current;
      const scrollAmount = clientWidth / 2;
      listRef.current.scrollTo({
        left:
          scrollLeft + (direction === "next" ? scrollAmount : -scrollAmount),
        behavior: "smooth",
      });
    }
  };
  return (
    <>
      <section className="add-user" style={{ height: "100%" }}>
        <section className="table-list userauth-submenu" style={{ height: "7%" }}>
          <div className="menu-list-2 usersub-menu">
            {Number(userId) !== 0 ? (
              <>
                <IconComponent
                  size={40}
                  iconName={"ArrowLeftSquareFill"}
                  color="#B5B5B5"
                  onClick={() => scrollMenu("prev")}
                />

                <ul className="second-list" ref={listRef}>
                  {menuList?.map((item, index) => (
                    <li
                      className="menu"
                      key={index}
                      onClick={() => {
                        navigate(item?.url);
                      }}>
                      <div
                        style={{ cursor: "pointer" }}
                        className={`k-d-flex k-align-items-center k-flex-row menu ${item?.url === pathArray?.[5] ? "active" : ""
                          }`}>
                        <span className="item-text">{item?.title}</span>
                      </div>
                    </li>
                  ))}
                </ul>

                <IconComponent
                  size={40}
                  iconName={"ArrowRightSquareFill"}
                  color="#B5B5B5"
                  onClick={() => scrollMenu("next")}
                />
              </>
            ) : (
              <></>
            )}
          </div>
        </section>
        <div style={{ height: '93%' }}>
          <Outlet />
        </div>
      </section>
    </>
  );
};

export default UserDetailsMainLayout;
