import React, { useEffect, useState } from "react";
import { DropDownList } from "@progress/kendo-react-dropdowns";
import { useParams } from "react-router-dom";

import { Input } from "@progress/kendo-react-inputs";
import { useSelector } from "react-redux";
import { GridLayout, GridLayoutItem } from "@progress/kendo-react-layout";
import { Label } from "@progress/kendo-react-labels";
import { toast } from "react-toastify";
import { Loader } from "@progress/kendo-react-indicators";
import { PostRequestCall } from "../apicall/PostRequest";
import {
  DELETE_ENTITIES,
  FETCH_ENTITIES,
  FETCH_RECORD_STATUSES,
  SAVE_ENTITIES,
} from "../constant/Apipath";
import KendoButton from "../common/KendoButton";
import Modal from "../common/Modal";
import Footerinformation from "../common/Footerinformation";

const AddEntity = () => {
  const { id } = useParams();
  const { loginData } = useSelector((state) => state.main);

  const [Site, setsite] = useState({
    Id: "",
    name: "",
    recordStatusId: 1,
    recordStatus: "New",
    createdBy: "",
    dateCreated: "",
    modifiedBy: "",
    dateModified: "",
    createdByUser: "",
    modifiedByUser: "",
  });
  const [showLoader, setShowLoader] = useState(false);
  const [siteTypes, setSiteTypes] = useState([]);
  const [recordStatusOptions, setRecordStatusOptions] = useState([]);

  const [modalState, setModalState] = useState({
    for: "",
    show: false,
    onActionBtnTitle: "",
  });
  const [isFieldEdited, setIsFiledEdited] = useState(false);

  useEffect(() => {
    if (id) {
      getUserDetails();
    }
    getRecordStatuses();
  }, []);

  const getRecordStatuses = async () => {
    try {
      let recordStatusesObj = {
        PageNr: 1,
        NrOfRecPerPage: 100,
        FullSearch: "",
        UserId: "1",
        SortList: [
          {
            FieldName: "Id",
            Direction: "ASC",
          },
        ],
        IncludeRecordNr: true,
      };
      const recordStatusesRes = await PostRequestCall(
        FETCH_RECORD_STATUSES,
        recordStatusesObj,
        loginData?.token
      );
      let recordStatusesData = recordStatusesRes?.data?.data?.map((record) => ({
        id: record?.id,
        text: record?.name,
      }));
      setRecordStatusOptions(recordStatusesData ?? []);
    } catch (error) {
      console.log("Error while getting record statuses data :: ", error);
    } finally {
      setShowLoader(false);
    }
  };

  const getUserDetails = async () => {
    setShowLoader(true);
    try {
      const data = JSON.stringify({
        PageNr: 1,
        NrOfRecPerPage: 100,
        FullSearch: "",
        UserId: loginData.userId,
        SearchList: [{ id: id }],
        IncludeRecordNr: true,
      });

      const result = await PostRequestCall(
        FETCH_ENTITIES,
        data,
        loginData?.token
      );
      let items = result.data.data[0] || [];
      setsite({
        ...Site,
        Id: items.id,
        name: items.name,
        recordStatusId: items.recordStatusId,
        recordStatus: items.recordStatus,
        createdBy: items.createdBy,
        dateCreated: items.dateCreated,
        modifiedBy: items.modifiedBy,
        dateModified: items.dateModified,
        createdByUser: items.createdByUser,
        modifiedByUser: items.modifiedByUser,
      });
    } catch (error) {
      console.log("Error while getting user details :: ", error);
    } finally {
      setShowLoader(false);
    }
  };

  const onCancelClick = () => {
    if (isFieldEdited) {
      return setModalState((preValue) => ({
        ...preValue,
        for: "edit",
        show: true,
        onActionBtnTitle: "Save",
      }));
    } else {
      return window.history.back();
    }
  };

  const onActionBtnClick = () => {
    setModalState((preValue) => ({
      ...preValue,
      for: "",
      show: false,
      onActionBtnTitle: "",
    }));
    if (modalState?.for === "delete") {
      onDeleteClick();
    } else if (modalState?.for === "edit") {
      onSubmit();
    } else {
      return null;
    }
  };

  const showDeleteConfirmation = () => {
    setModalState((preValue) => ({
      ...preValue,
      for: "delete",
      show: true,
      onActionBtnTitle: "Delete",
    }));
  };

  const onModalClose = () => {
    window.history.back();
  };

  const onSubmit = async () => {
    setShowLoader(true);
    if (!Site.name) {
      toast.error("Name is required", {
        position: "top-right",
      });
      return;
    }
    const obj = {
      UserId: loginData.userId,
      ReturnRecordId: true,
      ReturnRecordError: true,
      SaveList: [
        {
          Id: id === "add-entitities" ? 0 : id,
          Name: Site.name,
          RecordStatusId: Site.recordStatusId,
          ModifiedBy: loginData?.userId,
        },
      ],
    };

    try {
      const result = await PostRequestCall(
        SAVE_ENTITIES,
        obj,
        loginData?.token
      );

      console.log("result", result);

      if (result.data.status === true) {
        setShowLoader(false);
        toast.success(
          `Data ${id !== "add-entitities" ? "updated" : "added"} successfully`,
          {
            position: "top-right",
          }
        );
        window.history.back();
      } else {
        toast.error(result?.data?.message ?? "Error while ssaving the data", {
          position: "top-right",
        });
        setShowLoader(false);
      }
    } catch (error) {
      setShowLoader(false);
      toast.error("Something went wrong. Please try again later.", {
        position: "top-right",
      });
      console.error("Error saving data :", error);
    }
  };

  const onDeleteClick = async () => {
    try {
      setShowLoader(true);
      const deleteObj = {
        ReturnRecordError: true,
        ReturnRecordId: false,
        UserId: loginData.userId,
        DeleteList: [
          {
            Id: id,
            ModifiedBy: loginData.userId,
          },
        ],
      };
      return PostRequestCall(DELETE_ENTITIES, deleteObj, loginData?.token)
        .then((res) => {
          setShowLoader(false);
          if (res?.data?.status) {
            toast.success("Data deleted successfully", {
              position: "top-right",
            });
            window.history.back();
          } else {
            toast.error(
              res?.data?.message ??
                "Something went wrong. Please try again later.",
              {
                position: "top-right",
              }
            );
          }
        })
        .catch((error) => {
          setShowLoader(false);
          toast.error("Something went wrong. Please try again later.", {
            position: "top-right",
          });
          console.log("Error while deleting a record :: ", error);
        });
    } catch (error) {
      setShowLoader(false);
      toast.error("Something went wrong. Please try again later.", {
        position: "top-right",
      });
      console.log("Error while deleting a record :: ", error);
    }
  };

  if (showLoader) {
    return (
      <Loader
        type="converging-spinner"
        className="kendo-spinner"
        style={{
          display: "flex",
          justifyContent: "center",
          minHeight: "500px",
          alignItems: "center",
        }}
      />
    );
  }

  return (
    <section className="add-user">
      <section className="user-information">
        <div className="k-d-flex k-justify-content-between">
          <div className="k-d-flex k-align-items-center">
            {id !== "add-entitities" && (
              <span class="record-id-text">Entity Id : {id}</span>
            )}
          </div>
          <div className="k-d-flex k-p-2" style={{ gap: "10px" }}>
            <KendoButton
              iconClass=""
              size={16}
              onClick={onSubmit}
              className=""
              buttonName="Save"
              themeColor={"primary"}
            />
            {id !== "add-entitities" && (
              <KendoButton
                size={16}
                className=""
                onClick={showDeleteConfirmation}
                buttonName="Delete"
                themeColor={"secondary"}
              />
            )}
            <KendoButton
              iconClass=""
              size={16}
              className=""
              buttonName="Cancel"
              onClick={onCancelClick}
            />
          </div>
        </div>
        <div style={{ borderBottom: "1px solid #f5f5f5", width: "100%" }}></div>
        <GridLayout
          rows={[
            {
              height: 70,
            },
            {
              height: 70,
            },
            {
              height: 350,
            },
            {
              height: 30,
            },
          ]}
          cols={[
            {
              width: 375,
            },
          ]}
          gap={{
            rows: 25,
            cols: 35,
          }}
          style={{ padding: "25px 16px", gap: "0px 35px" }}>
          <GridLayoutItem col={1} row={1} className="form-inner">
            <label className="form-heading">Name :</label>

            <Input
              className="field-ip"
              value={Site?.name}
              onChange={(e) => {
                setIsFiledEdited(true);
                setsite({
                  ...Site,
                  name: e.target.value,
                });
              }}
            />
          </GridLayoutItem>
          {id !== "add-entitities" ? (
            <GridLayoutItem col={1} row={2} className="form-inner">
              <div className="form-group">
                <Label className="form-heading">Record Status :</Label>
                <div>
                  <DropDownList
                    style={{ width: "370px" }}
                    onChange={(e) => {
                      setIsFiledEdited(true);
                      setsite({
                        ...Site,
                        recordStatus: e.value?.text,
                        recordStatusId: e.value?.id,
                      });
                    }}
                    className="dropdown-list"
                    value={{
                      id: Site?.recordStatusId,
                      text: Site?.recordStatus,
                    }}
                    data={recordStatusOptions}
                    textField="text"
                    dataItemKey="id"
                  />
                </div>
              </div>
            </GridLayoutItem>
          ) : (
            <></>
          )}

          <GridLayoutItem
            col={1}
            row={id !== "add-entitities" ? 3 : 2}
            className="form-inner">
            <div className="k-d-flex" style={{ gap: "10px" }}>
              <KendoButton
                iconClass=""
                size={16}
                onClick={onSubmit}
                className=""
                buttonName="Save"
                themeColor={"primary"}
              />

              {id !== "add-entitities" && (
                <KendoButton
                  size={16}
                  className=""
                  onClick={showDeleteConfirmation}
                  buttonName="Delete"
                  themeColor={"secondary"}
                />
              )}
              <KendoButton
                iconClass=""
                size={16}
                className=""
                buttonName="Cancel"
                onClick={onCancelClick}
              />
            </div>
          </GridLayoutItem>
          {id !== "add-entitities" && (
            <GridLayoutItem colSpan={4} className="form-footer">
              <Footerinformation footerInfo={Site} />
            </GridLayoutItem>
          )}
        </GridLayout>
      </section>
      {modalState?.show && (
        <Modal
          for={modalState?.for}
          show={modalState?.show}
          onClose={onModalClose}
          onActionBtnClick={onActionBtnClick}
          onActionBtnTitle={modalState?.onActionBtnTitle}
        />
      )}
    </section>
  );
};

export default AddEntity;
