import React, { useEffect, useRef, useState } from "react";
import { DatePicker } from "@progress/kendo-react-dateinputs";
import { DropDownList } from "@progress/kendo-react-dropdowns";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import {
  DEACTIVATE_USER_ACCOUNT,
  FETCH_USERS,
  SAVE_USERS,
} from "../../constant/Apipath";
import { PostRequestCall } from "../../apicall/PostRequest";
import { Input, Switch } from "@progress/kendo-react-inputs";
import { useSelector } from "react-redux";
import { Form } from "@progress/kendo-react-form";
import { GridLayout, GridLayoutItem } from "@progress/kendo-react-layout";
import { Error, Label } from "@progress/kendo-react-labels";
import "./Adduserform.css";
import KendoButton from "../../common/KendoButton";
import moment from "moment";
import { IconComponent } from "../../common/Icon";
import Modal from "../../common/Modal";
import Footerinformation from "../../common/Footerinformation";
import { GetrequestCall } from "../../apicall/GetRequest";
import { toast } from "react-toastify";
import { Loader } from "@progress/kendo-react-indicators";

/****************
 *
 * This is the user add and edit form in the user auth user can add and edit the user
 *
 *****************/

const AddUserForm = () => {
  const { userId } = useParams();
  const isAddFrom = Number(userId) === 0;
  const location = useLocation();
  const { loginData } = useSelector((state) => state.main);
  const listRef = useRef(null);
  const [isPasswordChanged, setIspasswordChanged] = useState(false);
  const [userDetails, setUserdetails] = useState({
    userEmail: "",
    firstName: "",
    lastName: "",
    pictureUrl: null,
    rideShareinterested: false,
    birthday: "",
    active: false,
    resetCode: "",
    modifiedBy: "",
    password: "",
    referredBy: null,
    refferedsource: "",
  });

  const [showLoader, setShowLoader] = useState(false);
  const [modalState, setModalState] = useState({
    for: "",
    show: false,
    onActionBtnTitle: "",
  });
  const [isFieldEdited, setIsFiledEdited] = useState(false);

  useEffect(() => {
    // API getinitial data when we edit the record
    if (!isAddFrom) {
      getUserinformation();
    }
  }, []);

  const getUserinformation = async () => {
    let obj = { SearchList: [{ Id: userId }] };
    const fetchuserAuthRes = await PostRequestCall(
      FETCH_USERS,
      obj,
      loginData?.token
    );
    // console.log("fetchuserAuthRes", fetchuserAuthRes);
    if (fetchuserAuthRes.status === 201) {
      let getUserdetails = fetchuserAuthRes?.data?.data[0];
      setUserdetails({
        ...userDetails,
        ...getUserdetails,
        userEmail: getUserdetails?.userEmail,
        firstName: getUserdetails?.firstName,
        lastName: getUserdetails?.lastName,
        pictureUrl: getUserdetails?.pictureUrl,
        rideShareinterested: getUserdetails?.rideShareInterested,
        birthday: new Date(getUserdetails?.birthday),
        surveyDate: getUserdetails?.surveyDate
          ? new Date(getUserdetails?.surveyDate)
          : null,
        password: getUserdetails?.password,
        active: getUserdetails?.active,
        referredBy: getUserdetails?.referredBy?.trim() !== "" ? getUserdetails?.referredBy : null,
        refferedsource: getUserdetails?.refferedsource ?? "",
        lastDateLoggedIn: getUserdetails?.lastDateLoggedIn
          ? new Date(getUserdetails?.lastDateLoggedIn)
          : null,
      });
    }
  };

  const onInputChange = (e) => {
    setIsFiledEdited(true);
    setUserdetails({
      ...userDetails,
      [e.target.name]: e.target.value,
    });
    if (e.target.name === "password") {
      setIspasswordChanged(isPasswordChanged === false ? true : false);
    }
  };

  // On ride shared change

  // On birthday change
  const onSurveychange = (e) => {
    setIsFiledEdited(true);
    setUserdetails({
      ...userDetails,
      surveyDate: e.value,
    });
  };

  const onProfilechange = (e) => {
    setIsFiledEdited(true);
    if (e?.target?.files?.length > 0) {
      setUserdetails({
        ...userDetails,
        pictureUrl: e.target.files[0],
      });
    }
    e.target.value = "";
  };

  const onRemoveImage = () => {
    setIsFiledEdited(true);
    setUserdetails({
      ...userDetails,
      pictureUrl: null,
    });
  };

  const onSelectProfile = (e) => {
    setIsFiledEdited(true);
    e.preventDefault();
    document.getElementById("user-profile").click();
  };

  const saveCurrentdata = async () => {
    if (userDetails?.firstName?.trim() === "") {
      toast.error("Please enter first name.", {
        position: "top-right",
      });
      return;
    }

    if (userDetails?.lastName?.trim() === "") {
      toast.error("Please enter last name.", {
        position: "top-right",
      });
      return;
    }

    const emailId = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,6}$/;

    if (userDetails?.userEmail?.length === 0) {
      toast.error("Please enter email.", {
        position: "top-right",
      });
      return;
    }

    if (!emailId.test(userDetails?.userEmail.trim())) {
      toast.error("Please enter valid email address!", {
        position: "top-right",
      });
      return;
    }

    if (isAddFrom && userDetails?.password?.trim() === "") {
      toast.error("Please enter valid password", {
        position: "top-right",
      });
      return;
    }

    setShowLoader(true);
    const getRequest = new FormData();
    if (!isAddFrom) {
      getRequest.append("Id", userId);
    } else {
      getRequest.append("Id", 0);
    }
    getRequest.append("UserEmail", userDetails?.userEmail || "");
    getRequest.append("FirstName", userDetails?.firstName || "");
    getRequest.append("LastName", userDetails?.lastName || "");
    getRequest.append("PictureUrl", userDetails?.pictureUrl || "");
    getRequest.append(
      "RideShareInterested",
      userDetails?.rideShareinterested ?? false
    );

    if (userDetails?.surveyDate) {
      getRequest.append(
        "surveyDate",
        userDetails?.surveyDate
          ? moment(userDetails?.surveyDate).format("YYYY-MM-DD")
          : ""
      );
    }

    getRequest.append("Active", userDetails?.active ?? false);
    getRequest.append("referredBy", userDetails?.referredBy ?? 0);
    getRequest.append("refferedsource", userDetails?.refferedsource ?? "");
    getRequest.append(
      "IsProgressBarDismiss",
      userDetails?.isProgressBarDismiss ?? false
    );
    getRequest.append(
      "isPrivateUserEmail",
      userDetails?.isPrivateUserEmail ?? false
    );
    getRequest.append(
      "isPublicUserEmail",
      userDetails?.isPublicUserEmail ?? false
    );
    getRequest.append(
      "isTimeSheetsBannerFlag",
      userDetails?.isTimeSheetsBannerFlag ?? false
    );
    // getRequest.append("ResetCode", userDetails?.resetCode);
    getRequest.append("ModifiedBy", loginData?.userId);
    if (isAddFrom) {
      getRequest.append("Password", isAddFrom ? userDetails?.password : "");
    }

    await PostRequestCall(SAVE_USERS, getRequest, loginData?.token, "formData")
      .then((res) => {
        if (res?.data?.status) {
          if (res?.status === 201) {
            toast.success(
              isAddFrom
                ? "Data added successfully"
                : "Data updated successfully",
              {
                position: "top-right",
              }
            );
            setShowLoader(false);
            return window.history.back();
          } else {
            let errorMessage = res?.data?.errorMessage
              ? res?.data?.errorMessage
              : res?.data?.error
                ? res?.data?.error
                : "Something went wrong. Please try again later.";
            toast.error(errorMessage, {
              position: "top-right",
            });
            setShowLoader(false);
          }
        } else {
          let errorMessage = res?.data?.errorMessage
            ? res?.data?.errorMessage
            : res?.data?.error
              ? res?.data?.error
              : "Something went wrong. Please try again later.";
          toast.error(errorMessage, {
            position: "top-right",
          });
          setShowLoader(false);
        }
      })
      .catch((error) => {
        setShowLoader(false);
        toast.error("Something went wrong. Please try again later.", {
          position: "top-right",
        });
        console.log("Error while updating a records :: ", error);
      });
  };

  const onCancelClick = () => {
    if (isFieldEdited) {
      return setModalState((preValue) => ({
        ...preValue,
        for: "edit",
        show: true,
        onActionBtnTitle: "Save",
      }));
    } else {
      return window.history.back();
    }
  };

  const onActionBtnClick = () => {
    setModalState((preValue) => ({
      ...preValue,
      for: "",
      show: false,
      onActionBtnTitle: "",
    }));
    if (modalState?.for === "delete") {
      onDeleteClick();
    } else if (modalState?.for === "edit") {
      saveCurrentdata();
    } else {
      return null;
    }
  };

  const onDeleteClick = async () => {
    try {
      let userRewardsDataRes = await GetrequestCall(
        `${DEACTIVATE_USER_ACCOUNT}/${userId}`,
        loginData?.token
      );
      if (userRewardsDataRes?.status === 201) {
        toast.success("Data deleted successfully", {
          position: "top-right",
        });
        window.history.back();
      } else {
        toast.error("Something went wrong. Please try again later.", {
          position: "top-right",
        });
      }
    } catch (error) {
      // setShowLoader(false);
      toast.error("Something went wrong. Please try again later.", {
        position: "top-right",
      });
    }
  };

  const showDeleteConfirmation = () => {
    setModalState((preValue) => ({
      ...preValue,
      for: "delete",
      show: true,
      onActionBtnTitle: "Delete",
    }));
  };

  const onModalClose = () => {
    window.history.back();
  };

  const isCheck = (pictureUrl) => {
    if (Object.keys(pictureUrl)?.length === 0) {
      return URL.createObjectURL(pictureUrl);
    } else {
      return pictureUrl;
    }
  };

  if (showLoader) {
    return (
      <Loader
        type="converging-spinner"
        className="kendo-spinner"
        style={{
          display: "flex",
          justifyContent: "center",
          minHeight: "500px",
          alignItems: "center",
        }}
      />
    );
  }

  return (
    <>
      <section className="user-information">
        <div className="k-d-flex k-justify-content-between">
          <div className="k-d-flex k-align-items-center">
            {!isAddFrom && (
              <span class="record-id-text">User Id : {userId}</span>
            )}
          </div>
          <div
            className="k-d-flex k-justify-content-end k-p-2"
            style={{ gap: "10px" }}>
            <KendoButton
              iconClass=""
              size={16}
              className=""
              themeColor={"primary"}
              buttonName="Save"
              onClick={saveCurrentdata}
            />
            {/* {!isAddFrom && userDetails?.active === true && (
              <KendoButton
                size={16}
                className=""
                onClick={showDeleteConfirmation}
                buttonName="Delete"
                themeColor={"secondary"}
              />
            )} */}
            <KendoButton
              iconClass=""
              size={16}
              className=""
              buttonName="Cancel"
              onClick={onCancelClick}
            />
          </div>
        </div>
        <div style={{ borderBottom: "1px solid #f5f5f5", width: "100%" }}></div>
        <Form
          render={(formRenderProps) => (
            <GridLayout
              rows={[
                {
                  height: 70,
                },
                {
                  height: 70,
                },
                {
                  height: 70,
                },
                {
                  height: 70,
                },
                {
                  height: 70,
                },
                {
                  height: 30,
                },
              ]}
              cols={[
                {
                  width: 375,
                },
                {
                  width: 375,
                },
                {
                  width: 375,
                },
              ]}
              gap={{
                rows: 30,
                cols: 35,
              }}
              style={{ padding: "16px" }}>
              <GridLayoutItem col={1} row={1} className="form-inner">
                <Label className="form-heading">User Email:</Label>

                <Input
                  name="userEmail"
                  value={userDetails?.userEmail}
                  className="field-ip"
                  // disabled={!isAddFrom}
                  onChange={(e) => {
                    let value = e.target.value?.trim();
                    if (value.length > 0) {
                      value = value[0].toLowerCase() + value.slice(1);
                    }
                    setUserdetails((preValue) => ({
                      ...preValue,
                      userEmail: value,
                    }));
                  }}
                  type="email"
                />
                <Error></Error>
              </GridLayoutItem>
              <GridLayoutItem col={2} row={1} className="form-inner">
                <Label className="form-heading">First Name:</Label>
                <Input
                  name="firstName"
                  value={userDetails?.firstName}
                  className="field-ip"
                  // onChange={(e) => onInputChange(e)}
                  onChange={(e) => {
                    const inputValue = e?.target?.value;
                    const lettersOnly = /^[A-Za-z]*$/;
                    if (lettersOnly.test(inputValue)) {
                      setUserdetails((preValue) => ({
                        ...preValue,
                        firstName: inputValue,
                      }));
                    }
                  }}
                  type="text"
                />
                <Error></Error>
              </GridLayoutItem>
              <GridLayoutItem col={3} row={1} className="form-inner">
                <Label className="form-heading">Last Name:</Label>
                <Input
                  name="lastName"
                  value={userDetails?.lastName}
                  className="field-ip"
                  onChange={(e) => {
                    const inputValue = e?.target?.value;
                    const lettersOnly = /^[A-Za-z]*$/;
                    if (lettersOnly.test(inputValue)) {
                      setUserdetails((preValue) => ({
                        ...preValue,
                        lastName: inputValue,
                      }));
                    }
                  }}
                  type="text"
                />
                <Error></Error>
              </GridLayoutItem>
              <GridLayoutItem col={1} row={2} className="form-inner">
                <Label className="form-heading">Ride Share Interested:</Label>
                <div>
                  <Switch
                    size={"small"}
                    checked={userDetails?.rideShareinterested}
                    onChange={(e) => {
                      setIsFiledEdited(true);
                      setUserdetails((preValue) => ({
                        ...preValue,
                        rideShareinterested: e?.value,
                      }));
                    }}
                  />
                </div>
                <Error></Error>
              </GridLayoutItem>
              <GridLayoutItem col={2} row={2} className="form-inner">
                {/* <Label className="form-heading">Birthday:</Label>
                <DatePicker
                  name="birthday"
                  value={userDetails?.birthday}
                  onChange={onBirthdaychange}
                  format="dd/MM/yyyy"
                  className="dropdown-list"
                /> */}
                <Label className="form-heading">Progress Bar Dismiss:</Label>
                <div>
                  <Switch
                    size={"small"}
                    checked={userDetails?.isProgressBarDismiss}
                    onChange={(e) => {
                      setIsFiledEdited(true);
                      setUserdetails((preValue) => ({
                        ...preValue,
                        isProgressBarDismiss: e?.value,
                      }));
                    }}
                  />
                </div>
              </GridLayoutItem>
              <GridLayoutItem col={3} row={2} className="form-inner">
                {/* <Label className="form-heading">Password:</Label>
                <Input
                  name="password"
                  value={userDetails?.password}
                  className="field-ip"
                  onChange={(e) => onInputChange(e)}
                />
                <Error></Error> */}
                <Label className="form-heading">Private User Email:</Label>
                <div>
                  <Switch
                    size={"small"}
                    checked={userDetails?.isPrivateUserEmail}
                    onChange={(e) => {
                      setIsFiledEdited(true);
                      setUserdetails((preValue) => ({
                        ...preValue,
                        isPrivateUserEmail: e?.value,
                      }));
                    }}
                  />
                </div>
              </GridLayoutItem>
              <GridLayoutItem col={1} row={3} className="form-inner">
                {/* <Label className="form-heading">Reset Code:</Label>
                <Input
                  name="resetCode"
                  value={userDetails?.resetCode}
                  className="field-ip"
                  onChange={(e) => onInputChange(e)}
                />
                <Error></Error> */}
                <Label className="form-heading">Public User Email:</Label>
                <div>
                  <Switch
                    size={"small"}
                    checked={userDetails?.isPublicUserEmail}
                    onChange={(e) => {
                      setIsFiledEdited(true);
                      setUserdetails((preValue) => ({
                        ...preValue,
                        isPublicUserEmail: e?.value,
                      }));
                    }}
                  />
                </div>
              </GridLayoutItem>
              <GridLayoutItem col={2} row={3} className="form-inner">
                <Label className="form-heading">Active:</Label>
                <br />
                <div>
                  <Switch
                    size={"small"}
                    checked={userDetails?.active}
                    onChange={(e) => {
                      setIsFiledEdited(true);
                      setUserdetails((preValue) => ({
                        ...preValue,
                        active: e?.value,
                      }));
                    }}
                  />
                </div>
                <Error></Error>
              </GridLayoutItem>
              <GridLayoutItem col={3} row={3} className="form-inner">
                <Label className="form-heading">TimeSheets Banner Flag:</Label>
                <br />
                <div>
                  <Switch
                    size={"small"}
                    checked={userDetails?.isTimeSheetsBannerFlag}
                    onChange={(e) => {
                      setIsFiledEdited(true);
                      setUserdetails((preValue) => ({
                        ...preValue,
                        isTimeSheetsBannerFlag: e?.value,
                      }));
                    }}
                  />
                </div>
                <Error></Error>
              </GridLayoutItem>

              <GridLayoutItem col={2} row={5} className="form-inner">
                <Label className="form-heading"> Add Users Image:</Label>
                <div className="k-d-flex" style={{ gap: "15px" }}>
                  <input
                    type="file"
                    hidden
                    accept=".png,.jpg"
                    id="user-profile"
                    style={{ display: "none" }}
                    onChange={(e) => onProfilechange(e)}
                  />
                  {userDetails?.pictureUrl ? (
                    <div className="k-d-flex">
                      <div
                        // className="add-file"
                        style={{ position: "relative" }}>
                        <img
                          className="user-img"
                          src={isCheck(userDetails?.pictureUrl)}
                          alt="Ad"
                        />
                        <button
                          onClick={onRemoveImage}
                          style={{
                            position: "absolute",
                            top: "6px",
                            right: "8px",
                            backgroundColor: "red",
                            color: "white",
                            border: "none",
                            borderRadius: "50%",
                            cursor: "pointer",
                          }}>
                          <span>X</span>
                        </button>
                      </div>
                    </div>
                  ) : (
                    <>
                      <GridLayoutItem col={2} row={1} className="form-inner">
                        <div
                          // className="add-file"
                          style={{
                            position: "relative",
                            cursor: "pointer",
                            border: "0.5px solid #d3d3d3",
                            padding: "5px",
                            display: "flex",
                            justifyContent: "center",
                            borderRadius: "4px",
                            gap: "10px",
                          }}
                          onClick={onSelectProfile}>
                          <IconComponent iconName={"Image"} size={20} />
                          <span className="add-file-text">Choose image</span>
                        </div>
                      </GridLayoutItem>
                    </>
                  )}
                </div>
              </GridLayoutItem>
              <GridLayoutItem col={1} row={4} className="form-inner">
                {isAddFrom ? (
                  <>
                    <Label className="form-heading">Password:</Label>
                    <Input
                      name="password"
                      value={userDetails?.password}
                      className="field-ip"
                      onChange={(e) => onInputChange(e)}
                      type="password"
                    />
                  </>
                ) : (
                  <>
                    <Label className="form-heading">
                      Last Date Logged In :
                    </Label>
                    <Input
                      name="resetCode"
                      value={
                        userDetails?.lastDateLoggedIn
                          ? moment
                            .utc(userDetails?.lastDateLoggedIn)
                            .local()
                            .format("MMM DD YYYY h:mm:A")
                          : ""
                      }
                      disabled
                      className="field-ip"
                    />
                  </>
                )}

                <Error></Error>
              </GridLayoutItem>

              <GridLayoutItem col={2} row={4} className="form-inner">
                <Label className="form-heading">Referred By:</Label>
                <Input
                  name="referredBy"
                  value={userDetails?.referredBy}
                  className="field-ip"
                  onChange={(e) => onInputChange(e)}
                />
              </GridLayoutItem>

              <GridLayoutItem col={3} row={4} className="form-inner">
                <Label className="form-heading">Reffered Source:</Label>
                <Input
                  name="refferedsource"
                  value={userDetails?.refferedsource}
                  className="field-ip"
                  onChange={(e) => onInputChange(e)}
                />
              </GridLayoutItem>

              <GridLayoutItem col={1} row={5} className="form-inner">
                <Label className="form-heading">Survey Date:</Label>
                <DatePicker
                  name="surveyDate"
                  value={userDetails?.surveyDate}
                  onChange={onSurveychange}
                  format="dd/MM/yyyy"
                  className="dropdown-list"
                />
              </GridLayoutItem>
              <GridLayoutItem col={1} row={7} className="form-inner">
                <div className="k-d-flex" style={{ gap: "10px" }}>
                  <KendoButton
                    iconClass=""
                    size={16}
                    className="action-btn"
                    buttonName="Save"
                    onClick={saveCurrentdata}
                  />
                  {/* {!isAddFrom && userDetails?.active === true && (
                    <KendoButton
                      size={16}
                      className=""
                      onClick={showDeleteConfirmation}
                      buttonName="Delete"
                      themeColor={"secondary"}
                    />
                  )} */}
                  <KendoButton
                    iconClass=""
                    themeColor=""
                    size={16}
                    className=""
                    buttonName="Cancel"
                    onClick={onCancelClick}
                  />
                </div>
              </GridLayoutItem>

              {!isAddFrom && (
                <>
                  <GridLayoutItem colSpan={3} row={8} className="form-footer">
                    <Footerinformation
                      footerInfo={userDetails}
                      ownendBy={userDetails?.name}
                    />
                  </GridLayoutItem>
                </>
              )}
            </GridLayout>
          )}
        />
      </section>
      {modalState?.show && (
        <Modal
          for={modalState?.for}
          show={modalState?.show}
          onClose={onModalClose}
          onActionBtnClick={onActionBtnClick}
          onActionBtnTitle={modalState?.onActionBtnTitle}
        />
      )}
    </>
  );
};

export default AddUserForm;
