import React, { useEffect, useState } from "react";
import KendoButton from "../common/KendoButton";
import { useParams } from "react-router-dom";
import { AutoComplete } from "@progress/kendo-react-dropdowns";
import { GridLayout, GridLayoutItem } from "@progress/kendo-react-layout";
import { Error, Label } from "@progress/kendo-react-labels";
import { Form } from "@progress/kendo-react-form";
import { PostRequestCall } from "../apicall/PostRequest";
import { toast } from "react-toastify";
import {
  DELETE_PROJECT_PRODUCTS,
  FETCH_PRODUCTS,
  FETCH_PROJECT_PRODUCTS,
  FETCH_USER_SITES_PROJECTS,
  SAVE_PROJECT_PRODUCTS,
} from "../constant/Apipath";
import { useSelector } from "react-redux";
import { Loader } from "@progress/kendo-react-indicators";
import Modal from "../common/Modal";

const AddProjectProducts = () => {
  const { dataId, subMenu, userId } = useParams();
  const { loginData } = useSelector((state) => state.main);
  const [isFieldEdited, setIsFiledEdited] = useState(false);
  const [projectOptions, setProjectoptions] = useState([]);
  const [selectProject, setSelectproject] = useState(null);
  const [productOptions, setProductOptions] = useState([]);
  const [selectProduct, setSelectProduct] = useState(null);
  const [openProject, setOpenproject] = useState(false);
  const [openCompany, setOpencompany] = useState(false);
  const [showLoader, setShowLoader] = useState(false);

  const [dprojectProduct, setProdjectProduct] = useState({
    id: 0,
    startDate: new Date(),
    endDate: new Date(),
    present: false,
    includeInResume: false,
    description: "",
  });

  const [modalState, setModalState] = useState({
    for: "",
    show: false,
    onActionBtnTitle: "",
  });

  useEffect(() => {
    if (dataId !== "add-project-product") {
      fetchProjectProdcuts();
    }
  }, []);

  useEffect(() => {
    if (selectProject !== null && typeof selectProject?.value === "undefined") {
      if (selectProject !== "") {
        getProjectlist(false);
      }
    }
  }, [selectProject]);

  useEffect(() => {
    if (selectProduct !== null && typeof selectProduct?.value === "undefined") {
      if (selectProduct !== "") {
        getProductlist(false);
      }
    }
  }, [selectProduct]);

  const fetchProjectProdcuts = async () => {
    try {
      let getObject = {
        SearchList: [{ id: dataId }],
      };
      const getResult = await PostRequestCall(
        FETCH_PROJECT_PRODUCTS,
        getObject,
        loginData?.token
      );
      if (getResult?.status === 200) {
        if (getResult?.data?.data?.length > 0) {
          let getUserwork = getResult?.data?.data[0];
          setProdjectProduct({
            ...dprojectProduct,
            startDate: getUserwork?.startDate
              ? new Date(getUserwork?.startDate)
              : null,
            endDate:
              getUserwork?.endDate === null
                ? null
                : new Date(getUserwork?.endDate),
            includeInResume: getUserwork?.includeInResume,
            present: getUserwork?.present,
            description: getUserwork?.description,
          });

          setSelectproject({
            value: getUserwork?.projectId,
            label: getUserwork?.projectName,
          });
          setSelectProduct({
            value: getUserwork?.productId,
            label: getUserwork?.productName,
          });
        }
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    } finally {
    }
  };

  const getProjectlist = async (onFocus) => {
    try {
      let obj = {
        NrOfRecPerPage: onFocus ? 20 : 0,
        FullSearch: "",
        SortList: [{ FieldName: "Id", Direction: "DESC" }],
        SearchList: [{ projectName: selectProject }],
        IncludeRecordNr: true,
        TypeOfObjectReturned: "",
        FetchAllowedRecordsOnly: false,
      };
      const fetchSiteProjectListRes = await PostRequestCall(
        FETCH_USER_SITES_PROJECTS,
        obj,
        loginData?.token
      );
      if (fetchSiteProjectListRes?.data?.data?.length > 0) {
        const getList = fetchSiteProjectListRes?.data?.data.map((project) => ({
          value: project?.id,
          label: project?.projectName,
        }));
        setProjectoptions(getList);
        setOpenproject(true);
      }
    } catch (error) {
      console.log("error", error);
    }
  };

  const getProductlist = async (onFocus) => {
    try {
      let productObj = {
        NrOfRecPerPage: onFocus ? 20 : 0,
        FullSearch: "",
        UserId: loginData.userId,
        SearchList: [{ name: selectProduct }],
        SortList: [{ FieldName: "Id", Direction: "ASC" }],
        IncludeRecordNr: true,
      };
      const productRes = await PostRequestCall(
        FETCH_PRODUCTS,
        productObj,
        loginData?.token
      );
      let productData = productRes?.data?.data?.map((record) => ({
        value: record?.id,
        label: record?.name,
      }));
      setProductOptions(productData ?? []);
      setOpencompany(true);
    } catch (error) {
      console.log("Error while getting company list :: ", error);
    }
  };

  const onProjectchange = (event) => {
    setSelectproject(event?.target?.value);
  };

  const onCompanychange = (event) => {
    setSelectProduct(event?.target?.value);
  };

  const projectSelect = (project) => {
    setSelectproject(project);
    setOpenproject(false);
  };

  const companySelect = (company) => {
    setSelectProduct(company);
    setOpencompany(false);
  };

  const onProjectRender = (li, itemProps) => {
    const project = itemProps.dataItem;
    return (
      <div className="user-item" onClick={() => projectSelect(project)}>
        <span>{project?.label}</span>
      </div>
    );
  };

  const onCompanyRender = (li, itemProps) => {
    const company = itemProps.dataItem;
    return (
      <div className="user-item" onClick={() => companySelect(company)}>
        <span>{company?.label}</span>
      </div>
    );
  };

  const saveCurrentdata = async () => {
    let getObject = {
      ReturnRecordId: true,
      ReturnRecordError: true,
      UserId: loginData?.userId,
      SaveList: [
        {
          Id: dataId !== "add-project-product" ? dataId : 0,
          ProjectId: selectProject?.value,
          ProductId: selectProduct?.value,
          ModifiedBy: loginData?.userId,
        },
      ],
    };
    await PostRequestCall(SAVE_PROJECT_PRODUCTS, getObject, loginData?.token)
      .then((res) => {
        if (res?.data?.status) {
          toast.success("Data updated successfully", {
            position: "top-right",
          });
          window.history.back();
        } else {
          setShowLoader(false);
          toast.error(
            res?.data?.data?.[0]?.message ??
              "Something went wrong. Please try again later.",
            {
              position: "top-right",
            }
          );
        }
      })
      .catch((error) => {
        setShowLoader(false);
        toast.error("Something went wrong. Please try again later.", {
          position: "top-right",
        });
        console.log("Error while updating a records :: ", error);
      });
  };

  const onModalClose = () => {
    window.history.back();
  };

  const onActionBtnClick = () => {
    setModalState((preValue) => ({
      ...preValue,
      for: "",
      show: false,
      onActionBtnTitle: "",
    }));
    if (modalState?.for === "delete") {
      onDeleteClick();
    } else if (modalState?.for === "edit") {
      saveCurrentdata();
    } else {
      return null;
    }
  };

  const onDeleteClick = async () => {
    try {
      setShowLoader(true);

      const deleteObj = {
        UserId: 1,
        ReturnRecordId: false,
        ReturnRecordError: true,
        DeleteList: [
          {
            Id: Number(dataId),
            ModifiedBy: loginData?.userId,
          },
        ],
      };
      PostRequestCall(DELETE_PROJECT_PRODUCTS, deleteObj, loginData?.token)
        .then(async (res) => {
          setShowLoader(false);
          if (res?.data?.status) {
            toast.success("Data deleted successfully", {
              position: "top-right",
            });
            window.history.back();
          } else {
            toast.error(
              res?.data?.message ??
                "Something went wrong. Please try again later.",
              {
                position: "top-right",
              }
            );
          }
        })
        .catch(async (error) => {
          setShowLoader(false);
          toast.error("Something went wrong. Please try again later.", {
            position: "top-right",
          });
        });
    } catch (error) {
      setShowLoader(false);
      toast.error("Something went wrong. Please try again later.", {
        position: "top-right",
      });
    }
  };

  const showDeleteConfirmation = () => {
    setModalState((preValue) => ({
      ...preValue,
      for: "delete",
      show: true,
      onActionBtnTitle: "Delete",
    }));
  };

  const onCancelClick = () => {
    if (isFieldEdited) {
      return setModalState((preValue) => ({
        ...preValue,
        for: "edit",
        show: true,
        onActionBtnTitle: "Save",
      }));
    } else {
      return window.history.back();
    }
  };

  if (showLoader) {
    return (
      <Loader
        type="converging-spinner"
        className="kendo-spinner"
        style={{
          display: "flex",
          justifyContent: "center",
          minHeight: "500px",
          alignItems: "center",
        }}
      />
    );
  }

  return (
    <>
      <section className="add-user">
        <>
          <section className="user-information">
            <div className="k-d-flex k-justify-content-between">
              <div className="k-d-flex k-align-items-center">
                {dataId !== "add-project-product" && (
                  <span class="record-id-text">
                    User Work History: {dataId}
                  </span>
                )}
              </div>
              <div
                className="k-d-flex k-justify-content-end k-p-2"
                style={{ gap: "10px" }}>
                <KendoButton
                  iconClass=""
                  size={16}
                  className=""
                  themeColor={"primary"}
                  buttonName="Save"
                  onClick={saveCurrentdata}
                />
                {dataId !== "add-project-product" && (
                  <KendoButton
                    size={16}
                    className=""
                    onClick={showDeleteConfirmation}
                    buttonName="Delete"
                    themeColor={"secondary"}
                  />
                )}
                <KendoButton
                  iconClass=""
                  size={16}
                  className=""
                  buttonName="Cancel"
                  onClick={onCancelClick}
                />
              </div>
            </div>
            <div
              style={{
                borderBottom: "1px solid #f5f5f5",
                width: "100%",
              }}></div>
            <Form
              render={(formRenderProps) => (
                <GridLayout
                  rows={[
                    {
                      height: 70,
                    },
                    {
                      height: 70,
                    },
                    {
                      height: 70,
                    },
                    {
                      height: 70,
                    },
                    {
                      height: 80,
                    },
                    {
                      height: 70,
                    },
                  ]}
                  cols={[
                    {
                      width: 375,
                    },
                    {
                      width: 375,
                    },
                    {
                      width: 375,
                    },
                  ]}
                  gap={{
                    rows: 25,
                    cols: 35,
                  }}
                  style={{ padding: "25px 16px", gap: "20px 35px" }}>
                  <GridLayoutItem col={1} row={1} className="form-inner">
                    <Label className="form-heading">Project :</Label>
                    <AutoComplete
                      data={projectOptions}
                      onFocus={() => getProjectlist(true)}
                      onClose={() => setOpenproject(false)}
                      onBlur={() => setOpenproject(false)}
                      opened={openProject}
                      value={selectProject ? selectProject?.label : ""}
                      itemRender={onProjectRender}
                      className="field-ip"
                      onChange={onProjectchange}
                      textField="label"
                      placeholder="Select project..."
                    />
                    <Error></Error>
                  </GridLayoutItem>
                  <GridLayoutItem col={2} row={1} className="form-inner">
                    <Label className="form-heading">Products :</Label>
                    <AutoComplete
                      data={productOptions}
                      onFocus={() => getProductlist(true)}
                      onClose={() => setOpencompany(false)}
                      onBlur={() => setOpencompany(false)}
                      opened={openCompany}
                      value={selectProduct ? selectProduct?.label : ""}
                      className="field-ip"
                      itemRender={onCompanyRender}
                      onChange={onCompanychange}
                      textField="label"
                      placeholder="Select products..."
                    />
                    <Error></Error>
                  </GridLayoutItem>

                  <GridLayoutItem col={1} row={6} className="form-inner">
                    <div className="k-d-flex" style={{ gap: "10px" }}>
                      <KendoButton
                        iconClass=""
                        size={16}
                        themeColor={"primary"}
                        buttonName="Save"
                        onClick={saveCurrentdata}
                      />
                      {dataId !== "add-project-product" && (
                        <KendoButton
                          size={16}
                          className=""
                          onClick={showDeleteConfirmation}
                          buttonName="Delete"
                          themeColor={"secondary"}
                        />
                      )}
                      <KendoButton
                        iconClass=""
                        size={16}
                        className=""
                        buttonName="Cancel"
                        onClick={onCancelClick}
                      />
                    </div>
                  </GridLayoutItem>
                </GridLayout>
              )}
            />
          </section>
        </>
      </section>
      {modalState?.show && (
        <Modal
          for={modalState?.for}
          show={modalState?.show}
          onClose={onModalClose}
          onActionBtnClick={onActionBtnClick}
          onActionBtnTitle={modalState?.onActionBtnTitle}
        />
      )}
    </>
  );
};

export default AddProjectProducts;
