import React, { useEffect, useRef, useState } from "react";
import { AutoComplete, DropDownList } from "@progress/kendo-react-dropdowns";
import { useLocation, useParams } from "react-router-dom";
import {
  FETCH_RECORD_STATUSES,
  FETCH_TICKETS,
  FETCH_TICKET_TYPES,
  FETCH_MANUFACTURER,
  SAVE_TICKETS,
  DELETE_TICKET,
} from "../../constant/Apipath";
import { PostRequestCall } from "../../apicall/PostRequest";
import { Input } from "@progress/kendo-react-inputs";
import { useSelector } from "react-redux";
import { Form } from "@progress/kendo-react-form";
import { GridLayout, GridLayoutItem } from "@progress/kendo-react-layout";
import { Error, Label } from "@progress/kendo-react-labels";
import KendoButton from "../../common/KendoButton";
import { IconComponent } from "../../common/Icon";
import { toast } from "react-toastify";
import { Loader } from "@progress/kendo-react-indicators";
import SharedTickets from "../sharedtickets/SharedTickets";
import { DatePicker } from "@progress/kendo-react-dateinputs";
import moment from "moment";
import Modal from "../../common/Modal";
import Footerinformation from "../../common/Footerinformation";

const AddNewTicket = () => {
  const { ticketId } = useParams();
  const { loginData } = useSelector((state) => state.main);
  const listRef = useRef(null);
  const location = useLocation();
  const isAddForm = Number(ticketId) === 0;

  const [createdBy, setCreatedBy] = useState("");
  const [chatGroupOptions, setChaGroupOptions] = useState([]);
  const [selectTicketType, setSelectChatGroup] = useState(null);
  const [userOptions, setUserOptions] = useState([]);
  const [selectIssueByUser, setToUser] = useState(null);
  const [ticketTypeOpen, setTicketTypeOpen] = useState(false);
  const [openIssueByList, setIssueByOpen] = useState(false);
  const [showLoader, setShowLoader] = useState(false);
  const [recordStatusOptions, setRecordStatusOptions] = useState([]);
  const [tickets, setTickets] = useState({
    name: "",
    description: "",
    backPictureUrl: "",
    frontPictureUrl: "",
    recordStatus: "New",
    recordStatusId: 1,
    expiry: "",
    issuedOn: "",
  });

  const [modalState, setModalState] = useState({
    for: "",
    show: false,
    onActionBtnTitle: "",
  });

  useEffect(() => {
    getRecordStatuses();
    if (
      selectTicketType !== null &&
      typeof selectTicketType?.value === "undefined"
    ) {
      if (selectTicketType !== "") {
        getTicketType(false);
      }
    }
  }, [selectTicketType]);

  useEffect(() => {
    if (!isAddForm) {
      getTickets();
    }
    getRecordStatuses();
  }, []);

  useEffect(() => {
    if (
      selectIssueByUser !== null &&
      typeof selectIssueByUser?.value === "undefined"
    ) {
      if (selectIssueByUser !== "") {
        getIssuedByList(false);
      }
    }
  }, [selectIssueByUser]);

  const getTicketType = async (onFocus) => {
    try {
      var obj = JSON.stringify({
        PageNr: 1,
        NrOfRecPerPage: 100,
        FullSearch: "",
        UserId: loginData.userId,
        SortList: [{ FieldName: "Id", Direction: "ASC" }],
        SearchList: onFocus ? [] : [{ Name: selectTicketType }],
        IncludeRecordNr: true,
      });
      const fetchSiteProjectListRes = await PostRequestCall(
        FETCH_TICKET_TYPES,
        obj,
        loginData?.token
      );
      if (fetchSiteProjectListRes?.data?.data?.length > 0) {
        const getList = fetchSiteProjectListRes?.data?.data.map((project) => ({
          value: String(project?.id),
          label: String(project?.name),
        }));
        setChaGroupOptions(getList);
        setTicketTypeOpen(true);
      }
    } catch (error) {
      console.log("error", error);
    }
  };

  const getIssuedByList = async (onFocus) => {
    try {
      let companyObj = {
        NrOfRecPerPage: onFocus ? 20 : 0,
        FullSearch: "",
        UserId: loginData.userId,
        SearchList: onFocus ? [] : [{ name: selectIssueByUser }],
        SortList: [{ FieldName: "Id", Direction: "ASC" }],
        IncludeRecordNr: true,
      };
      const companyRes = await PostRequestCall(
        FETCH_MANUFACTURER,
        companyObj,
        loginData?.token
      );
      let UserData = companyRes?.data?.data?.map((record) => ({
        value: record?.id,
        label: record?.name,
      }));
      setUserOptions(UserData ?? []);
      setIssueByOpen(true);
    } catch (error) {
      console.log("Error while getting company list :: ", error);
    }
  };

  const getRecordStatuses = async () => {
    try {
      let recordStatusesObj = {
        PageNr: 1,
        NrOfRecPerPage: 100,
        FullSearch: "",
        UserId: "1",
        SortList: [
          {
            FieldName: "Id",
            Direction: "ASC",
          },
        ],
        IncludeRecordNr: true,
      };
      const recordStatusesRes = await PostRequestCall(
        FETCH_RECORD_STATUSES,
        recordStatusesObj,
        loginData?.token
      );
      let recordStatusesData = recordStatusesRes?.data?.data?.map((record) => ({
        id: record?.id,
        text: record?.name,
      }));
      setRecordStatusOptions(recordStatusesData ?? []);
    } catch (error) {
      console.log("Error while getting record statuses data :: ", error);
    }
  };

  const projectSelect = (groupID) => {
    setSelectChatGroup(groupID);
    setTicketTypeOpen(false);
  };

  const getTickets = async () => {
    var data = JSON.stringify({
      PageNr: 1,
      NrOfRecPerPage: 20,
      FullSearch: "",
      UserId: loginData?.userId,
      TypeOfObjectReturned: "",
      SearchList: [
        {
          Id: ticketId,
        },
      ],
      SortList: [
        {
          FieldName: "Id",
          Direction: "ASC",
        },
      ],
      IncludeRecordNr: true,
    });
    const fetchuserAuthRes = await PostRequestCall(
      FETCH_TICKETS,
      data,
      loginData?.token
    );
    let ticketsData = fetchuserAuthRes?.data?.data[0];
    setTickets({
      ...tickets,
      ...ticketsData,
      backPictureUrl: ticketsData?.backPictureUrl ?? "",
      frontPictureUrl: ticketsData?.frontPictureUrl ?? "",
      recordStatus: ticketsData?.recordStatus,
      recordStatusId: ticketsData?.recordStatusId,
      ticketId: ticketsData?.ticketId,
      expiry: ticketsData?.expiry ? new Date(ticketsData?.expiry) : "",
      issuedOn: ticketsData?.issuedOn ? new Date(ticketsData?.issuedOn) : "",
    });
    setCreatedBy(ticketsData?.createdBy);
    setSelectChatGroup({
      value: ticketsData?.ticketTypeId,
      label: ticketsData?.ticketType,
    });
    setToUser({
      value: ticketsData?.issuedById,
      label: ticketsData?.issuedBy,
    });
  };

  const onInputChange = (e) => {
    setTickets({
      ...tickets,
      [e.target.name]: e.target.value,
    });
  };

  const onFrontPiture = (e) => {
    if (e?.target?.files?.length > 0) {
      setTickets({
        ...tickets,
        frontPictureUrl: e.target.files[0],
      });
    }
    e.target.value = "";
  };

  const onBackPiture = (e) => {
    if (e?.target?.files?.length > 0) {
      setTickets({
        ...tickets,
        backPictureUrl: e.target.files[0],
      });
    }

    e.target.value = "";
  };

  const onSelectProfile = (e) => {
    e.preventDefault();
    document.getElementById("user-profile").click();
  };
  const onSelectBackImage = (e) => {
    e.preventDefault();
    document.getElementById("user-profile2").click();
  };

  const saveCurrentdata = async () => {
    if (selectTicketType?.value == undefined) {
      toast.error("Please Select Ticket Type.", {
        position: "top-right",
      });
      return;
    }
    setShowLoader(true);

    if (
      !tickets?.expiry ||
      !selectIssueByUser?.value ||
      !tickets?.issuedOn ||
      !tickets?.frontPictureUrl
    ) {
      setShowLoader(false);
      toast.error(
        "Please fill out all required fields: Expiry, Issued On, Front Image and Issued By.",
        {
          position: "top-right",
        }
      );
      return;
    }

    const getRequest = new FormData();

    if (!isAddForm) {
      getRequest.append("Id", ticketId);
      getRequest.append("CreatedBy", createdBy);
    } else {
      getRequest.append("Id", 0);
      getRequest.append("CreatedBy", loginData?.userId);
    }

    getRequest.append("TicketTypeId", selectTicketType?.value || 0);
    getRequest.append(
      "Expiry",
      moment(tickets?.expiry).format("YYYY-MM-DD") || ""
    );
    getRequest.append("TicketId", tickets?.ticketId || 0);
    getRequest.append("IssuedById", selectIssueByUser?.value);
    getRequest.append(
      "IssuedOn",
      moment(tickets?.issuedOn).format("YYYY-MM-DD") ||
      moment().format("YYYY-MM-DD")
    );
    getRequest.append("ImageFront", tickets?.frontPictureUrl || "");
    getRequest.append("ImageBack", tickets?.backPictureUrl || "");
    getRequest.append("RecordStatusId", tickets?.recordStatusId || 1);
    getRequest.append("ModifiedBy", loginData?.userId);

    let getResponse = await PostRequestCall(
      SAVE_TICKETS,
      getRequest,
      loginData?.token,
      "formData"
    );

    if (getResponse.data.status === false) {
      setShowLoader(false);
      toast.error(
        getResponse?.data?.data?.[0].message ??
        "Something went wrong. Please try again later.",
        {
          position: "top-right",
        }
      );
    } else {
      setShowLoader(false);
      toast.success(`Data ${!isAddForm ? "updated" : "added"} successfully`, {
        position: "top-right",
      });
      window.history.back();
    }
  };

  const cancelCurrentdata = () => {
    window.history.back();
  };

  // Handle Image
  const onRemoveFrontImg = () => {
    setTickets({
      ...tickets,
      frontPictureUrl: "",
    });
  };

  const onRemoveBackImage = () => {
    setTickets({
      ...tickets,
      backPictureUrl: "",
    });
  };

  const checkImg = (frontPictureUrl) => {
    try {
      if (Object.keys(frontPictureUrl)?.length === 0) {
        return URL.createObjectURL(frontPictureUrl);
      } else {
        return frontPictureUrl;
      }
    } catch (error) {
      console.log("Error while getting front picture url ::", error);
    }
  };

  const showDeleteConfirmation = () => {
    setModalState((preValue) => ({
      ...preValue,
      for: "delete",
      show: true,
      onActionBtnTitle: "Delete",
    }));
  };

  const onDeleteClick = async () => {
    try {
      setShowLoader(true);
      const deleteObj = [
        {
          Id: Number(ticketId),
          ModifiedBy: loginData?.userId,
        },
      ];
      const newuserTrades = {
        ReturnRecordError: true,
        ReturnRecordId: false,
        UserId: loginData?.userId,
        DeleteList: deleteObj,
      };
      return PostRequestCall(DELETE_TICKET, newuserTrades, loginData?.token)
        .then((res) => {
          setShowLoader(false);
          if (res?.status === 201) {
            toast.success("Trade deleted successfully", {
              position: "top-right",
            });

            window.history.back();
          } else {
            toast.error(
              res?.data?.message ??
              "Something went wrong. Please try again later...",
              {
                position: "top-right",
              }
            );
          }
        })
        .catch((error) => {
          setShowLoader(false);
          toast.error("Something went wrong. Please try again later.", {
            position: "top-right",
          });
          console.log("Error while deleting a record :: ", error);
        });
    } catch (error) {
      setShowLoader(false);
      toast.error("Something went wrong. Please try again later.", {
        position: "top-right",
      });
      console.log("Error while deleting a record :: ", error);
    }
  };

  const onTicketTypeOnChange = (event) => {
    setSelectChatGroup(event?.target?.value);
  };

  const onTicketTypeRender = (li, itemProps) => {
    const project = itemProps.dataItem;
    return (
      <div className="user-item" onClick={() => projectSelect(project)}>
        <span>{project?.label}</span>
      </div>
    );
  };

  const toUserSelect = (user) => {
    setToUser(user);
    setIssueByOpen(false);
  };

  const onIssueByRender = (li, itemProps) => {
    const company = itemProps.dataItem;
    return (
      <div className="user-item" onClick={() => toUserSelect(company)}>
        <span>{company?.label}</span>
      </div>
    );
  };
  const onActionBtnClick = () => {
    onModalClose();
    if (modalState?.for === "delete") {
      onDeleteClick();
    } else {
      return null;
    }
  };

  const onModalClose = () => {
    setModalState((preValue) => ({
      ...preValue,
      for: "",
      show: false,
      onActionBtnTitle: "",
    }));
  };

  const onIssueByOnChange = (event) => {
    setToUser(event?.target?.value);
  };

  if (showLoader) {
    return (
      <Loader
        type="converging-spinner"
        className="kendo-spinner"
        style={{
          display: "flex",
          justifyContent: "center",
          minHeight: "500px",
          alignItems: "center",
        }}
      />
    );
  }

  return (
    <>
      <section className="add-user">
        <>
          <section className="user-information">
            <div className="k-d-flex k-justify-content-between">
              <div className="k-d-flex k-align-items-center">
                {!isAddForm && (
                  <span class="record-id-text">Ticket Id : {ticketId}</span>
                )}
              </div>
              <div
                className="k-d-flex k-justify-content-end k-p-2"
                style={{ gap: "10px" }}>
                <KendoButton
                  iconClass=""
                  size={16}
                  className=""
                  themeColor={"primary"}
                  buttonName="Save"
                  onClick={saveCurrentdata}
                />
                {!isAddForm && (
                  <KendoButton
                    size={16}
                    className=""
                    themeColor={"secondary"}
                    onClick={showDeleteConfirmation}
                    buttonName="Delete"
                  />
                )}
                <KendoButton
                  iconClass=""
                  size={16}
                  className=""
                  buttonName="Cancel"
                  onClick={cancelCurrentdata}
                />
              </div>
            </div>
            <div
              style={{
                borderBottom: "1px solid #f5f5f5",
                width: "100%",
              }}></div>
            <Form
              render={(formRenderProps) => (
                <GridLayout
                  rows={[
                    {
                      height: 70,
                    },
                    {
                      height: 70,
                    },
                    {
                      height: 70,
                    },
                    {
                      height: 70,
                    },
                  ]}
                  cols={[
                    {
                      width: 375,
                    },
                    {
                      width: 375,
                    },
                    {
                      width: 375,
                    },
                  ]}
                  gap={{
                    rows: 30,
                    cols: 35,
                  }}
                  style={{ padding: "16px" }}>
                  <GridLayoutItem col={1} row={1} className="form-inner">
                    <Label className="form-heading">Ticket Type:</Label>

                    <AutoComplete
                      data={chatGroupOptions}
                      onFocus={() => getTicketType(true)}
                      onClose={() => setTicketTypeOpen(false)}
                      onBlur={() => setTicketTypeOpen(false)}
                      opened={ticketTypeOpen}
                      value={selectTicketType ? selectTicketType?.label : ""}
                      itemRender={onTicketTypeRender}
                      className="field-ip"
                      onChange={onTicketTypeOnChange}
                      textField="label"
                      placeholder="Select Ticket Type..."
                    />
                  </GridLayoutItem>

                  <GridLayoutItem col={2} row={1} className="form-inner">
                    <Label className="form-heading"> Ticket Id :</Label>
                    <Input
                      name="ticketId"
                      type="number"
                      value={tickets?.ticketId}
                      className="field-ip"
                      onChange={(e) => onInputChange(e)}
                    />
                    <Error></Error>
                  </GridLayoutItem>
                  <GridLayoutItem col={3} row={1} className="form-inner">
                    <Label className="form-heading">Issued By :</Label>

                    <AutoComplete
                      data={userOptions}
                      onFocus={() => getIssuedByList(true)}
                      onClose={() => setIssueByOpen(false)}
                      onBlur={() => setIssueByOpen(false)}
                      opened={openIssueByList}
                      value={selectIssueByUser ? selectIssueByUser?.label : ""}
                      className="field-ip"
                      itemRender={onIssueByRender}
                      onChange={onIssueByOnChange}
                      textField="label"
                      // disabled={!isAddForm}
                      placeholder="Select issued by..."
                    />
                  </GridLayoutItem>
                  <GridLayoutItem col={1} row={2} className="form-inner">
                    <Label className="form-heading">Issued Date :</Label>
                    <DatePicker
                      id="startDate"
                      value={tickets?.issuedOn}
                      name="issuedOn"
                      format={"dd/MM/yyyy"}
                      className="field-ip"
                      onChange={onInputChange}
                    />
                    <Error></Error>
                  </GridLayoutItem>
                  <GridLayoutItem col={2} row={2} className="form-inner">
                    <Label className="form-heading">Expiry Date :</Label>
                    <DatePicker
                      id="endDate"
                      value={tickets?.expiry}
                      name="expiry"
                      format={"dd/MM/yyyy"}
                      className="field-ip"
                      onChange={onInputChange}
                    />
                    <Error></Error>
                  </GridLayoutItem>

                  {!isAddForm && (
                    <GridLayoutItem
                      col={3}
                      row={2}
                      className="form-inner"
                      style={{ width: "163px" }}>
                      <div className="form-group">
                        <Label className="form-heading">Record Status :</Label>
                        <div>
                          <DropDownList
                            style={{ width: "370px" }}
                            onChange={(e) => {
                              setTickets((preValue) => ({
                                ...preValue,
                                recordStatus: e.value?.text,
                                recordStatusId: e.value?.id,
                              }));
                            }}
                            className="dropdown-list"
                            value={{
                              id: tickets?.recordStatusId,
                              text: tickets?.recordStatus,
                            }}
                            data={recordStatusOptions}
                            textField="text"
                            dataItemKey="id"
                          />
                        </div>
                      </div>
                    </GridLayoutItem>
                  )}
                  <GridLayoutItem col={1} row={3} className="form-inner">
                    <Label className="form-heading">Front Image :</Label>
                    <div className="k-d-flex" style={{ gap: "15px" }}>
                      <input
                        type="file"
                        hidden
                        accept=".png,.jpg"
                        id="user-profile"
                        style={{ display: "none" }}
                        onChange={(e) => {
                          onFrontPiture(e);
                        }}
                      />
                      {tickets?.frontPictureUrl ? (
                        <div className="k-d-flex">
                          <div style={{ position: "relative" }}>
                            <img
                              className="user-img"
                              src={checkImg(tickets?.frontPictureUrl)}
                              alt="Ad"
                            />
                            <button
                              onClick={onRemoveFrontImg}
                              style={{
                                position: "absolute",
                                top: "6px",
                                right: "8px",
                                backgroundColor: "red",
                                color: "white",
                                border: "none",
                                borderRadius: "50%",
                                cursor: "pointer",
                              }}>
                              <span>X</span>
                            </button>
                          </div>
                        </div>
                      ) : (
                        <>
                          <GridLayoutItem
                            col={2}
                            row={1}
                            className="form-inner">
                            <div
                              style={{
                                position: "relative",
                                cursor: "pointer",
                                border: "0.5px solid #d3d3d3",
                                padding: "5px",
                                display: "flex",
                                justifyContent: "center",
                                borderRadius: "4px",
                                gap: "10px",
                              }}
                              onClick={onSelectProfile}>
                              <IconComponent iconName={"Image"} size={20} />
                              <span className="add-file-text">
                                Choose image
                              </span>
                            </div>
                          </GridLayoutItem>
                        </>
                      )}
                    </div>
                  </GridLayoutItem>
                  <GridLayoutItem col={2} row={3} className="form-inner">
                    <Label className="form-heading">Back Image :</Label>
                    <div className="k-d-flex" style={{ gap: "15px" }}>
                      <input
                        type="file"
                        hidden
                        accept=".png,.jpg"
                        id="user-profile2"
                        style={{ display: "none" }}
                        onChange={(e) => {
                          onBackPiture(e);
                        }}
                      />
                      {tickets?.backPictureUrl ? (
                        <div className="k-d-flex">
                          <div style={{ position: "relative" }}>
                            <img
                              className="user-img"
                              src={checkImg(tickets?.backPictureUrl)}
                              alt="Ad"
                            />
                            <button
                              onClick={onRemoveBackImage}
                              style={{
                                position: "absolute",
                                top: "6px",
                                right: "8px",
                                backgroundColor: "red",
                                color: "white",
                                border: "none",
                                borderRadius: "50%",
                                cursor: "pointer",
                              }}>
                              <span>X</span>
                            </button>
                          </div>
                        </div>
                      ) : (
                        <>
                          <GridLayoutItem
                            col={2}
                            row={1}
                            className="form-inner">
                            <div
                              style={{
                                position: "relative",
                                cursor: "pointer",
                                border: "0.5px solid #d3d3d3",
                                padding: "5px",
                                display: "flex",
                                justifyContent: "center",
                                borderRadius: "4px",
                                gap: "10px",
                              }}
                              onClick={onSelectBackImage}>
                              <IconComponent iconName={"Image"} size={20} />
                              <span className="add-file-text">
                                Choose image
                              </span>
                            </div>
                          </GridLayoutItem>
                        </>
                      )}
                    </div>
                  </GridLayoutItem>
                  <GridLayoutItem col={1} row={5} className="form-inner">
                    <div className="k-d-flex k-mt-5" style={{ gap: "10px" }}>
                      <KendoButton
                        iconClass=""
                        size={16}
                        className=""
                        themeColor={"primary"}
                        buttonName="Save"
                        onClick={saveCurrentdata}
                      />
                      {!isAddForm && (
                        <KendoButton
                          size={16}
                          className=""
                          themeColor={"secondary"}
                          onClick={showDeleteConfirmation}
                          buttonName="Delete"
                        />
                      )}
                      <KendoButton
                        iconClass=""
                        size={16}
                        className=""
                        buttonName="Cancel"
                        onClick={cancelCurrentdata}
                      />
                    </div>
                  </GridLayoutItem>
                  {!isAddForm && (
                    <GridLayoutItem colSpan={7} row={6} className="form-footer">
                      <Footerinformation footerInfo={tickets} />
                    </GridLayoutItem>
                  )}
                </GridLayout>
              )}
            />
          </section>
        </>

        {modalState?.show && (
          <Modal
            for={modalState?.for}
            show={modalState?.show}
            onClose={onModalClose}
            onActionBtnClick={onActionBtnClick}
            onActionBtnTitle={modalState?.onActionBtnTitle}
          />
        )}
      </section>
    </>
  );
};

export default AddNewTicket;
