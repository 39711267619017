import React from "react";
import { useParams } from "react-router-dom";
import ChatGroupMessages from "../chatgroupmessages/ChatGroupMessages";

const ChatDetailsLayout = () => {
  const { chatSubMenu } = useParams();

  const renderTableListBasedOnUrl = () => {
    let ui = <></>;
    switch (chatSubMenu) {
      case "chat-groups-messages":
        ui = (
          <>
            {" "}
            <ChatGroupMessages />{" "}
          </>
        );
        break;

      default:
        ui = <></>;
        break;
    }

    return ui;
  };
  return <>{renderTableListBasedOnUrl()}</>;
};

export default ChatDetailsLayout;
