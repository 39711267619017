import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import {
  DELETE_POSTS,
  FETCH_POSTS,
  SAVE_POSTS,
} from "../../constant/Apipath";
import { PostRequestCall } from "../../apicall/PostRequest";
import { Input, TextArea } from "@progress/kendo-react-inputs";
import { useSelector } from "react-redux";
import { GridLayout, GridLayoutItem } from "@progress/kendo-react-layout";
import { Label } from "@progress/kendo-react-labels";
import { toast } from "react-toastify";
import { Loader } from "@progress/kendo-react-indicators";
import { Form } from "@progress/kendo-react-form";
import UserSearchInput from "../../common/UserSearchInput";
import AddPageLayout from "../../addpagelayout/AddPageLayout";
import RecordStatusInput from "../../common/RecordStatusInput";
import AppSearchInput from "../../common/AppSearchInput";

const AddNewPost = () => {
  const { postId, subMenu, userId } = useParams();
  const { loginData } = useSelector((state) => state.main);
  const isAddForm = Number(postId) === 0;
  const [footerInfo, setFooterInfo] = useState({});

  let navigate = useNavigate();

  const [post, setPost] = useState({
    userName: "",
    userId: userId ?? 0,
    formatTypeId: "",
    postedByUserId: 0,
    appId: 0,
    appName: "",
    appRecordId: "",
    message: "",
    recordStatusId: 1,
    recordStatus: "New",
  });

  const [showLoader, setShowLoader] = useState(false);
  const [isFieldEdited, setIsFiledEdited] = useState(false);

  useEffect(() => {
    if (!isAddForm) {
      loadPostDetails();
    }
  }, []);

  const loadPostDetails = async () => {
    if (!isAddForm) {
      var obj = JSON.stringify({
        PageNr: 1,
        NrOfRecPerPage: 20,
        FullSearch: "",
        SearchList: [
          {
            Id: postId,
          },
        ],
        SortList: [
          {
            FieldName: "Id",
            Direction: "ASC",
          },
        ],
        IncludeRecordNr: true,
      });

      const result = await PostRequestCall(FETCH_POSTS, obj, loginData?.token);
      let data = result?.data?.data?.[0] ?? null;
      setPost((prevData) => ({
        ...prevData,
        ...data,
        userId: data?.postedByUserId,
        userName: data?.postedByUserName,
      }));
      let footerObj = {
        dateCreated: data?.dateCreated ?? null,
        modifiedByUser: data?.modifiedByUser ?? "",
        dateModified: data?.dateModified ?? "",
        createdByUser: data?.createdByUser ?? "",
      };
      setFooterInfo(footerObj);
    }
  };

  const onInputChange = (e, type) => {
    let inputValue = e.target.value;
    if (type === "number") {
      inputValue = inputValue.replace(/\D/g, "");
    }
    setIsFiledEdited(true);
    setPost({ ...post, [e.target.name]: inputValue });
  };

  const onNumberInputChange = (e) => {
    let inputValue = e.target.value;
    if (/^\d*$/.test(inputValue)) {
      setPost({
        ...post,
        [e.target.name]: inputValue,
      });
      setIsFiledEdited(true);
    }
  }

  const saveCurrentData = async () => {

    if (post?.userId === 0) {
      return toast.error("Please select a vaild user", { position: "top-right" });
    }
    if (post?.appId === 0) {
      return toast.error("Please select a vaild app", { position: "top-right" });
    }

    if (post.message?.trim() === "") {
      return toast.error("Please enter a vaild message", { position: "top-right" });
    }


    const obj = {
      UserId: loginData?.userId,
      ReturnRecordId: true,
      ReturnRecordError: true,
      SaveList: [
        {
          Id: isAddForm ? 0 : postId,
          FormatTypeId: post.formatTypeId || 1,
          PostedByUserId: post?.userId,
          AppId: post?.appId ?? 0,
          RecordStatusId: post?.recordStatusId || 1,
          AppRecordId: post?.appRecordId || 0,
          Message: post?.message,
          ModifiedBy: isAddForm ? post?.userId : loginData?.userId,
        },
      ],
    };

    setShowLoader(true);

    try {
      const result = await PostRequestCall(SAVE_POSTS, obj, loginData.token);

      if (result.data.status === false) {
        toast.error(
          result?.data?.message ??
          "Something went wrong. Please try again later.",
          { position: "top-right" }
        );
        setShowLoader(false);
      } else {
        toast.success(`Data ${!isAddForm ? "updated" : "added"} successfully`, {
          position: "top-right",
        });
        if (userId) {
          navigate(`/user/accounts/users/${userId}/user-post`)
        } else {
          navigate('/user/post/posts')
        }
      }
    } catch (error) {
      setShowLoader(false);
      toast.error("Something went wrong. Please try again later.", {
        position: "top-right",
      });
      console.error("Error saving data:", error);
    }
  };

  const onDeleteClick = async () => {
    try {
      setShowLoader(true);
      const deleteObj = {
        ReturnRecordError: true,
        ReturnRecordId: false,
        UserId: loginData.userId,
        DeleteList: [
          {
            Id: postId,
            ModifiedBy: loginData.userId,
          },
        ],
      };
      return PostRequestCall(DELETE_POSTS, deleteObj, loginData?.token)
        .then((res) => {
          setShowLoader(false);
          if (res?.data?.status) {
            toast.success("Data deleted successfully", {
              position: "top-right",
            });
            if (userId) {
              navigate(`/user/accounts/users/${userId}/user-post`)
            } else {
              navigate('/user/post/posts')
            }
          } else {
            toast.error(
              res?.data?.message ??
              "Something went wrong. Please try again later.",
              {
                position: "top-right",
              }
            );
          }
        })
        .catch((error) => {
          setShowLoader(false);
          toast.error("Something went wrong. Please try again later.", {
            position: "top-right",
          });
          console.log("Error while deleting a record :: ", error);
        });
    } catch (error) {
      setShowLoader(false);
      toast.error("Something went wrong. Please try again later.", {
        position: "top-right",
      });
      console.log("Error while deleting a record :: ", error);
    }
  };


  if (showLoader) {
    return (
      <Loader
        type="converging-spinner"
        className="kendo-spinner"
        style={{
          display: "flex",
          justifyContent: "center",
          minHeight: "500px",
          alignItems: "center",
        }}
      />
    );
  }

  return (
    <div className="add_form_height_95">
      <AddPageLayout
        dataId={postId}
        addForm={isAddForm}
        showFooter={!isAddForm}
        isFieldEdited={isFieldEdited}
        footerInfo={footerInfo}
        recordText={"Post ID"}
        saveCurrentData={saveCurrentData}
        onDeleteClick={onDeleteClick}>
        <div style={{ width: "100%" }}>
          <Form
            render={() => (
              <GridLayout
                rows={[
                  { height: 70 },
                  { height: 70 },
                  { height: 70 },
                  { height: 130 },
                  { height: 30 },
                ]}
                cols={[{ width: 375 }, { width: 375 }, { width: 375 }]}
                gap={{ rows: 30, cols: 35 }}
                style={{ padding: "16px" }}>
                <GridLayoutItem col={1} row={1} className="form-inner">
                  {console.log("isAddForm", isAddForm)}
                  <UserSearchInput
                    value={post?.userName}
                    setSelectedUser={({ label, id }) => {
                      setIsFiledEdited(true);
                      setPost({
                        ...post,
                        userName: label,
                        userId: id,
                      });
                    }}
                    disabled={!isAddForm || userId}
                    userId={userId}
                  />
                </GridLayoutItem>
                <GridLayoutItem col={2} row={1} className="form-inner">
                  <AppSearchInput
                    value={post?.appName}
                    setSelectedApp={({ label, id }) => {
                      setIsFiledEdited(true);
                      setPost({
                        ...post,
                        appName: label,
                        appId: id,
                      });
                    }}
                  />
                </GridLayoutItem>

                <GridLayoutItem
                  col={3}
                  row={1}
                  className="form-inner"
                  style={{ width: "370px" }}>
                  <div className="form-group">
                    <Label className="form-heading">Format Type Id :</Label>
                    <Input
                      id="formatTypeId"
                      name="formatTypeId"
                      value={post?.formatTypeId}
                      className="field-ip"
                      onChange={(e) => onNumberInputChange(e)}
                    />
                  </div>
                </GridLayoutItem>
                <GridLayoutItem
                  col={1}
                  row={2}
                  className="form-inner"
                  style={{ width: "370px" }}>
                  <div className="form-group">
                    <Label className="form-heading">App Record Id :</Label>
                    <Input
                      id="appRecordId"
                      name="appRecordId"
                      value={post?.appRecordId}
                      className="field-ip"
                      onChange={(e) => onNumberInputChange(e)}
                    />
                  </div>
                </GridLayoutItem>
                <GridLayoutItem
                  col={2}
                  row={2}
                  className="form-inner"
                  style={{ width: "370px" }}>
                  <div className="form-group">
                    <Label className="form-heading">Message :</Label>
                    <TextArea
                      type="text"
                      id="message"
                      name="message"
                      value={post?.message}
                      className="field-ip"
                      onChange={(e) => onInputChange(e)}
                      rows={4}
                    />
                  </div>
                </GridLayoutItem>

                {!isAddForm || subMenu ? (
                  <GridLayoutItem col={3} row={2} className="form-inner">
                    <RecordStatusInput
                      recordStatusId={post?.recordStatusId}
                      recordStatus={post?.recordStatus}
                      onChange={({ recordStatus, recordStatusId }) => {
                        setIsFiledEdited(true);
                        setPost((preValue) => ({
                          ...preValue,
                          recordStatus,
                          recordStatusId,
                        }));
                      }}
                    />
                  </GridLayoutItem>
                ) : (
                  <></>
                )}
              </GridLayout>
            )}
          />
        </div>
      </AddPageLayout>
    </div>
  );
};

export default AddNewPost;
