import React, { useEffect, useState } from "react";
import { AutoComplete, DropDownList } from "@progress/kendo-react-dropdowns";
import { useNavigate, useParams } from "react-router-dom";
import {
  DELETE_RESUMES_SHARED_WITH,
  FETCH_RECORD_STATUSES,
  FETCH_RESUMES_SHARED_WITH,
  FetchUsers2,
  SAVE_RESUMES_SHARED_WITH,
} from "../../constant/Apipath";
import { PostRequestCall } from "../../apicall/PostRequest";
import { Input } from "@progress/kendo-react-inputs";
import { useSelector } from "react-redux";
import { Label } from "@progress/kendo-react-labels";
import { GridLayout, GridLayoutItem } from "@progress/kendo-react-layout";
import KendoButton from "../../common/KendoButton";
import { toast } from "react-toastify";
import { Loader } from "@progress/kendo-react-indicators";

const AddNewResumeSharedWith = () => {
  const navigate = useNavigate();

  const { loginData } = useSelector((state) => state.main);
  const { dataId, subMenu, userId } = useParams();

  const [resume, setResume] = useState({
    userName: "",
    userId: 0,
    sharedWithEmail: "",
    recordStatus: "New",
    recordStatusId: 1,
  });
  const [recordStatusOptions, setRecordStatusOptions] = useState([]);
  const [showLoader, setShowLoader] = useState(false);
  const [value, setValue] = useState("");
  const [options, setOptions] = useState([]);



  useEffect(() => {
    if (dataId !== "add-resume-shared-with" || subMenu) {
      loadData();
    }
    if (userId && dataId === "add-resume-shared-with") {
      getUserDetails();
    }
    getRecordStatuses();

  }, []);


  useEffect(() => {
    const fetchData = async () => {
      try {
        const srchList = [{ Name: value }];
        const data = JSON.stringify({
          PageNr: 1,
          NrOfRecPerPage: 100,
          FullSearch: value,
          UserId: loginData.userId,
          SearchList: srchList,
          IncludeRecordNr: true,
        });

        const result = await PostRequestCall(
          FetchUsers2,
          data,
          loginData?.token
        );
        const users = result.data.data || [];

        const userOptions = users.map((user) => ({
          value: user.id,
          label: `${user.firstName} ${user.lastName}`,
        }));

        setOptions(userOptions);
      } catch (error) {
        console.error("Error fetching users:", error);
      }
    };

    if (value.trim() !== "") {
      fetchData();
    } else {
      setOptions([]);
    }
  }, [value]);

  const loadData = async () => {
    if (dataId !== "add-resume-shared-with") {
      var data = JSON.stringify({
        PageNr: 1,
        NrOfRecPerPage: 20,
        FullSearch: "",
        UserId: loginData.userId,
        SearchList: [
          {
            Id: dataId,
          },
        ],
      });

      const result = await PostRequestCall(
        FETCH_RESUMES_SHARED_WITH,
        data,
        loginData?.token
      );

      const resulData = result?.data?.data?.[0] ?? null;

      if (resulData) {
        setResume((prevData) => ({
          ...prevData,
          userName: resulData?.userName,
          userId: resulData?.userId,
          sharedWithEmail: resulData?.sharedWithEmail,
          recordStatus: resulData?.recordStatus,
          recordStatusId: resulData?.recordStatusId,
        }));
      }
    }
  };

  const getUserDetails = async () => {
    try {
      const data = JSON.stringify({
        PageNr: 1,
        NrOfRecPerPage: 100,
        FullSearch: "",
        UserId: loginData.userId,
        SearchList: [{ id: userId }],
        IncludeRecordNr: true,
      });

      const result = await PostRequestCall(FetchUsers2, data, loginData?.token);
      setResume((prevData) => ({
        ...prevData,
        userName: result?.data?.data?.[0]?.name,
        userId,
      }));
    } catch (error) {
      console.log("Error while getting user details :: ", error);
    }
  };

  const getRecordStatuses = async () => {
    try {
      let recordStatusesObj = {
        PageNr: 1,
        NrOfRecPerPage: 100,
        FullSearch: "",
        UserId: "1",
        SortList: [
          {
            FieldName: "Id",
            Direction: "ASC",
          },
        ],
        IncludeRecordNr: true,
      };
      const recordStatusesRes = await PostRequestCall(
        FETCH_RECORD_STATUSES,
        recordStatusesObj,
        loginData?.token
      );
      let recordStatusesData = recordStatusesRes?.data?.data?.map((record) => ({
        id: record?.id,
        text: record?.name,
      }));
      setRecordStatusOptions(recordStatusesData ?? []);
    } catch (error) {
      console.log("Error while getting record statuses data :: ", error);
    }
  };

  const onInputChange = (e) => {
    setResume({ ...resume, [e.target.name]: e.target.value });
  };

  const handleChange = (event) => {
    setValue(event.target.value);
  };

  const onCancelClick = () => {
    window.history.back();
  };

  const onSubmit = async (e) => {
    e.preventDefault();
    const firstOptionValue = options.length > 0 ? options[0].value : null;

    if (resume?.userId === 0) {
      if (!firstOptionValue) {
        return toast.error("Please select a user", {
          position: "top-right",
        });
      }
    }

    if (resume.sharedWithEmail === "" || !/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(resume.sharedWithEmail)) {
      return toast.error("Please enter valid email addresses", {
        position: "top-right",
      });
    }


    const saveObj = {
      ReturnRecordId: false,
      ReturnRecordError: true,
      UserId: loginData.userId,
      SaveList: [
        {
          Id: dataId === "add-resume-shared-with" ? 0 : dataId,
          UserId: resume.userId || firstOptionValue,
          RecordStatusId: resume?.recordStatusId || 1,
          SharedWithEmail: resume.sharedWithEmail,
          ModifiedBy: loginData.userId,
        },
      ],
    };
    try {
      setShowLoader(true);
      const result = await PostRequestCall(
        SAVE_RESUMES_SHARED_WITH,
        saveObj,
        loginData?.token
      );
      if (result.data.status === false) {
        setShowLoader(false);
        toast.error(
          result?.data?.data?.[0].message ??
          "Something went wrong. Please try again later.",
          {
            position: "top-right",
          }
        );
      } else {
        setShowLoader(false);
        toast.success(`Data ${dataId !== "add-resume-shared-with" ? "updated" : "added"} successfully`, {
          position: "top-right",
        });
        window.history.back();
      }
    } catch (error) {
      setShowLoader(false);
      toast.error("Something went wrong. Please try again later.", {
        position: "top-right",
      });
      console.error("Error saving email:", error);
    }
  };

  const onDeleteClick = async (e) => {
    try {
      e.stopPropagation();
      if (window.confirm("Are you sure you wish to delete this record?")) {
        setShowLoader(true);
        const deleteData = [
          {
            Id: Number(dataId),
            ModifiedBy: loginData?.userId,
          },
        ];

        const deleteObj = {
          ReturnRecordError: true,
          ReturnRecordId: false,
          UserId: loginData?.userId,
          DeleteList: deleteData,
        };
        return PostRequestCall(DELETE_RESUMES_SHARED_WITH, deleteObj, loginData?.token)
          .then((res) => {
            setShowLoader(false);
            if (res?.status === 201) {
              toast.success("Data deleted successfully", {
                position: "top-right",
              });
              window.history.back();
            } else {
              toast.error(
                res?.data?.message ??
                "Something went wrong. Please try again later.",
                {
                  position: "top-right",
                }
              );
            }
          })
          .catch((error) => {
            setShowLoader(false);
            toast.error("Something went wrong. Please try again later.", {
              position: "top-right",
            });
            console.log("Error while deleting a record :: ", error);
          });
      }
    } catch (error) {
      setShowLoader(false);
      toast.error("Something went wrong. Please try again later.", {
        position: "top-right",
      });
      console.log("Error while deleting a record :: ", error);
    }
  };

  if (showLoader) {
    return (
      <Loader
        type="converging-spinner"
        className="kendo-spinner"
        style={{
          display: "flex",
          justifyContent: "center",
          minHeight: "500px",
          alignItems: "center",
        }}
      />
    );
  }

  return (
    <section className="add-user">
      <section className="user-information">
        <GridLayout
          rows={[
            {
              height: 70,
            },
            {
              height: 70,
            },
            {
              height: 70,
            },
            {
              height: 70,
            },
            {
              height: 70,
            },
            {
              height: 70,
            },
            {
              height: 70,
            },
            {
              // height: 20,
            },
          ]}
          cols={[
            {
              width: 375,
            },
            {
              width: 375,
            },
            {
              width: 375,
            },
          ]}
          gap={{
            rows: 25,
            cols: 35,
          }}
          className="layout"
          style={{ padding: "25px 16px", gap: "0px 35px" }}
        >
          <GridLayoutItem col={1} row={1} className="form-inner">
            <label className="form-heading">User :</label>
            <div className="form-control">
              {dataId !== "add-resume-shared-with" || subMenu ? (
                <AutoComplete
                  data={options}
                  className="field-ip"
                  value={resume?.userName}
                  textField="label"
                  placeholder="Choose User..."
                  disabled
                />
              ) : (
                <AutoComplete
                  data={options}
                  value={value}
                  className="field-ip"
                  onChange={handleChange}
                  textField="label"
                  placeholder="Search for users..."
                />
              )}
            </div>
          </GridLayoutItem>

          <GridLayoutItem col={2} row={1} className="form-inner">
            <label className="form-heading">Shared With Email :</label>
            <div className="form-control">
              <Input
                type="text"
                id="sharedWithEmail"
                name="sharedWithEmail"
                value={resume?.sharedWithEmail}
                className="field-ip"
                onChange={(e) => onInputChange(e)}
              />
            </div>
          </GridLayoutItem>
          {dataId !== "add-resume-shared-with" || subMenu ? (
            <GridLayoutItem col={3} row={1} className="form-inner">
              <div className="form-group">
                <Label className="form-heading">Record Status :</Label>
                <div>
                  <DropDownList
                    style={{ width: "370px" }}
                    onChange={(e) => {
                      setResume((preValue) => ({
                        ...preValue,
                        recordStatus: e.value?.text,
                        recordStatusId: e.value?.id,
                      }));
                    }}
                    className="dropdown-list"
                    value={{
                      id: resume?.recordStatusId,
                      text: resume?.recordStatus,
                    }}
                    data={recordStatusOptions}
                    textField="text"
                    dataItemKey="id"
                  />
                </div>
              </div>
            </GridLayoutItem>
          ) : (
            <></>
          )}

          <GridLayoutItem col={3} row={4} className="form-inner">
            <div
              className="k-d-flex k-justify-content-end"
              style={{ gap: "10px" }}>
              <KendoButton
                iconClass=""
                size={16}
                onClick={onSubmit}
                className="action-btn"
                buttonName="Save"
              />
              {dataId !== "add-resume-shared-with" && (
                <KendoButton
                  size={16}
                  className="action-btn delete-btn"
                  onClick={onDeleteClick}
                  buttonName="Delete"
                />
              )}

              <KendoButton
                iconClass=""
                themeColor=""
                size={16}
                className=""
                buttonName="Cancel"
                onClick={onCancelClick}
              />
            </div>
          </GridLayoutItem>
        </GridLayout>
      </section>
    </section>
  );
};

export default AddNewResumeSharedWith;

