import React from "react";
import { Outlet, useNavigate, useParams } from "react-router-dom";

const ClientDetailsMainLayout = (props) => {
    const { clientId } = useParams();
    const navigate = useNavigate();

    const pathname = window.location.pathname;
    const pathArray = pathname.split("/");

    let menuList = [
        { title: "Edit record", url: "edit-record" },
        { title: "Client Ads", url: "client-ads" },
        { title: "Client Devices", url: "client-devices" },
    ];

    return (
        <>
            <section className="add-user" style={{ height: "92%" }}>
                <section className="table-list userauth-submenu">
                    <div className="menu-list-2 usersub-menu">
                        {Number(clientId) !== 0 ? (
                            <>
                                <ul className="second-list">
                                    {menuList?.map((item, index) => (
                                        <li
                                            className="menu"
                                            key={index}
                                            onClick={() => {
                                                navigate(item?.url);
                                            }}>
                                            <div
                                                style={{ cursor: "pointer" }}
                                                className={`k-d-flex k-align-items-center k-flex-row menu ${item?.url === pathArray?.[5] ? "active" : ""
                                                    }`}>
                                                <span className="item-text">{item?.title}</span>
                                            </div>
                                        </li>
                                    ))}
                                </ul>
                            </>
                        ) : (
                            <></>
                        )}

                    </div>
                </section>
                <Outlet />
            </section>
        </>
    );
};

export default ClientDetailsMainLayout;
