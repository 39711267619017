import React from "react";
import { useParams } from "react-router-dom";
import UserEmail from "../../users/user-emails/UserEmail";
import ContactEvents from "../../users/contact-events/ContactEvents";
import UserPhone from "../../users/user-phones/UserPhone";
import UserDevices from "../../users/user-devices/UserDevices";
import UserCompanies from "../../users/user-companies/UserCompanies";
import Certification from "../../users/certification/Certification";
import UserUsages from "../../home/user-usages/UserUsages";
import DataIssues from "../../home/data-issues/DataIssues";
import UserTrades from "../../users/user-trades/UserTrades";
import UserQuestions from "../../users/user-questions/UserQuestions";
import UserAnswers from "../../users/user-answers/UserAnswers";
import ResumeSharedWith from "../../users/resume-shared-with/ResumeSharedWith";
import UserRolesAuth from "./UserRolesAuth";
import SharedTickets from "../../ticket/sharedtickets/SharedTickets";
import UserPurchase from "../../rewards/userpurchases/UserPurchase";
import UserRewardActivities from "../../users/user-reward-Activities/UserRewardActivities";
import UserWorkHistory from "../../experience/UserWorkHistory";
import PostComments from "../../users/post-comments/PostComments";
import Post from "../../users/posts/Post";
import Sites from "../../sites/sites/Sites";
import UserSitesProjects from "../../users/user-sites-projects/UserSitesProjects";
import Ticket from "../../ticket/ticket/Ticket";

const UserTableDetailsLayout = () => {
  const { subMenu } = useParams();

  const renderTableListBasedOnUrl = () => {
    let ui = <></>;
    switch (subMenu) {
      case "user-emails":
        ui = (
          <>
            {" "}
            <UserEmail />{" "}
          </>
        );
        break;
      case "contact-events":
        ui = (
          <>
            {" "}
            <ContactEvents />{" "}
          </>
        );
        break;
      case "certification":
        ui = (
          <>
            {" "}
            <Certification />{" "}
          </>
        );
        break;
      case "user-phones":
        ui = (
          <>
            {" "}
            <UserPhone />{" "}
          </>
        );
        break;
      case "user-usage":
        ui = (
          <>
            {" "}
            <UserUsages />{" "}
          </>
        );
        break;
      case "user-experience":
        ui = <> userExperience </>;
        break;
      case "user-addresses":
        ui = <> userAddresses </>;
        break;
      case "user-tickets":
        ui = (
          <>
            {" "}
            <Ticket />{" "}
          </>
        );
        break;
      case "user-quizzes":
        ui = (
          <>
            {" "}
            <UserQuestions />{" "}
          </>
        );
        break;
      case "user-answers":
        ui = (
          <>
            {" "}
            <UserAnswers />{" "}
          </>
        );
        break;
      case "user-support":
        ui = (
          <>
            {" "}
            <DataIssues />{" "}
          </>
        );
        break;
      case "user-trades":
        ui = (
          <>
            {" "}
            <UserTrades />{" "}
          </>
        );
        break;
      case "user-companies":
        ui = (
          <>
            {" "}
            <UserCompanies />{" "}
          </>
        );
        break;
      case "user-purchase":
        ui = (
          <>
            {" "}
            <UserPurchase />{" "}
          </>
        );
        break;
      case "ticket-shared-with":
        ui = (
          <>
            {" "}
            <SharedTickets />{" "}
          </>
        );
        break;
      case "resume-shared-with":
        ui = (
          <>
            {" "}
            <ResumeSharedWith />{" "}
          </>
        );
        break;
      case "user-rewards":
        ui = <> userRewards </>;
        break;
      case "user-roles-auth":
        ui = (
          <>
            {" "}
            <UserRolesAuth />{" "}
          </>
        );
        break;
      case "user-devices":
        ui = (
          <>
            {" "}
            <UserDevices />{" "}
          </>
        );
        break;
      case "user-reward-activity":
        ui = (
          <>
            {" "}
            <UserRewardActivities />{" "}
          </>
        );
        break;

      case "user-work-history":
        ui = (
          <>
            {" "}
            <UserWorkHistory />{" "}
          </>
        );
        break;
      case "user-post-comments":
        ui = (
          <>
            {" "}
            <PostComments />{" "}
          </>
        );
        break;
      case "user-post":
        ui = (
          <>
            {" "}
            <Post />{" "}
          </>
        );
        break;

      case "user-sites":
        ui = (
          <>
            {" "}
            <Sites />{" "}
          </>
        );
        break;

      case "user-site-projects":
        ui = (
          <>
            {" "}
            <UserSitesProjects />{" "}
          </>
        );
        break;

      default:
        ui = <></>;
        break;
    }

    return ui;
  };
  return <>{renderTableListBasedOnUrl()}</>;
};

export default UserTableDetailsLayout;
